/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export const STATE_KEY = 'JOURNEY_BANNER';

const SET_JOURNEY_BANNER_VISIBILITY = `${STATE_KEY}/SET_VISIBILITY`;

export const JourneyBannerActionTypes = {
  SET_JOURNEY_BANNER_VISIBILITY,
};

export const JourneyBannerActions = {
  setBannerVisibility: (isVisible: boolean) => ({
    type: SET_JOURNEY_BANNER_VISIBILITY,
    payload: { isVisible },
  }),
};
