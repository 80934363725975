/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useContext } from 'react';
import styled from 'styled-components';
import { GridBoxProps } from './GridTypings';
import { GridContext } from './constants';

const GridBox: React.FC<GridBoxProps> = ({
  children,
  mobile,
  tablet,
  desktop,
  className,
}) => {
  const {
    colNumber,
    mobileGutterH,
    tabletGutterH,
    desktopGutterH,
    mobileGutterV,
    tabletGutterV,
    desktopGutterV,
  } = useContext(GridContext);

  const mobilePercentage: number =
    ((typeof mobile === 'number' ? mobile : mobile.size) / colNumber) * 100;
  const mobileOffsetPercentage: number =
    typeof mobile === 'number' ? 0 : (mobile.offset / colNumber) * 100;

  let tabletPercentage: number;
  let tabletOffsetPercentage: number;
  if (tablet === undefined) {
    tabletPercentage = mobilePercentage;
    tabletOffsetPercentage = mobileOffsetPercentage;
  } else if (typeof tablet === 'number') {
    tabletPercentage = (tablet / colNumber) * 100;
    tabletOffsetPercentage = 0;
  } else {
    tabletPercentage = (tablet.size / colNumber) * 100;
    tabletOffsetPercentage = (tablet.offset / colNumber) * 100;
  }

  let desktopPercentage: number;
  let desktopOffsetPercentage: number;
  if (desktop === undefined) {
    desktopPercentage = tabletPercentage;
    desktopOffsetPercentage = tabletOffsetPercentage;
  } else if (typeof desktop === 'number') {
    desktopPercentage = (desktop / colNumber) * 100;
    desktopOffsetPercentage = 0;
  } else {
    desktopPercentage = (desktop.size / colNumber) * 100;
    desktopOffsetPercentage = (desktop.offset / colNumber) * 100;
  }

  return (
    <StyledGridBox
      mobile={mobilePercentage}
      tablet={tabletPercentage}
      desktop={desktopPercentage}
      mobileOffset={mobileOffsetPercentage}
      tabletOffset={tabletOffsetPercentage}
      desktopOffset={desktopOffsetPercentage}
      mobileGutterH={mobileGutterH}
      tabletGutterH={tabletGutterH}
      desktopGutterH={desktopGutterH}
      mobileGutterV={mobileGutterV}
      tabletGutterV={tabletGutterV}
      desktopGutterV={desktopGutterV}
      className={className}
    >
      {children}
    </StyledGridBox>
  );
};

const StyledGridBox = styled.div<{
  mobile: number;
  tablet: number;
  desktop: number;
  mobileOffset: number;
  tabletOffset: number;
  desktopOffset: number;
  mobileGutterH: number;
  tabletGutterH: number;
  desktopGutterH: number;
  mobileGutterV: number;
  tabletGutterV: number;
  desktopGutterV: number;
}>`
  min-width: 0;

  flex-basis: ${(props) => props.mobile}%;
  margin-left: ${(props) => props.mobileOffset}%;
  margin-top: ${(props) => props.mobileGutterV}px;
  padding: 0 ${(props) => props.mobileGutterH / 2}px;

  ${(props) => props.theme.media.tablet} {
    flex-basis: ${(props) => props.tablet}%;
    margin-left: ${(props) => props.tabletOffset}%;
    margin-top: ${(props) => props.tabletGutterV}px;
    padding: 0 ${(props) => props.tabletGutterH / 2}px;
  }

  ${(props) => props.theme.media.desktop} {
    flex-basis: ${(props) => props.desktop}%;
    margin-left: ${(props) => props.desktopOffset}%;
    margin-top: ${(props) => props.desktopGutterV}px;
    padding: 0 ${(props) => props.desktopGutterH / 2}px;
  }
`;

export default React.memo(GridBox);
