/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable react/display-name */
import React from 'react';
import { FormattedMessage, defineMessages } from '@app/components/Intl';

export const LoginMessages = defineMessages({
  apple: {
    id: 'web.login.apple-text',
    description: 'The "Sign in with Apple" text on the Apple login button',
    defaultMessage: 'Sign in with Apple',
  },
  facebook: {
    id: 'web.login.facebook-text',
    description: 'The "Log in with Facebook" text on the Facebook login button',
    defaultMessage: 'Log in with Facebook',
  },
  google: {
    id: 'web.login.google-text',
    description: 'The "Sign in with Google" text on the Google login button',
    defaultMessage: 'Sign in with Google',
  },
  phoneLogin: {
    id: 'web.login.phone-login-text',
    description:
      'The text on the Phone login button when the registration with phone is disabled',
    defaultMessage: 'Existing account with Phone?',
  },
  phone: {
    id: 'web.login.phone-text',
    description: 'The "Sign in with Phone" text on the Phone login button',
    defaultMessage: 'Sign in with Phone',
  },
  phoneNumber: {
    id: 'web.login.phone-number-text',
    description:
      'The "Phone Number" text on the phone number input field in the phone login flow',
    defaultMessage: 'Phone Number',
  },
  pinCode: {
    id: 'web.login.pin-code-text',
    description: 'The text on the pin code input field in the phone login flow',
    defaultMessage: 'Enter code',
  },
  email: {
    id: 'web.login.email-text',
    description: 'The "Sign in with Email" text on the Email login button',
    defaultMessage: 'Sign in with Email',
  },
  emailInput: {
    id: 'web.login.email-input',
    description: 'The "Email" text on the email inputs',
    defaultMessage: 'Email',
  },
  password: {
    id: 'web.login.password-input',
    description: 'The "Password" text in the password inputs',
    defaultMessage: 'Password',
  },
  newPassword: {
    id: 'web.login.new-password-input',
    description: 'The "Enter new password" text in the new password input',
    defaultMessage: 'Enter new password',
  },
  newPasswordConfirm: {
    id: 'web.login.new-password-confirm-input',
    description:
      'The "Retype new password" text in the new password confirm input',
    defaultMessage: 'Retype new password',
  },
  chooseUsername: {
    id: 'web.login.choose-username-input',
    description: 'The "Choose a Username" text in the username input',
    defaultMessage: 'Choose a Username',
  },
});

export const FormattedLoginMessages = {
  LogIn: ({ children }) => (
    <FormattedMessage
      id="web.login.login-header.text"
      description='The "Log In" text on the top and sidebars'
      defaultMessage="Log In"
    >
      {(label) => children(label)}
    </FormattedMessage>
  ),
  profileLink: (
    <FormattedMessage
      id="web.login.profile-link"
      description='The "Profile" link text in the top bar'
      defaultMessage="Profile"
    />
  ),
  NotificationsLink: ({ children }) => (
    <FormattedMessage
      id="web.login.notifications-link"
      description='The "Notifications" link text in the sidebar'
      defaultMessage="Notifications"
    >
      {(label) => children(label)}
    </FormattedMessage>
  ),
  SettingsLink: ({ children }) => (
    <FormattedMessage
      id="web.login.settings-link"
      description='The "Settings" link text in the top and sidebars'
      defaultMessage="Settings"
    >
      {(label) => children(label)}
    </FormattedMessage>
  ),
  LogOut: ({ children }) => (
    <FormattedMessage
      id="web.login.logout-header-text"
      description='The "Log Out" text in the top and sidebars'
      defaultMessage="Log Out"
    >
      {(label) => children(label)}
    </FormattedMessage>
  ),
  setPasswordHeader: (
    <FormattedMessage
      id="web.login.set-password-flow.header"
      description="The text for the header element for the reset password login flow"
      defaultMessage="New Password"
    />
  ),
  setPasswordText: (
    <FormattedMessage
      id="web.login.set-password-flow.text"
      description="The main message for the reset password login flow"
      defaultMessage="Please choose a new password"
    />
  ),
  setPasswordSuccessText: (
    <FormattedMessage
      id="web.login.set-password-success-flow.text"
      description="The message displayed when the password has been successfully changed"
      defaultMessage="Your password has been successfully updated."
    />
  ),
  passwordFormatHint: (
    <FormattedMessage
      id="web.login.errors.password-format-hint"
      description="An informative message under the password field used
                to notify the user about the sign up password format"
      defaultMessage="Use 8 characters or more with a mix of letters, numbers & symbols"
    />
  ),
  signUpButton: (
    <FormattedMessage
      id="web.login.email-flow.sign-up-button"
      description="Text for the Sign Up button in the email login flow"
      defaultMessage="Sign Up"
    />
  ),
  cancelButton: (
    <FormattedMessage
      id="web.login.cancel-button"
      description="Text for the 'Cancel' button to back out of email login and reset password flows"
      defaultMessage="Cancel"
    />
  ),
  doneButton: (
    <FormattedMessage
      id="web.login.done-button"
      description="Text for 'Done' button when completing password reset, setting username."
      defaultMessage="Done"
    />
  ),
  activationDoneButton: (
    <FormattedMessage
      id="web.login.activation-sent-done-button"
      description="Text for 'Done' button when confirming email activation sent"
      defaultMessage="Done"
    />
  ),
  cookiesDisabled: (
    <FormattedMessage
      id="web.login.cookies-disabled-header"
      description="Text for the cookies disabled header"
      defaultMessage="Oh no! It looks like your cookies are disabled. Please enable them to sign in."
    />
  ),
  AccountLink: ({ children }) => (
    <FormattedMessage
      id="web.login.account-link"
      description="Text shown for links to the Account page"
      defaultMessage="Account"
    >
      {(label) => children(label)}
    </FormattedMessage>
  ),
  activationSuccess: () => (
    <FormattedMessage
      id="web.login.activation-success"
      description="Text shown on Activation Success flow"
      defaultMessage="Your account has been verified. Thank you."
    />
  ),
  continueButton: () => (
    <FormattedMessage
      id="web.login.continue-button"
      description="Text shown on login continue button"
      defaultMessage="Continue"
    />
  ),
  enterPhoneNumber: (
    <FormattedMessage
      id="web.login.phone.enter-phone"
      description="The text used in the phone authentication screen"
      defaultMessage="Enter your phone number"
    />
  ),
  enterPhoneLoginNumber: (
    <FormattedMessage
      id="web.login.phone.enter-phone-login"
      description="The text used as a heading when the phone registration is disabled"
      defaultMessage="Please enter the phone number you used to register with Smule.
      We will send you a text message to verify it."
    />
  ),
  submitPhoneButton: (
    <FormattedMessage
      id="web.login.phone.next-button"
      description="Text for the Next button in the phone login flow"
      defaultMessage="Next"
    />
  ),
  enterPinCode: (
    <FormattedMessage
      id="web.login.phone.enter-code"
      description="The text used in the phone code submission screen"
      defaultMessage="Enter the code we sent to"
    />
  ),
  verifyPinButton: (
    <FormattedMessage
      id="web.login.phone.enter-pin-code"
      description="The label of the button used in the phone code submission screen"
      defaultMessage="Verify"
    />
  ),
};

export const FormattedLoginErrors = {
  invalidEmailFormat: (
    <FormattedMessage
      id="web.login.errors.invalid-email-format"
      description="The error message shown when the email has an invalid format"
      defaultMessage="Email format is not valid."
    />
  ),
  emailTooShort: (
    <FormattedMessage
      id="web.login.errors.email-too-short"
      description="The error message shown when the email is too short"
      defaultMessage="Email is too short."
    />
  ),
  emailTooLong: (
    <FormattedMessage
      id="web.login.errors.email-too-long"
      description="The error message shown when the email is too long"
      defaultMessage="Email is too long."
    />
  ),
  emailTaken: (
    <FormattedMessage
      id="web.login.errors.email-taken"
      description="The error message shown when the email is taken"
      defaultMessage="Email is taken."
    />
  ),
  emailNotFound: (
    <FormattedMessage
      id="web.login.errors.email-not-found"
      description="The error message shown when no Smule account is found for the email address entered"
      defaultMessage="No account with this email. Sign up with this email?
                  You will receive a link to complete the registration."
    />
  ),
  invalidPassword: (
    <FormattedMessage
      id="web.login.errors.invalid-password"
      description="The error message when a user tries to log in with an invalid password"
      defaultMessage="Password Invalid."
    />
  ),
  passwordTooShort: (
    <FormattedMessage
      id="web.login.errors.password-too-short"
      description="The error message when a user tries to sign up with a password that is too short"
      defaultMessage="Use 8 characters or more for your password"
    />
  ),
  passwordTooLong: (
    <FormattedMessage
      id="web.login.errors.password-too-long"
      description="The error message when a user tries to sign up with a password that is too long"
      defaultMessage="Password length exceeds maximum limit"
    />
  ),
  passwordTooWeak: (
    <FormattedMessage
      id="web.login.errors.password-too-weak"
      description="The error message when a user tries to sign up with a password that is too weak"
      defaultMessage="Please choose a stronger password. Try a mix of numbers and symbols"
    />
  ),
  passwordDoesNotMatch: (
    <FormattedMessage
      id="web.login.errors.password-does-not-match"
      description="The error message when a user tries to confirm their new password, but the two fields don't match"
      defaultMessage="Password does not match."
    />
  ),
  invalidUsername: (
    <FormattedMessage
      id="web.login.errors.invalid-username"
      description="The error message when a the username has an invalid format"
      defaultMessage="Username format is not valid."
    />
  ),
  usernameTooShort: (
    <FormattedMessage
      id="web.login.errors.username-too-short"
      description="The error message when a the username is too short"
      defaultMessage="Username is too short."
    />
  ),
  usernameTooLong: (
    <FormattedMessage
      id="web.login.errors.username-too-long"
      description="The error message when a the username is too long"
      defaultMessage="Username is too long."
    />
  ),
  usernameTaken: (
    <FormattedMessage
      id="web.login.errors.username-taken"
      description="The error message when a the username is taken"
      defaultMessage="Username is taken."
    />
  ),
  handleTaken: (
    <FormattedMessage
      id="web.login.errors.handle-taken"
      description="The error message when a the handle is taken"
      defaultMessage="Handle is taken."
    />
  ),
  handleUpdateFailed: (
    <FormattedMessage
      id="web.login.errors.handle-update-failed"
      description="The error message when updating the user's handle fails"
      defaultMessage="Handle update failed."
    />
  ),
  accountDisabled: (
    <FormattedMessage
      id="web.login.errors.account-disabled"
      description="The error message when a user's account has been disabled"
      defaultMessage="Your account is currently disabled. Please email community@smule.com for details."
    />
  ),
  // TODO: Figure out how to format the unexpected error within the login code
  unexpectedError: (
    <FormattedMessage
      id="web.login.errors.unexpected-error"
      description="The error message shown when an unexpected error occurs"
      defaultMessage="Unexpected error occurred. Please reload the page and try again"
    />
  ),
  // TODO: Figure out how this is used
  cannotCreateAccount: (
    <FormattedMessage
      id="web.login.errors.cannot-create-account"
      description="The error shown when user can't create account through Facebook"
      defaultMessage="Cannot create an account"
    />
  ),
  facebookLoginError: (
    <FormattedMessage
      id="web.login.errors.facebook-login"
      description="The error shown when user can't sign in to Facebook"
      defaultMessage="Sorry, we were unable to sign in with Facebook."
    />
  ),
  activationSentFail: (
    <FormattedMessage
      id="web.login.errors.activation-sent-fail"
      description="The error message when activation email fails to send"
      defaultMessage="Failed to send activation. Please reload the page and try again"
    />
  ),
  phoneWrongPinError: (
    <FormattedMessage
      id="web.login.errors.phone-wrong-pin-error"
      description="Entered pin did not verify successfully"
      defaultMessage="Invalid code"
    />
  ),
  phoneTtlExpiredError: (
    <FormattedMessage
      id="web.login.errors.phone-ttl-expired-error"
      /* eslint-disable-next-line max-len */
      description="Another pin was reissued to user to the 60min window has passed; the user needs to request a new PIN."
      defaultMessage="This code has expired"
    />
  ),
  phoneMaxAttemptsError: (
    <FormattedMessage
      id="web.login.errors.phone-max-attempts-error"
      description="The user has entered too many invalid/expired pins"
      defaultMessage="You've reached the max number of code entry attempts"
    />
  ),
  phoneSmsNotSendError: (
    <FormattedMessage
      id="web.login.errors.phone-sms-not-send-error"
      description="Infobip cannot send the SMS to the supplied phone number"
      defaultMessage="Phone number cannot be reached"
    />
  ),
  phoneUserNotFoundError: (
    <FormattedMessage
      id="web.login.errors.phone-user-not-found-error"
      description="There is no registered user with the entered phone number"
      defaultMessage="Can't find an account associated with this phone number."
    />
  ),
  phoneGenericError: (
    <FormattedMessage
      id="web.login.errors.phone-generic-error"
      description="Generic error message for all other scenarios"
      defaultMessage="Something went wrong"
    />
  ),
};
