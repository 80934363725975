/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@app/store/configureStore';
import { noop } from '@app/utils/utils';
import { Selectors, FLOW_ID } from './reducer';
import * as AsyncActions from './async-actions';
import usePrevious from './usePrevious';

const useRequireLogin = (
  launchOrigin,
  initialClick = noop,
  click = noop,
  cancel = noop
) => {
  const dispatch = useAppDispatch();
  const modalVisible = useSelector((state) =>
    Selectors.getModalVisibility(state)
  );
  const modalInAnimation = useSelector((state) =>
    Selectors.isModalInAnimation(state)
  );
  const isLoggedIn = useSelector((state) => Selectors.isLoggedIn(state));
  const showLoginFlow = () =>
    dispatch(
      AsyncActions.launchFlow(FLOW_ID.LOGIN, {
        interactive: true,
        launchOrigin,
      })
    );
  const [active, setActive] = useState(false);

  const prevModalInAnimation = usePrevious(modalInAnimation);

  const needAuth = (event) => {
    if (initialClick) initialClick();
    if (!isLoggedIn) {
      event.preventDefault();
      event.stopPropagation();
      showLoginFlow();
      setActive(true);
    } else {
      click();
    }
  };

  useEffect(() => {
    if (active && !modalVisible && prevModalInAnimation && !modalInAnimation) {
      if (isLoggedIn && click) {
        click();
      } else if (cancel) {
        cancel();
      }
      setActive(false);
    }
  }, [active, modalVisible, modalInAnimation, isLoggedIn]);

  return [needAuth];
};

export default useRequireLogin;
