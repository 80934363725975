/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import {
  DenormalizedCommentRuby,
  City,
  DenormalizedPerformer,
  DenormalizedLove as DenormalizedLoveRuby,
  VerifiedType,
  RecordingFormType,
} from '@app/WebConfig/types';
import { AccountIcon } from '@app/pages/AwesomePlaylists/types';

export enum InlineOverlayType {
  LOVES = 'LOVES',
  SEND_GIFT = 'SEND_GIFT',
  RECEIVED_GIFTS = 'RECEIVED_GIFTS',
  COMMENTS = 'COMMENTS',
  SHARING = 'SHARING',
  VIEW_GIFTS = 'VIEW_GIFTS',
  SINGERS_LIST = 'SINGERS_LIST',
  INVITE_FOLLOWERS = 'INVITE_FOLLOWERS',
}

export enum ModalOverlayType {
  DELETE = 'DELETE',
  REPORT_ABUSE = 'REPORT_ABUSE',
  REPORT_INFRINGEMENT = 'REPORT_INFRINGEMENT',
}

export interface PerformanceStats {
  totalPerformers: number;
  truncatedOtherPerformers: string;
  totalListens: number;
  totalLoves: number;
  totalComments: number;
  totalCommenters: number;
  totalGifts: number;
}

interface PerformanceBase {
  key: string;
  type: string;
  title: string;
  artist: string;
  message: string;
  createdAt: string;
  expireAt: null | string | number;
  seed: boolean | number | string;
  closed: boolean;
  ensembleType: string;
  childCount: number;
  appUid: string;
  arrKey: string;
  songId: string;
  songLength: number;
  arrType: string;
  perfStatus: string;
  artistTwitter: null | string;
  coverUrl: string;
  webUrl: string | undefined;
  songInfoUrl: null | string;
  mediaUrls: {
    video: null | string;
    videoMp4: null | string;
    audio: null | string;
    visualizer: null | string;
  };
  stats: PerformanceStats;
  isPrivate: boolean;
  isLoved: boolean;
  isLovedChecked: boolean;
  lovesLoaded: boolean;
  isFavorite: boolean;
  isFavoriteChecked: boolean;
  commentsLoaded: boolean;
  commentsNextOffset: number;
  canDelete: boolean;
  performedBy: string;
  poi: number;
  recId: null | string;
  formType: RecordingFormType;
  videoResolution: string | null;
}

export interface DPerformance extends PerformanceBase {
  owner: DenormalizedPerformer;
  loves: Array<DenormalizedLoveRuby>;
  comments: Array<DenormalizedCommentRuby>;
  otherPerformers: Performer[];
}

export interface NormalizedPerformance extends PerformanceBase {
  loves: Array<string>;
  comments: Array<string>;
  owner: number;
  otherPerformers: number[];
}

export interface DenormalizedPerformance extends PerformanceBase {
  loves: Array<DenormalizedLove>;
  comments: Array<DenormalizedComment>;
  owner: Performer;
  otherPerformers: Performer[];
}

export interface DenormalizedLove {
  key: string;
  givenBy: Performer;
}
export interface Performer {
  accountId: number;
  firstName: string;
  lastName: string;
  city?: City;
  handle: string;
  picUrl: string;
  lat: number;
  lon: number;
  url: string;
  isVip: boolean;
  isVerified: boolean;
  isFollowing: boolean;
  isFollowStatusUpdating: boolean;
  isBlocked: boolean;
  verifiedType: VerifiedType;
}

export interface Cursor {
  next: null | string;
  hasNext: boolean;
}

type Cover = {
  tileUrls: Array<string>;
};

export interface PlaylistBase {
  id: string;
  type: string;
  name: string;
  message: string;
  urlName?: string;
  topicId?: string;
  cursor: Cursor;
  offset?: number;
  visibility?: string;
  webUrl?: string;
  cover?: Cover;
  accountIcon?: AccountIcon;
}

export interface NormalizedPlaylist extends PlaylistBase {
  perfList: Array<string>;
}

export interface DenormalizedPlaylist extends PlaylistBase {
  perfList: Array<DenormalizedPerformance>;
}

export type InitializePlaylistSchemaEntities = {
  playlists: {
    [key: string]: NormalizedPlaylist;
  };
};

export type PlaylistSchemaEntities = InitializePlaylistSchemaEntities & {
  performers: { [key: string]: Performer };
  performances: { [key: string]: NormalizedPerformance };
};

interface BaseComment {
  key: string;
  createdAt: string;
  message: string;
  messageRaw: string;
  messageXml: string;
  likeCount: number;
  isLiked: boolean;
  hasCommentersTag: boolean;
}

export interface Comment extends BaseComment {
  postedBy: number;
}

export interface DenormalizedComment extends BaseComment {
  postedBy: Performer;
}

export interface Love {
  key: string;
  givenBy: number;
}
export enum ArrangementTypes {
  ORIGINAL = 'original',
  COVER = 'cover',
}

export interface CampaignsType {
  fb_campaign_id?: string;
}

export type Follower = {
  accountId: number;
  firstName: string;
  lastName: string;
  handle: string;
  picUrl: string;
  url: string;
  isVerified: boolean;
  verifiedType: VerifiedType;
  isVip: boolean;
  jid: string;
};
