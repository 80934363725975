/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable max-len */
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { COLORS } from '@app/constants';

export enum LoaderSkin {
  light = 'light',
  neutral = 'neutral',
  dark = 'dark',
}

export enum LoaderSize {
  small = 'small',
  regular = 'regular',
  big = 'big',
}

interface LoaderProps {
  skin?: LoaderSkin;
  size?: LoaderSize;
}

const LoaderSkinToColor = {
  [LoaderSkin.light]: COLORS.white,
  [LoaderSkin.neutral]: COLORS.silver400,
  [LoaderSkin.dark]: COLORS.purple500,
};

const LoaderSizeToPx = {
  [LoaderSize.small]: 16,
  [LoaderSize.regular]: 24,
  [LoaderSize.big]: 32,
};

const LoaderSizeToAnimationSteps = {
  [LoaderSize.small]: 6,
  [LoaderSize.regular]: 8,
  [LoaderSize.big]: 8,
};

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderWrapper = styled.div<{
  size: LoaderSize;
}>`
  display: flex;
  animation: ${rotateAnimation} 1s
    steps(${(props) => LoaderSizeToAnimationSteps[props.size]}) infinite;
`;

const LoaderSvg = ({ size, skin }) => {
  const sizePx = LoaderSizeToPx[size];
  const color = LoaderSkinToColor[skin];
  switch (size) {
    case LoaderSize.small:
      return (
        <svg
          width={sizePx}
          height={sizePx}
          viewBox={`0 0 ${sizePx} ${sizePx}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.25"
            d="M5.0359 5.13414C5.51419 5.41028 5.67807 6.02187 5.40192 6.50017C5.12578 6.97846 4.51419 7.14233 4.0359 6.86619L1.43782 5.36619C0.95953 5.09005 0.795655 4.47846 1.0718 4.00017C1.34794 3.52187 1.95953 3.358 2.43782 3.63414L5.0359 5.13414Z"
            fill={color}
          />
          <path
            opacity="0.4"
            d="M4.0359 9.1339C4.51419 8.85776 5.12578 9.02163 5.40192 9.49992C5.67807 9.97822 5.51419 10.5898 5.0359 10.8659L2.43782 12.3659C1.95953 12.6421 1.34794 12.4782 1.0718 11.9999C0.795654 11.5216 0.959529 10.91 1.43782 10.6339L4.0359 9.1339Z"
            fill={color}
          />
          <path
            opacity="0.55"
            d="M7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V12Z"
            fill={color}
          />
          <path
            opacity="0.7"
            d="M14.5622 10.6341C15.0405 10.9103 15.2043 11.5219 14.9282 12.0002C14.6521 12.4785 14.0405 12.6423 13.5622 12.3662L10.9641 10.8662C10.4858 10.59 10.3219 9.97846 10.5981 9.50017C10.8742 9.02187 11.4858 8.858 11.9641 9.13414L14.5622 10.6341Z"
            fill={color}
          />
          <path
            opacity="0.85"
            d="M13.5622 3.6339C14.0405 3.35776 14.6521 3.52163 14.9282 3.99992C15.2043 4.47822 15.0405 5.08981 14.5622 5.36595L11.9641 6.86595C11.4858 7.14209 10.8742 6.97822 10.5981 6.49992C10.3219 6.02163 10.4858 5.41004 10.9641 5.1339L13.5622 3.6339Z"
            fill={color}
          />
          <path
            d="M7 1C7 0.447715 7.44772 0 8 0C8.55228 0 9 0.447715 9 1V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V1Z"
            fill={color}
          />
        </svg>
      );
    case LoaderSize.big:
      return (
        <svg
          width={sizePx}
          height={sizePx}
          viewBox={`0 0 ${sizePx} ${sizePx}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M11.7574 8.92901C12.5384 9.71006 12.5384 10.9764 11.7574 11.7574C10.9763 12.5385 9.70998 12.5385 8.92894 11.7574L4.6863 7.5148C3.90525 6.73375 3.90525 5.46742 4.6863 4.68637C5.46734 3.90532 6.73367 3.90532 7.51472 4.68637L11.7574 8.92901Z"
            fill={color}
          />
          <path
            opacity="0.4"
            d="M8 14C9.10457 14 10 14.8954 10 16C10 17.1046 9.10457 18 8 18L2 18C0.89543 18 -4.82823e-08 17.1046 0 16C4.82823e-08 14.8954 0.895431 14 2 14L8 14Z"
            fill={color}
          />
          <path
            opacity="0.5"
            d="M7.51472 27.3136C6.73367 28.0946 5.46734 28.0946 4.6863 27.3136C3.90525 26.5325 3.90525 25.2662 4.6863 24.4852L8.92894 20.2425C9.70998 19.4615 10.9763 19.4615 11.7574 20.2425C12.5384 21.0236 12.5384 22.2899 11.7574 23.071L7.51472 27.3136Z"
            fill={color}
          />
          <path
            opacity="0.6"
            d="M14 24C14 22.8954 14.8954 22 16 22C17.1046 22 18 22.8954 18 24V30C18 31.1046 17.1046 32 16 32C14.8954 32 14 31.1046 14 30V24Z"
            fill={color}
          />
          <path
            opacity="0.7"
            d="M27.3137 24.4852C28.0948 25.2662 28.0948 26.5325 27.3137 27.3136C26.5327 28.0946 25.2663 28.0946 24.4853 27.3136L20.2426 23.071C19.4616 22.2899 19.4616 21.0236 20.2426 20.2425C21.0237 19.4615 22.29 19.4615 23.0711 20.2425L27.3137 24.4852Z"
            fill={color}
          />
          <path
            opacity="0.8"
            d="M30 14C31.1046 14 32 14.8954 32 16C32 17.1046 31.1046 18 30 18L24 18C22.8954 18 22 17.1046 22 16C22 14.8954 22.8954 14 24 14L30 14Z"
            fill={color}
          />
          <path
            opacity="0.9"
            d="M23.0711 11.7574C22.29 12.5385 21.0237 12.5385 20.2426 11.7574C19.4616 10.9764 19.4616 9.71006 20.2426 8.92901L24.4853 4.68637C25.2663 3.90532 26.5327 3.90532 27.3137 4.68637C28.0948 5.46742 28.0948 6.73375 27.3137 7.5148L23.0711 11.7574Z"
            fill={color}
          />
          <path
            d="M14 2C14 0.89543 14.8954 0 16 0C17.1046 0 18 0.895431 18 2V8C18 9.10457 17.1046 10 16 10C14.8954 10 14 9.10457 14 8V2Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg
          width={sizePx}
          height={sizePx}
          viewBox={`0 0 ${sizePx} ${sizePx}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M9.17157 7.75746C9.5621 8.14798 9.5621 8.78115 9.17157 9.17167C8.78105 9.5622 8.14788 9.5622 7.75736 9.17167L3.51472 4.92903C3.12419 4.53851 3.12419 3.90534 3.51472 3.51482C3.90524 3.12429 4.53841 3.12429 4.92893 3.51482L9.17157 7.75746Z"
            fill={color}
          />
          <path
            opacity="0.4"
            d="M7 11C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13L1 13C0.447715 13 -2.41411e-08 12.5523 0 12C2.41411e-08 11.4477 0.447715 11 1 11L7 11Z"
            fill={color}
          />
          <path
            opacity="0.5"
            d="M7.75736 14.8285C8.14788 14.438 8.78105 14.438 9.17157 14.8285C9.5621 15.2191 9.5621 15.8522 9.17157 16.2428L4.92893 20.4854C4.53841 20.8759 3.90524 20.8759 3.51472 20.4854C3.12419 20.0949 3.12419 19.4617 3.51472 19.0712L7.75736 14.8285Z"
            fill={color}
          />
          <path
            opacity="0.6"
            d="M11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V17Z"
            fill={color}
          />
          <path
            opacity="0.7"
            d="M20.4853 19.0712C20.8758 19.4617 20.8758 20.0949 20.4853 20.4854C20.0948 20.8759 19.4616 20.8759 19.0711 20.4854L14.8284 16.2428C14.4379 15.8522 14.4379 15.2191 14.8284 14.8285C15.219 14.438 15.8521 14.438 16.2426 14.8285L20.4853 19.0712Z"
            fill={color}
          />
          <path
            opacity="0.8"
            d="M23 11C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13L17 13C16.4477 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11L23 11Z"
            fill={color}
          />
          <path
            opacity="0.9"
            d="M19.0711 3.51482C19.4616 3.12429 20.0948 3.12429 20.4853 3.51482C20.8758 3.90534 20.8758 4.53851 20.4853 4.92903L16.2426 9.17167C15.8521 9.5622 15.219 9.5622 14.8284 9.17167C14.4379 8.78115 14.4379 8.14798 14.8284 7.75746L19.0711 3.51482Z"
            fill={color}
          />
          <path
            d="M11 1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7V1Z"
            fill={color}
          />
        </svg>
      );
  }
};

const Loader: React.FC<LoaderProps> = ({
  skin = LoaderSkin.light,
  size = LoaderSize.regular,
}) => {
  return (
    <LoaderWrapper size={size}>
      <LoaderSvg size={size} skin={skin} />
    </LoaderWrapper>
  );
};

export default Loader;
