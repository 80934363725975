/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export const AutocompleteActionsPrefix = 'AUTOCOMPLETE';

export const STATE_KEY = 'autocomplete';

const SET_RESULTS = `${AutocompleteActionsPrefix}/SET_RESULTS`;

export const AutocompleteSearchActionTypes = {
  SET_RESULTS,
};
export const AutocompleteSearchActions = {
  setResults: (results: Array<any>) => ({ type: SET_RESULTS, results }),
};
