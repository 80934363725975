/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { Suspense, lazy } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

import Config from '@app/WebConfig';
import LoginModal from '@app/layout/Login/LoginModal';
import TermsBanner from '@app/layout/TermsBanner';
import { COLORS } from '@app/constants';
import theme from '@app/theme/theme';
import Loader, { LoaderSize, LoaderSkin } from '@app/components/Loaders';
import { GlobalStyle } from '@app/styles/global';
import { I18nPropsType } from '@app/i18n/types';
import { Page } from './pageMeta';

const FbAutoLogin = lazy(() => import('@app/layout/Login/FbAutoLogin'));

const DEFAULT_FALLBACK_COLOR = COLORS.white;

interface PageProps {
  component: any;
  navigationBar: any;
  footer: any;
  pageProps?: Page;
  i18nProps: I18nPropsType;
}

type FallbackProps = {
  bgColor?: string;
};

export const Fallback = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: ${(props: FallbackProps) =>
    props.bgColor ? props.bgColor : DEFAULT_FALLBACK_COLOR};
`;

const PageLayoutBase: React.FC<PageProps> = ({
  component: Component,
  navigationBar: NavigationBar,
  footer: Footer,
  pageProps = {} as Page,
  i18nProps: i18nProps,
  ...rest
}) => {
  const getFallback = () => {
    return (
      <Fallback bgColor={pageProps.bgColor}>
        {pageProps.loadingSpinner && (
          <Loader size={LoaderSize.big} skin={LoaderSkin.neutral} />
        )}
      </Fallback>
    );
  };

  // We'll consider the page as an embed once it's header and footer are don't displayed.
  const isPageEmbed = Config.headerConfig.show && Config.footerConfig.show;

  return (
    <>
      <Helmet
        title={pageProps.title}
        meta={[
          { name: 'theme-color', content: pageProps.themeColor },
          { name: 'msapplication-TileColor', content: pageProps.themeColor },
        ]}
      />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <>
          {!Config.headerConfig.show && (
            <Suspense fallback={getFallback()}>
              <NavigationBar
                i18nProps={i18nProps}
                background={pageProps.bgNavigationBar}
              />
            </Suspense>
          )}
          <LoginModal />
          <Suspense fallback={getFallback()}>
            <Component {...rest} />
          </Suspense>
          {!Config.footerConfig.show && (
            <Suspense fallback={getFallback()}>
              <Footer i18nProps={i18nProps} />
            </Suspense>
          )}
          {!isPageEmbed && <TermsBanner />}
          <Suspense fallback={<div />}>
            <FbAutoLogin />
          </Suspense>
        </>
      </ThemeProvider>
    </>
  );
};

export default PageLayoutBase;
