/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { LOCATION_CHANGE } from 'connected-react-router';
import { NowPlayingActionTypes } from '@app/pages/NowPlaying/actions';

export interface BrowserNavigateState {
  browserNavigated: boolean;
}

const initialState: BrowserNavigateState = {
  browserNavigated: false,
};

export const browserNavigatedReducer = (state = initialState, action) => {
  switch (action.type) {
    // This is used to update the playlist cursor when the browser navigates forward/backwards
    case LOCATION_CHANGE: {
      return {
        browserNavigated: true,
      };
    }
    case NowPlayingActionTypes.PLAY_NEXT:
    case NowPlayingActionTypes.PLAY_PREVIOUS: {
      return {
        browserNavigated: false,
      };
    }
    default:
      return state;
  }
};
