/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createCachedSelector } from 're-reselect';
import { denormalize } from 'normalizr';

import { nowPlayingSelectors } from '@app/pages/NowPlaying/selectors';
import { processRecording } from '@app/utils/sign.js';
import { PlaylistsSelectors } from '@app/pages/NowPlaying/modules/playlists/selectors';
import { PerformancesSelectors } from '@app/pages/NowPlaying/modules/performances/selectors';
import { PerformersSelectors } from '@app/pages/NowPlaying/modules/performers/selectors';
import { CommentsSelectors } from '@app/pages/NowPlaying/modules/comments/selectors';
import { LovesSelectors } from '@app/pages/NowPlaying/modules/loves/selectors';
import * as schema from '@app/schemas/performance';
import {
  createCachedSelectorByPlayerId,
  PlayersSelectors,
} from '@app/components/Player/selectors';
import { VideoJSPlayerSelectors } from '@app/components/Player/player_types/video_js/video_js_player_selectors';
import { isHdVideo } from '@app/utils/utils';

export const STATE_KEY = 'PERFORMANCE';

const getPerformancePlayer = createCachedSelectorByPlayerId(
  PlayersSelectors.getPlayerState,
  (playerState) => playerState[STATE_KEY]
);

const getPerfKey: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState && playerState.perfKey
);
const getPerformance = createCachedSelector(
  getPerfKey,
  PerformancesSelectors.getPerformances,
  PerformersSelectors.getPerformers,
  CommentsSelectors.getComments,
  LovesSelectors.getLoves,
  (perfKey, performances, performers, comments, loves) =>
    denormalize(perfKey, schema.performance, {
      performances,
      performers,
      comments,
      loves,
    })
)(getPerfKey);

// Performance State
const getPerfType: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.type
);
const getPerfStatus: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.perfStatus
);
const getPerfSongUid: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.songId
);
const getPerfEnsembleType: (state, props) => any =
  createCachedSelectorByPlayerId(
    getPerformance,
    (performance) => performance && performance.ensembleType
  );
const getPerfArrKey: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.arrKey
);
const getPerfIsVideo: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.type === 'video'
);
const getMediaUrls = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.mediaUrls
);
const getSrc: (state, props) => any = createCachedSelectorByPlayerId(
  [getPerfType, getMediaUrls],
  (perfType, mediaUrls) => {
    if (!perfType || !mediaUrls) return null;
    // some popular videos provide hls stream by default which is broken on chrome for certain cdns
    // so explicitly need to request videoMp4Url
    switch (perfType) {
      case 'video':
        return processRecording(mediaUrls.video || mediaUrls.videoMp4);
      case 'visualizer':
        return processRecording(mediaUrls.visualizer);
      default:
        return processRecording(mediaUrls.audio);
    }
  }
);
const extensionToTypeMap = {
  mp4: 'video/mp4',
  m3u8: 'application/x-mpegURL',
  m4a: 'audio/mp4',
  mp3: 'audio/mp3',
};
const getSrcType: (state, props) => any = createCachedSelectorByPlayerId(
  getSrc,
  (src) => src && extensionToTypeMap[src.slice(src.lastIndexOf('.') + 1)]
);
const getCoverUrl: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.coverUrl
);
const getIsLoved: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.isLoved
);
const getPoi: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => (performance ? performance.poi : 0)
);
const getOwner: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.owner
);

const getOtherPerformers: (state, props) => any =
  createCachedSelectorByPlayerId(
    getPerformance,
    (performance) => performance && performance.otherPerformers
  );
const getSongLength: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformance,
  (performance) => performance && performance.songLength
);

// Playlist State
const getPlaylistId: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.playlistId
);
const getPlaylistCursor = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.playlistCursor
);
const getPerfList = (state, props) =>
  PlaylistsSelectors.getFilledPerfList(state, getPlaylistId(state, props));
const getNextPerformance: (state, props) => any =
  createCachedSelectorByPlayerId(
    [getPerfList, getPlaylistCursor],
    (perfList, cursor) => perfList && perfList[cursor + 1]
  );
const getNextPlayablePerformance: (state, props) => any =
  createCachedSelectorByPlayerId(
    getPerfList,
    (perfList) =>
      perfList &&
      perfList
        .slice(0)
        .find(
          (perf) =>
            perf.mediaUrls.audio ||
            perf.mediaUrls.video ||
            perf.mediaUrls.visualizer
        )
  );
const getHasNext: (state, props) => any = createCachedSelectorByPlayerId(
  [getNextPlayablePerformance, nowPlayingSelectors.getFetchingUpNext],
  (nextPlayablePerf, fetchingUpNext) => !!nextPlayablePerf && !fetchingUpNext
);
const getPreviousPerformance: (state, props) => any =
  createCachedSelectorByPlayerId(
    [getPerfList, getPlaylistCursor],
    (perfList, cursor) => perfList && perfList[cursor - 1]
  );
const getPreviousPlayablePerformance = createCachedSelectorByPlayerId(
  [getPerfList, getPlaylistCursor],
  (perfList, cursor) =>
    perfList
      .slice(0, cursor)
      .find(
        (perf) =>
          perf.mediaUrls.audio ||
          perf.mediaUrls.video ||
          perf.mediaUrls.visualizer
      )
);
const getHasPrevious: (state, props) => any = createCachedSelectorByPlayerId(
  getPreviousPlayablePerformance,
  (prevPlayablePerf) => !!prevPlayablePerf
);

// Rendering State
const getRendering: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.rendering
);

const getRenderComplete: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.renderComplete
);
const getRenderFailed: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.renderFailed
);

const getCheckRenderStarted: (state, props) => any =
  createCachedSelectorByPlayerId(
    getPerformancePlayer,
    (playerState) => playerState.checkRenderStarted
  );

const getCancelledRender: (state, props) => any =
  createCachedSelectorByPlayerId(
    getPerformancePlayer,
    (playerState) => playerState.cancelledRender
  );

// Other UI State
const getShowLoadingSpinner: (state, props) => any =
  createCachedSelectorByPlayerId(
    getPerformancePlayer,
    (playerState) => playerState.showLoadingSpinner
  );
/**
 * Returns a boolean which indicates if the Map/Globe is shown in the PreformancePlayer in Now Playing
 **/
const getMapSelected: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.mapSelected
);
const getUiHidden: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.uiHidden
);
const getShowUpNext: (state, props) => any = createCachedSelectorByPlayerId(
  [VideoJSPlayerSelectors.getEnded, nowPlayingSelectors.getFetchingUpNext],
  (ended, fetchingUpNext) => !fetchingUpNext && ended
);

// Analytics State
const getEventCategory: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.eventCategory
);
const getEventLabel: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.eventLabel
);
const getListenTrigger: (state, props) => any = createCachedSelectorByPlayerId(
  getPerformancePlayer,
  (playerState) => playerState.listenTrigger || 'none'
);

const getVideoResolution: (state, props) => any =
  createCachedSelectorByPlayerId(
    getPerformance,
    (performance) => performance.videoResolution
  );

const getIsHdVideo: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoResolution,
  (resolution) => isHdVideo(resolution)
);

export const PerformancePlayerSelectors = {
  getPerfKey,
  getPerfType,
  getPerfStatus,
  getPerfSongUid,
  getPerfEnsembleType,
  getPerfArrKey,
  getPerfIsVideo,
  getMediaUrls,
  getIsHdVideo,
  getSrc,
  getSrcType,
  getCoverUrl,
  getIsLoved,
  getOwner,
  getOtherPerformers,
  getSongLength,

  getPlaylistId,
  getPlaylistCursor,
  getHasNext,
  getHasPrevious,
  getNextPerformance,
  getNextPlayablePerformance,
  getPreviousPerformance,

  getRendering,
  getRenderComplete,
  getRenderFailed,
  getCheckRenderStarted,
  getCancelledRender,

  getShowLoadingSpinner,
  getMapSelected,
  getUiHidden,
  getShowUpNext,

  getEventCategory,
  getEventLabel,
  getListenTrigger,
  getPoi,
};
