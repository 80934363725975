/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { currentTimeZone } from './utils';
import signedAjax from './signed_request.js';
import { trackFacebookPixel } from './fb_pixel';

export const emitEvent = (name, data = {}) => {
  signedAjax({
    url: '/api/analytics/el',
    method: 'post',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    data: JSON.stringify({
      events: [{ name, data }],
      tz_offset: currentTimeZone,
    }),
  });
};

export const trackFacebookPixelEvent = (name) => {
  trackFacebookPixel(name);
};
