/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { schema } from 'normalizr';
import {
  ExtendedRecording,
  DenormalizedPerformer,
  DenormalizedLove,
  DenormalizedCommentRuby,
  DenormalizedStats,
  DenormalizedPlaylistBackendNew,
} from '@app/WebConfig/types';
import {
  NormalizedPlaylist,
  Performer,
  NormalizedPerformance,
  Love,
  Comment,
  DenormalizedPerformance,
  DenormalizedPlaylist,
  PerformanceStats,
  DPerformance,
} from '@app/pages/NowPlaying/types';

export interface NormalizedRecommendations {
  performances: { [key: number]: NormalizedPerformance };
  performers: { [key: number]: Performer };
  playlists: { [key: string]: NormalizedPlaylist };
}

const buildPerformer = (performer: DenormalizedPerformer): Performer => ({
  accountId: performer.account_id,
  handle: performer.handle,
  firstName: performer.first_name || '',
  lastName: performer.last_name || '',
  picUrl: performer.pic_url,
  // Obfuscate lat and lon: https://smule-inc.atlassian.net/browse/WEB-10403
  lat: performer.price,
  lon: performer.discount,
  url: performer.url,
  isVip:
    performer.is_vip ||
    (performer.sub_apps &&
      (performer.sub_apps.indexOf('sing') !== -1 ||
        performer.sub_apps.indexOf('sing_google') !== -1)) ||
    false,
  isVerified: performer.is_verified,
  isFollowing: performer.is_following || false,
  isFollowStatusUpdating: false,
  isBlocked: performer.is_blocked || false,
  verifiedType: performer.verified_type,
  city: performer.city,
});

export const performer = new schema.Entity<Performer>(
  'performers',
  {},
  {
    idAttribute: 'account_id',
    processStrategy: (value) => buildPerformer(value),
  }
);

const buildLove = (love: DenormalizedLove): Love => ({
  key: love.key,
  givenBy: love.given_by,
});

export const love = new schema.Entity<Love>(
  'loves',
  {
    givenBy: performer,
  },
  {
    idAttribute: 'key',
    processStrategy: (value) => buildLove(value),
  }
);

const buildComment = (comment: DenormalizedCommentRuby): Comment => ({
  key: comment.key,
  postedBy: comment.posted_by,
  createdAt: comment.created_at,
  message: comment.message,
  messageRaw: comment.message_raw,
  messageXml: comment.message_xml,
  likeCount: comment.like_count,
  isLiked: comment.is_liked,
  hasCommentersTag: comment.has_commenters_tag,
});

export const comment = new schema.Entity<Comment>(
  'comments',
  {
    postedBy: performer,
  },
  {
    idAttribute: 'key',
    processStrategy: (value) => buildComment(value),
  }
);

const buildPerformanceStats = (stats: DenormalizedStats): PerformanceStats => ({
  totalPerformers: stats.total_performers,
  truncatedOtherPerformers: stats.truncated_other_performers,
  totalListens: stats.total_listens,
  totalLoves: stats.total_loves,
  totalComments: stats.total_comments,
  totalCommenters: stats.total_commenters,
  totalGifts: stats.total_gifts,
});

const buildPerformance = (performance: ExtendedRecording): DPerformance => ({
  key: performance.key,
  type: performance.type,
  title: performance.title,
  artist: performance.artist,
  message: performance.message || '',
  createdAt: performance.created_at,
  expireAt: performance.expire_at,
  seed: performance.seed,
  closed: performance.closed,
  ensembleType: performance.ensemble_type,
  childCount: performance.child_count,
  appUid: performance.app_uid,
  arrKey: performance.arr_key,
  songId: performance.song_id,
  songLength: performance.song_length,
  arrType: performance.arr_type,
  perfStatus: performance.perf_status,
  artistTwitter: performance.artist_twitter,
  mediaUrls: {
    video: performance.video_media_url,
    videoMp4: performance.video_media_mp4_url,
    audio: performance.media_url,
    visualizer: performance.visualizer_media_url,
  },
  coverUrl: performance.cover_url,
  webUrl: performance.web_url,
  songInfoUrl: performance.song_info_url,
  stats: buildPerformanceStats(performance.stats || {}),
  owner: performance.owner,
  otherPerformers: performance.other_performers || [],
  isPrivate: performance.private,
  isLoved: performance.is_loved || false,
  isLovedChecked: performance.is_loved_checked || false,
  loves: performance.loves || [],
  lovesLoaded: performance.loves_loaded || false,
  isFavorite: performance.is_favorite || false,
  isFavoriteChecked: performance.is_favorite_checked || false,
  comments: performance.comments || [],
  commentsLoaded: performance.comments_loaded || false,
  commentsNextOffset: performance.comments_next_offset || 0,
  canDelete: performance.can_delete || false,
  performedBy: performance.performed_by,
  poi: performance.poi === 0 || !performance.poi ? 0 : performance.poi / 1000,
  recId: performance.rec_id || null,
  formType: performance.form_type,
  videoResolution: performance.video_resolution,
});

export const performance = new schema.Entity<DenormalizedPerformance>(
  'performances',
  {
    owner: performer,
    otherPerformers: [performer],
    loves: [love],
    comments: [comment],
  },
  {
    idAttribute: 'key',
    processStrategy: (value) =>
      buildPerformance(value) as unknown as DenormalizedPerformance,
  }
);

const buildPlaylist = (
  playlist: DenormalizedPlaylistBackendNew
): DenormalizedPlaylist => {
  // TODO: Use this if old recommendations loading is needed
  // const next = playlist.cursor ? playlist.cursor.next : playlist.next_offset;
  // const hasNext = playlist.cursor ? playlist.cursor.hasNext : playlist.next_offset > -1;
  // const perfList = playlist.perfList || playlist.list;
  // const type = playlist.type;
  // const name = playlist.name || (playlist.topic && playlist.topic.name);
  return {
    id: playlist.id,
    type: playlist.type,
    name: playlist.name,
    topicId: playlist.topic && playlist.topic.id,
    urlName: playlist.url_name,
    message: playlist.message,
    perfList: playlist.perfList,
    cursor: playlist.cursor,
    offset: playlist.offset,
    visibility: playlist.visibility,
    webUrl: playlist.webUrl,
    cover: playlist.cover,
    accountIcon: playlist.accountIcon,
  };
};

const buildLike = (like) => ({
  key: like.key,
  givenBy: like.given_by,
});

export const like = new schema.Entity(
  'likes',
  {
    givenBy: performer,
  },
  {
    idAttribute: 'key',
    processStrategy: (value) => buildLike(value),
  }
);

export const playlist = new schema.Entity<DenormalizedPlaylist>(
  'playlists',
  {
    perfList: [performance],
  },
  {
    processStrategy: (value) => buildPlaylist(value),
  }
);

const buildSearchPerformer = (searchPerformer) => {
  const apps = searchPerformer.sub_apps || [];
  const hasSing =
    apps.indexOf('sing') !== -1 || apps.indexOf('sing_google') !== -1;
  return {
    accountId: searchPerformer.account_id,
    handle: searchPerformer.handle,
    firstName: searchPerformer.first_name,
    lastName: searchPerformer.last_name,
    picUrl: searchPerformer.pic_url,
    // Obfuscate lat and lon: https://smule-inc.atlassian.net/browse/WEB-10403
    lat: searchPerformer.price,
    lon: searchPerformer.discount,
    isVip: hasSing,
  };
};

export const searchPerformer = new schema.Entity(
  'searchPerformers',
  {},
  {
    idAttribute: 'account_id',
    processStrategy: (value) => buildSearchPerformer(value),
  }
);

export type PerformanceSchemaType = {
  performer: typeof performer;
  love: typeof love;
  comment: typeof comment;
  performance: typeof performance;
  like: typeof like;
  playlist: typeof playlist;
  searchPerformer: typeof searchPerformer;
};
