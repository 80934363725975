/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { State } from '@app/reducer';
import { SupportedLocales } from '@app/i18n/supportedLanguages';
import { I18nActions, I18nSelectors } from '@app/i18n/reducer';
import * as screenType from '@app/styles/style_breakpoints';
import * as i18nHelpers from '@app/i18n/i18nHelpers';
import * as LanguageHelpers from '@app/i18n/supportedLanguages';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LanguageSelectSvg = styled.svg`
  position: absolute;
  top: 10px;
  right: 10px;
  fill: rgb(255, 255, 255);
  ${screenType.mobileHeaderBreakPoint} {
    top: 20px;
    right: 48px;
  }
`;

const LanguageSelectContainer = styled.div`
  margin-top: 3px;
  position: relative;
  ${screenType.mobileHeaderBreakPoint} {
    background-color: rgb(17, 14, 57);
    height: 50px;
    padding: 0px 48px 0px 40px;
    margin-left: -48px;
    margin-right: -48px;
  }
`;

const LanguageSelectComponent = styled.select`
  width: 100%;
  height: 32px;
  font-weight: bold;
  border: none;
  margin: 0;
  background-color: rgb(17, 14, 57);
  position: relative;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding-left: 8px;
  color: rgb(255, 255, 255);
  font-size: 17px;
  ${screenType.mobileHeaderBreakPoint} {
    height: 100%;
  }
`;

const LanguageSelect: React.FC = () => {
  const dispatch = useDispatch();
  const setLocale = (locale: string) => dispatch(I18nActions.setLocale(locale));
  const { pathname: locationPath } = useLocation();
  const { i18n } = useTranslation();
  const currentLocale = useSelector((state: State) =>
    I18nSelectors.getLocale(state)
  );
  const routerPush = (path: string) => dispatch(push(path));

  const optionsList = Object.keys(SupportedLocales).sort((langA, langB) =>
    SupportedLocales[langA] > SupportedLocales[langB] ? 1 : -1
  );

  const onChange = (e) => {
    const newLocale = e.target.value;
    const newLanguage = LanguageHelpers.getLangFromLocale(newLocale);

    setLocale(newLocale);
    i18nHelpers.setLocaleCookie(newLocale);

    const routeLng = i18nHelpers.getLanguageFromPath(locationPath);
    if (routeLng !== newLanguage) {
      i18nHelpers.setLocaleCookie(newLocale);
      const newPath = locationPath.replace(LanguageHelpers.localesRegex, '');
      routerPush(`/${newLanguage}${newPath}`);
      i18n.changeLanguage(newLanguage);
    }
  };

  useEffect(() => {
    const { locale } = i18nHelpers.getLanguageLocale(locationPath);
    locale && onChange({ target: { value: locale } });
    console.log('!!!', locale);
  }, []);

  return (
    <LanguageSelectContainer>
      <LanguageSelectComponent value={currentLocale} onChange={onChange}>
        {optionsList.map((locale) => (
          <option
            value={locale}
            key={locale}
            data-test-id={`select-option-${locale}`}
          >
            {SupportedLocales[locale]}
          </option>
        ))}
      </LanguageSelectComponent>
      <LanguageSelectSvg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        xmlns="http://www.w3.org/2000/svg"
        className=""
      >
        {/* eslint-disable-next-line max-len */}
        <path d="M7.16985 5L0.830152 5C0.122277 5 -0.259609 4.26513 0.201069 3.78945L3.37092 0.25608C3.70159 -0.0853601 4.29841 -0.0853601 4.62908 0.25608L7.79893 3.78945C8.25961 4.26513 7.87772 5 7.16985 5Z" />
        {/* eslint-disable-next-line max-len */}
        <path d="M0.830151 7H7.16985C7.87772 7 8.25961 7.73487 7.79893 8.21055L4.62908 11.7439C4.29841 12.0854 3.70159 12.0854 3.37092 11.7439L0.201069 8.21055C-0.25961 7.73487 0.122276 7 0.830151 7Z" />
      </LanguageSelectSvg>
    </LanguageSelectContainer>
  );
};

export default LanguageSelect;
