/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export type Color =
  | 'basePurple'
  | 'purple100'
  | 'purple200'
  | 'purple300'
  | 'purple400'
  | 'purple500'
  | 'purple600'
  | 'purpleRain'
  | 'baseBlue'
  | 'blue100'
  | 'blue200'
  | 'blue300'
  | 'blue400'
  | 'baseMagenta'
  | 'magenta100'
  | 'magenta200'
  | 'magenta300'
  | 'magenta400'
  | 'baseTeal'
  | 'teal100'
  | 'teal200'
  | 'teal300'
  | 'teal400'
  | 'baseSilver'
  | 'silver100'
  | 'silver200'
  | 'silver300'
  | 'silver400'
  | 'silver500'
  | 'baseGray'
  | 'gray100'
  | 'gray200'
  | 'gray300'
  | 'gray400'
  | 'yellow100'
  | 'black'
  | 'white'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'red';

export type Colors = { [key in Color]: string };

const colors: Colors = {
  basePurple: '#6C2EFF',
  purple100: '#ECE8FF',
  purple200: '#9F85FF',
  purple300: '#8A59FF',
  purple400: '#4521B6',
  purple500: '#2D2867',
  purple600: '#110E39',
  purpleRain: '#8826FB',
  baseBlue: '#3C86F7',
  blue100: '#F0F6FF',
  blue200: '#8BD2FD',
  blue300: '#25ADFB',
  blue400: '#26559D',
  baseMagenta: '#FD286E',
  magenta100: '#FEEEF3',
  magenta200: '#FEACC7',
  magenta300: '#FD6A99',
  magenta400: '#A11946',
  baseTeal: '#0DD3FF',
  teal100: '#ECF7FB',
  teal200: '#7ECBE4',
  teal300: '#0F9ED0',
  teal400: '#004169',
  baseSilver: '#D9E0E7',
  silver100: '#FBFBFB',
  silver200: '#F0F2F4',
  silver300: '#E4E9EE',
  silver400: '#C0CCD8',
  silver500: '#728CA6',
  baseGray: '#1F2021',
  gray100: '#C8CACF',
  gray200: '#AAADB4',
  gray300: '#81838C',
  gray400: '#585A64',
  yellow100: '#FBF5D0',
  black: '#000000',
  white: '#FFFFFF',
  green: '#00B818',
  yellow: '#FFCE42',
  orange: '#F76C09',
  red: '#EB4646',
};

export default colors;
