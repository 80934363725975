/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable from 'seamless-immutable';

import { VideoJSState } from '@app/components/Player/types';
import { STATE_KEY as PLAYER_STATE_KEY } from '@app/components/Player/selectors';
import {
  hasPlayerId,
  PlayersActionTypes,
} from '@app/components/Player/actions';

import { STATE_KEY } from './video_js_player_selectors';
export { STATE_KEY } from './video_js_player_selectors';

// Player Status
const SET_AUTOPLAY = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_AUTOPLAY`;
const SET_READY = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_READY`;
const CAN_PLAY = `${PLAYER_STATE_KEY}/${STATE_KEY}/CAN_PLAY`;
const SEEKING = `${PLAYER_STATE_KEY}/${STATE_KEY}/SEEKING`;
const SEEKED = `${PLAYER_STATE_KEY}/${STATE_KEY}/SEEKED`;
const WAITING = `${PLAYER_STATE_KEY}/${STATE_KEY}/WAITING`;
const SET_PASSIVE_CONSUMPTION = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_PASSIVE_CONSUMPTION`;
const SET_BACKGROUND_CONSUMPTION = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_BACKGROUND_CONSUMPTION`;
const SET_ACTIVE_CONSUMPTION = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_ACTIVE_CONSUMPTION`;
const LOAD_START = `${PLAYER_STATE_KEY}/${STATE_KEY}/LOAD_START`;

// Timing
const SET_PLAY_REQUESTED = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_PLAY_REQUESTED`;
const SET_HANDLE_WAITING_REQUESTED = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_HANDLE_WAITING_REQUESTED`;
const PLAY_CLICK = `${PLAYER_STATE_KEY}/${STATE_KEY}/PLAY_CLICK`;
const PLAY = `${PLAYER_STATE_KEY}/${STATE_KEY}/PLAY`;
const SIMULATE_PLAY = `${PLAYER_STATE_KEY}/${STATE_KEY}/SIMULATE_PLAY`;
const PLAYING = `${PLAYER_STATE_KEY}/${STATE_KEY}/PLAYING`;
const SET_PAUSE_REQUESTED = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_PAUSE_REQUESTED`;
const PAUSE_CLICK = `${PLAYER_STATE_KEY}/${STATE_KEY}/PAUSE_CLICK`;
const PAUSE = `${PLAYER_STATE_KEY}/${STATE_KEY}/PAUSE`;
const SET_CURRENT_TIME = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_CURRENT_TIME`;
const SET_PREVIOUS_END_TIME = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_PREVIOUS_END_TIME`;
const SEEKED_TO_POSITION = `${PLAYER_STATE_KEY}/${STATE_KEY}/SEEKED_TO_POSITION`;
const SET_NEW_CURRENT_TIME = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_NEW_CURRENT_TIME`;
const END = `${PLAYER_STATE_KEY}/${STATE_KEY}/END`;
const SET_LISTEN_START_TIME = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_LISTEN_START_TIME`;
const SET_MANUAL_START_ACTION = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_MANUAL_START_ACTION`;
const SET_DURATION = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_DURATION`;
const SET_ERROR = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_ERROR`;

// Volume
const SET_VOLUME = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_VOLUME`;
const SET_MUTED = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_MUTED`;
const TOGGLE_MUTE = `${PLAYER_STATE_KEY}/${STATE_KEY}/TOGGLE_MUTE`;
const VOLUME_CHANGED = `${PLAYER_STATE_KEY}/${STATE_KEY}/VOLUME_CHANGED`;
const UNLOAD = `${PLAYER_STATE_KEY}/${STATE_KEY}/UNLOAD`;
const STALLED = `${PLAYER_STATE_KEY}/${STATE_KEY}/STALLED`;

export const VideoJSPlayerActionTypes = {
  SET_AUTOPLAY,
  SET_READY,
  CAN_PLAY,
  SEEKING,
  SEEKED,
  WAITING,
  SET_DURATION,
  SET_ACTIVE_CONSUMPTION,
  SET_PASSIVE_CONSUMPTION,
  SET_BACKGROUND_CONSUMPTION,
  SET_PLAY_REQUESTED,
  PLAY_CLICK,
  PLAY,
  SIMULATE_PLAY,
  PLAYING,
  SET_PAUSE_REQUESTED,
  PAUSE_CLICK,
  PAUSE,
  SET_CURRENT_TIME,
  SET_PREVIOUS_END_TIME,
  SEEKED_TO_POSITION,
  SET_NEW_CURRENT_TIME,
  SET_LISTEN_START_TIME,
  SET_MANUAL_START_ACTION,
  END,

  SET_VOLUME,
  SET_MUTED,
  TOGGLE_MUTE,
  VOLUME_CHANGED, // Only used for analytics... Should this be a PerformancePlayer Action?
  UNLOAD, // Only used for analytics
  LOAD_START,
  STALLED,
};

export const VideoJSPlayerActions = {
  setAutoplay: hasPlayerId((autoplay) => ({
    type: SET_AUTOPLAY,
    payload: { autoplay },
  })),
  setReady: hasPlayerId(() => ({ type: SET_READY, payload: {} })),
  setCanPlay: hasPlayerId(() => ({ type: CAN_PLAY, payload: {} })),
  seeking: hasPlayerId(() => ({ type: SEEKING, payload: {} })),
  seeked: hasPlayerId(() => ({ type: SEEKED, payload: {} })),
  waiting: hasPlayerId(() => ({ type: WAITING, payload: {} })),
  setDuration: hasPlayerId((duration) => ({
    type: SET_DURATION,
    payload: { duration },
  })),
  setActiveConsumption: hasPlayerId(() => ({
    type: SET_ACTIVE_CONSUMPTION,
    payload: {},
  })),
  setPassiveConsumption: hasPlayerId(() => ({
    type: SET_PASSIVE_CONSUMPTION,
    payload: {},
  })),
  setBackgroundConsumption: hasPlayerId(() => ({
    type: SET_BACKGROUND_CONSUMPTION,
    payload: {},
  })),
  setPlayRequested: hasPlayerId((playRequested) => ({
    type: SET_PLAY_REQUESTED,
    payload: { playRequested },
  })),
  setHandleWaitingRequested: hasPlayerId((handleWaitingRequested) => ({
    type: SET_HANDLE_WAITING_REQUESTED,
    payload: { handleWaitingRequested },
  })),
  playClick: hasPlayerId(() => ({ type: PLAY_CLICK, payload: {} })),
  play: hasPlayerId((homepagePlayClick) => ({
    type: PLAY,
    payload: { homepagePlayClick },
  })),
  simulatePlay: hasPlayerId(() => ({ type: SIMULATE_PLAY, payload: {} })),

  playing: hasPlayerId(() => ({ type: PLAYING, payload: {} })),
  setPauseRequested: hasPlayerId((pauseRequested) => ({
    type: SET_PAUSE_REQUESTED,
    payload: { pauseRequested },
  })),
  pauseClick: hasPlayerId(() => ({ type: PAUSE_CLICK, payload: {} })),
  pause: hasPlayerId(() => ({ type: PAUSE, payload: {} })),
  setCurrentTime: hasPlayerId((time, droppedVideoFrames) => ({
    type: SET_CURRENT_TIME,
    payload: { time, droppedVideoFrames },
  })),
  setPreviousEndTime: hasPlayerId((time) => ({
    type: SET_PREVIOUS_END_TIME,
    payload: { time },
  })),
  seekedToPosition: hasPlayerId((seeked) => ({
    type: SEEKED_TO_POSITION,
    payload: { seeked },
  })),
  setNewCurrentTime: hasPlayerId((time) => ({
    type: SET_NEW_CURRENT_TIME,
    payload: { time },
  })),
  setListenStartTime: hasPlayerId((time) => ({
    type: SET_LISTEN_START_TIME,
    payload: { time },
  })),
  setManualStartAction: hasPlayerId((manual) => ({
    type: SET_MANUAL_START_ACTION,
    payload: { manual },
  })),
  end: hasPlayerId(() => ({ type: END, payload: {} })),

  setVolume: hasPlayerId((volume) => ({
    type: SET_VOLUME,
    payload: { volume },
  })),
  setMuted: hasPlayerId((muted) => ({ type: SET_MUTED, payload: { muted } })),
  toggleMute: hasPlayerId(() => ({ type: TOGGLE_MUTE, payload: {} })),
  volumeChanged: hasPlayerId((volume) => ({
    type: VOLUME_CHANGED,
    payload: { volume },
  })),
  unload: hasPlayerId(() => ({ type: UNLOAD, payload: {} })),
  loadStart: hasPlayerId(() => ({ type: LOAD_START, payload: {} })),
  setError: hasPlayerId((error) => ({ type: SET_ERROR, payload: { error } })),
  stalled: hasPlayerId(() => ({ type: STALLED, payload: {} })),
};

const initialVideoJSPlayerState = Immutable<VideoJSState>({
  // Player Status
  autoplay: true,
  ready: false,
  canPlay: false,
  seeking: false,
  playRequested: false,
  pauseRequested: false,
  paused: true,
  ended: false,
  consumptionType: 0, // 0 for active, 1 for passive, 2 for background
  loadStartTime: null,
  canPlayTime: null,
  error: null,
  stallCount: 0,

  // Timing
  duration: 0,
  currentTime: 0,
  previousEndTime: 0,
  newCurrentTime: null,
  listenStartTime: 0,
  manualStartAction: false,
  seekedToPosition: false,
  droppedVideoFrames: 0,

  // Volume
  volume: 0.1,
  muted: false,

  options: Immutable({}),
  handleWaitingRequested: false,
});

export default (state = initialVideoJSPlayerState, action) => {
  switch (action.type) {
    case PlayersActionTypes.INIT: {
      // only set to playerOptions.autoplay if it exists
      // this is to minimize further changes needed to live player and performance player
      const { playerOptions } = action.payload;
      const autoplay =
        playerOptions && typeof playerOptions.autoplay !== 'undefined'
          ? playerOptions.autoplay
          : true;
      return state.set('autoplay', autoplay);
    }

    case SET_AUTOPLAY: {
      return state.set('autoplay', action.payload.autoplay);
    }
    case SET_READY: {
      return state.set('ready', true);
    }
    case CAN_PLAY: {
      return state.set('canPlay', true).set('canPlayTime', Date.now());
    }
    case LOAD_START: {
      return state
        .set('loadStartTime', Date.now())
        .set('canPlayTime', null)
        .set('error', null)
        .set('stallCount', 0);
    }
    case SET_ERROR: {
      return state.set('error', action.payload.error);
    }
    case STALLED: {
      return state.set('stallCount', state.stallCount + 1);
    }
    case SEEKING: {
      return state.set('seeking', true);
    }
    case SEEKED: {
      return state.set('seeked', false);
    }
    case WAITING: {
      return state.set('canPlay', false);
    }
    case SET_DURATION: {
      return state.set('duration', action.payload.duration);
    }
    case SET_PASSIVE_CONSUMPTION: {
      return state.set('consumptionType', 1);
    }
    case SET_ACTIVE_CONSUMPTION: {
      return state.set('consumptionType', 0);
    }
    case SET_BACKGROUND_CONSUMPTION: {
      return state.set('consumptionType', 2);
    }
    case SET_PLAY_REQUESTED:
      return state.set('playRequested', action.payload.playRequested);
    case SET_HANDLE_WAITING_REQUESTED:
      return state.set(
        'handleWaitingRequested',
        action.payload.handleWaitingRequested
      );
    case PLAY_CLICK: {
      return state.set('playRequested', true);
    }
    case PLAY: {
      return state
        .set(
          'manualStartAction',
          action.payload.homepagePlayClick
            ? true
            : state.playRequested
            ? true
            : state.manualStartAction
        )
        .set('playRequested', false)
        .set('listenStartTime', state.currentTime)
        .set('canPlay', true)
        .set('paused', false)
        .set('ended', false)
        .set('handleWaitingRequested', false);
    }
    case SIMULATE_PLAY: {
      return state
        .set(
          'manualStartAction',
          state.playRequested ? true : state.manualStartAction
        )
        .set('playRequested', false)
        .set('listenStartTime', state.currentTime);
    }
    case PLAYING: {
      return state
        .set('playRequested', false)
        .set('canPlay', true)
        .set('paused', false)
        .set('ended', false)
        .set('handleWaitingRequested', false);
    }
    case SET_PAUSE_REQUESTED:
      return state.set('pauseRequested', action.payload.pauseRequested);
    case PAUSE_CLICK: {
      return state.set('pauseRequested', true);
    }
    case PAUSE: {
      return state
        .set('pauseRequested', false)
        .set('paused', true)
        .set('manualStartAction', false);
    }
    case SET_CURRENT_TIME: {
      return state
        .set('currentTime', action.payload.time)
        .set('newCurrentTime', null)
        .set('ended', action.payload.time === state.duration)
        .set('droppedVideoFrames', action.payload.droppedVideoFrames);
    }
    case SET_NEW_CURRENT_TIME: {
      return state.set('newCurrentTime', action.payload.time);
    }
    case SET_LISTEN_START_TIME: {
      return state.set('listenStartTime', action.payload.time);
    }
    case SET_MANUAL_START_ACTION: {
      return state.set('manualStartAction', action.payload.manual);
    }
    case END: {
      return state
        .set('paused', true)
        .set('ended', true)
        .set('currentTime', state.duration);
    }

    case SET_VOLUME: {
      return state.set('volume', action.payload.volume);
    }
    case SET_MUTED: {
      return state.set('muted', action.payload.muted);
    }
    case TOGGLE_MUTE: {
      return state.set('muted', !state.muted);
    }
    case SET_PREVIOUS_END_TIME: {
      return state.set('previousEndTime', action.payload.time);
    }
    case SEEKED_TO_POSITION: {
      return state.set(
        'seekedToPosition',
        action.payload.seeked && !state.paused
      );
    }
    default:
      return state;
  }
};
