/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { State } from '@app/reducer';
import { NormalizedPerformance } from '@app/pages/NowPlaying/types';

const getPerformances = (state: State) => state.performances;
const getPerfKey = (_: State, perfKey: string) => perfKey;
const getPerformance = createCachedSelector(
  getPerformances,
  getPerfKey,
  (performances, perfKey) => performances[perfKey]
)((state, perfKey) => perfKey);
const getMediaUrls = createSelector(
  getPerformance,
  (performance) => performance && performance.mediaUrls
);
const getHasSrc = createSelector(
  getMediaUrls,
  (mediaUrls) => !!(mediaUrls.video || mediaUrls.visualizer || mediaUrls.audio)
);
const getSongInfoUrl = createSelector(
  getPerformance,
  (performance) => performance && performance.songInfoUrl
);
const getArrKey = createSelector(
  getPerformance,
  (performance) => performance && performance.arrKey
);
const OPEN_MIC_ARR_KEY = '3402003_3402003';
const getNeedFullPerf = createSelector(
  [getArrKey, getSongInfoUrl],
  (arrKey, songInfoUrl) => arrKey !== OPEN_MIC_ARR_KEY && !songInfoUrl
);

const getPerfIsFavorite = createSelector(
  getPerformance,
  (performance) => performance && performance.isFavorite
);
const getPerfIsFavoriteChecked = createSelector(
  getPerformance,
  (performance) => performance && performance.isFavoriteChecked
);
const getPerfLovesLoaded = createSelector(
  getPerformance,
  (performance) => performance && performance.lovesLoaded
);
const getPerfIsLoved = createSelector(
  getPerformance,
  (performance) => performance && performance.isLoved
);
const getPerfIsLovedChecked = createSelector(
  getPerformance,
  (performance) => performance && performance.isLovedChecked
);

const getPerfCommentsNextOffset = createSelector(
  getPerformance,
  (performance) => performance && performance.commentsNextOffset
);

const getPerformancesByPerfKeys = createSelector(
  [getPerformances, (_, perfKeys) => perfKeys],
  (
    items: NormalizedPerformance,
    perfKeys: string[]
  ): NormalizedPerformance[] | null => {
    if (!(Object.keys(items).length > 0 && perfKeys)) return null;

    return perfKeys.reduce((acc: NormalizedPerformance[], key: string) => {
      if (items[key]) {
        acc.push(items[key]);
      }
      return acc;
    }, []);
  }
);

export const PerformancesSelectors = {
  getPerformances,
  getPerformance,
  getHasSrc,
  getNeedFullPerf,
  getPerfIsFavorite,
  getPerfIsFavoriteChecked,
  getSongInfoUrl,
  getPerfLovesLoaded,
  getPerfIsLoved,
  getPerfIsLovedChecked,
  getPerfCommentsNextOffset,
  getPerformancesByPerfKeys,
};
