/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

interface Props {
  location: {
    pathname: string;
  };
}

const ScrollToTop: React.FC<Props> = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default withRouter(ScrollToTop as any);
