/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export const LoginActionsPrefix = 'LOGIN';

export const TOGGLE_TOPBAR_OPTION = `${LoginActionsPrefix}/TOGGLE_TOPBAR_OPTION`;
export const CLOSE_TOPBAR_OPTION = `${LoginActionsPrefix}/CLOSE_TOPBAR_OPTION`;
export const OPEN_LOGIN_MODAL = `${LoginActionsPrefix}/OPEN_LOGIN_MODAL`;
export const CLOSE_LOGIN_MODAL = `${LoginActionsPrefix}/CLOSE_LOGIN_MODAL`;

export const SHOW_FLOW = `${LoginActionsPrefix}/SHOW_FLOW`;
export const FB_SDK_LOADED = `${LoginActionsPrefix}/FB_SDK_LOADED`;
export const APPLE_SDK_LOADED = `${LoginActionsPrefix}/APPLE_SDK_LOADED`;
export const GOOGLE_SDK_LOADED = `${LoginActionsPrefix}/GOOGLE_SDK_LOADED`;
export const RECAPTCHA_LOADED = `${LoginActionsPrefix}/RECAPTCHA_LOADED`;
export const UPDATE_LOGIN_STATUS = `${LoginActionsPrefix}/UPDATE_LOGIN_STATUS`;
export const UPDATE_VIP_STATUS = `${LoginActionsPrefix}/UPDATE_VIP_STATUS`;
export const UPDATE_PROFILE_PIC = `${LoginActionsPrefix}/UPDATE_PROFILE_PIC`;
export const UPDATE_ACCOUNT_PAGE = `${LoginActionsPrefix}/UPDATE_ACCOUNT_PAGE`;
export const UPDATE_FEED_STATUS = `${LoginActionsPrefix}/UPDATE_FEED_STATUS`;
export const CANCEL_FEED_STATUS_POLL = `${LoginActionsPrefix}/CANCEL_FEED_STATUS_POLL`;
export const ENABLE_FEED_STATUS_POLL = `${LoginActionsPrefix}/ENABLE_FEED_STATUS_POLL`;
export const USER_LOGOUT = `${LoginActionsPrefix}/USER_LOGOUT`;
export const INPUT_EMAIL = `${LoginActionsPrefix}/INPUT_EMAIL`;
export const SET_AGE_LIMIT = `${LoginActionsPrefix}/SET_AGE_LIMIT`;
export const SET_RESUBMIT = `${LoginActionsPrefix}/SET_RESUBMIT`;
export const INPUT_BDAY = `${LoginActionsPrefix}/INPUT_BDAY`;
export const INPUT_PASSWORD = `${LoginActionsPrefix}/INPUT_PASSWORD`;
export const INPUT_PASSWORD_CONFIRM = `${LoginActionsPrefix}/INPUT_PASSWORD_CONFIRM`;
export const TOGGLE_SPINNER = `${LoginActionsPrefix}/TOGGLE_SPINNER`;
export const SET_EMAIL_ERROR = `${LoginActionsPrefix}/SET_EMAIL_ERROR`;
export const SET_PASSWORD_ERROR = `${LoginActionsPrefix}/SET_PASSWORD_ERROR`;
export const SET_PASSWORD_CONFIRM_ERROR = `${LoginActionsPrefix}/SET_PASSWORD_CONFIRM_ERROR`;
export const TOGGLE_RECAPTCHA = `${LoginActionsPrefix}/TOGGLE_RECAPTCHA`;
export const SET_LOGIN_REMEMBER_ME = `${LoginActionsPrefix}/SET_LOGIN_REMEMBER_ME`;
export const SET_NEW_ACCOUNT_OPT_IN = `${LoginActionsPrefix}/SET_NEW_ACCOUNT_OPT_IN`;
export const SHOW_NEW_ACCOUNT_OPT_IN = `${LoginActionsPrefix}/SHOW_NEW_ACCOUNT_OPT_IN`;
export const RESET_PASSWORD_EMAIL_SENT = `${LoginActionsPrefix}/RESET_PASSWORD_EMAIL_SENT`;
export const UNEXPECTED_ERROR = `${LoginActionsPrefix}/UNEXPECTED_ERROR`;
export const SET_USER_NAME_FLOW = `${LoginActionsPrefix}/SET_USER_NAME_FLOW`;
export const SET_FB_AUTH_RESPONSE = `${LoginActionsPrefix}/SET_FB_AUTH_RESPONSE`;
export const SET_APPLE_AUTH_RESPONSE = `${LoginActionsPrefix}/SET_APPLE_AUTH_RESPONSE`;
export const SET_GOOGLE_AUTH_RESPONSE = `${LoginActionsPrefix}/SET_GOOGLE_AUTH_RESPONSE`;
export const SET_MODAL_ANIMATION_STATE = `${LoginActionsPrefix}/SET_MODAL_ANIMATION_STATE`;
export const SET_FB_LOGIN_ERROR = `${LoginActionsPrefix}/SET_FACEBOOK_LOGIN_ERROR`;

export const INPUT_PHONE = `${LoginActionsPrefix}/INPUT_PHONE`;
export const SET_PHONE_PIN_ID = `${LoginActionsPrefix}/SET_PHONE_PIN_ID`;
export const INPUT_PIN = `${LoginActionsPrefix}/INPUT_PIN`;
export const SET_PHONE_ERROR = `${LoginActionsPrefix}/SET_PHONE_ERROR`;

export const GET_FOLLOWEES_START = `${LoginActionsPrefix}/GET_FOLLOWEES_START`;
export const GET_FOLLOWEES_SUCCESS = `${LoginActionsPrefix}/GET_FOLLOWEES_SUCCESS`;
export const GET_FOLLOWEES_FAIL = `${LoginActionsPrefix}/GET_FOLLOWEES_FAIL`;

export const GET_FOLLOWERS_START = `${LoginActionsPrefix}/GET_FOLLOWERS_START`;
export const GET_FOLLOWERS_SUCCESS = `${LoginActionsPrefix}/GET_FOLLOWERS_SUCCESS`;
export const GET_FOLLOWERS_FAIL = `${LoginActionsPrefix}/GET_FOLLOWERS_FAIL`;

export const SET_SNP_SETTINGS = `${LoginActionsPrefix}/SET_SNP_SETTINGS`;
export const SET_PLAYER_ID = `${LoginActionsPrefix}/SET_PLAYER_ID`;

export const SET_COOKIE_BANNER_VERSION = `${LoginActionsPrefix}/SET_COOKIE_BANNER_VERSION`;

export const REG_SUCCESS = `${LoginActionsPrefix}/REG_SUCCESS`;
