/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable, { ImmutableObject } from 'seamless-immutable';

const CLEAR_WARNING = 'CLEAR_WARNING';
const SET_INITIAL_WARNING_CODE = 'SET_INITIAL_WARNING_CODE';

export const Actions = {
  clearWarning: () => ({
    type: CLEAR_WARNING,
  }),
  setInitialWarningCode: (payload: number) => ({
    type: SET_INITIAL_WARNING_CODE,
    payload,
  }),
};

export interface WarningReducerState {
  code: number | null;
}

const warningState: ImmutableObject<WarningReducerState> = Immutable({
  code: null,
});

export const warningReducer = (state = warningState, action: any) => {
  switch (action.type) {
    case SET_INITIAL_WARNING_CODE:
      return state.set('code', action.payload);
    case CLEAR_WARNING:
      return state.set('code', null);
    default:
      return state;
  }
};
