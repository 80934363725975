/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export enum LoginLaunchOrigins {
  fromNavBar = 'nav_bar_click',
  fromStandalonePage = 'standalone',
  fromLove = 'love_intent',
  fromGift = 'gift_intent',
  fromFavorite = 'favorite_intent',
  fromUnfavorite = 'unfavorite_intent',
  fromComment = 'comment_intent',
  fromFollow = 'follow_intent',
  fromReportComment = 'report_comment_intent',
}

export const authEvents = {
  verifyMethod: {
    NAME: 'verify_method_pgview',
    k2: {
      EMAIL: 'email',
      PHONE: 'phone',
    },
  },
  regClick: {
    NAME: 'reg_method_clk',
    k1: {
      FB: 'fb',
      GOOGLE: 'GOOG',
      APPLE: 'APPLE',
      PHONE: 'phone',
      EMAIL: 'email',
    },
  },
  verifyMethodClick: {
    NAME: 'verify_method_clk',
    k2: {
      PHONE: 'phone',
    },
  },
  codeVerify: {
    NAME: 'codeverify_pgview',
    k2: {
      EMAIL: 'email',
      PHONE: 'phone',
    },
  },
  codeVerifyClick: {
    NAME: 'codeverify_clk',
    k2: {
      EMAIL: 'email',
      PHONE: 'phone',
    },
  },
  regComplete: {
    NAME: 'reg_flow_complete',
    k2: {
      CODE: 'CODE',
      LINK: 'LINK',
    },
    k1: {
      FB: 'fb',
      GOOGLE: 'GOOG',
      APPLE: 'APPLE',
      PHONE: 'phone',
      EMAIL: 'email',
      ACTIVATION: 'activation',
    },
    value: {
      NEW: 'new',
      EXISTING: 'existing',
    },
  },
  regPending: {
    NAME: 'reg_flow_pending_verification',
    k1: {
      EMAIL: 'email',
    },
  },
  activationSend: {
    NAME: 'activation_send',
  },
  regBirthdayPGView: {
    NAME: 'reg_birthdate_pgview',
  },
  regBirthdayClk: {
    NAME: 'reg_birthdate_confirm_clk',
  },
  regBirthdayError: {
    NAME: 'reg_birthdate_error',
  },
};
