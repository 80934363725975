/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { DenormalizedRecording } from '@app/types/recording';
import { DenormalizedArrangement } from '@app/types/arrangement';
import { doApiRequest } from './utils';

export type Performances = {
  list: DenormalizedRecording[];
  offset: number;
};

const getDataByKey = (key: string) =>
  doApiRequest<DenormalizedArrangement>({
    url: '/api/arrangement',
    method: 'get',
    params: {
      key,
    },
  });

const getDataById = (id: string) =>
  doApiRequest<DenormalizedArrangement>({
    url: '/api/arrangement',
    method: 'get',
    params: {
      id,
    },
  });

const getPerformances = (key: string, offset: number) =>
  doApiRequest<Performances>({
    url: '/api/arrangement/performances',
    method: 'get',
    params: {
      key,
      offset,
    },
  });

export type ArrangementAPIType = {
  getDataByKey: typeof getDataByKey;
  getDataById: typeof getDataById;
  getPerformances: typeof getPerformances;
};

export const ArrangementApi = {
  getDataByKey: getDataByKey,
  getDataById: getDataById,
  getPerformances,
};
