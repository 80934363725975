/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import useTheme from '@app/theme/useTheme';
import { Breakpoints } from '@app/theme/themeTyping';
import { GridProps } from './GridTypings';
import { GridContext } from './constants';

const Grid: React.FC<GridProps> = ({ gutter, children, className }) => {
  const theme = useTheme();
  const { colNumber } = theme.grid;
  const {
    mobile: mobileGutter,
    tablet: tabletGutter,
    desktop: desktopGutter,
  } = gutter ? gutter : theme.grid.gutter;
  const maxWidthCalculated: Breakpoints = theme.grid.maxWidth;

  const mobileGutterH: number =
    typeof mobileGutter === 'number' ? mobileGutter : mobileGutter.h;
  const mobileGutterV: number =
    typeof mobileGutter === 'number' ? mobileGutter : mobileGutter.v;
  const mobileGutterO: number =
    (typeof mobileGutter === 'number' ? mobileGutter : mobileGutter.o) -
    mobileGutterH / 2;

  let tabletGutterH: number;
  let tabletGutterV: number;
  let tabletGutterO: number;
  if (tabletGutter === undefined) {
    tabletGutterH = mobileGutterH;
    tabletGutterV = mobileGutterV;
    tabletGutterO = mobileGutterO;
  } else if (typeof tabletGutter === 'number') {
    tabletGutterH = tabletGutter;
    tabletGutterV = tabletGutter;
    tabletGutterO = tabletGutter - tabletGutterH / 2;
  } else {
    tabletGutterH = tabletGutter.h;
    tabletGutterV = tabletGutter.v;
    tabletGutterO = tabletGutter.o - tabletGutterH / 2;
  }

  let desktopGutterH: number;
  let desktopGutterV: number;
  let desktopGutterO: number;
  if (desktopGutter === undefined) {
    desktopGutterH = tabletGutterH;
    desktopGutterV = tabletGutterV;
    desktopGutterO = tabletGutterO;
  } else if (typeof desktopGutter === 'number') {
    desktopGutterH = desktopGutter;
    desktopGutterV = desktopGutter;
    desktopGutterO = desktopGutter - desktopGutterH / 2;
  } else {
    desktopGutterH = desktopGutter.h;
    desktopGutterV = desktopGutter.v;
    desktopGutterO = desktopGutter.o - desktopGutterH / 2;
  }

  return (
    <GridContext.Provider
      value={{
        colNumber: colNumber,
        mobileGutterH: mobileGutterH,
        tabletGutterH: tabletGutterH,
        desktopGutterH: desktopGutterH,
        mobileGutterV: mobileGutterV,
        tabletGutterV: tabletGutterV,
        desktopGutterV: desktopGutterV,
      }}
    >
      <Container
        className={className}
        maxWidth={maxWidthCalculated}
        mobileGutterO={mobileGutterO}
        tabletGutterO={tabletGutterO}
        desktopGutterO={desktopGutterO}
        mobileGutterV={mobileGutterV}
        tabletGutterV={tabletGutterV}
        desktopGutterV={desktopGutterV}
      >
        {children}
      </Container>
    </GridContext.Provider>
  );
};

export default React.memo(Grid);

const Container = styled.div<{
  maxWidth: Breakpoints;
  mobileGutterO: number;
  tabletGutterO: number;
  desktopGutterO: number;
  mobileGutterV: number;
  tabletGutterV: number;
  desktopGutterV: number;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  padding: 0 ${(props) => props.mobileGutterO}px;
  max-width: ${(props) => props.maxWidth.mobile}px;
  margin-top: -${(props) => props.mobileGutterV}px;

  ${(props) => props.theme.media.tablet} {
    padding: 0 ${(props) => props.tabletGutterO}px;
    max-width: ${(props) => props.maxWidth.tablet}px;
    margin-top: -${(props) => props.tabletGutterV}px;
  }
  ${(props) => props.theme.media.desktop} {
    padding: 0 ${(props) => props.desktopGutterO}px;
    max-width: ${(props) => props.maxWidth.desktop}px;
    margin-top: -${(props) => props.desktopGutterV}px;
  }
`;
