/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { schema } from 'normalizr';

const buildSmuleFamilyInfo = (smuleFamilyInfo) => {
  const {
    smuleFamily,
    smuleFamilyStat,
    owner,
    membership,
    inviteExpireAt,
    adminIcons,
  } = smuleFamilyInfo;
  return {
    ...smuleFamily,
    ...smuleFamilyStat,
    membership,
    inviteExpireAt,
    owner,
    adminIcons,
  };
};

const buildSmuleFamily = (smuleFamily) => ({ ...smuleFamily });

// this is to parse smulefamilyInfo response object, like getting family data by family id
export const smuleFamilyInfo = new schema.Entity(
  'smuleFamilyInfo',
  {},
  {
    idAttribute: (value) => value.smuleFamily.smuleFamilyId,
    processStrategy: (value) => buildSmuleFamilyInfo(value),
  }
);

// this is mainly for user's smulefamilies response object, brief data of families that user belongs to
export const smuleFamily = new schema.Entity(
  'smuleFamily',
  {},
  {
    idAttribute: 'smuleFamilyId',
    processStrategy: (value) => buildSmuleFamily(value),
  }
);

export type GroupsSchemaType = {
  smuleFamilyInfo: typeof smuleFamilyInfo;
  smuleFamily: typeof smuleFamily;
};
