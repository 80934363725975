/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  &:hover {
    opacity: 0.7;
  }
`;

const SocialLink = ({ href, children }) => (
  <span>
    <Link href={href}>{children}</Link>
  </span>
);

export default SocialLink;
