/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { PerformancesActionTypes } from '@app/pages/NowPlaying/modules/performances/actions';
import { emitGAEvent } from '@app/utils/utils';
import { LoginLaunchOrigins } from '@app/layout/Login/analytics/constants';

const PerformancesAnalytics = (store, action) => {
  switch (action.type) {
    case PerformancesActionTypes.TRY_UPDATE_FAVORITE: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: !action.meta.isFavorite
          ? LoginLaunchOrigins.fromFavorite
          : LoginLaunchOrigins.fromUnfavorite,
        eventLabel: 'player_bar_performance',
      });
      break;
    }
    case PerformancesActionTypes.UPDATE_FAVORITE_SUCCESS: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: action.payload.isFavorite ? 'favorite' : 'unfavorite',
        eventLabel: 'player_bar_performance',
      });
      break;
    }

    // no default
  }
};

export default PerformancesAnalytics;
