/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { InlineOverlayType } from './types';

export const RecordingPageActionsPrefix = 'RECORDING';

const OPEN_OVERLAY = `${RecordingPageActionsPrefix}/OPEN_OVERLAY`; // SELECT_TAB alternative
const SET_SELECTED_PERFORMANCE = `${RecordingPageActionsPrefix}/SET_SELECTED_PERFORMANCE`;
const SET_PLAYLIST = `${RecordingPageActionsPrefix}/SET_PLAYLIST`;
const SET_FETCHING_UP_NEXT = `${RecordingPageActionsPrefix}/SET_FETCHING_UP_NEXT`;

const SET_INTERVALS_COUNT = `${RecordingPageActionsPrefix}/SET_INTERVALS_COUNT`;
const SET_TOTAL_LISTEN_INTERVAL = `${RecordingPageActionsPrefix}/SET_TOTAL_LISTEN_INTERVAL`;
const DELETE_PERFORMANCE_START = `${RecordingPageActionsPrefix}/DELETE_PERFORMANCE_START`;
const DELETE_PERFORMANCE_SUCCESS = `${RecordingPageActionsPrefix}/DELETE_PERFORMANCE_SUCCESS`;
const DELETE_PERFORMANCE_FAIL = `${RecordingPageActionsPrefix}/DELETE_PERFORMANCE_FAIL`;

const REPORT_PERFORMANCE_START = `${RecordingPageActionsPrefix}/REPORT_PERFORMANCE_START`;
const REPORT_PERFORMANCE_SUCCESS = `${RecordingPageActionsPrefix}/REPORT_PERFORMANCE_SUCCESS`;
const REPORT_PERFORMANCE_FAIL = `${RecordingPageActionsPrefix}/REPORT_PERFORMANCE_FAIL`;
const REPORT_PERFORMANCE_DONE = `${RecordingPageActionsPrefix}/REPORT_PERFORMANCE_DONE`;

const SHOW_MORE_ACTIONS_MENU = `${RecordingPageActionsPrefix}/SHOW_MORE_ACTIONS_MENU`;
const HIDE_MORE_ACTIONS_MENU = `${RecordingPageActionsPrefix}/HIDE_MORE_ACTIONS_MENU`;

const SET_VISIBILE_INLINE_OVERLAY = `${RecordingPageActionsPrefix}/SET_VISIBILE_INLINE_OVERLAY`;
const SET_AUTOPLAY = `${RecordingPageActionsPrefix}/SET_AUTOPLAY`;
const PLAY_NEXT = `${RecordingPageActionsPrefix}/PLAY_NEXT`;
const PLAY_PREVIOUS = `${RecordingPageActionsPrefix}/PLAY_PREVIOUS`;
const PERF_REFERRAL_CLICK = `${RecordingPageActionsPrefix}/PERF_REFERRAL_CLICK`;

const CLICK_TO_STORE = `${RecordingPageActionsPrefix}/CLICK_TO_STORE`;
const JOIN_BUTTON_CLICK = `${RecordingPageActionsPrefix}/JOIN_BUTTON_CLICK`;

const SHARE = `${RecordingPageActionsPrefix}/SHARE`;
const SHARE_CONFIRMED = `${RecordingPageActionsPrefix}/SHARE_CONFIRMED`;
const LOAD_COMMENTS_START = `${RecordingPageActionsPrefix}/LOAD_COMMENTS_START`;
const LOAD_COMMENTS_SUCCESS = `${RecordingPageActionsPrefix}/LOAD_COMMENTS_SUCCESS`;
const LOAD_COMMENTS_FAIL = `${RecordingPageActionsPrefix}/LOAD_COMMENTS_FAIL`;
const SET_USER_COMMENTER = `${RecordingPageActionsPrefix}/SET_USER_COMMENTER`;
const REPORT_COMMENT_START = `${RecordingPageActionsPrefix}/REPORT_COMMENT_START`;
const TRY_REPORT_COMMENT = `${RecordingPageActionsPrefix}/TRY_REPORT_COMMENT`;
const REPORT_COMMENT_SUCCESS = `${RecordingPageActionsPrefix}/REPORT_COMMENT_SUCCESS`;
const REPORT_COMMENT_FAIL = `${RecordingPageActionsPrefix}/REPORT_COMMENT_FAIL`;
const DELETE_COMMENT_START = `${RecordingPageActionsPrefix}/DELETE_COMMENT_START`;
const DELETE_COMMENT_SUCCESS = `${RecordingPageActionsPrefix}/DELETE_COMMENT_SUCCESS`;
const DELETE_COMMENT_FAIL = `${RecordingPageActionsPrefix}/DELETE_COMMENT_FAIL`;
const LIKE_COMMENT_START = `${RecordingPageActionsPrefix}/LIKE_COMMENT_START`;
const LIKE_COMMENT_SUCCESS = `${RecordingPageActionsPrefix}/LIKE_COMMENT_SUCCESS`;
const LIKE_COMMENT_FAIL = `${RecordingPageActionsPrefix}/LIKE_COMMENT_FAIL`;
const UNLIKE_COMMENT_START = `${RecordingPageActionsPrefix}/UNLIKE_COMMENT_START`;
const UNLIKE_COMMENT_SUCCESS = `${RecordingPageActionsPrefix}/UNLIKE_COMMENT_SUCCESS`;
const UNLIKE_COMMENT_FAIL = `${RecordingPageActionsPrefix}/UNLIKE_COMMENT_FAIL`;
const SHOW_COMMENT_RECAPTCHA = `${RecordingPageActionsPrefix}/SHOW_COMMENT_RECAPTCHA`;
const SET_COMMENT_INPUT = `${RecordingPageActionsPrefix}/SET_COMMENT_INPUT`;
const TRY_SUBMIT_COMMENT = `${RecordingPageActionsPrefix}/TRY_SUBMIT_COMMENT`;
const SUBMIT_COMMENT_START = `${RecordingPageActionsPrefix}/SUBMIT_COMMENT_START`;
const SUBMIT_COMMENT_SUCCESS = `${RecordingPageActionsPrefix}/SUBMIT_COMMENT_SUCCESS`;
const SUBMIT_COMMENT_FAIL = `${RecordingPageActionsPrefix}/SUBMIT_COMMENT_FAIL`;
const UPDATE_QUERY = `${RecordingPageActionsPrefix}/UPDATE_QUERY`;
const GET_COMMENTERS_START = `${RecordingPageActionsPrefix}/GET_COMMENTERS_START`;
const GET_COMMENTERS_SUCCESS = `${RecordingPageActionsPrefix}/GET_COMMENTERS_SUCCESS`;
const GET_COMMENTERS_FAIL = `${RecordingPageActionsPrefix}/GET_COMMENTERS_FAIL`;

const LAND_CAMPAIGN = `${RecordingPageActionsPrefix}/LAND_CAMPAIGN`;
const LAND_PAGE = `${RecordingPageActionsPrefix}/LAND_PAGE`;
const SET_CAMPAIGN_ID = `${RecordingPageActionsPrefix}/SET_CAMPAIGN_ID`;

const UPDATE_FOLLOW_STATUS_START = `${RecordingPageActionsPrefix}/UPDATE_FOLLOW_STATUS_START`;
const UPDATE_FOLLOW_STATUS = `${RecordingPageActionsPrefix}/UPDATE_FOLLOW_STATUS`;
const UPDATE_FOLLOW_STATUS_FAIL = `${RecordingPageActionsPrefix}/UPDATE_FOLLOW_STATUS_FAIL`;

const INVITE_FOLLOWERS_START = `${RecordingPageActionsPrefix}/INVITE_FOLLOWERS_START`;
const INVITE_FOLLOWERS_SUCCESS = `${RecordingPageActionsPrefix}/INVITE_FOLLOWERS_SUCCESS`;
const INVITE_FOLLOWERS_FAIL = `${RecordingPageActionsPrefix}/INVITE_FOLLOWERS_FAIL`;

const REC_VIEW_EVENT = `${RecordingPageActionsPrefix}/REC_VIEW_EVENT`;
const HIDE_MODAL = `${RecordingPageActionsPrefix}/HIDE_MODAL`;

const SET_SHORT_FORM_SEEN = `${RecordingPageActionsPrefix}/SET_SHORT_FORM_SEEN`;
const SET_PLAYBACK_PANEL_SEEN = `${RecordingPageActionsPrefix}/SET_PLAYBACK_PANEL_SEEN`;

export const NowPlayingActionTypes = {
  OPEN_OVERLAY,
  SET_SELECTED_PERFORMANCE,
  SET_PLAYLIST,
  SET_INTERVALS_COUNT,
  SET_TOTAL_LISTEN_INTERVAL,
  SET_VISIBILE_INLINE_OVERLAY,
  SET_AUTOPLAY,
  PLAY_NEXT,
  PLAY_PREVIOUS,
  PERF_REFERRAL_CLICK,
  SET_FETCHING_UP_NEXT,
  DELETE_PERFORMANCE_START,
  DELETE_PERFORMANCE_SUCCESS,
  DELETE_PERFORMANCE_FAIL,
  SHOW_MORE_ACTIONS_MENU,
  HIDE_MORE_ACTIONS_MENU,
  REPORT_PERFORMANCE_START,
  REPORT_PERFORMANCE_SUCCESS,
  REPORT_PERFORMANCE_FAIL,
  REPORT_PERFORMANCE_DONE,
  CLICK_TO_STORE,
  JOIN_BUTTON_CLICK,
  SHARE,
  SHARE_CONFIRMED,
  LAND_CAMPAIGN,
  LAND_PAGE,
  SET_CAMPAIGN_ID,
  UPDATE_FOLLOW_STATUS_START,
  UPDATE_FOLLOW_STATUS,
  LOAD_COMMENTS_START,
  LOAD_COMMENTS_SUCCESS,
  LOAD_COMMENTS_FAIL,
  SET_USER_COMMENTER,
  TRY_REPORT_COMMENT,
  REPORT_COMMENT_START,
  REPORT_COMMENT_SUCCESS,
  REPORT_COMMENT_FAIL,
  DELETE_COMMENT_START,
  DELETE_COMMENT_FAIL,
  DELETE_COMMENT_SUCCESS,
  LIKE_COMMENT_START,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAIL,
  UNLIKE_COMMENT_START,
  UNLIKE_COMMENT_SUCCESS,
  UNLIKE_COMMENT_FAIL,
  SHOW_COMMENT_RECAPTCHA,
  SET_COMMENT_INPUT,
  TRY_SUBMIT_COMMENT,
  SUBMIT_COMMENT_START,
  SUBMIT_COMMENT_SUCCESS,
  SUBMIT_COMMENT_FAIL,
  UPDATE_QUERY,
  GET_COMMENTERS_START,
  GET_COMMENTERS_SUCCESS,
  GET_COMMENTERS_FAIL,
  INVITE_FOLLOWERS_START,
  INVITE_FOLLOWERS_SUCCESS,
  INVITE_FOLLOWERS_FAIL,
  UPDATE_FOLLOW_STATUS_FAIL,
  REC_VIEW_EVENT,
  HIDE_MODAL,
  SET_SHORT_FORM_SEEN,
  SET_PLAYBACK_PANEL_SEEN,
};

export const RecordingPageActions = {
  setSelectedPerformance: (selectedPerformanceKey: string) => ({
    type: SET_SELECTED_PERFORMANCE,
    payload: { selectedPerformanceKey },
  }),
  setPlaylist: (playlistId: string | null, playlistCursor?: number) => ({
    type: SET_PLAYLIST,
    payload: { playlistId, playlistCursor },
  }),
  setTotalListenInterval: (interval: number) => ({
    type: SET_TOTAL_LISTEN_INTERVAL,
    payload: { interval },
  }),
  setIntervalsCount: (count: number) => ({
    type: SET_INTERVALS_COUNT,
    payload: { count },
  }),
  setVisibleInlineOverlay: (type: InlineOverlayType) => ({
    type: SET_VISIBILE_INLINE_OVERLAY,
    payload: { type },
  }),
  hideInlineOverlay: () => ({
    type: SET_VISIBILE_INLINE_OVERLAY,
    payload: { type: null },
  }),
  setAutoplay: (enabled: boolean, initialize = false) => ({
    type: SET_AUTOPLAY,
    payload: { enabled },
    meta: { initialize },
  }),
  setFetchingUpNext: (value: boolean) => ({
    type: SET_FETCHING_UP_NEXT,
    payload: { value },
  }),
  playNext: ({ newCursor }) => ({ type: PLAY_NEXT, payload: { newCursor } }),
  playPrevious: ({ newCursor }) => ({
    type: PLAY_PREVIOUS,
    payload: { newCursor },
  }),

  perfReferralClick: (
    clickedPerfId,
    clickedPosition,
    clickedSection,
    trackingName,
    type
  ) => ({
    type: PERF_REFERRAL_CLICK,
    payload: { clickedPerfId, clickedPosition, clickedSection },
    meta: { trackingName, type },
  }),

  deletePerformanceStart: () => ({
    type: DELETE_PERFORMANCE_START,
    payload: {},
  }),
  deletePerformanceSuccess: () => ({
    type: DELETE_PERFORMANCE_SUCCESS,
    payload: {},
  }),
  deletePerformanceFail: () => ({ type: DELETE_PERFORMANCE_FAIL, payload: {} }),

  reportPerformanceStart: () => ({
    type: REPORT_PERFORMANCE_START,
    payload: {},
  }),
  reportPerformanceSuccess: () => ({
    type: REPORT_PERFORMANCE_SUCCESS,
    payload: {},
  }),
  reportPerformanceFail: () => ({ type: REPORT_PERFORMANCE_FAIL, payload: {} }),

  showMoreActionsMenu: () => ({ type: SHOW_MORE_ACTIONS_MENU, payload: {} }),
  hideMoreActionsMenu: () => ({ type: HIDE_MORE_ACTIONS_MENU, payload: {} }),

  joinButtonClick: () => ({ type: JOIN_BUTTON_CLICK, payload: {} }),
  clickToStore: (storeType, appFamily) => ({
    type: CLICK_TO_STORE,
    payload: {},
    meta: { storeType, appFamily },
  }),

  share: ({
    perfKey,
    shareType,
    perfStatus,
    ensembleType,
    arrKey,
    isVideo,
  }) => ({
    type: SHARE,
    meta: { perfKey, shareType, perfStatus, ensembleType, arrKey, isVideo },
  }),
  shareConfirmed: ({
    perfKey,
    shareType,
    perfStatus,
    ensembleType,
    arrKey,
    isVideo,
  }) => ({
    type: SHARE_CONFIRMED,
    meta: { perfKey, shareType, perfStatus, ensembleType, arrKey, isVideo },
  }),

  landCampaign: (campaignId, perfKey) => ({
    type: LAND_CAMPAIGN,
    payload: { campaignId, perfKey },
  }),
  landPage: (perfKey, compId) => ({
    type: LAND_PAGE,
    payload: { perfKey, compId },
  }),
  setCampaignId: (name, id) => ({
    type: SET_CAMPAIGN_ID,
    payload: { name, id },
  }),
  updateFollowStatusStart: (accountId) => ({
    type: UPDATE_FOLLOW_STATUS_START,
    payload: { accountId },
  }),
  updateFollowStatus: (accountId, active) => ({
    type: UPDATE_FOLLOW_STATUS,
    payload: { accountId, active },
  }),

  loadCommentsStart: () => ({
    type: LOAD_COMMENTS_START,
    payload: {},
  }),
  loadCommentsSuccess: () => ({
    type: LOAD_COMMENTS_SUCCESS,
    payload: {},
  }),
  loadCommentsFail: () => ({
    type: LOAD_COMMENTS_FAIL,
    payload: {},
  }),
  setUserCommenter: (isCommenter: boolean) => ({
    type: SET_USER_COMMENTER,
    payload: { isCommenter },
  }),
  tryReportComment: () => ({
    type: TRY_REPORT_COMMENT,
    payload: {},
  }),
  reportCommentStart: () => ({ type: REPORT_COMMENT_START, payload: {} }),
  reportCommentSuccess: () => ({ type: REPORT_COMMENT_SUCCESS, payload: {} }),
  reportCommentFail: () => ({ type: REPORT_COMMENT_FAIL, payload: {} }),
  deleteCommentStart: () => ({ type: DELETE_COMMENT_START, payload: {} }),
  deleteCommentSuccess: () => ({ type: DELETE_COMMENT_SUCCESS, payload: {} }),
  deleteCommentFail: () => ({ type: DELETE_COMMENT_FAIL, payload: {} }),
  likeCommentStart: (commentKey: string) => ({
    type: LIKE_COMMENT_START,
    payload: { commentKey },
  }),
  likeCommentSuccess: (commentKey: string) => ({
    type: LIKE_COMMENT_SUCCESS,
    payload: { commentKey },
  }),
  likeCommentFail: (commentKey: string) => ({
    type: LIKE_COMMENT_FAIL,
    payload: { commentKey },
  }),
  unLikeCommentStart: (commentKey: string) => ({
    type: UNLIKE_COMMENT_START,
    payload: { commentKey },
  }),
  unLikeCommentSuccess: (commentKey: string) => ({
    type: UNLIKE_COMMENT_SUCCESS,
    payload: { commentKey },
  }),
  unLikeCommentFail: (commentKey: string) => ({
    type: UNLIKE_COMMENT_FAIL,
    payload: { commentKey },
  }),
  showCommentRecaptcha: () => ({ type: SHOW_COMMENT_RECAPTCHA, payload: {} }),
  setCommentInput: (value: string) => ({
    type: SET_COMMENT_INPUT,
    payload: { value },
  }),
  trySubmitComment: () => ({ type: TRY_SUBMIT_COMMENT, payload: {} }),
  submitCommentStart: () => ({ type: SUBMIT_COMMENT_START, payload: {} }),
  submitCommentSuccess: () => ({ type: SUBMIT_COMMENT_SUCCESS, payload: {} }),
  submitCommentFail: () => ({ type: SUBMIT_COMMENT_FAIL, payload: {} }),
  updateQuery: (query: string) => ({ type: UPDATE_QUERY, payload: { query } }),
  getCommentersStart: () => ({ type: GET_COMMENTERS_START, payload: {} }),
  getCommentersSuccess: () => ({ type: GET_COMMENTERS_SUCCESS, payload: {} }),
  getCommentersFail: () => ({ type: GET_COMMENTERS_FAIL, payload: {} }),
  inviteFollowersStart: () => ({ type: INVITE_FOLLOWERS_START, payload: {} }),
  inviteFollowersSuccess: (count) => ({
    type: INVITE_FOLLOWERS_SUCCESS,
    payload: {},
    meta: { count },
  }),
  inviteFollowersFail: () => ({ type: INVITE_FOLLOWERS_FAIL, payload: {} }),
  updateFollowStatusFail: (accountId) => ({
    type: UPDATE_FOLLOW_STATUS_FAIL,
    payload: { accountId },
  }),
  sendRecView: (keys, values) => ({
    type: REC_VIEW_EVENT,
    payload: { keys, values },
  }),
  hideModal: () => ({ type: HIDE_MODAL, payload: {} }),
  setShortFormSeen: () => ({ type: SET_SHORT_FORM_SEEN, payload: {} }),
  setPlaybackPanelSeen: () => ({ type: SET_PLAYBACK_PANEL_SEEN, payload: {} }),
};
