/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import styled from 'styled-components';
import {
  desktopMediaQuery,
  FOOTER_MIN_HEIGHT,
  FOOTER_MOBILE_HEIGHT,
  FOOTER_TABLET_HEIGHT,
} from '@app/layout/Footer';
import theme from '@app/theme/theme';

const Wrapper = styled.div<{ showFooter: boolean }>`
  position: relative;
  min-height: 100vh;
  padding-bottom: ${({ showFooter }) =>
    !showFooter ? '0px !important' : `${FOOTER_MOBILE_HEIGHT}px`};

  ${theme.media.tablet} {
    padding-bottom: ${FOOTER_TABLET_HEIGHT}px;
  }

  ${desktopMediaQuery} {
    padding-bottom: ${FOOTER_MIN_HEIGHT}px;
  }
`;

export default Wrapper;
