/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { Selectors as LoginSelectors } from '@app/layout/Login/reducer';

export const STATE_KEY = 'player';

const getPlayersState = (state) => state[STATE_KEY];
const getActiveUserId = (state) => LoginSelectors.getUserAccountId(state);
const getCurrentPlayerId = createSelector(
  getPlayersState,
  (playersState) => playersState.currentPlayerId || 0
);
const getPlayerId = (_, props) => props.id;
const getPlayer = createSelector(
  getPlayersState,
  getPlayerId,
  (playersState, id) => playersState[id]
);
const getPlayerType = createSelector(getPlayer, (player) =>
  player ? player.type : null
);
const getPlayerState = createSelector(
  getPlayer,
  (player) => player.playerState
);
const getCurrentPlayer = createSelector(
  getPlayersState,
  getCurrentPlayerId,
  (playersState, id) => playersState[id]
);
const isCurrentPlayerPlaying = createSelector(
  getCurrentPlayer,
  (currentPlayer) =>
    currentPlayer &&
    !currentPlayer.playerState.VIDEO_JS.paused &&
    !currentPlayer.playerState.VIDEO_JS.ended
);
const getIsPerformer = createSelector(
  getActiveUserId,
  (id) => (ownerId, performersIds) => {
    if (!id) return false;
    return id === ownerId ? true : performersIds.includes(id);
  }
);

// Creates a Re-reselect cached selector that is cached by id
// TODO: Add TS checks
export const createCachedSelectorByPlayerId = (selector, combiner) =>
  createCachedSelector(selector, combiner)((state, props) => props.id);

export const PlayersSelectors = {
  getCurrentPlayerId,
  getPlayerType,
  getPlayerState,
  getCurrentPlayer,
  getIsPerformer,
  isCurrentPlayerPlaying,
};
