/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

interface Props {
  checked: boolean;
  label?: JSX.Element;
  onToggle: (checked: boolean) => void;
  testId?: string;
}

const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

const Wrapper = styled.div<{ checked: boolean }>`
  background: ${(props) =>
    props.checked ? props.theme.colors.baseBlue : props.theme.colors.silver400};
  border-radius: 10px;
  width: 36px;
  height: 20px;
  padding: ${(props) => props.theme.spacing / 4}px;
  position: relative;
`;

const Button = styled.div<{ checked: boolean }>`
  box-shadow: ${(props) => props.theme.shadows.light};
  background: ${(props) => props.theme.colors.white};
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 100%;
  left: ${(props) => (props.checked ? '18px' : '2px')};
  top: 2px;
  transition: left 100ms ease-in-out;
`;

const ToggleButton: React.FC<Props> = ({
  checked,
  label,
  onToggle,
  testId,
}) => {
  const onClick = () => {
    onToggle(!checked);
  };
  return (
    <Container onClick={onClick} data-test-id={testId}>
      {label}
      <Wrapper checked={checked}>
        <Button checked={checked} />
      </Wrapper>
    </Container>
  );
};

export default ToggleButton;
