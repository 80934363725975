/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import {
  WebConfig,
  DataStore,
  OrEmpty,
  RecordingPageConfig,
  GroupsPageConfig,
  SiteLinks,
  MetaData,
  LoginUrls,
  InitialLoginStatus,
  LoginConfig,
  PasswordResetPage,
  FbSdk,
  Recaptcha,
  SearchWidgetConfig,
  HeaderConfig,
  FooterConfig,
  GDPRConsentConfig,
} from './types';

class Config {
  private config: WebConfig;
  initWith(dataStore: DataStore): void {
    this.config = this.config || {};
    const data = dataStore || window['DataStore'] || {};
    const currentUser = data.Widgets.Login.init.currentUser;
    const loginStatus = data.LoginStatus;
    const loginInit = data.Widgets.Login.init;

    this.config.siteLinks = data.SiteLinks;
    this.config.publicApiHost = data.PublicApiHost;
    this.config.assetHost = data.AssetHost;
    this.config.metaData = data.meta;

    // todo not used in Spa v2, can be deleted
    this.config.loginApi = data.Widgets.Login.init.urls;

    this.config.loginConfig = {
      embeddedPlayer: loginInit.embeddedPlayer,
      useCaptcha: loginInit.useCaptcha,
      activationResult: loginInit.activationResult,
      activationFromWeb: loginInit.activationFromWeb,
      activationWithCreation: loginInit.activationWithCreation,
      activationApp: loginInit.activationApp,
      // todo not used
      updateEmailResult: loginInit.updateEmailResult,
      pagePath: loginInit.pagePath,
      fbAppId: loginInit.fbAppId,
      dauPeriodExeeded: loginInit.dauPeriodExeeded,
      // todo not used
      remoteIP: loginInit.remoteIP,
    };

    this.config.initialLoginStatus = {
      isLoggedIn: loginStatus.isLoggedIn,
      accountId: currentUser ? currentUser.account_id : null,
      handle: loginStatus.handle,
      picUrl: loginStatus.picUrl,
      isVerified: currentUser ? currentUser.is_verified : false,
      url: currentUser ? currentUser.url : null,
      jid: currentUser ? currentUser.jid : null,
      xmppHosts: currentUser ? currentUser.xmpp_hosts : [],
      session: loginStatus.session,
    };

    this.config.passwordResetPage = data.Pages.PasswordReset || {
      error: false,
    };
    this.config.recordingPage = data.Pages
      .Recording as OrEmpty<RecordingPageConfig>;
    this.config.groupsPage = data.Pages.SmuleFamily;

    this.config.facebookSdkConfig = data.Widgets.FbSdk;
    this.config.recaptchaWidget = data.Widgets.Recaptcha;
    // TODO - not used
    this.config.searchWidgetConfig = data.Widgets.AutoCompleteSearch;
    this.config.headerConfig = data.Widgets.Header;
    this.config.footerConfig = data.Widgets.Footer;
    // TODO - not used
    this.config.gdprConsent = data.gdprConsent;
    this.config.warningCode = data.WarningCode;
  }
  setConfig(newConfig: WebConfig): void {
    this.config = newConfig;
  }
  get siteLinks(): SiteLinks {
    return this.config.siteLinks;
  }
  get metaData(): MetaData {
    return this.config.metaData;
  }
  // TODO - not used
  get loginApi(): LoginUrls {
    return this.config.loginApi;
  }
  get loginConfig(): LoginConfig {
    return this.config.loginConfig;
  }
  get initialLoginStatus(): InitialLoginStatus {
    return this.config.initialLoginStatus;
  }
  get passwordResetPage(): OrEmpty<PasswordResetPage> {
    return this.config.passwordResetPage;
  }
  get initialRecordingPage(): OrEmpty<RecordingPageConfig> {
    return this.config.recordingPage;
  }
  get initialGroupsPage(): OrEmpty<GroupsPageConfig> {
    return this.config.groupsPage;
  }
  get facebookSdkConfig(): FbSdk {
    return this.config.facebookSdkConfig;
  }
  get recaptchaWidget(): Recaptcha {
    return this.config.recaptchaWidget;
  }
  get publicApiHost(): string {
    return this.config.publicApiHost;
  }
  get assetHost(): string {
    return this.config.assetHost;
  }
  get searchWidgetConfig(): SearchWidgetConfig {
    return this.config.searchWidgetConfig;
  }
  get headerConfig(): HeaderConfig {
    return this.config.headerConfig;
  }
  get footerConfig(): FooterConfig {
    return this.config.footerConfig;
  }
  get gdprConsent(): GDPRConsentConfig | undefined {
    return this.config.gdprConsent;
  }
  get warningCode(): number | undefined {
    return this.config.warningCode;
  }
}

const config = new Config();

export default config;
