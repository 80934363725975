/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { autorapSections as sectionIds } from '@app/constants';
import Config from '@app/WebConfig';

declare global {
  interface Window {
    MSStream: any;
  }
}

export interface GAEvent {
  eventLabel?: string;
  eventName?: string;
  eventCategory?: string;
  eventValue?: string | number;
  nonInteractive?: boolean;
  nonInteraction?: number;
  customData?: any;
}

export const noop = function () {
  // do nothing
};

const isOSDevice: boolean =
  /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream;
const isAndroidDevice: boolean = /android|\\s+adr\\s+\\d+/i.test(
  navigator.userAgent
);
const isHmsDevice: boolean = /HMSCore/i.test(navigator.userAgent);
const getViewportWidth = (): number =>
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const isLaptop = getViewportWidth() <= 1368;
const isMobile = getViewportWidth() <= 600;

export const smuleLog = (...args) => {
  if (
    typeof console !== 'undefined' &&
    console &&
    console.log &&
    !!console.log.apply
  ) {
    console.log(...args);
  }
};

const buildTrackingEvent =
  (isInteraction: boolean) =>
  (
    eventName?: string,
    eventCategory?: string,
    label?: string,
    value?: string | number,
    customData: any = {}
  ) => {
    const data = {
      eventName,
      event_category: eventCategory,
      event_label: label,
      eventValue: value,
      nonInteraction: isInteraction ? 0 : 1,
      ...customData,
    };

    // Just in case, we delete from the object all values that were not passed in
    Object.keys(data).forEach((key) => {
      if (!data[key] && data[key] !== 0) delete data[key];
    });

    try {
      // GA4 analytics
      if (window.gtag) {
        const { eventName, ...ga4Data } = data;
        window.gtag('event', eventName, ga4Data);
      }
    } catch (e) {
      smuleLog('Failed to track analytics event', e); // eslint-disable-line no-console
    }
  };

const sendNonInteractionEvent = buildTrackingEvent(false);
const sendInteractionEvent = buildTrackingEvent(true);

const emitGAEvent = ({
  eventName,
  eventCategory,
  eventLabel,
  eventValue,
  nonInteractive,
  customData,
}: GAEvent): void => {
  if (nonInteractive) {
    sendNonInteractionEvent(
      eventName,
      eventCategory,
      eventLabel,
      eventValue,
      customData
    );
  } else {
    sendInteractionEvent(
      eventName,
      eventCategory,
      eventLabel,
      eventValue,
      customData
    );
  }
};

const getVisibleHeightPx = (
  element: HTMLElement,
  viewportHeight: number
): number => {
  const rect = element.getBoundingClientRect();
  const height = rect.bottom - rect.top;
  const visible = {
    top: rect.top >= 0 && rect.top < viewportHeight,
    bottom: rect.bottom > 0 && rect.bottom < viewportHeight,
  };
  let visiblePx = 0;

  if (visible.top && visible.bottom) {
    // Whole element is visible
    visiblePx = height;
  } else if (visible.top) {
    visiblePx = viewportHeight - rect.top;
  } else if (visible.bottom) {
    visiblePx = rect.bottom;
  } else if (height > viewportHeight && rect.top < 0) {
    const absTop = Math.abs(rect.top);

    if (absTop < height) {
      // Part of the element is visible
      visiblePx = height - absTop;
    }
  }

  return visiblePx;
};

const getMostVisibleSection = (): string => {
  let element;
  const viewportHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  let max = 0;

  Object.values(sectionIds).forEach((sectionId) => {
    const sectionDOMNode = document.getElementById(sectionId);
    if (sectionDOMNode) {
      const visiblePx = getVisibleHeightPx(sectionDOMNode, viewportHeight);

      if (visiblePx > max) {
        max = visiblePx;
        element = sectionId;
      }
    }
  });

  return element;
};

const emitAnalyticsEvent = ({
  eventLabel,
  eventName,
  eventCategory,
}: GAEvent): void => {
  const storeLabel = eventCategory || (isAndroidDevice ? 'Google' : 'Apple');
  emitGAEvent({
    eventLabel: eventLabel || getMostVisibleSection(),
    eventName: eventName || 'Autorap to Store',
    eventCategory: storeLabel,
  });
};

// Convert HEX color with opacity to RGBA.
const convertHexToRgba = (hex: string, opacity: number): string => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

/**
 * The time-zone offset is the difference, in minutes, between UTC and local time.
 * Note that this means that the offset is positive if the local timezone is behind UTC and
 * negative if it is ahead. For example, if your time zone is UTC+10 (Australian Eastern Standard Time),
 * -600 will be returned. Daylight saving time prevents this value from being a constant even for a given
 * locale.
 *   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
 *
 * thus the negative -60 (converting to seconds and reversing the sign based on the Player_Timezone_Offset_tech_spec)
 */
const currentTimeZone = new Date().getTimezoneOffset() * -60;

// https://stackoverflow.com/questions/29001977/safari-in-ios8-is-scrolling-screen-when-fixed-elements-get-focus
export const safari8InputFocusScrollFix = () => {
  if (isMobile && isOSDevice) {
    // seems fixed on ios 10
    setTimeout(() => window.scrollTo(0, 0), 200);
  }
};

export const constructImageUrl = (url, size) => {
  if (
    typeof size === 'undefined' ||
    typeof url !== 'string' ||
    !/^https?:\/\/.*smule\.com?/.test(url) ||
    url.match(Config.assetHost)
  ) {
    return url;
  }

  return url.replace(/(?:_\d{3,4})*\.(jpe?g|png)$/i, `_${size}.$1`);
};

const IMAGE_SIZES = [128, 256, 512, 1024];

export const imageSize = (url, size) => {
  let dim;
  switch (size) {
    case 'small':
      dim = IMAGE_SIZES[0];
      break;
    case 'medium':
      dim = IMAGE_SIZES[1];
      break;
    case 'large':
      dim = IMAGE_SIZES[2];
      break;
    case 'xlarge':
      dim = IMAGE_SIZES[3];
      break;
    default:
      smuleLog(`Invalid size [${size}] for url [${url}]`);
      return url;
  }
  return constructImageUrl(url, dim);
};

export const detectLocale = () => {
  try {
    if (
      'userLanguage' in window.navigator &&
      window.navigator['userLanguage']
    ) {
      return window.navigator['userLanguage'];
    }
    if ('language' in window.navigator && window.navigator.language) {
      return window.navigator.language;
    }
    return null;
  } catch (e) {
    smuleLog(`Failed to detect locale: ${e}`);
    return null;
  }
};

export const areCookiesEnabled = () => window.navigator.cookieEnabled;

const canUseWebP = (() => {
  let result: boolean | undefined;

  const isWebPSupported = () => {
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  };

  return () => {
    if (result !== undefined) {
      return result;
    } else {
      result = isWebPSupported();
      return result;
    }
  };
})();

// Removed as canUseWebP is attached to window
// const applyBackgroundWebP: (a: string, b: string) => string =
//   (webPBg: string, imgBg: string) => canUseWebP() ? webPBg : imgBg;

export const isObject = (target: any): boolean =>
  target !== null &&
  typeof target === 'object' &&
  !(target instanceof Array) &&
  !(target instanceof Date);

let iOSCachedVersion;
// NOTE that the method below was returning false, changed to empty string for consistency
export const getiOSVersion = (ua?: string) => {
  if (iOSCachedVersion) return iOSCachedVersion;
  ua = (ua || window.navigator.userAgent).toLowerCase();
  const match = ua.match(/(?:iphone|ipad).*?os\s([0-9_]*)/);
  iOSCachedVersion = match ? match[1].replace(/_/g, '.') : '';

  return iOSCachedVersion;
};

export const compareVersion = (A: string, B: string) => {
  const d1: any = A.match(/(\d+)/g);
  const d2: any = B.match(/(\d+)/g);
  let a, b;
  for (let i = 0; i < Math.max(d1.length, d2.length); i++) {
    a = parseInt(d1[i], 0) || 0;
    b = parseInt(d2[i], 0) || 0;
    if (a !== b) return a - b;
  }
  return 0;
};

export const createObjectURL = (file) =>
  window.URL ? window.URL.createObjectURL(file) : null;
export const destroyObjectURL = (url) =>
  window.URL ? window.URL.revokeObjectURL(url) : null;

// validation of perf, arr, playlist keys e.g.123_456 /^\d+_\d+/
const ENTITY_KEY_REG_EXP = /^\d+_\d+$/;
const isValidEntityKey = (key) => ENTITY_KEY_REG_EXP.test(key) || false;

const detectTouchPad = (e) => {
  let isTouchPad = false;
  if (e.wheelDeltaY) {
    if (e.wheelDeltaY === e.deltaY * -3) {
      isTouchPad = true;
    }
  } else if (e.deltaMode === 0) {
    isTouchPad = true;
  }

  return isTouchPad;
};

// Used to determine if a video should be considered of HD quality
const isHdVideo = (resolution: string | null): boolean => {
  // Details on resolitions here: https://smule-inc.atlassian.net/browse/WEB-12183
  if (resolution) {
    const dimensions = resolution.match(/[0-9]+/g);
    if (dimensions && dimensions.length) {
      return dimensions.every((dim) => parseInt(dim) >= 720);
    }
  }
  return false;
};

export {
  isOSDevice,
  isAndroidDevice,
  isHmsDevice,
  getViewportWidth,
  isLaptop,
  isMobile,
  getMostVisibleSection,
  sendNonInteractionEvent,
  sendInteractionEvent,
  emitAnalyticsEvent,
  emitGAEvent,
  currentTimeZone,
  convertHexToRgba,
  canUseWebP,
  isValidEntityKey,
  detectTouchPad,
  isHdVideo,
};
