/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { VerifiedType } from '@app/WebConfig/types';

export interface GroupsState {
  userGroupsPage: GroupsPageState;
  groupsCatalogPage: GroupsPageState;
  groupPage: GroupPageState;
  errorModal: CLIENT_ERROR_CODES | number | null;
}

export interface GroupsPageState {
  families: SmuleFamilyInfoEntities;
  familyIds: SmuleFamilyIds;
  cursor: Cursor;
  fetchingFamilies: boolean;
}

export interface PostEntity {
  postId: number;
  postOwner: Account;
  performanceKey: string;
}

export interface GroupPageState {
  selectedFamilyId: string | null;
  familyInfo: SmuleFamilyInfo | null;
  fetchingInfo: boolean;
  familyFeeds: FeedList;
  postEntities: PostEntity[];
  fetchingFeeds: boolean;
  familyMembers: MembersList;
  fetchingMembers: boolean;
  pendingMembers: {
    pendingRequests: MembersList;
    fetchingPendingRequests: boolean;
    pendingInvites: MembersList;
    fetchingPendingInvites: boolean;
  };
}

export interface SmuleFamily {
  smuleFamilyId: number;
  name: string;
  desc: string | null;
  picUrl: string;
  tags: string;
  smuleFamilyTag: string;
  enrollStatus: 0 | 1;
  approvalStatus: 0 | 1;
  expireAt: string | null;
  loc: string;
  lang: string;
}

export interface SmuleFamilyInfo extends SmuleFamily {
  postCount: number;
  memberCount: number;
  membership: Membership | null;
  inviteExpireAt: string | null;
  owner: Account | null;
  adminIcons: Account[];
}

type SmuleFamilyInfoEntities = { [key: number]: SmuleFamilyInfo };

type SmuleFamilyIds = number[];

interface FeedList {
  cursor: Cursor;
}

export interface MembersList {
  list: Account[];
  cursor: Cursor;
}

export interface Account {
  accountId: number;
  handle: string;
  blurb?: string | null;
  jid?: string;
  picUrl: string;
  subApps: string[];
  verifiedType: VerifiedType;
  firstName?: string | null;
  lastName?: string | null;
  verifiedUrls?: VerifiedUrl[];
  latitude?: string | null;
  longitude?: string | null;
  sfamCount?: number;
}

export enum Membership {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  PENDING_RQST = 'PENDING_RQST',
  PENDING_INVTN = 'PENDING_INVTN',
  GUEST = 'GUEST',
}

type Cursor =
  | 'start'
  | {
      hasNext: boolean;
      next?: string;
    };

interface VerifiedUrl {
  type: string;
  desc: string;
  url: string;
}

export enum CLIENT_ERROR_CODES {
  MISSING_FAMILY_IMAGE = 'MISSING_FAMILY_IMAGE',
  MISSING_FAMILY_NAME = 'MISSING_FAMILY_NAME',
  MISSING_FAMILY_HASHTAG = 'MISSING_FAMILY_HASHTAG',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  GENERAL_ERROR = 'GENERAL_ERROR',
}

export enum SNP_FAMILY_ROLES {
  OWNER = 0,
  ADMIN = 1,
  MEMBER = 2,
}

export enum GROUPS_FILTER_OPTIONS {
  MOST_POPULAR = 'POPULAR',
  MOST_RECENT = 'RECENT',
  NAMEAZ = 'AZ',
  NAMEZA = 'ZA',
}

export enum MANAGE_GROUP_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum FORM_FIELD_IDS {
  PIC_ID = 'picId',
  GROUP_NAME = 'name',
  GROUP_HASHTAG = 'sfamTag',
  DESCRIPTION = 'desc',
  HASHTAGS = 'tags',
  LOCATION = 'loc',
  LANGUAGE = 'lang',
  INVITE_ONLY = 'enrollStatus',
  AUTO_APPROVE = 'approvalStatus',
}

export enum MEMBERSHIP_ACTIONS {
  acceptInvite = 'acceptInvite',
  denyInvite = 'denyInvite',
  requestJoin = 'requestJoin',
  cancelJoin = 'cancelJoin',
  acceptJoin = 'acceptJoin',
  denyJoin = 'denyJoin',
}
