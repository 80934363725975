/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { RouterState } from 'connected-react-router';
import { createSelector } from 'reselect';
import qs from 'qs';
import { State } from '@app/reducer';

const getRouterState = (state: State): RouterState => state.router;
const getLocation = createSelector(getRouterState, (router) => router.location);
const getPathname = createSelector(
  getLocation,
  (location) => location.pathname
);
const getQuery = createSelector(getLocation, (location) =>
  qs.parse(location.search, { ignoreQueryPrefix: true })
);

const getCampaignAdId = createSelector(
  getQuery,
  (query) =>
    (query.utm_content || '').split('_')[
      (query.utm_content || '').split('_').length - 1
    ] || null // eslint-disable-line
);

const getIsJustCreated = createSelector(
  getQuery,
  (query) => !!query.just_created
);

const getIsChallenge = createSelector(
  getPathname,
  (url): boolean => !!url && url.startsWith('/c/')
);

export const RouterSelectors = {
  getCampaignAdId,
  getIsChallenge,
  getIsJustCreated,
  getPathname,
  getQuery,
};
