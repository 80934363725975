/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

const HEADER = 'header';
const ABOUT = 'about';
const FEATURES = 'features';
const CONTACT = 'contact';

export const autorapSections = {
  HEADER,
  ABOUT,
  FEATURES,
  CONTACT,
};

export const autorapLayoutSizes = {
  mobileHeaderHeight: 60,
  headerHeight: 100,
};

export const smuleLandingSections = {
  HEADER,
  ABOUT,
  FEATURES,
  CONTACT,
};

export const smuleLandingLayoutSizes = {
  mobileHeaderHeight: 60,
  headerHeight: 120,
};

export const COOKIES_BANNER_COOKIE_NAME = 'smule_cookie_banner_disabled';
export const COOKIES_BANNER_EXPIRATION = 20 * 365 * 24 * 60 * 60; // 20 years
export const COOKIES_LOGGED_OUT_INITIATED = 'logged_out_initiated';
export const CONSENT_COOKIES_NAME = 'smule_consent';
export const CONSENT_COOKIES_EXPIRATION = (365 / 2) * 24 * 60 * 60; // 6 mounts
export const FLASH_MESSAGE_COOKIE_NAME = 'flash_message';

export enum COOKIES_BANNER_VERSIONS {
  default = 1,
  gdpr = 2,
}

export enum CONSENT_COOKIES_CATEGORIES {
  marketing = 'marketing',
  functionality = 'functionality',
}

export const NOW_PLAYING_SINGING_TIPS_URL =
  'https://blog.smule.com/10-easy-tips-to-sound-good-on-smule';

export const EXPLORE_SINGING_TIPS_URL =
  'https://blog.smule.com/singing-tips-you-dont-want-to-miss-by-smule-experts';

export const COLORS = {
  baseBlue: '#3C86F7',
  blue100: '#F0F6FF',
  blue200: '#8BD2FD',
  blue300: '#25ADFB',
  blue400: '#26559D',
  baseMagenta: '#FD286E',
  magenta100: '#FEEEF3',
  magenta200: '#FEACC7',
  magenta300: '#FD6A99',
  magenta400: '#A11946',
  basePurple: '#6C2EFF',
  purple100: '#ECE8FF',
  purple200: '#9F85FF',
  purple300: '#8A59FF',
  purple400: '#4521B6',
  purple500: '#2D2867',
  purple600: '#110E39',
  baseTeal: '#0DD3FF',
  teal100: '#ECF7FB',
  teal200: '#7ECBE4',
  teal300: '#0F9ED0',
  teal400: '#004169',
  semanticRed: '#ED0000',
  semanticOrange: '#F76C09',
  semanticYellow: '#FFCE42',
  semanticGreen: '#00B818',
  baseSilver: '#D9E0E7',
  silver100: '#FBFBFB',
  silver200: '#F0F2F4',
  silver300: '#E4E9EE',
  silver400: '#C0CCD8',
  silver500: '#728CA6',
  baseGray: '#1F2021',
  gray100: '#C8CACF',
  gray200: '#AAADB4',
  gray300: '#81838C',
  gray400: '#585A64',
  white: '#FFFFFF',
  transparentWhite70: 'rgba(255, 255, 255, 0.7)',
  black: '#000000',
  homeBackgroundDesktop: '#673FF5',
  homeBackground: '#6E3AF6',
};

export const SHADOWS = {
  lightShadow: '0px 2px 8px rgba(18, 11, 39, 0.1)',
  lightInnerShadow: 'inset 0px 2px 1px rgba(18, 11, 39, 0.04)',
  mediumShadow: '0px 2px 6px rgba(18, 11, 39, 0.3)',
  heavyShadow: '0px 4px 12px rgba(31, 32, 33, 0.3)',
};

export const NAVIGATION_BAR_SIZES = {
  DESKTOP_HEIGHT_PX: 72,
  MOBILE_HEIGHT_PX: 64,
  JOURNEY_BANNER_HEIGHT_PX: 60,
  DESKTOP_APP_BUTTONS_BANNER_HEIGHT_PX: 54,
};
