/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import axios from 'axios';

import signedAjax from '@app/utils/signed_request.js';
import { doApiRequest } from './utils';

export const getPlayerId = () =>
  signedAjax({
    url: '/api/user/player-id',
    method: 'get',
  });

export const getSettings = (settingsIds) =>
  signedAjax({
    url: '/api/user/settings',
    method: 'get',
    params: { settingsIds },
  });

export const getCookieBannerVersion = () =>
  signedAjax({
    url: '/api/user/cookie-banner-version',
    method: 'get',
  });

export const getUserSubStatus = () =>
  signedAjax({
    url: '/api/social/getUserSubStatus',
    method: 'get',
  });

export const getFeedActivity = () =>
  doApiRequest<{ activity_count: number; has_activity: boolean }>({
    url: '/api/social/hasFeedActivity',
    method: 'get',
  });

export const getFollowees = (offset: number) =>
  axios({
    url: '/api/social/followees',
    method: 'get',
    params: { offset },
  });

export const getFollowers = () =>
  signedAjax({
    url: '/api/social/followers',
    method: 'get',
  });

export type LoginAPIType = {
  getPlayerId: typeof getPlayerId;
  getSettings: typeof getSettings;
  getCookieBannerVersion: typeof getCookieBannerVersion;
  getFeedActivity: typeof getFeedActivity;
  getUserSubStatus: typeof getUserSubStatus;
  getFollowees: typeof getFollowees;
  getFollowers: typeof getFollowers;
};

export const LoginApi = {
  getPlayerId,
  getSettings,
  getCookieBannerVersion,
  getFeedActivity,
  getUserSubStatus,
  getFollowees,
  getFollowers,
};
