/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import axios from 'axios';
import qs from 'qs';

import {
  hexMd5,
  CSRF_SELECTOR,
  SECRET,
  DECODED_KEY,
  PATH,
  CSRF,
  HEADER_NAME,
  CSRF_TOKEN_HEADER,
} from './sign.js';

export const getCsrf = () =>
  document.querySelector(CSRF_SELECTOR).getAttribute('content');

export const queryStringify = (data) => {
  if (data == null) return '';

  switch (typeof data) {
    case 'string':
      return data;
    default:
      // Warning this only accepts object, so no FormData
      // $.param is not strict. doesn't enocde '!', this encodes '!'
      // $.param encode q[]=1 as q%5B%5D=1 , this doesn't
      // $.param encode ' ' as '+',  this encodes as '%20'
      return qs.stringify(data);
  }
};

export const postErrorInterceptor = (error) => Promise.reject(error);

export const digest = (token, stringifiedData, path) => {
  // sort in order to make it independent from serialization order of toDigest
  const digestStr =
    `${SECRET}=${DECODED_KEY}&${PATH}=${path}&${CSRF}=${token}&${stringifiedData}`
      .split('&')
      .sort()
      .join('&');

  return hexMd5(digestStr);
};

const urlParse = (url) => {
  const parser = document.createElement('a');
  parser.href = url;

  return parser;
};

export const postInterceptor = (config) => {
  config.headers = config.headers || {};
  const token = getCsrf();
  const stringifiedData = queryStringify(config.data);
  const path = urlParse(config.url).pathname;

  if (stringifiedData.length > 0) {
    config.data = stringifiedData;
  }
  config.headers[CSRF_TOKEN_HEADER] = token;
  config.headers[HEADER_NAME] = digest(token, stringifiedData, path);
  return config;
};

const signedAjax = axios.create();
signedAjax.interceptors.request.use(postInterceptor, postErrorInterceptor);
export default signedAjax;
