/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { PlaylistTypes } from '@app/pages/NowPlaying/modules/playlists/types';
import axios from 'axios';

interface APIParamsType {
  primary?: 1;
  topics?: 1;
  secondary?: 1;
}

export const getExploreRecPlaylist = ({
  primaryExplore,
  topics,
  secondaryExplore,
}) => {
  const params: APIParamsType = {};
  if (primaryExplore) params.primary = 1;
  if (topics) params.topics = 1;
  if (secondaryExplore) params.secondary = 1;
  return axios({
    method: 'get',
    url: '/api/explore',
    params,
  });
};

// id could be topicId or playlist id
export const getPlaylist = (id, cursor, type: PlaylistTypes) =>
  axios({
    url: `/api/explore/${id}`,
    params: {
      cursor,
      type,
    },
  });

export const initPlaylist = (id, type: PlaylistTypes) => {
  switch (type) {
    case PlaylistTypes.RECOMMENDATIONS_LEGACY:
    case PlaylistTypes.LEGACY:
    case PlaylistTypes.EXPLORE:
    case PlaylistTypes.TOPIC:
      return getPlaylist(id, 'start', type);
  }

  return Promise.resolve();
};

export type PlaylistAPIType = {
  getExploreRecPlaylist: typeof getExploreRecPlaylist;
  initPlaylist: typeof initPlaylist;
  getPlaylist: typeof getPlaylist;
};

export const PlaylistApi = {
  getExploreRecPlaylist,
  initPlaylist,
  getPlaylist,
};
