/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import { GridContextType } from './GridTypings';

export const GridContext = React.createContext<GridContextType>({
  colNumber: 12,
  mobileGutterH: 10,
  tabletGutterH: 10,
  desktopGutterH: 10,
  mobileGutterV: 10,
  tabletGutterV: 10,
  desktopGutterV: 10,
});
