/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable from 'seamless-immutable';
import { AutocompleteSearchActionTypes } from './actions';
import { AutocompleteSearchState } from './types';

const initialState = Immutable<AutocompleteSearchState>({
  results: [],
});

export default (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case AutocompleteSearchActionTypes.SET_RESULTS: {
      const results = action.results instanceof Array ? action.results : [];
      newState = state.set('results', results);
      break;
    }
    default:
      break;
  }
  return newState;
};
