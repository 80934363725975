/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import {
  createCachedSelectorByPlayerId,
  PlayersSelectors,
} from '@app/components/Player/selectors';

export const STATE_KEY = 'VIDEO_JS';

const getVideoJSPlayer = createCachedSelectorByPlayerId(
  PlayersSelectors.getPlayerState,
  (playerState) => playerState[STATE_KEY]
);

// VideoJS Player Status
const getAutoplay: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.autoplay
);
const getReady = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.ready
);
const getCanPlay: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.canPlay
);
const getSeeking: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.getSeeking
);
const getPlayRequested: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.playRequested
);
const getHandleWaitingRequested: (state, props) => any =
  createCachedSelectorByPlayerId(
    getVideoJSPlayer,
    (playerState) => playerState.handleWaitingRequested
  );
const getPauseRequested: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.pauseRequested
);
const getPauseRequestedCs: (state, props) => any =
  createCachedSelectorByPlayerId(getVideoJSPlayer, (playerState) =>
    playerState.pauseRequested ? 'manual' : 'auto'
  );
const getPaused: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.paused
);
const getEnded: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.ended
);

const getDuration: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.duration
);
const getCurrentTime: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.currentTime
);
const getDroppedVideoFrames: (state, props) => any =
  createCachedSelectorByPlayerId(
    getVideoJSPlayer,
    (playerState) => playerState.droppedVideoFrames
  );
const getPreviousEndTime: (state, props?) => any =
  createCachedSelectorByPlayerId(getVideoJSPlayer, (playerState) =>
    playerState.previousEndTime.toFixed(2)
  );
const getCurrentTimeCs: (state, props?) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.currentTime.toFixed(2)
);

const getNewCurrentTime: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.newCurrentTime
);
const getListenStartTime: (state, props?) => any =
  createCachedSelectorByPlayerId(getVideoJSPlayer, (playerState) =>
    playerState.listenStartTime.toFixed(2)
  );
const getManualStartAction: (state, props) => any =
  createCachedSelectorByPlayerId(getVideoJSPlayer, (playerState) =>
    playerState.manualStartAction ? 'manual' : 'auto'
  );
const getSeekedToPosition: (state, props?) => any =
  createCachedSelectorByPlayerId(
    getVideoJSPlayer,
    (playerState) => playerState.seekedToPosition
  );
const getVolume: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.volume
);
const getMuted: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.muted
);
const getConsumptionType: (state, props) => any =
  createCachedSelectorByPlayerId(getVideoJSPlayer, (player) =>
    player.consumptionType === 0
      ? 'active'
      : player.consumptionType === 1
      ? 'passive'
      : 'background'
  );
const getLoadStartTime: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.loadStartTime
);
const getCanPlayTime: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.canPlayTime
);
const getError: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.error
);
const getStallCount: (state, props) => any = createCachedSelectorByPlayerId(
  getVideoJSPlayer,
  (playerState) => playerState.stallCount
);

export const VideoJSPlayerSelectors = {
  getAutoplay,
  getReady,
  getCanPlay,
  getSeeking,
  getPlayRequested,
  getHandleWaitingRequested,
  getPauseRequested,
  getPauseRequestedCs,
  getPaused,
  getEnded,
  getConsumptionType,

  getDuration,
  getCurrentTime,
  getPreviousEndTime,
  getCurrentTimeCs,
  getNewCurrentTime,
  getListenStartTime,
  getManualStartAction,

  getVolume,
  getMuted,
  getSeekedToPosition,
  getLoadStartTime,
  getCanPlayTime,
  getError,
  getStallCount,
  getDroppedVideoFrames,
};
