/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import axios from 'axios';
import StackTrace from 'stacktrace-js';
import { smuleLog } from './utils';

const MAX_FATAL_ERRORS_COUNT = 5;

interface ErrorInfo {
  errorMsg: string;
  file?: string | null;
  lineNumber?: number | null;
  colNumber?: number | null;
  userAgent?: string;
}

const sendError = (
  stacktrace,
  { errorMsg, file, lineNumber, colNumber, userAgent }: ErrorInfo
) => {
  axios({
    url: '/s/js-log',
    method: 'post',
    params: {
      error_message: errorMsg,
      file: file || '',
      url: window.location.href,
      line_number: lineNumber || '',
      col_number: colNumber || '',
      stack: stacktrace || '',
      ua: userAgent,
      client: 'smule_spa_v2',
    },
  });
};

const GOOGLE_BOT_REGEX = /googlebot/i;
// Only send fatal error to smule MAX_FATAL_ERRORS_COUNT times
let sentErrorsCount = 0;
export const smuleError = (errorMsg, file, lineNumber, colNumber, error) => {
  try {
    const userAgent = window.navigator.userAgent;
    const errorInfo = { errorMsg, file, lineNumber, colNumber, userAgent };
    console.log(errorInfo);
    if (
      sentErrorsCount < MAX_FATAL_ERRORS_COUNT &&
      !GOOGLE_BOT_REGEX.test(userAgent)
    ) {
      sentErrorsCount += 1;
      if (error) {
        StackTrace.fromError(error, { offline: true })
          .then((stackframes) => {
            const stackText: string[] = [];
            for (let i = 0; i < stackframes.length; i++) {
              stackText.push(stackframes[i].toString());
            }
            sendError(stackText.join('\n'), errorInfo);
          })
          .catch((e) => {
            sendError(e.message, errorInfo);
          });
      } else {
        sendError(undefined, { errorMsg });
      }
    }
  } catch (e) {
    smuleLog('Failed to send error: ', errorMsg, file, lineNumber, e);
  }
  return false;
};

export const emitSmuleError = (errorMsg: string) =>
  smuleError(errorMsg, null, null, null, null);

const ErrorLogger = {
  init: () => {
    window.onerror = smuleError;
    window.addEventListener('unhandledrejection', (e) => {
      smuleError(
        (e.reason || {}).message || e.reason,
        null,
        null,
        null,
        e.reason
      );
    });
  },
};

export default ErrorLogger;
