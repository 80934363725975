/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable max-len */
const tabletLandscapeBreak = 1024;
const tabletPortraitBreak = 768;

const mobileLandscapeBreak = 736;
const mobilePortraitBreak = 599;

const mobileSmallLandscapeBreak = 568;
const mobileSmallPortraitBreak = 320;

export const desktop =
  `@media only screen and (min-width: ${
    tabletLandscapeBreak + 1
  }px) and (orientation: landscape),` +
  `screen and (min-width: ${
    tabletPortraitBreak + 1
  }px) and (orientation: portrait)`;

export const tablet =
  `@media only screen and (max-width: ${tabletLandscapeBreak}px) and (orientation: landscape),` +
  `screen and (max-width: ${tabletPortraitBreak}px) and (orientation: portrait)`;

export const tabletLandscape = `@media only screen and (max-width: ${tabletLandscapeBreak}px) and (orientation: landscape)`;

export const tabletPortrait = `@media only screen and (max-width: ${tabletPortraitBreak}px) and (orientation: portrait)`;

export const mobile =
  `@media only screen and (max-width: ${mobileLandscapeBreak}px) and (orientation: landscape),` +
  `screen and (max-width: ${mobilePortraitBreak}px) and (orientation: portrait)`;

export const mobileLandscape = `@media only screen and (max-width: ${mobileLandscapeBreak}px) and (orientation: landscape)`;

export const mobileSmall =
  `@media only screen and (max-width: ${mobileSmallLandscapeBreak}px) and (orientation: landscape),` +
  `screen and (max-width: ${mobileSmallPortraitBreak}px) and (orientation: portrait)`;

export const retina =
  '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

export const print = '@media print';

export const phone =
  '@media only screen and (min-device-width : 320px) and (max-device-width : 480px)';

export const smallScreenOrAbove = `@media only screen and (min-width: ${
  mobilePortraitBreak + 1
}px)`;
export const mediumScreenOrAbove = `@media only screen and (min-width: ${
  tabletPortraitBreak + 1
}px)`;
export const largeScreenOrAbove = `@media only screen and (min-width: ${
  tabletLandscapeBreak + 1
}px)`;

export const extraLargeScreenOrAbove =
  '@media only screen and (min-width: 1280px)';

export const heightBelow860 = '@media only screen and (max-height: 860px)';

export const heightBelow600 = '@media only screen and (max-height: 600px)';

const iPadPortrait =
  'only screen and (width:768px) and (min-height:824px) and (max-height:1024px) and (-webkit-min-device-pixel-ratio: 1)';
const iPadLandscape =
  'only screen and (width:1024px) and (min-height:568px) and (max-height:768px) and (-webkit-min-device-pixel-ratio: 1)';
const iPadPro11inchPortrait =
  'only screen and (width:834px) and (min-height:994px) and (max-height:1194px) and (-webkit-min-device-pixel-ratio: 2)';
const iPadPro11inchLandscape =
  'only screen and (width:1194px) and (min-height:634px) and (max-height:834px) and (-webkit-min-device-pixel-ratio: 2)';
const iPadPro13inchPortrait =
  'only screen and (width:1024px) and (min-height:1266px) and (max-height:1366px) and (-webkit-min-device-pixel-ratio: 2)';
const iPadPro13inchLandscape =
  'only screen and (width:1366px) and (min-height:824px) and (max-height:1024px) and (-webkit-min-device-pixel-ratio: 2)';

export const mobileHeaderBreakPoint = `@media only screen and (max-width: 768px), ${iPadPortrait}, ${iPadLandscape}, ${iPadPro11inchPortrait}, ${iPadPro11inchLandscape}, ${iPadPro13inchPortrait}, ${iPadPro13inchLandscape}`;
