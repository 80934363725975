/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import styled from 'styled-components';

const Link = styled.a.attrs(() => ({
  target: '_blank',
}))`
  color: ${(props) => props.theme.colors.basePurple};
`;

export default Link;
