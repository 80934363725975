/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import axios, { AxiosRequestConfig } from 'axios';
import signedAjax from '@app/utils/signed_request.js';

export const doApiRequest = <T = unknown>(
  config: AxiosRequestConfig
): Promise<T> => {
  const client = config.method?.toLowerCase() === 'get' ? axios : signedAjax;
  return client.request<T>(config).then((r) => r.data);
};
