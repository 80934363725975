/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export const hasFacebookPixel = () => typeof window['fbq'] !== 'undefined';

export const trackFacebookPixel = (name): void => {
  if (hasFacebookPixel()) {
    window['fbq']('trackCustom', name);
  }
};

export const facebookPixelGrantConsent = (): void => {
  if (hasFacebookPixel()) {
    window['fbq']('consent', 'grant');
  }
};
