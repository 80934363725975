/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { combineReducers } from 'redux';

import RouteChangeBlockerReducer from './routeChangeBlocker/reducer';
import { STATE_KEY as ROUTE_CHANGE_BLOCKER_STATE_KEY } from './routeChangeBlocker/actions';
import AutocompleteReducer from './autocompleteSearch/reducer';
import { STATE_KEY as AUTOCOMPLETE_STATE_KEY } from './autocompleteSearch/actions';
import { WidgetsState } from './types';
import { warningReducer } from '@app/pages/Home/components/Warning/reducer';
import { browserNavigatedReducer } from './browserNavigated/browserNavigatedReducer';
import { JourneyBannerReducer } from './JourneyBanner/reducer';
import { STATE_KEY as JOURNEY_BANNER_STATE_KEY } from './JourneyBanner/actions';
import toastNotificationsReducer from '@app/layout/ToastNotifications/reducer';

export const WIDGETS_STATE_KEY = 'widgets';

const widgetsReducer = combineReducers<WidgetsState>({
  [ROUTE_CHANGE_BLOCKER_STATE_KEY]: RouteChangeBlockerReducer,
  [AUTOCOMPLETE_STATE_KEY]: AutocompleteReducer,
  warning: warningReducer,
  browserNavigated: browserNavigatedReducer,
  [JOURNEY_BANNER_STATE_KEY]: JourneyBannerReducer,
  toastNotifications: toastNotificationsReducer,
});

export default widgetsReducer;
