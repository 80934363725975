/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Config from '@app/WebConfig';
import { FLOW_ID } from '@app/layout/Login/reducer';
import Wrapper from '@app/components/Wrapper';
import ScrollToTop from '@app/components/ScrollToTop';
import EnsemblesPage from '@app/pages/NowPlaying/EnsemblesPage';
import injectPageReducers from './utils/injectPageReducers';
import { RedirectToProfile } from '@app/pages/Profile/RedirectToProfile';

/* Layouts */
import { pageMeta } from '@app/layout/pageMeta';
import PageLayout from '@app/layout/PageLayout';
import Loader, { LoaderSize, LoaderSkin } from '@app/components/Loaders';

const PageLayoutI18n = lazy(() => import('@app/layout/PageLayoutI18n'));

/* Lazy Page declarations with reducers */
const Home = lazy(() => import('./pages/Home/Home').then(injectPageReducers));
const Explore = lazy(() => import('./pages/Explore').then(injectPageReducers));
const Listen = lazy(() => import('./pages/Listen').then(injectPageReducers));
const TopArtists = lazy(() =>
  import('./pages/TopArtists/TopArtists').then(injectPageReducers as any)
);
const NowPlaying = lazy(() =>
  import('./pages/NowPlaying').then(injectPageReducers as any)
);
const Groups = lazy(() => import('./pages/Groups').then(injectPageReducers));
const AwesomePlaylist = lazy(() =>
  import('./pages/AwesomePlaylists').then(injectPageReducers)
);
const Profile = lazy(() => import('./pages/Profile').then(injectPageReducers));
const CommunityGuidelines = lazy(() =>
  import('./pages/Legal/CommunityGuidelines').then(injectPageReducers)
);
const Copyright = lazy(() =>
  import('./pages/Legal/Copyright').then(injectPageReducers)
);
const Privacy = lazy(() =>
  import('./pages/Legal/Privacy').then(injectPageReducers)
);
const PrivacyCalifornia = lazy(() =>
  import('./pages/Legal/PrivacyCalifornia').then(injectPageReducers)
);
const TermsOfService = lazy(() =>
  import('./pages/Legal/TermsOfService').then(injectPageReducers)
);

const TipsTerms = lazy(() =>
  import('./pages/Legal/TipsTerms').then(injectPageReducers)
);

const SummaryOfChanges = lazy(() =>
  import('./pages/Legal/SummaryOfChanges').then(injectPageReducers)
);

/* Lazy Page declarations without reducers */
const GetVipICICI = lazy(() => import('./pages/GetVipICICI/GetVipICICI'));
const Login = lazy(() => import('./pages/LoginPage'));
const Apps = lazy(() => import('./pages/Apps'));
const Press = lazy(() => import('./pages/Press'));
const CookiePolicy = lazy(() => import('./pages/CookiePolicy'));
const Acknowledgments = lazy(() => import('./pages/Acknowledgments'));
const About = lazy(() => import('./pages/About/About'));
const Jobs = lazy(() => import('./pages/Jobs/Jobs'));
const SmuleScience = lazy(() => import('./pages/SmuleScience'));
const Patents = lazy(() => import('./pages/Patents/Patents'));
const Notifications = lazy(() => import('./pages/Notifications'));
const StylesLanding = lazy(() => import('./pages/StylesLanding'));
const NDA = lazy(() => import('./pages/Legal/NDA'));
const Arrangement = lazy(() =>
  import('./pages/Arrangement').then(injectPageReducers)
);
const Songbook = lazy(() =>
  import('./pages/Songbook').then(injectPageReducers)
);

/* Pages */
const HomePage = (props) => (
  <PageLayout component={Home} pageProps={pageMeta.home} {...props} />
);
const ExplorePage = (props) => (
  <PageLayout component={Explore} pageProps={pageMeta.explore} {...props} />
);
const ListenPage = (props) => (
  <PageLayout component={Listen} pageProps={pageMeta.listen} {...props} />
);
const TopArtistPage = (props) => (
  <PageLayout
    component={TopArtists}
    pageProps={pageMeta.partnerArtists}
    {...props}
  />
);
const GetVipICICIPage = (props) => (
  <PageLayout
    component={GetVipICICI}
    pageProps={pageMeta.getVipICICI}
    {...props}
  />
);
const LoginPage = (props) => (
  <PageLayout component={Login} pageProps={pageMeta.home} {...props} />
);
const NowPlayingPage = (props) => (
  <PageLayout
    component={NowPlaying}
    pageProps={pageMeta.nowPlaying}
    {...props}
  />
);
const GroupsPage = (props) => (
  <PageLayout component={Groups} pageProps={pageMeta.groups} {...props} />
);
const AppsPage = (props) => (
  <PageLayout component={Apps} pageProps={pageMeta.apps} {...props} />
);
const PressPage = (props) => (
  <PageLayout component={Press} pageProps={pageMeta.press} {...props} />
);
const CookiePolicyPage = (props) => (
  <PageLayout
    component={CookiePolicy}
    pageProps={pageMeta.cookiePolicy}
    {...props}
  />
);
const AcknowledgmentsPage = (props) => (
  <PageLayout
    component={Acknowledgments}
    pageProps={pageMeta.acknowledgments}
    {...props}
  />
);
const AboutPage = (props) => (
  <PageLayout component={About} pageProps={pageMeta.about} {...props} />
);
const JobsPage = (props) => (
  <PageLayout component={Jobs} pageProps={pageMeta.jobs} {...props} />
);
const SmuleSciencePage = (props) => (
  <PageLayout
    component={SmuleScience}
    pageProps={pageMeta.smuleScience}
    {...props}
  />
);
const ProfilePage = (props) => (
  <PageLayout
    component={Profile}
    pageProps={pageMeta.profilePage}
    omitEvent
    {...props}
  />
);

const AwesomePlaylistPage = (props) => (
  <PageLayout
    component={AwesomePlaylist}
    pageProps={pageMeta.awesomePlaylistPage}
    {...props}
  />
);

const NotificationsPage = (props) => (
  <PageLayout
    component={Notifications}
    pageProps={pageMeta.notifications}
    {...props}
  />
);

const StylesLandingPage = (props) => (
  <PageLayout
    component={StylesLanding}
    pageProps={pageMeta.stylesLanding}
    {...props}
  />
);

// temporary fallback component for the Legal pages
const FallBackPageLoading = () => (
  <div style={{ width: '30px', height: '30px', margin: 'auto' }}>
    <Loader size={LoaderSize.big} skin={LoaderSkin.neutral} />
  </div>
);

const CommunityGuidelinesPage = (props) => (
  <Suspense fallback={<FallBackPageLoading />}>
    <PageLayoutI18n
      component={CommunityGuidelines}
      pageProps={pageMeta.communityGuidelines}
      {...props}
    />
  </Suspense>
);

const CopyrightPage = (props) => (
  <Suspense fallback={<FallBackPageLoading />}>
    <PageLayoutI18n
      component={Copyright}
      pageProps={pageMeta.copyright}
      {...props}
    />
  </Suspense>
);

const PrivacyPage = (props) => (
  <Suspense fallback={<FallBackPageLoading />}>
    <PageLayoutI18n
      component={Privacy}
      pageProps={pageMeta.privacy}
      {...props}
    />
  </Suspense>
);

const PrivacyCaliforniaPage = (props) => (
  <Suspense fallback={<FallBackPageLoading />}>
    <PageLayoutI18n
      component={PrivacyCalifornia}
      pageProps={pageMeta.privacyCalifornia}
      {...props}
    />
  </Suspense>
);

const TermsOfServicePage = (props) => (
  <Suspense fallback={<FallBackPageLoading />}>
    <PageLayoutI18n
      component={TermsOfService}
      pageProps={pageMeta.termsOfService}
      {...props}
    />
  </Suspense>
);

const TipsTermsPage = (props) => (
  <PageLayout component={TipsTerms} pageProps={pageMeta.tipsTerms} {...props} />
);

const SummaryOfChangesPage = (props) => (
  <Suspense fallback={<FallBackPageLoading />}>
    <PageLayoutI18n
      component={SummaryOfChanges}
      pageProps={pageMeta.summaryOfChanges}
      {...props}
    />
  </Suspense>
);

const ArrangementPage = (props) => (
  <PageLayout
    component={Arrangement}
    pageProps={pageMeta.arrangement}
    {...props}
  />
);

const SongbookPage = (props) => (
  <PageLayout component={Songbook} pageProps={pageMeta.songbook} {...props} />
);

const NDAPage = (props) => (
  <Suspense fallback={<FallBackPageLoading />}>
    <PageLayout component={NDA} pageProps={pageMeta.nda} {...props} />
  </Suspense>
);

const PatentsPage = (props) => (
  <PageLayout component={Patents} pageProps={pageMeta.patents} {...props} />
);
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  public render(): JSX.Element {
    return (
      <Wrapper showFooter={!Config.footerConfig.show}>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/explore">
            <ExplorePage />
          </Route>
          <Route path="/listen">
            <ListenPage />
          </Route>
          <Route path="/top-artists" component={TopArtistPage} />
          <Route path="/icici-2020" component={GetVipICICIPage} />
          <Route path="/login">
            <LoginPage
              flow={FLOW_ID.LOGIN}
              bgColor={pageMeta.home.themeColor}
            />
          </Route>
          <Route path="/user/login">
            <LoginPage
              flow={FLOW_ID.LOGIN}
              bgColor={pageMeta.home.themeColor}
            />
          </Route>
          <Route path="/user/register">
            <LoginPage
              flow={FLOW_ID.LOGIN}
              bgColor={pageMeta.home.themeColor}
            />
          </Route>
          {/* Force rerender notif page due to  WEB-11962*/}
          <Route
            path="/user/notifications"
            render={() => <NotificationsPage key={Date.now()} />}
          />
          <Route path="/user/password">
            <LoginPage
              flow={FLOW_ID.RESET_PASSWORD}
              bgColor={pageMeta.home.themeColor}
            />
          </Route>
          <Route path="/password_reset">
            <LoginPage
              flow={FLOW_ID.SET_PASSWORD}
              bgColor={pageMeta.home.themeColor}
            />
          </Route>
          <Route path="/c/:perfKey">
            <NowPlayingPage />
          </Route>
          <Route path="/p/:perfKey">
            <NowPlayingPage />
          </Route>
          <Route exact path="/recording/:perfSEOId/:perfKey/ensembles">
            <EnsemblesPage />
          </Route>
          <Route path="/recording/:recordingSlug/:perfKey">
            <NowPlayingPage />
          </Route>
          <Route path="/sing-recording/:perfKey">
            <NowPlayingPage />
          </Route>
          <Route path="/groups">
            <GroupsPage />
          </Route>
          <Route path="/apps">
            <AppsPage />
          </Route>
          <Route path="/press">
            <PressPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/jobs">
            <JobsPage />
          </Route>
          <Route path="/cookiepolicy">
            <CookiePolicyPage />
          </Route>
          <Route path="/acknowledgments">
            <AcknowledgmentsPage />
          </Route>
          <Route path="/smule-science">
            <SmuleSciencePage />
          </Route>
          <Route path="/patents">
            <PatentsPage />
          </Route>
          <Route path="/s/patents/embed">
            <PatentsPage />
          </Route>
          <Route path="/styles">
            <StylesLandingPage />
          </Route>
          <Route path="/styles/embed">
            <StylesLandingPage />
          </Route>
          <Route exact path="/user/profile">
            <RedirectToProfile />
          </Route>
          <Route path="/playlist/:id">
            <AwesomePlaylistPage />
          </Route>
          <Route path="/profile/:id">
            <ProfilePage />
          </Route>
          <Route path="/s/communityguidelines">
            <CommunityGuidelinesPage />
          </Route>
          <Route path="/:lng/s/communityguidelines">
            <CommunityGuidelinesPage />
          </Route>
          <Route path="/s/communityguidelines/embed">
            <CommunityGuidelinesPage />
          </Route>
          <Route path=":lng/s/communityguidelines/embed">
            <CommunityGuidelinesPage />
          </Route>
          {/* this is the SEO arrangement path*/}
          {/* /song/<arrangement-slug>/<arrangement-key>/arrangement */}
          <Route path="/song/:arrangementSlug/:arrangementKey/arrangement">
            <ArrangementPage />
          </Route>
          {/* this is the LEGACY arrangement path*/}
          {/* /song/arr/<arrangement-key>/<optional-slug> */}
          <Route path="/song/arr/:arrangementKey/:optionalSlug?">
            <ArrangementPage />
          </Route>
          {/* these are the RAVEN song arrangement paths*/}
          {/* /song/<song-slug>/<song_id> */}
          {/* /song/<song_id>/<optional-slug> */}
          <Route path="/song/:arg1/:arg2?">
            <ArrangementPage />
          </Route>
          <Route path="/copyright">
            <CopyrightPage />
          </Route>
          <Route path="/:lng/copyright">
            <CopyrightPage />
          </Route>
          <Route path="/copyright/embed">
            <CopyrightPage />
          </Route>
          <Route path="/privacy/california">
            <PrivacyCaliforniaPage />
          </Route>
          <Route path="/:lng/privacy/california">
            <PrivacyCaliforniaPage />
          </Route>
          <Route path="/privacy">
            <PrivacyPage />
          </Route>
          <Route path="/:lng/privacy">
            <PrivacyPage />
          </Route>
          <Route path="/privacy/embed">
            <PrivacyPage />
          </Route>
          <Route path="/termsofservice">
            <TermsOfServicePage />
          </Route>
          <Route path="/:lng/termsofservice">
            <TermsOfServicePage />
          </Route>
          <Route path="/termsofservice/embed">
            <TermsOfServicePage />
          </Route>
          <Route path="/tips/terms">
            <TipsTermsPage />
          </Route>
          <Route path="/tips/terms/embed">
            <TipsTermsPage />
          </Route>
          <Route path="/summary_of_changes">
            <SummaryOfChangesPage />
          </Route>
          <Route path="/:lng/summary_of_changes">
            <SummaryOfChangesPage />
          </Route>
          <Route path="/songs">
            <SongbookPage />
          </Route>
          <Route path="/s/nda/embed">
            <NDAPage />
          </Route>
          {/* Keep this route last! */}
          <Route path="/:handle">
            <ProfilePage />
          </Route>
        </Switch>
      </Wrapper>
    );
  }
}

export default App;
