/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useState, useEffect } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { State } from '@app/reducer';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  smuleAppIcon,
  smuleAppIcon2x,
  uploadStickerIcon,
  uploadStickerIcon2x,
  autorapAppIcon,
} from '@app/assets';
import { COLORS, NAVIGATION_BAR_SIZES } from '@app/constants';
import IconChevronRight from '@app/components/icons/IconChevronRight';
import SocialIcons from '@app/components/SocialIcons';
import Config from '@app/WebConfig';
import { convertHexToRgba, emitAnalyticsEvent } from '@app/utils/utils';
import useRequireLogin from '@app/layout/Login/useRequireLogin';
import { updateSNPSettings } from '@app/layout/Login/async-actions';
import { Selectors as LoginSelectors } from '@app/layout/Login/reducer';
import SocialLink from './SocialLink';
import UploadButton, {
  UploadButtonStyles,
  LabelWrapper,
  MainLabel,
  SubLabel,
} from './UploadButton';
import * as screenType from '@app/styles/style_breakpoints';
import { I18nPropsType } from '@app/i18n/types';
import LanguageSelect from './LanguageSelect';

const MAX_DESKTOP_WIDTH_PX = 712;
const MAX_DESKTOP_HEIGHT_PX = 328;
const HAMBURGER_BTN_WIDTH = 56;
const HAMBURGER_BTN_WIDTH_MOBILE = 48;

interface ContainerProps {
  isOpened: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${COLORS.purple300};
  width: ${(props) =>
    props.isOpened ? MAX_DESKTOP_WIDTH_PX : HAMBURGER_BTN_WIDTH}px;
  height: ${(props) =>
    props.isOpened ? MAX_DESKTOP_HEIGHT_PX + 'px' : '100%'};
  z-index: 99;

  ${screenType.mobileHeaderBreakPoint} {
    width: ${(props) =>
      props.isOpened ? '100%' : HAMBURGER_BTN_WIDTH_MOBILE + 'px'};
    height: ${(props) => (props.isOpened ? 'auto' : '100%')};
  }
`;

const ToggleMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: ${NAVIGATION_BAR_SIZES.DESKTOP_HEIGHT_PX}px;
  padding: 0;
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active {
    opacity: 0.7;
  }

  ${screenType.mobileHeaderBreakPoint} {
    width: 48px;
    height: ${NAVIGATION_BAR_SIZES.MOBILE_HEIGHT_PX}px;
  }
`;

const HamburgerIcon = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 3px;
  background-color: ${COLORS.white};
  border-radius: 1.5px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: ${COLORS.white};
    border-radius: 1.5px;
  }

  &:before {
    top: -7px;
  }

  &:after {
    bottom: -7px;
  }
`;

const XIcon = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 9px;
    width: 3px;
    height: 21px;
    background-color: ${COLORS.white};
    border-radius: 1.5px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const BackIconUpperSlant = styled.span``;
const BackIconLowerSlant = styled.span``;
const BackIconLine = styled.span``;
const BackIconStyles = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  ${BackIconUpperSlant}, ${BackIconLowerSlant}, ${BackIconLine} {
    display: inline-block;
    position: absolute;
    background-color: ${COLORS.white};
    border-radius: 1.5px;
    transform-origin: center left;
  }

  ${BackIconUpperSlant} {
    transform: rotate(45deg);
    top: calc(50% - 3px);
    left: 3px;
    width: 12px;
    height: 3px;
  }

  ${BackIconLowerSlant} {
    transform: rotate(-45deg);
    bottom: calc(50% - 2px);
    left: 3px;
    width: 12px;
    height: 3px;
  }

  ${BackIconLine} {
    bottom: calc(50% - 1px);
    left: 3px;
    width: 16px;
    height: 3px;
  }
`;

const BackIcon = () => (
  <BackIconStyles>
    <BackIconUpperSlant />
    <BackIconLowerSlant />
    <BackIconLine />
  </BackIconStyles>
);

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${convertHexToRgba(COLORS.purple500, 0.5)};
  z-index: 90;
`;

const PrimaryNavigation = styled.div`
  flex: 1;
`;

const NavigationWrapper = styled.div`
  display: flex;
  padding: 59px 56px 0;

  & > * {
    flex: 1;
  }

  & > *:not(:last-child) {
    margin-right: 24px;
  }

  ${screenType.mobileHeaderBreakPoint} {
    flex-direction: column;
    padding: 51px 48px 41px;
  }
`;

const SecondaryNavigation = styled.div`
  flex: 1;
  background-color: ${COLORS.purple500};
`;

const SecondaryNavigationWrapper = styled(NavigationWrapper as any)`
  padding-top: 18px;
  padding-bottom: 18px;

  & > *:not(:last-child) {
    flex: 0 50%;
    margin-right: 0;
  }

  ${screenType.mobileHeaderBreakPoint} {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 18px;
    padding-bottom: 0;
  }
`;

const SecondaryNavigationColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SecondaryNavigationColumnSocials = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${screenType.mobileHeaderBreakPoint} {
    flex-direction: row;
    flex-direction: column-reverse;
  }
`;

const NavLink = styled.a`
  color: ${COLORS.white};
  font-weight: bold;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${COLORS.transparentWhite70};
  }
`;

const PrimaryNavLink = styled(NavLink as any)`
  font-size: 24px;
  line-height: 40px;
`;

const SubmenuLink = styled.button`
  color: ${COLORS.white};
  background: none;
  border: none;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  text-decoration: none;
  position: relative;

  &:hover,
  &:active {
    color: ${COLORS.transparentWhite70};
    outline: none;
  }

  &:focus {
    outline: none;
  }

  svg {
    vertical-align: middle;
  }
`;

const PrimarySPANavLink = styled(Link).attrs((props) => ({
  to: props.href || '#',
}))`
  color: ${COLORS.white};
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${COLORS.transparentWhite70};
  }
`;

const AppsLinkWrapper = styled.span`
  margin-right: 0 !important;
`;

const MobileLinkWrapper = styled.span`
  display: none;

  ${screenType.mobileHeaderBreakPoint} {
    display: initial;
  }
`;

const SecondarySPANavLink = styled(Link).attrs((props) => ({
  to: props.href || '#',
}))`
  color: ${COLORS.white};
  font-size: 17px;
  line-height: 32px;
  text-decoration: none;
  font-weight: bold;

  &:hover,
  &:active {
    color: ${COLORS.transparentWhite70};
  }
`;

const SecondaryNavLink = styled(NavLink as any)`
  font-size: 17px;
  line-height: 32px;
`;

const SocialIconsColumn = styled(SecondaryNavigationColumn as any)`
  margin-top: 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  ${screenType.mobileHeaderBreakPoint} {
    justify-content: space-between;
    margin-top: 26px;
    margin-bottom: 20px;
  }
`;

const NavigationContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
`;

const ListWrapper = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;

  li {
    ${UploadButtonStyles} {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 0;
        height: 2px;
        background-color: ${COLORS.purple500};
        opacity: 0.1;
      }
    }
    ${LabelWrapper} {
      display: block;
    }
    ${MainLabel},
    ${SubLabel} {
      font-size: 14px;
      line-height: 20px;
      color: ${COLORS.white};
    }
    &:last-child {
      ${UploadButtonStyles} {
        &:before {
          display: none;
        }
      }
    }
    &:hover {
      ${MainLabel},
      ${SubLabel} {
        color: ${COLORS.white};
      }
    }
  }
`;

interface HamburgerMenuStateProps {
  referralEnabledSetting?: boolean;
}

interface HamburgerMenuDispatchProps {
  getSnpSettings: () => void;
}

interface HamburgerMenuOwnProps {
  i18nProps: I18nPropsType;
}

type HamburgerMenuProps = HamburgerMenuStateProps &
  HamburgerMenuDispatchProps &
  HamburgerMenuOwnProps;

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  referralEnabledSetting,
  getSnpSettings,
  i18nProps,
}) => {
  const [showLoginFlowForUploadSong] = useRequireLogin(
    '',
    undefined,
    () => (window.location.href = Config.siteLinks.upload)
  );
  const [showLoginFlowForUploadSticker] = useRequireLogin(
    '',
    undefined,
    () => (window.location.href = Config.siteLinks.upload_sticker)
  );
  const [showLoginFlowForUploadBeat] = useRequireLogin(
    '',
    undefined,
    () => (window.location.href = Config.siteLinks.upload_beat)
  );
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isSubmenuOpened, setIsSubmenuOpened] = useState(false);
  const { t, isEnabled: isI18nEnabled }: I18nPropsType = i18nProps;
  useEffect(() => {
    if (isMenuOpened && referralEnabledSetting === undefined) {
      getSnpSettings();
    }
    if (isMenuOpened) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpened]);

  const toggleSubmenu = () => {
    setIsSubmenuOpened(!isSubmenuOpened);
  };

  const toggleMenu = () => {
    emitAnalyticsEvent({
      eventLabel: isMenuOpened ? 'close' : 'open',
      eventName: 'Hamburger toggle',
      eventCategory: isMenuOpened ? 'close' : 'open',
    });
    isSubmenuOpened && toggleSubmenu();
    setIsMenuOpened(!isMenuOpened);
  };
  const closeMenuOnNavigatingToPage = () => {
    if (isMenuOpened) {
      setIsMenuOpened(false);
    }
  };
  const renderContent = () => {
    return (
      <NavigationContainer>
        <PrimaryNavigation>
          <NavigationWrapper>
            {isSubmenuOpened ? (
              <ListWrapper>
                <li>
                  <UploadButton
                    onClick={showLoginFlowForUploadSong}
                    testId="navbar-upload"
                    mainLabel="Upload a song to Smule. "
                    subLabel="Available only on desktop browsers."
                    disabled
                    backgroundUrl={smuleAppIcon}
                    backgroundUrl2x={smuleAppIcon2x}
                  />
                </li>
                <li>
                  <UploadButton
                    onClick={showLoginFlowForUploadSticker}
                    testId="navbar-upload-sticker"
                    mainLabel="Upload a sticker to Smule "
                    subLabel="and become part of our catalog."
                    backgroundUrl={uploadStickerIcon}
                    backgroundUrl2x={uploadStickerIcon2x}
                  />
                </li>
                <li>
                  <UploadButton
                    onClick={showLoginFlowForUploadBeat}
                    testId="navbar-upload-beat"
                    mainLabel="Upload a beat to AutoRap "
                    subLabel="and get discovered."
                    backgroundUrl={autorapAppIcon}
                    backgroundUrl2x={autorapAppIcon}
                  />
                </li>
              </ListWrapper>
            ) : (
              <React.Fragment>
                <MobileLinkWrapper>
                  <PrimarySPANavLink
                    href={Config.siteLinks.root}
                    data-test-id="navbar-homepage"
                  >
                    {t('web.site-layout.home-page-title', 'Home')}
                  </PrimarySPANavLink>
                </MobileLinkWrapper>
                <MobileLinkWrapper>
                  <PrimarySPANavLink
                    href={Config.siteLinks.explore}
                    data-test-id="navbar-explore"
                  >
                    {t('web.site-layout.explore-page-title', 'Explore')}
                  </PrimarySPANavLink>
                </MobileLinkWrapper>
                <MobileLinkWrapper>
                  <PrimaryNavLink
                    href={Config.siteLinks.songbook}
                    data-test-id="navbar-songbook"
                  >
                    {t('web.site-layout.songs-link', 'Songbook')}
                  </PrimaryNavLink>
                </MobileLinkWrapper>
                <span>
                  <PrimarySPANavLink
                    href={Config.siteLinks.partnerartists}
                    data-test-id="navbar-partnerartists"
                  >
                    {t('web.site-layout.partnerartists-page', 'Top Artists')}
                  </PrimarySPANavLink>
                </span>
                <MobileLinkWrapper>
                  <SubmenuLink
                    onClick={toggleSubmenu}
                    data-test-id="upload-mobile-button"
                  >
                    {t('upload-song.upload.header.label', 'Upload')}{' '}
                    <IconChevronRight />
                  </SubmenuLink>
                </MobileLinkWrapper>
                <span>
                  <PrimarySPANavLink
                    href={Config.siteLinks.smuleFamily}
                    data-test-id="navbar-groups"
                    onClick={closeMenuOnNavigatingToPage}
                  >
                    Groups
                  </PrimarySPANavLink>
                </span>
                <AppsLinkWrapper>
                  <PrimarySPANavLink
                    href={Config.siteLinks.apps}
                    data-test-id="navbar-apps"
                  >
                    {t('web.site-layout.apps-link', 'Apps')}
                  </PrimarySPANavLink>
                </AppsLinkWrapper>
                <MobileLinkWrapper>
                  <PrimaryNavLink
                    href={Config.siteLinks.billing}
                    data-test-id="navbar-get-vip"
                  >
                    {t('web.site-layout.get-vip-link', 'Get VIP')}
                  </PrimaryNavLink>
                </MobileLinkWrapper>
              </React.Fragment>
            )}
          </NavigationWrapper>
        </PrimaryNavigation>
        <SecondaryNavigation>
          <SecondaryNavigationWrapper>
            <SecondaryNavigationColumn>
              <span>
                <SecondarySPANavLink
                  href={Config.siteLinks.about}
                  data-test-id="navbar-about"
                >
                  {t('web.site-layout.about-page', 'About')}
                </SecondarySPANavLink>
              </span>
              <span>
                <SecondaryNavLink
                  href={Config.siteLinks.blog}
                  data-test-id="navbar-blog"
                >
                  {t('web.site-layout.blog-link', 'Blog')}
                </SecondaryNavLink>
              </span>
              <span>
                <SecondarySPANavLink
                  href={Config.siteLinks.styles}
                  data-test-id="navbar-styles"
                >
                  Styles
                </SecondarySPANavLink>
              </span>
              <span>
                <SecondaryNavLink
                  href={Config.siteLinks.jobs}
                  data-test-id="navbar-careers"
                >
                  {t('web.site-layout.careers-page', 'Careers')}
                </SecondaryNavLink>
              </span>
              <span>
                <SecondarySPANavLink
                  href={Config.siteLinks.press}
                  data-test-id="navbar-press"
                >
                  {t('web.site-layout.press-page', 'Press')}
                </SecondarySPANavLink>
              </span>
            </SecondaryNavigationColumn>
            <SecondaryNavigationColumn>
              <span>
                <SecondarySPANavLink
                  href={Config.siteLinks.communityguidelines}
                  data-test-id="navbar-guidelines"
                >
                  {t('web.site-footer.community-guidelines', 'Guidelines')}
                </SecondarySPANavLink>
              </span>
              {referralEnabledSetting && (
                <span>
                  <SecondaryNavLink
                    href={Config.siteLinks.referAFriend}
                    data-test-id="navbar-refer-a-friend"
                  >
                    {t('web.site-layout.refer-a-friend-page', 'Refer-a-Friend')}
                  </SecondaryNavLink>
                </span>
              )}
              <span>
                <SecondarySPANavLink
                  href={Config.siteLinks.smulescience}
                  data-test-id="navbar-smule-science"
                >
                  Smule Science
                </SecondarySPANavLink>
              </span>
              <span>
                <SecondaryNavLink
                  href={Config.siteLinks.support}
                  data-test-id="navbar-support"
                >
                  {t('web.site-layout.support-page', 'Help')}
                </SecondaryNavLink>
              </span>
            </SecondaryNavigationColumn>
            <SecondaryNavigationColumnSocials>
              {isI18nEnabled && <LanguageSelect />}
              <SocialIconsColumn>
                <SocialLink
                  href="http://www.facebook.com/Smule"
                  data-test-id="navbar-facebook"
                >
                  <SocialIcons.FacebookIcon width={30} height={30} />
                </SocialLink>
                <SocialLink
                  href="http://twitter.com/smule"
                  data-test-id="navbar-twitter"
                >
                  <SocialIcons.TwitterIcon width={30} height={30} />
                </SocialLink>
                <SocialLink
                  href="https://instagram.com/smule"
                  data-test-id="navbar-instagram"
                >
                  <SocialIcons.InstagramIcon width={30} height={30} />
                </SocialLink>
                <SocialLink
                  href="http://www.youtube.com/user/smuleage"
                  data-test-id="navbar-yt"
                >
                  <SocialIcons.YoutubeIcon width={30} height={30} />
                </SocialLink>
                <SocialLink
                  href="https://www.tiktok.com/@smule"
                  data-test-id="navbar-tiktok"
                >
                  <SocialIcons.TikTokIcon width={30} height={30} />
                </SocialLink>
              </SocialIconsColumn>
            </SecondaryNavigationColumnSocials>
          </SecondaryNavigationWrapper>
        </SecondaryNavigation>
      </NavigationContainer>
    );
  };

  const renderToggleButton = () =>
    isSubmenuOpened ? (
      <ToggleMenuButton
        onClick={toggleSubmenu}
        aria-label="Back to main menu"
        data-test-id="navbar-back-to-menu"
      >
        <BackIcon aria-hidden="true" />
      </ToggleMenuButton>
    ) : (
      <ToggleMenuButton
        onClick={toggleMenu}
        aria-label={isMenuOpened ? 'Close the menu' : 'Open the menu'}
        data-test-id={
          isMenuOpened ? 'navbar-sidebar-close' : 'navbar-hamburger'
        }
      >
        {isMenuOpened ? (
          <XIcon aria-hidden="true" />
        ) : (
          <HamburgerIcon aria-hidden="true" />
        )}
      </ToggleMenuButton>
    );

  return (
    <>
      {isMenuOpened && <Backdrop onClick={toggleMenu} />}
      <Container isOpened={isMenuOpened}>
        {renderToggleButton()}
        {isMenuOpened && renderContent()}
      </Container>
    </>
  );
};

const mapStateToProps: MapStateToProps<HamburgerMenuStateProps, {}, State> = (
  state
) => ({
  referralEnabledSetting: LoginSelectors.getReferralEnabledSetting(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSnpSettings: () => dispatch(updateSNPSettings(['web.uiux'])),
});

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);
