/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { schema } from 'normalizr';
import { AccountIcon } from '@app/pages/AwesomePlaylists/types';

export type NormalizedSongbookArrangement = {
  arrKey: string;
  name?: string;
  compTitle: string;
  ownerAccountIcon: number;
  artist: string;
  coverUrl: string;
};

export type SongbookArrangementInfoEntity = {
  [key: number]: NormalizedSongbookArrangement;
};

export type SongbookArrangementOwnerInfoEntity = {
  [key: number]: AccountIcon;
};

export type SongbookEntities = {
  songbookArrangementInfo: SongbookArrangementInfoEntity;
  songbookArrangementOwnerInfo: SongbookArrangementOwnerInfoEntity;
};

export const songbookArrangementOwnerInfo = new schema.Entity<AccountIcon>(
  'songbookArrangementOwnerInfo',
  {},
  {
    idAttribute: 'accountId',
  }
);

export const songbookArrangementInfo =
  new schema.Entity<NormalizedSongbookArrangement>(
    'songbookArrangementInfo',
    {
      ownerAccountIcon: songbookArrangementOwnerInfo,
    },
    {
      idAttribute: 'arrKey',
    }
  );

export type SongbookSchemaType = {
  songbookArrangementOwnerInfo: typeof songbookArrangementOwnerInfo;
  songbookArrangementInfo: typeof songbookArrangementInfo;
};
