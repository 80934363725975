/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import axios from 'axios';
import signedAjax from '@app/utils/signed_request.js';
import { FlowTypes } from '@app/pages/NowPlaying/modules/gifting/types';

type FlowParam = FlowTypes | null;

const getWalletCredit = () =>
  axios({
    url: '/api/gifts/wallet',
  });

const getGiftCatalog = (flow: FlowParam) =>
  axios({
    url: '/api/gifts/catalog',
    params: {
      type: flow, // PERF
    },
  });

const getTransactionList = (
  flow: FlowParam,
  accountId,
  performanceKey,
  cursor = 'start',
  limit = 25
) =>
  axios({
    url: '/api/gifts/list/recent',
    params: {
      type: flow, // ACCT or PERF
      accountId, // needed when type = ACCT
      performanceKey, // PERF
      cursor,
      limit,
    },
  });

const getTopGiverList = (flow: FlowParam, accountId, performanceKey) =>
  axios({
    url: '/api/gifts/list/topgivers',
    params: {
      type: flow, // ACCT or PERF
      accountId, // needed when type = ACCT
      performanceKey, // PERF
    },
  });

const getReceiverList = (performanceKey) =>
  axios({
    url: '/api/gifts/performance-receivers',
    params: {
      performanceKey, // PERF
    },
  });

const giveGift = (giftId, performanceKey, flow: FlowParam, note, receivers) =>
  signedAjax({
    url: '/api/gifts/give',
    method: 'post',
    data: {
      giftId, // id of the gift
      type: flow, // PERF
      performanceKey, // performanceKey
      note, // sender's note, optional
      receivers, // [accountId]
    },
  });

const getData = (url) => axios({ url });

export type GiftingAPIType = {
  getWalletCredit: typeof getWalletCredit;
  getGiftCatalog: typeof getGiftCatalog;
  getTransactionList: typeof getTransactionList;
  getTopGiverList: typeof getTopGiverList;
  getReceiverList: typeof getReceiverList;
  giveGift: typeof giveGift;
  getData: typeof getData;
};

export const GiftingApi = {
  getWalletCredit,
  getGiftCatalog,
  getTransactionList,
  getTopGiverList,
  getReceiverList,
  giveGift,
  getData,
};
