/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import axios from 'axios';

export const getPlaylists = (accountId, appFamily, cursor, limit) =>
  axios({
    url: '/api/playlists',
    method: 'get',
    params: {
      accountId,
      appFamily,
      cursor,
      limit,
    },
  });

export const getPlaylist = (playlistKey) =>
  axios({
    url: `/api/playlists/${playlistKey}`,
  });

export const getPlaylistItems = (playlistKey, cursor) => {
  return axios({
    url: '/api/playlists/aplist/view',
    method: 'get',
    params: {
      playlistKey,
      cursor,
    },
  });
};

export type AwesomePlaylistsAPIType = {
  getPlaylists: typeof getPlaylists;
  getPlaylist: typeof getPlaylist;
  getPlaylistItems: typeof getPlaylistItems;
};

export const AwesomePlaylistsApi = {
  getPlaylists,
  getPlaylist,
  getPlaylistItems,
};
