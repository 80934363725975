/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export const STATE_KEY = 'ROUTE_CHANGE_BLOCKER';

const SET_ROUTE_CHANGE_BLOCKER = `${STATE_KEY}/SET`;

export const RouteChangeBlockerActionTypes = {
  SET_ROUTE_CHANGE_BLOCKER,
};

export const RouteChangeBlockerActions = {
  setRouteChangeBlocker: (enable: boolean, message = '') => ({
    type: SET_ROUTE_CHANGE_BLOCKER,
    payload: { enable, message },
  }),
};
