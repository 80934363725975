/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable from 'seamless-immutable';

import { PerformanceState } from '@app/components/Player/types';
import { STATE_KEY as PLAYER_STATE_KEY } from '@app/components/Player/selectors';
import {
  hasPlayerId,
  PlayersActionTypes,
} from '@app/components/Player/actions';
import { VideoJSPlayerActionTypes } from '../video_js/video_js_player_reducer';

import { STATE_KEY } from './performance_player_selectors';
export { STATE_KEY } from './performance_player_selectors';

// Current Performance and Playlist
const SET_PERFORMANCE = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_PERFORMANCE`;
const SET_PLAYLIST = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_PLAYLIST`;

// Rendering
const RENDER_RECORDING_START = `${PLAYER_STATE_KEY}/${STATE_KEY}/RENDER_RECORDING_START`;
const RENDER_RECORDING_SUCCESS = `${PLAYER_STATE_KEY}/${STATE_KEY}/RENDER_RECORDING_SUCCESS`;
const RENDER_RECORDING_SUCCESS_CONFIRM = `${PLAYER_STATE_KEY}/${STATE_KEY}/RENDER_RECORDING_SUCCESS_CONFIRM`;
const CHECK_RENDER_STARTED = `${PLAYER_STATE_KEY}/${STATE_KEY}/CHECK_RENDER_STARTED`;
const SET_CANCEL_RENDER = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_CANCEL_RENDER`;
const RENDER_RECORDING_FAIL = `${PLAYER_STATE_KEY}/${STATE_KEY}/RENDER_RECORDING_FAIL`;
const CLOSE_RENDERING_MODAL = `${PLAYER_STATE_KEY}/${STATE_KEY}/CLOSE_RENDERING_MODAL`;

// UI State
const SHOW_LOADING_SPINNER = `${PLAYER_STATE_KEY}/${STATE_KEY}/SHOW_LOADING_SPINNER`;
const TOGGLE_MAP = `${PLAYER_STATE_KEY}/${STATE_KEY}/TOGGLE_MAP`;
const SHOW_UI = `${PLAYER_STATE_KEY}/${STATE_KEY}/SHOW_UI`;
const HIDE_UI = `${PLAYER_STATE_KEY}/${STATE_KEY}/HIDE_UI`;

// Analytics
const LISTEN_START_BEGIN = `${PLAYER_STATE_KEY}/${STATE_KEY}/LISTEN_START_BEGIN`;
const LISTEN_START_SUCCESS = `${PLAYER_STATE_KEY}/${STATE_KEY}/LISTEN_START_SUCCESS`;
const LISTEN_START_FAIL = `${PLAYER_STATE_KEY}/${STATE_KEY}/LISTEN_START_FAIL`;
const LISTEN_BEGIN = `${PLAYER_STATE_KEY}/${STATE_KEY}/LISTEN_BEGIN`;
const LISTEN_SUCCESS = `${PLAYER_STATE_KEY}/${STATE_KEY}/LISTEN_SUCCESS`;
const LISTEN_FAIL = `${PLAYER_STATE_KEY}/${STATE_KEY}/LISTEN_FAIL`;
const SET_LISTEN_TRIGGER = `${PLAYER_STATE_KEY}/${STATE_KEY}/SET_LISTEN_TRIGGER`;
const AUTOPLAY_NEXT = `${PLAYER_STATE_KEY}/${STATE_KEY}/AUTOPLAY_NEXT`;
const PLAY_NEXT = `${PLAYER_STATE_KEY}/${STATE_KEY}/PLAY_NEXT`;
const PLAY_PREVIOUS = `${PLAYER_STATE_KEY}/${STATE_KEY}/PLAY_PREVIOUS`;

export const PerformancePlayerActionTypes = {
  SET_PERFORMANCE,
  SET_PLAYLIST,

  RENDER_RECORDING_START,
  RENDER_RECORDING_SUCCESS,
  RENDER_RECORDING_SUCCESS_CONFIRM,
  RENDER_RECORDING_FAIL,
  CLOSE_RENDERING_MODAL,

  SHOW_LOADING_SPINNER,
  TOGGLE_MAP,
  SHOW_UI,
  HIDE_UI,

  LISTEN_START_BEGIN,
  LISTEN_START_SUCCESS,
  LISTEN_START_FAIL,
  LISTEN_BEGIN,
  LISTEN_SUCCESS,
  LISTEN_FAIL,
  SET_LISTEN_TRIGGER,
  AUTOPLAY_NEXT,
  PLAY_NEXT,
  PLAY_PREVIOUS,
};

export const PerformancePlayerActions = {
  setPerformance: hasPlayerId((perfKey) => ({
    type: SET_PERFORMANCE,
    payload: { perfKey },
  })),
  setPlaylist: hasPlayerId((playlistId, playlistCursor) => ({
    type: SET_PLAYLIST,
    payload: { playlistId, playlistCursor },
  })),

  renderRecordingStart: hasPlayerId(() => ({
    type: RENDER_RECORDING_START,
    payload: {},
  })),
  renderRecordingSuccess: hasPlayerId(() => ({
    type: RENDER_RECORDING_SUCCESS,
    payload: {},
  })),
  renderRecordingSuccessConfirm: hasPlayerId(() => ({
    type: RENDER_RECORDING_SUCCESS_CONFIRM,
    payload: {},
  })),
  renderRecordingFail: hasPlayerId(() => ({
    type: RENDER_RECORDING_FAIL,
    payload: {},
  })),
  closeRenderingModal: hasPlayerId(() => ({
    type: CLOSE_RENDERING_MODAL,
    payload: {},
  })),
  checkRenderStarted: hasPlayerId(() => ({
    type: CHECK_RENDER_STARTED,
    payload: {},
  })),
  setCancelRender: hasPlayerId((canceled) => ({
    type: SET_CANCEL_RENDER,
    payload: { canceled },
  })),
  showLoadingSpinner: hasPlayerId(() => ({
    type: SHOW_LOADING_SPINNER,
    payload: {},
  })),
  toggleMap: hasPlayerId(() => ({ type: TOGGLE_MAP, payload: {} })),
  showUi: hasPlayerId(() => ({ type: SHOW_UI, payload: {} })),
  hideUi: hasPlayerId(() => ({ type: HIDE_UI, payload: {} })),

  listenStartBegin: hasPlayerId(() => ({
    type: LISTEN_START_BEGIN,
    payload: {},
  })),
  listenStartSuccess: hasPlayerId(() => ({
    type: LISTEN_START_SUCCESS,
    payload: {},
  })),
  listenStartFail: hasPlayerId(() => ({
    type: LISTEN_START_FAIL,
    payload: {},
  })),
  listenBegin: hasPlayerId(() => ({ type: LISTEN_BEGIN, payload: {} })),
  listenSuccess: hasPlayerId(() => ({ type: LISTEN_SUCCESS, payload: {} })),
  listenFail: hasPlayerId(() => ({ type: LISTEN_FAIL, payload: {} })),
  setListenTrigger: hasPlayerId((listenTrigger) => ({
    type: SET_LISTEN_TRIGGER,
    payload: { listenTrigger },
  })),
  autoplayNext: hasPlayerId(() => ({ type: AUTOPLAY_NEXT, payload: {} })),
  playNext: hasPlayerId(() => ({ type: PLAY_NEXT, payload: {} })),
  playPrevious: hasPlayerId(() => ({ type: PLAY_PREVIOUS, payload: {} })),
};

const initialPerformancePlayerState = Immutable<PerformanceState>({
  // Current Performance and Playlist
  perfKey: null,
  playlistId: null,
  playlistCursor: 0,

  // Rendering
  rendering: false,
  renderComplete: false,
  renderFailed: false,
  checkRenderStarted: false,
  cancelledRender: false,

  // UI State
  showLoadingSpinner: false,
  mapSelected: false,
  uiHidden: false,

  // Analytics
  eventCategory: null,
  eventLabel: null,
  listenTriggerQueue: null,
  listenTrigger: null,
});

export default (state = initialPerformancePlayerState, action) => {
  switch (action.type) {
    case PlayersActionTypes.INIT: {
      const { perfKey, eventCategory, eventLabel } =
        action.payload.playerOptions;
      return state
        .set('perfKey', perfKey)
        .set('eventCategory', eventCategory)
        .set('eventLabel', eventLabel);
    }

    case SET_PERFORMANCE: {
      return state
        .set('perfKey', action.payload.perfKey)
        .set('listenTrigger', state.listenTriggerQueue)
        .set('showLoadingSpinner', true);
    }
    case SET_PLAYLIST: {
      return state
        .set('playlistId', action.payload.playlistId)
        .set('playlistCursor', action.payload.playlistCursor);
    }

    case RENDER_RECORDING_START: {
      return state
        .set('rendering', true)
        .set('cancelledRender', false)
        .set('checkRenderStarted', true);
    }
    case RENDER_RECORDING_SUCCESS: {
      return state
        .set('rendering', false)
        .set('renderComplete', true)
        .set('checkRenderStarted', false);
    }
    case RENDER_RECORDING_SUCCESS_CONFIRM: {
      return state.set('renderComplete', false);
    }
    case RENDER_RECORDING_FAIL: {
      return state
        .set('rendering', false)
        .set('renderFailed', true)
        .set('checkRenderStarted', false);
    }
    case CLOSE_RENDERING_MODAL: {
      return state
        .set('rendering', false)
        .set('renderComplete', false)
        .set('renderFailed', false)
        .set('checkRenderStarted', false)
        .set('cancelledRender', true);
    }
    case CHECK_RENDER_STARTED: {
      return state.set('checkRenderStarted', true);
    }
    case SET_CANCEL_RENDER: {
      return state.set('cancelledRender', action.payload.canceled);
    }
    case SHOW_LOADING_SPINNER: {
      return state.set('showLoadingSpinner', true);
    }
    case VideoJSPlayerActionTypes.CAN_PLAY:
    case VideoJSPlayerActionTypes.SEEKED:
    case VideoJSPlayerActionTypes.PLAY:
    case VideoJSPlayerActionTypes.PLAYING: {
      return state.set('showLoadingSpinner', false); // reset activity timer if playback state changes
    }
    case TOGGLE_MAP: {
      return state.set('mapSelected', !state.mapSelected);
    }
    case SHOW_UI: {
      return state.set('uiHidden', false);
    }
    case HIDE_UI: {
      return state.set('uiHidden', true);
    }
    case SET_LISTEN_TRIGGER: {
      return state.set('listenTriggerQueue', action.payload.listenTrigger);
    }
    case AUTOPLAY_NEXT: {
      return state.set('listenTriggerQueue', 'autoplay');
    }
    case PLAY_NEXT: {
      return state.set('listenTriggerQueue', 'next');
    }
    case PLAY_PREVIOUS: {
      return state.set('listenTriggerQueue', 'previous');
    }
    default:
      return state;
  }
};
