/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { LovesState } from './types';

export const LovesActionsPrefix = 'LOVE';

const SET_LOVE_ENTITIES = `${LovesActionsPrefix}/SET_LOVE_ENTITIES`;

const LOAD_LOVES_START = `${LovesActionsPrefix}/LOAD_LOVES_START`;
const LOAD_LOVES_SUCCESS = `${LovesActionsPrefix}/LOAD_LOVES_SUCCESS`;
const LOAD_LOVES_FAIL = `${LovesActionsPrefix}/LOAD_LOVES_FAIL`;

const TRY_SUBMIT_LOVE = `${LovesActionsPrefix}/TRY_SUBMIT_LOVE`;
const SUBMIT_LOVE_START = `${LovesActionsPrefix}/SUBMIT_LOVE_START`;
const SUBMIT_LOVE_SUCCESS = `${LovesActionsPrefix}/SUBMIT_LOVE_SUCCESS`;
const SUBMIT_LOVE_FAIL = `${LovesActionsPrefix}/SUBMIT_LOVE_FAIL`;

export const LovesActionTypes = {
  SET_LOVE_ENTITIES,

  LOAD_LOVES_START,
  LOAD_LOVES_SUCCESS,
  LOAD_LOVES_FAIL,

  TRY_SUBMIT_LOVE,
  SUBMIT_LOVE_START,
  SUBMIT_LOVE_SUCCESS,
  SUBMIT_LOVE_FAIL,
};

export const LovesActions = {
  setLoveEntities: (
    entities: { loves: LovesState },
    perfKey: string,
    result: Array<string>,
    forceReload: boolean
  ) => ({
    type: SET_LOVE_ENTITIES,
    payload: { entities, perfKey, result, forceReload },
  }),
  loadLovesStart: () => ({ type: LOAD_LOVES_START, payload: {} }),
  loadLovesSuccess: () => ({ type: LOAD_LOVES_SUCCESS, payload: {} }),
  loadLovesFail: () => ({ type: LOAD_LOVES_FAIL, payload: {} }),

  trySubmitLove: (eventLabel) => ({
    type: TRY_SUBMIT_LOVE,
    payload: {},
    meta: { eventLabel },
  }),
  submitLoveStart: (perfKey) => ({
    type: SUBMIT_LOVE_START,
    payload: { perfKey },
  }),
  submitLoveSuccess: (perfKey, eventLabel) => ({
    type: SUBMIT_LOVE_SUCCESS,
    payload: { perfKey },
    meta: { eventLabel },
  }),
  submitLoveFail: () => ({ type: SUBMIT_LOVE_FAIL, payload: {} }),
};
