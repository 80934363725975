/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createCachedSelector } from 're-reselect';
import { ImmutableObject } from 'seamless-immutable';
import { createSelector } from 'reselect';

import { State } from '@app/reducer';
import { CommentsState, Comment } from './types';

const getComments = (state: State): ImmutableObject<CommentsState> =>
  state.comments;
const getCommentKey = (_: State, commentKey: string): string => commentKey;
const getComment = createCachedSelector(
  getComments,
  getCommentKey,
  (
    comments: ImmutableObject<CommentsState>,
    commentKey: string
  ): Comment | undefined => comments[commentKey]
)((_: State, commentKey: string) => commentKey);
const getModal = createSelector(getComments, (comments) => comments.modal);
const getAccounts = createSelector(
  getModal,
  (modal) => modal && modal.accounts
);
const getAccountsLoaded = createSelector(
  getModal,
  (modal) => modal && modal.accountsLoaded
);
const getNextCursor = createSelector(
  getModal,
  (modal) => modal && modal.nextCursor
);
const getTotalAccounts = createSelector(
  getModal,
  (modal) => modal && modal.accountCount
);
const getSearchResults = createSelector(
  getComments,
  (comments) => comments.suggestionList
);
const getSuggestionListNext = createSelector(
  getComments,
  (comments) => comments.suggestionListNext
);
const getNoResults = createSelector(
  getComments,
  (comments) => comments.noSuggestionResults
);

export const CommentsSelectors = {
  getComments,
  getComment,
  getAccounts,
  getAccountsLoaded,
  getNextCursor,
  getTotalAccounts,
  getSearchResults,
  getSuggestionListNext,
  getNoResults,
};
