/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { LoginApi, LoginAPIType } from './loginApi';
import { PerformanceApi, PerformanceAPIType } from './performanceApi';
import { ProfileApi, ProfileAPItype } from './profileApi';
import { GiftingApi, GiftingAPIType } from './giftingApi';
import { GroupsApi, GroupsApiType } from './groupsApi';
import { PlaylistApi, PlaylistAPIType } from './playlistApi';
import {
  AwesomePlaylistsApi,
  AwesomePlaylistsAPIType,
} from './awesomePlaylistsApi';
import { NotificationsApi, NotificationsAPIType } from './notificationsApi';
import { ArrangementApi, ArrangementAPIType } from './arrangement';
import { SongbookApi, SongbookAPIType } from './songbook';

const api: API = {
  login: LoginApi,
  performance: PerformanceApi,
  gifting: GiftingApi,
  profile: ProfileApi,
  groups: GroupsApi,
  playlist: PlaylistApi,
  awesomePlaylists: AwesomePlaylistsApi,
  notifications: NotificationsApi,
  arrangement: ArrangementApi,
  songbook: SongbookApi,
};

export type API = {
  login: LoginAPIType;
  performance: PerformanceAPIType;
  profile: ProfileAPItype;
  gifting: GiftingAPIType;
  groups: GroupsApiType;
  playlist: PlaylistAPIType;
  awesomePlaylists: AwesomePlaylistsAPIType;
  notifications: NotificationsAPIType;
  arrangement: ArrangementAPIType;
  songbook: SongbookAPIType;
};

export default api;
