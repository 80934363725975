/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { State as AppState } from '@app/reducer';
import Immutable, { ImmutableObject } from 'seamless-immutable';

export const getToastNotificationsState = (
  state: AppState
): ImmutableObject<State> => state.widgets.toastNotifications;

const SET_ACTIVE = 'TOAST_NOTIFICATIONS/SET_ACTIVE';
const SET_INACTIVE = 'TOAST_NOTIFICATIONS/SET_INACTIVE';

const action =
  <T extends string>(type: T) =>
  <P>() =>
  (payload: P) => ({ type, payload });

const actionNoPayload =
  <T extends string>(type: T) =>
  () => ({ type });

export const actions = {
  setActive: action(SET_ACTIVE)<string>(),
  setInactive: actionNoPayload(SET_INACTIVE),
} as const;

export type State =
  | {
      active: true;
      content: string;
    }
  | { active: false };

const initialState: ImmutableObject<State> = Immutable({
  active: false,
});

type Action = ReturnType<(typeof actions)[keyof typeof actions]>;

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_ACTIVE: {
      const newState = { active: true, content: action.payload } as const;
      return state.replace(newState);
    }

    case SET_INACTIVE: {
      const newState = { active: false } as const;
      return state.replace(newState);
    }

    default:
      return state;
  }
};

export default reducer;
