/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { getStore } from '@app/store/configureStore';
import { DependencyReducer, ReducerTypes, SetupReducerFunction } from './types';
import { ILoadedReducers } from '@app/reducer';

const store = getStore();

export default {
  injectReducers(dependencyReducers: DependencyReducer[]) {
    const reducerMap = [] as { key: ReducerTypes; reducer: ILoadedReducers }[];
    dependencyReducers.forEach((dependencyReducer) => {
      const { key, reducer } = dependencyReducer;
      if (!store.checkIfReducerIsInjected(key)) {
        reducerMap.push({ key, reducer });
      }
    });
    store.injectReducers(reducerMap);
  },
  setupReducer(key: ReducerTypes, setupFunction: SetupReducerFunction) {
    if (!store.checkIfReducerIsSetup(key)) {
      setupFunction(store);
      store.setupReducer(key);
    }
  },
};
