/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import NavigationBar from '@app/layout/NavigationBar';
import Footer from '@app/layout/Footer';
import React from 'react';
import PageLayoutBase from './PageLayoutBase';
import Config from '@app/WebConfig';
import { I18nPropsType } from '@app/i18n/types';
import { Page } from './pageMeta';

const getDefaultText = (key: string, defaults: any) =>
  // translations with interplolations need to go here
  key === 'web.site-footer.smule-copyright'
    ? `© ${Config.footerConfig.copyRightYear} Smule, Inc. All Rights Reserved.`
    : defaults;

interface PageProps {
  component: any;
  pageProps?: Page;
}

const i18nProps: I18nPropsType = {
  isEnabled: false,
  t: getDefaultText,
};

const PageLayout: React.FC<PageProps> = ({
  component: Component,
  pageProps = {} as Page,
  ...rest
}) => {
  return (
    <PageLayoutBase
      i18nProps={i18nProps}
      pageProps={pageProps}
      component={Component}
      navigationBar={NavigationBar}
      footer={Footer}
      {...rest}
    />
  );
};

export default PageLayout;
