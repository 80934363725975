/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { schema } from 'normalizr';
import {
  DenormalizedAccountInfo,
  NormalizedAccountInfo,
  DenormalizedSocialInfo,
  NormalizedSocialInfo,
} from '@app/pages/Profile/types';

const buildAccountInfo = (
  info: DenormalizedAccountInfo
): NormalizedAccountInfo => ({
  accountId: info.account_id,
  handle: info.handle,
  firstName: info.first_name,
  lastName: info.last_name,
  picUrl: info.pic_url,
  url: info.url,
  followersCount: info.followers,
  followeesCount: info.followees,
  numPerformances: info.num_performances,
  blurb: info.blurb,
  isFollowing: info.is_following,
  isVip: info.is_vip,
  isVerified: info.is_verified,
  verifiedType: info.verified_type,
  sfamCount: info.sfam_count,
  wallet: info.wallet,
  installedApps: info.installed_apps,
  isOwnProfile: info.is_current_user_profile,
});

export const accountInfo = new schema.Entity<NormalizedAccountInfo>(
  'accountInfo',
  {},
  {
    idAttribute: 'account_id',
    processStrategy: (value) => buildAccountInfo(value),
  }
);

const buildFollowInfo = (
  info: DenormalizedSocialInfo
): NormalizedSocialInfo => ({
  accountId: info.account_id,
  handle: info.handle,
  isFollowing: info.is_following,
  isVerified: info.is_verified,
  isVip: info.is_vip,
  picUrl: info.pic_url,
  url: info.url,
  verifiedType: info.verified_type,
  firstName: info.first_name,
  lastName: info.last_name,
});

export const followsInfo = new schema.Entity<NormalizedSocialInfo>(
  'followsInfo',
  {},
  {
    idAttribute: 'account_id',
    processStrategy: (value) => buildFollowInfo(value),
  }
);

export type ProfileSchemaType = {
  accountInfo: typeof accountInfo;
  followsInfo: typeof followsInfo;
};
