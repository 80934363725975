/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import '@app/polyfills';
import '@app/styles/global';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import Root from '@app/Root';
import store, { history } from '@app/store/configureStore';
import ErrorLogger from '@app/utils/error-logger';

// We need to initialize the ErrorLogger before anything else so we can log errors for all Smule code
ErrorLogger.init();

const container = document.getElementById('app');
const root = createRoot(container!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

root.render(
  <Provider store={store}>
    <Root history={history} />
  </Provider>
);
