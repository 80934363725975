/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { useEffect, useRef } from 'react';
import { noop } from '@app/utils/utils';

type CallbackType = () => void;

/**
 * useInterval is a hook that starts and clears interval, based on component lifecycle.
 * @param callback Callback function for interval. Can be changed, without clearing the interval.
 * @param delay Interval delay in ms. If null is passed, interval is cleared.
 * @param isActive Boolean flag, for enable/disable the interval
 */
export const useInterval = (
  callback: CallbackType,
  delay: number | null,
  isActive: boolean
) => {
  const callbackRef = useRef<CallbackType>(noop);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay == null) return;
    if (!isActive) return;

    const intervalId = setInterval(() => {
      callbackRef.current();
    }, delay);
    return () => clearInterval(intervalId);
  }, [delay, isActive]);
};
