/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
  }

  * {
    box-sizing: border-box;
  }

  #app {
    min-height: 100%;
    max-width: 100vw;
  }
`;
