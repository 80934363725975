/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable from 'seamless-immutable';

import { JourneyBannerActionTypes, JourneyBannerActions } from './actions';

export interface JourneyBannerState {
  isVisible: boolean;
}

const initialState = Immutable<JourneyBannerState>({
  isVisible: false,
});

type Action = ReturnType<
  (typeof JourneyBannerActions)[keyof typeof JourneyBannerActions]
>;

export const JourneyBannerReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case JourneyBannerActionTypes.SET_JOURNEY_BANNER_VISIBILITY: {
      return state.set('isVisible', action.payload.isVisible);
    }
    default:
      break;
  }
  return state;
};
