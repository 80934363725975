/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { Component } from 'react';
import styled from 'styled-components';

import Config from '@app/WebConfig';
import { noop } from '@app/utils/utils';

const getSitekey = () => {
  return (
    Config.recaptchaWidget.sitekey || '6Le0UB4TAAAAAP04Tg9G5sHF7qO6nhvDpL3NcDEY'
  );
};

const DefaultRecaptchaLocale = 'en';
const BillingLocaleToRecaptchaLocale = {
  'en-US': 'en',
  'en-GB': 'en-GB',
  'id-ID': 'id',
  'pt-PT': 'pt-PT',
  'es-419': 'es-419',
  'es-ES': 'es',
  'ru-RU': 'ru',
  'ms-MY': 'ms',
  'th-TH': 'th',
  'ja-JP': 'ja',
  'tr-TR': 'tr',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'ko-KR': 'ko',
  'it-IT': 'it',
  'zh-Hans-CN': 'zh-CN',
};

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const ONLOAD_CALLBACK_NAME = 'recaptchaOnloadCallback';
let loaded = false;
let loading = false;
let recaptcha;

export const loadRecaptcha = () =>
  new Promise<void>((resolve, reject) => {
    if (loaded) {
      resolve();
      return;
    }

    if (loading) {
      reject('Recaptcha is already loading.');
      return;
    }

    const script = document.createElement('script');
    script.src = `//www.google.com/recaptcha/api.js?onload=${ONLOAD_CALLBACK_NAME}&render=explicit`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    window[ONLOAD_CALLBACK_NAME] = () => {
      loaded = true;
      loading = false;
      recaptcha = window.grecaptcha;
      resolve();
    };

    loading = true;
  });

const Container = styled.div<{
  isVisible: boolean;
}>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;
  margin: 0 -12px;
`;

interface Props {
  visible: boolean;
  onSolve: (response: object, resubmit?: boolean) => void;
  locale: string;
}

class Recaptcha extends Component<Props> {
  public static defaultProps = {
    locale: DefaultRecaptchaLocale,
    visible: false,
    onSolve: noop,
  };

  recaptchaId = null;
  captchaEl;

  constructor(props) {
    super(props);
    this.onSolve = this.onSolve.bind(this);
  }
  componentDidMount() {
    if (this.recaptchaId == null && this.props.visible) {
      this.recaptchaId = this.createOrResetRecaptcha();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.recaptchaId = this.createOrResetRecaptcha();
    }
  }
  componentWillUnmount() {
    this.recaptchaId = null;
  }
  onSolve() {
    this.props.onSolve(this.getResponse());
  }
  getResponse() {
    if (this.recaptchaId == null) return null;
    return recaptcha.getResponse(this.recaptchaId);
  }
  createOrResetRecaptcha() {
    if (!loaded) return null;
    if (this.recaptchaId == null) {
      return recaptcha.render(this.captchaEl, {
        sitekey: getSitekey(),
        callback: this.onSolve,
        hl:
          BillingLocaleToRecaptchaLocale[this.props.locale] ||
          DefaultRecaptchaLocale, // this loads the language.
      });
    }
    recaptcha.reset(this.recaptchaId);
    return this.recaptchaId;
  }
  get id() {
    return this.recaptchaId;
  }
  render() {
    return (
      <Container
        ref={(el) => {
          this.captchaEl = el;
        }}
        isVisible={this.props.visible}
      />
    );
  }
}

export default Recaptcha;
