/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

const MAX_USER_AGE = 120;
const MONTHS_SHORT_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
let monthsArr;
let yearsArr;
let daysInMonth;
// zero based
const daysInMonthGroups = {
  31: [0, 2, 4, 6, 7, 9, 11],
  28: [1],
  30: [3, 5, 8, 10],
};

const isLeap = (year) => new Date(year, 1, 29).getDate() === 29;

export const padLeft = (num) => {
  if (num < 10) {
    return '0' + num; // eslint-disable-line
  }

  return num;
};

export const getMonths = () => {
  if (!monthsArr) {
    monthsArr = [];

    for (let i = 0; i < 12; i++) {
      // eslint-disable-line no-plusplus
      monthsArr.push({
        shortName: MONTHS_SHORT_NAMES[i],
        index: padLeft(i + 1),
      });
    }
  }

  return monthsArr;
};

export const getYears = () => {
  if (!yearsArr) {
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - MAX_USER_AGE;
    yearsArr = [];

    for (let i = currentYear; i >= minYear; i--) {
      // eslint-disable-line no-plusplus
      yearsArr.push(i);
    }
  }

  return yearsArr;
};

export const getDaysInMonth = (year, month) => {
  let count = 31;

  if (month === 1) {
    count = isLeap(year) ? 29 : 28;
  } else if (daysInMonthGroups[30].indexOf(month) > -1) {
    count = 30;
  }

  if (!daysInMonth || daysInMonth.length !== count) {
    daysInMonth = [];

    for (let i = 1; i <= count; i++) {
      // eslint-disable-line no-plusplus
      daysInMonth.push(padLeft(i));
    }
  }

  return daysInMonth;
};

export const formatSNPBDay = (bday) => {
  const date = new Date(bday);
  const m = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  return `${yyyy},${m}`;
};

export const formatInputBDay = (date) => {
  const d = new Date(date);
  const dd = padLeft(d.getDate());
  const mm = padLeft(d.getMonth() + 1);
  const yyyy = d.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};
