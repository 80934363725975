/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { LazyLoadedPage } from '@app/pages/types';
import reducerInjector from '@app/store/reducerInjector';

const injectPageReducers = (module: { default: LazyLoadedPage }) => {
  const reduxMeta = module.default.reduxMeta;
  if (reduxMeta) {
    const { reducerKey, setupFunction, reducers } = reduxMeta;
    reducers.length > 0 && reducerInjector.injectReducers(reducers);
    reducerKey &&
      setupFunction &&
      reducerInjector.setupReducer(reducerKey, setupFunction);
  }
  return module;
};

export default injectPageReducers;
