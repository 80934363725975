/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import {
  smuleAppIcon,
  smuleAppIcon2x,
  uploadStickerIcon,
  uploadStickerIcon2x,
  autorapAppIcon,
} from '@app/assets';
import { COLORS, SHADOWS } from '@app/constants';
import IconChevron from '@app/components/icons/IconChevron';
import Config from '@app/WebConfig';
import useRequireLogin from '@app/layout/Login/useRequireLogin';
import UploadButton, {
  UploadButtonStyles,
  MainLabel,
  SubLabel,
} from './UploadButton';

const Container = styled.div`
  position: relative;
  height: 100%;
`;
const DropdownBtn = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 20px 0;
  color: ${COLORS.white};
  font-size: 17px;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer;

  &:hover,
  &:active {
    opacity: 0.7;
  }
`;
const DropdownBtnLabel = styled.span`
  margin-right: 8px;
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 401;
`;
const ListWrapper = styled.ul`
  position: absolute;
  top: 62px;
  width: 376px;
  margin: 0 0 0 50%;
  padding: 0;
  list-style-type: none;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: ${SHADOWS.heavyShadow};
  transform: translateX(-50%);
  z-index: 401;
  overflow: hidden;

  li {
    background-color: ${COLORS.white};

    ${UploadButtonStyles} {
      border-bottom: 2px solid ${COLORS.silver200};
    }
    &:last-child {
      ${UploadButtonStyles} {
        border-bottom: none;
      }
    }
    &:hover {
      ${MainLabel},
      ${SubLabel} {
        color: ${COLORS.white};
      }
    }
  }
`;

const UploadDropdown: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showLoginFlowForUploadSong] = useRequireLogin(
    '',
    undefined,
    () => (window.location.href = Config.siteLinks.upload)
  );
  const [showLoginFlowForUploadSticker] = useRequireLogin(
    '',
    undefined,
    () => (window.location.href = Config.siteLinks.upload_sticker)
  );
  const [showLoginFlowForUploadBeat] = useRequireLogin(
    '',
    undefined,
    () => (window.location.href = Config.siteLinks.upload_beat)
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Container>
      <DropdownBtn onClick={toggleDropdown}>
        <DropdownBtnLabel>Upload</DropdownBtnLabel>
        <IconChevron />
      </DropdownBtn>
      {isDropdownOpen && (
        <React.Fragment>
          <Backdrop onClick={toggleDropdown} />
          <ListWrapper>
            <li>
              <UploadButton
                onClick={showLoginFlowForUploadSong}
                testId="navbar-upload"
                mainLabel="Upload a song to Smule"
                subLabel="Add your arrangement to а song to the songbook library."
                backgroundUrl={smuleAppIcon}
                backgroundUrl2x={smuleAppIcon2x}
              />
            </li>
            <li>
              <UploadButton
                onClick={showLoginFlowForUploadSticker}
                testId="navbar-upload-sticker"
                mainLabel="Upload a sticker to Smule"
                subLabel="Upload your original sticker to the Smule sticker catalog."
                backgroundUrl={uploadStickerIcon}
                backgroundUrl2x={uploadStickerIcon2x}
              />
            </li>
            <li>
              <UploadButton
                onClick={showLoginFlowForUploadBeat}
                testId="navbar-upload-beat"
                mainLabel="Upload a beat to AutoRap"
                subLabel="Share your beats with the community and get discovered."
                backgroundUrl={autorapAppIcon}
                backgroundUrl2x={autorapAppIcon}
              />
            </li>
          </ListWrapper>
        </React.Fragment>
      )}
    </Container>
  );
};

export default UploadDropdown;
