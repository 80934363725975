/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import NavTracker from '@app/utils/NavTracker';
import { emitEvent } from '@app/utils/event_logger';
import { RouterSelectors } from '@app/utils/router-selectors';
import { VideoJSPlayerActions } from '@app/components/Player/player_types/video_js/video_js_player_reducer';
import {
  RecordingPageActions,
  NowPlayingActionTypes,
} from '@app/pages/NowPlaying/actions';
import { InlineOverlayType } from '@app/pages/NowPlaying/types';

import { nowPlayingSelectors } from '@app/pages/NowPlaying/selectors';
// import { PerformanceTypes } from '@app/components/Player/types';
import { PerformancePlayerSelectors } from '@app/components/Player/player_types/performance/performance_player_selectors';
import { PlayersSelectors } from '@app/components/Player/selectors';
import { VideoJSPlayerSelectors } from '@app/components/Player/player_types/video_js/video_js_player_selectors';
import { LoginLaunchOrigins } from '@app/layout/Login/analytics/constants';
import { emitGAEvent } from '@app/utils/utils';

const RecordingPageAnalytics = (store, action) => {
  const state = store.getState();
  const getBrowserName = (userAgent) => {
    let browserName = window.navigator.appName;
    let nameOffset;
    let verOffset;
    if (userAgent.indexOf('Opera') !== -1) {
      verOffset = userAgent.indexOf('Opera');
      browserName = 'Opera';
    } else if (userAgent.indexOf('MSIE') !== -1) {
      browserName = 'Microsoft Internet Explorer';
    } else if (userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
    } else if (
      userAgent.lastIndexOf(' ') + 1 <
      (verOffset = userAgent.lastIndexOf('/'))
    ) {
      // eslint-disable-line
      browserName = userAgent.substring(nameOffset, verOffset);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = window.navigator.appName;
      }
    }
    return browserName;
  };
  const emitConsumptionEvent = (pauseEvent) => {
    const idx = PlayersSelectors.getCurrentPlayerId(state);
    const props = { id: idx };
    if (PlayersSelectors.getPlayerType(state, props) === 'LIVE_PLAYER') return;
    const isPaused = VideoJSPlayerSelectors.getPaused(state, props);
    if ((pauseEvent !== null && !isPaused) || pauseEvent === null) {
      const listenStartTime = VideoJSPlayerSelectors.getListenStartTime(
        state,
        props
      );
      let listenEndTime = VideoJSPlayerSelectors.getCurrentTimeCs(state, props);
      const seekedToPosition = VideoJSPlayerSelectors.getSeekedToPosition(
        state,
        props
      );
      const lastEndTime = VideoJSPlayerSelectors.getPreviousEndTime(
        state,
        props
      );
      if (seekedToPosition) {
        listenEndTime = lastEndTime;
        store.dispatch(VideoJSPlayerActions.seekedToPosition(idx, false));
      }
      if (listenStartTime < listenEndTime) {
        const consumptionType = VideoJSPlayerSelectors.getConsumptionType(
          state,
          props
        );
        const manualStartAction = VideoJSPlayerSelectors.getManualStartAction(
          state,
          props
        );
        const manualEndAction =
          pauseEvent !== null
            ? pauseEvent
            : VideoJSPlayerSelectors.getPauseRequestedCs(state, props);
        const perfKey = nowPlayingSelectors.getPerfKey(state, props);
        const isPerformer = nowPlayingSelectors.getIsPerformer(state, props);
        const isVideo = nowPlayingSelectors.getIsVideo(state, props);
        const perfArrKey = PerformancePlayerSelectors.getPerfArrKey(
          state,
          props
        );
        const compositionId = nowPlayingSelectors.getSongId(state, props);
        const totalDuration =
          listenEndTime -
          listenStartTime +
          nowPlayingSelectors.getTotalListenInterval(state);
        const totalIntervals =
          nowPlayingSelectors.getListenIntervalCount(state) + 1;
        if (totalDuration) {
          emitEvent('listen_interval', {
            target: perfKey || '-',
            context: consumptionType,
            value: isPerformer,
            k1: compositionId,
            k2: listenStartTime,
            k3: listenEndTime,
            k4: null,
            k5: perfArrKey,
            k6: isVideo,
            k7: manualStartAction,
            k8: manualEndAction,
            k9: `${totalDuration.toFixed(2)},${totalIntervals}`,
          });
        }
        store.dispatch(
          RecordingPageActions.setTotalListenInterval(totalDuration)
        );
        store.dispatch(RecordingPageActions.setIntervalsCount(totalIntervals));
      }
    }
  };
  switch (action.type) {
    // SHARING
    case NowPlayingActionTypes.SHARE: {
      emitGAEvent({
        eventName: 'Player - Share',
        eventCategory: action.meta.shareType,
      });

      emitEvent('share_ext_clk', {
        cf: null,
        target: action.meta.perfKey,
        value:
          action.meta.shareType === 'whatsApp'
            ? 'whatsapp'
            : action.meta.shareType,
        k1: 'basic',
        k2: action.meta.perfStatus,
        k3: action.meta.ensembleType,
        k5: action.meta.arrKey,
        k6: action.meta.isVideo,
      });
      break;
    }
    case NowPlayingActionTypes.SHARE_CONFIRMED: {
      emitGAEvent({
        eventName: 'Player - Share (Confirmed)',
        eventCategory: action.meta.shareType,
      });

      emitEvent('share_ext', {
        target: action.meta.perfKey,
        value:
          action.meta.shareType === 'whatsApp'
            ? 'whatsapp'
            : action.meta.shareType,
        k1: 'basic',
        k2: action.meta.perfStatus,
        k3: action.meta.ensembleType,
        k5: action.meta.arrKey,
        k6: action.meta.isVideo,
      });
      break;
    }

    // ACTION BAR
    case NowPlayingActionTypes.SET_VISIBILE_INLINE_OVERLAY: {
      switch (action.payload.type) {
        case InlineOverlayType.COMMENTS: {
          emitGAEvent({
            eventName: 'Performance',
            eventCategory: 'tab_clicked',
            eventLabel: 'comments',
          });
          break;
        }
        case InlineOverlayType.LOVES: {
          emitGAEvent({
            eventName: 'Performance',
            eventCategory: 'tab_clicked',
            eventLabel: 'loves',
          });
          break;
        }
        case InlineOverlayType.SHARING: {
          emitGAEvent({
            eventName: 'Player',
            eventCategory: 'share_icon_clk',
          });
          break;
        }
        // no default
      }
      break;
    }

    // todo: ACTION BUTTON
    // leave it as it's similar to the journey banner which we'll replace at some point
    /*case (NowPlayingActionTypes.SMART_BANNER_INSTALL_CLICK): {
      emitGAEvent({
        eventName: 'Click Smart Banner',
        eventCategory: `${action.meta.storeType} click`,
        eventLabel: action.meta.appFamily
      });
      break;
    }*/
    /*case (NowPlayingActionTypes.SHOW_INVITE_FRIENDS_MODAL): {
      emitGAEvent({
        eventName: 'perf_page',
        eventCategory: 'invite_button_clicked'
      });

      break;
    }*/
    /*case (NowPlayingActionTypes.HIDE_INVITE_FRIENDS_MODAL): {
      if (!action.meta.canceled) break;
      emitGAEvent({
        eventName: 'invite',
        eventCategory: 'invite_cancel'
      });

      break;
    }*/
    /*case (NowPlayingActionTypes.INVITE_FRIENDS_SUCCESS): {
      emitGAEvent({
        eventName: 'invite',
        eventCategory: 'invite_sent',
        value: action.meta.count
      });

      const performance = nowPlayingSelectors.getSelectedPerformance(store.getState());
      emitEvent('perf_invite', {
        target: performance.key,
        value: 'snp',
        k1: performance.songId,
        k3: performance.ensembleType,
        k5: performance.arrKey,
        k6: performance.type === PerformanceTypes.VIDEO
      });

      break;
    }*/
    case NowPlayingActionTypes.CLICK_TO_STORE: {
      emitGAEvent({
        eventName: 'Click To Store',
        eventCategory: `${action.meta.storeType} click`,
        eventLabel: action.meta.appFamily,
      });

      break;
    }
    case NowPlayingActionTypes.JOIN_BUTTON_CLICK: {
      let lastSearch = NavTracker.getFlashScope('search');
      if (lastSearch) {
        const songId = nowPlayingSelectors.getSongId(state);
        const perfKey = nowPlayingSelectors.getPerfKey(state);
        const ownerAccountId = nowPlayingSelectors.getOwnerId(state);
        const arrKey = nowPlayingSelectors.getArrKey(state);
        const isVideo = nowPlayingSelectors.getIsVideo(state);
        lastSearch = Object.assign({}, lastSearch, { context: 'back' });

        emitEvent('search_result_clk', {
          target: `${lastSearch.direct ? 'direct ' : ''}invite`,
          context: 'join',
          value: 'grouppage',
          k1: songId,
          k2: perfKey,
          k3: 0,
          k4: ownerAccountId,
          k5: arrKey,
          k6: isVideo,
          k7: 1,
          k8: 0,
        });
      }
      emitGAEvent({
        eventName: 'perf_page',
        eventCategory: 'join_button_clicked',
        eventLabel: 'join_in_app',
      });
      break;
    }

    // COMMENTS
    case NowPlayingActionTypes.TRY_SUBMIT_COMMENT: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: LoginLaunchOrigins.fromComment,
        eventLabel: 'performance',
      });
      break;
    }
    case NowPlayingActionTypes.SHOW_COMMENT_RECAPTCHA: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: 'comment_recaptcha',
        eventLabel: 'performance',
      });
      break;
    }
    case NowPlayingActionTypes.SUBMIT_COMMENT_START: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: 'comment_recaptcha_solved',
        eventLabel: 'performance',
      });
      break;
    }
    case NowPlayingActionTypes.SUBMIT_COMMENT_SUCCESS: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: 'comment',
        eventLabel: 'performance',
      });
      break;
    }
    case NowPlayingActionTypes.DELETE_COMMENT_SUCCESS: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: 'delete_comment',
        eventLabel: 'performance',
      });
      break;
    }
    case NowPlayingActionTypes.TRY_REPORT_COMMENT: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: LoginLaunchOrigins.fromReportComment,
        eventLabel: 'performance',
      });
      break;
    }
    case NowPlayingActionTypes.REPORT_COMMENT_SUCCESS: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: 'report_abuse',
        eventLabel: 'performance',
      });
      break;
    }
    // note the implementation is different in the old react
    case NowPlayingActionTypes.REC_VIEW_EVENT: {
      const playlistId = nowPlayingSelectors.getPlaylistId(state);
      if (action.payload.keys !== '' && action.payload.values !== '') {
        emitEvent('recsys_vw', {
          context: 'recording', // current page
          value: action.payload.keys, // recommendation ID
          k1: action.payload.values, // position in list
          k2: playlistId, // playlistId
          k3: action.payload.type,
        });
      }
      break;
    }

    // UP NEXT
    case NowPlayingActionTypes.PERF_REFERRAL_CLICK: {
      // on clicking on the recommendation list
      emitConsumptionEvent(null);

      emitGAEvent({
        eventName: 'Perf Referral',
        eventCategory: `${action.meta.trackingName} - ${action.meta.type}`,
        eventLabel: window.location.pathname,
      });

      emitEvent('recsys_clk', {
        context: 'recording', // current page
        value: action.payload.clickedPerfId, // recommendation ID
        k1: action.payload.clickedPosition.toString(), // position in list
        k2: action.payload.clickedSection, // section
        k3: 'listen',
      });
      break;
    }
    case NowPlayingActionTypes.SET_AUTOPLAY: {
      if (action.meta.initialize) break;
      emitGAEvent({
        eventName: 'Player',
        eventCategory: `${
          action.payload.enabled ? 'Enable' : 'Disable'
        } Autoplay`,
        eventLabel: 'performance',
      });
      break;
    }
    // legacy tab_click - up next visible
    case NowPlayingActionTypes.SET_PLAYLIST: {
      emitGAEvent({
        eventName: 'Performance',
        eventCategory: 'tab_clicked',
        eventLabel: 'related',
      });
      break;
    }

    // PAGE LANDING
    case NowPlayingActionTypes.LAND_CAMPAIGN: {
      const query = RouterSelectors.getQuery(state);
      const utmCampaignFields = (query.utm_campaign || '').split('_');
      const utmContentFields = (query.utm_content || '').split('_');
      // Make sure to clean all space characters from the campaign name as the space is an invalid character
      const campaignName = utmCampaignFields[0].split(' ').join('_');

      emitEvent('campaign_landing', {
        target: action.payload.campaignId,
        context: action.payload.perfKey,
        value: campaignName,
        k1: utmCampaignFields[3],
        k2: utmCampaignFields[7],
        k3: utmCampaignFields[8],
        k4: utmContentFields[3],
      });
      break;
    }
    case NowPlayingActionTypes.LAND_PAGE: {
      const nAgt = window.navigator.userAgent;
      const pathname = RouterSelectors.getPathname(state);
      emitEvent('page_landing', {
        target: getBrowserName(nAgt),
        context: nAgt,
        value: action.payload.perfKey,
        k1: pathname,
        k2: action.payload.compId,
      });
      break;
    }
    // no default
  }
};

export default RecordingPageAnalytics;
