/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const useTheme = () => useContext(ThemeContext);

export default useTheme;
