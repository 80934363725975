/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';

/* eslint-disable max-len */
const IconChevron = ({ color = 'white' }) => (
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
    <path
      d="M8.96231 0H1.03769C0.152845 0 -0.324512 1.02882 0.251336 1.69477L4.21365 6.64149C4.62699 7.1195 5.37301 7.1195 5.78635 6.64149L9.74866 1.69477C10.3245 1.02882 9.84715 0 8.96231 0Z"
      fill={color}
    />
  </svg>
);

export default IconChevron;
