/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';

import App from '@app/App';

interface Props {
  history: History;
}

class Root extends React.Component<Props> {
  public render(): JSX.Element {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    );
  }
}

export default Root;
