/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { NotificationsResponse } from '@app/pages/Notifications/types';
import { doApiRequest } from './utils';

const getNotifications = (offset: number) =>
  doApiRequest<NotificationsResponse>({
    url: '/api/social/notifications',
    method: 'get',
    params: {
      offset,
    },
  });

export type NotificationsAPIType = {
  getNotifications: typeof getNotifications;
};

export const NotificationsApi = {
  getNotifications,
};
