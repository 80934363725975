/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { emitEvent } from '@app/utils/event_logger';
import { GAEvent, emitGAEvent } from '@app/utils/utils';
import { FLOW_ID, ERRORS } from '@app/layout/Login/reducer';
import { authEvents } from './constants';

const emitLoginGAEvent = ({
  eventCategory,
  eventLabel,
  eventValue,
  nonInteractive,
}: GAEvent) => {
  emitGAEvent({
    eventName: 'registration',
    eventCategory,
    eventLabel,
    eventValue,
    nonInteractive,
  });
};

export const onShowFlow = ({ flow, interactive, opt }) => {
  switch (flow) {
    case FLOW_ID.LOGIN: {
      let regFlowStartLabel = 'none_specified';
      if (opt.activationFailed) {
        emitLoginGAEvent({
          eventCategory: 'verification_fail',
          eventLabel: 'smule_v1_reg',
          nonInteractive: !interactive,
        });
      } else if (opt.launchOrigin) {
        regFlowStartLabel = opt.launchOrigin;
      }
      emitEvent('reg_flow_start');
      emitLoginGAEvent({
        eventCategory: 'reg_flow_start',
        eventLabel: regFlowStartLabel,
        nonInteractive: !interactive,
      });
      break;
    }
    case FLOW_ID.NEW_ACCOUNT: {
      emitEvent(authEvents.verifyMethod.NAME, {
        k2: authEvents.verifyMethod.k2.EMAIL,
      });
      emitLoginGAEvent({
        eventCategory: 'reg_start',
        eventLabel: 'smule_v1_reg',
        nonInteractive: !interactive,
      });
      break;
    }
    case FLOW_ID.PHONE_AUTHENTICATION: {
      emitEvent(authEvents.verifyMethod.NAME, {
        k2: authEvents.verifyMethod.k2.PHONE,
      });
      break;
    }
    case FLOW_ID.PHONE_CODE_SUBMISSION: {
      emitEvent(authEvents.codeVerify.NAME, {
        k2: authEvents.codeVerify.k2.PHONE,
      });
      break;
    }
    case FLOW_ID.RESET_PASSWORD: {
      emitLoginGAEvent({
        eventCategory: 'forgot_pswf_start',
        eventLabel: 'forgot_password',
        nonInteractive: !interactive,
      });
      break;
    }
    case FLOW_ID.SET_PASSWORD: {
      emitLoginGAEvent({
        eventCategory: 'new_pswd_start',
        eventLabel: 'forgot_password',
        nonInteractive: !interactive,
      });
      break;
    }
    case FLOW_ID.ACTIVATION_SUCCESS: {
      if (opt.activationFromWeb) {
        emitEvent(authEvents.regComplete.NAME, {
          k1: authEvents.regComplete.k1.ACTIVATION,
          k2: authEvents.regComplete.k2.LINK,
          value: opt.activationWithCreation
            ? authEvents.regComplete.value.NEW
            : authEvents.regComplete.value.EXISTING,
        });
      }
      emitLoginGAEvent({
        eventCategory: 'verification_success',
        eventLabel: 'smule_v1_reg',
        nonInteractive: !interactive,
      });
      break;
    }
    // no default
  }
};

export const onInputError = (errorType) => {
  if (errorType && errorType !== ERRORS.NONE) {
    emitLoginGAEvent({
      eventCategory: 'login_fail',
      eventLabel: 'smule_v1_reg',
    });
  }
};
