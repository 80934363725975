/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable max-len */
export const filePath = (requirePath: string): string => requirePath;

//ABOUT ASSETS
export const aboutImages = {
  jeff: {
    img: filePath(require('../images/about/img-jeff.jpg')),
    webp: filePath(require('../images/about/webP/img-jeff.webp')),
  },
  bill: {
    img: filePath(require('../images/about/img-bill.jpg')),
    webp: filePath(require('../images/about/webP/img-bill.webp')),
  },
  ariel: {
    img: filePath(require('../images/about/img-ariel.jpg')),
    webp: filePath(require('../images/about/webP/img-ariel.webp')),
  },
  jason: {
    img: filePath(require('../images/about/img-jason.jpg')),
    webp: filePath(require('../images/about/webP/img-jason.webp')),
  },
  tom: {
    img: filePath(require('../images/about/img-tom.jpg')),
    webp: filePath(require('../images/about/webP/img-tom.webp')),
  },
  luanne: {
    img: filePath(require('../images/about/img-luanne.jpg')),
    webp: filePath(require('../images/about/webP/img-luanne.webp')),
  },
  ken: {
    img: filePath(require('../images/about/img-kenr.jpg')),
    webp: filePath(require('../images/about/webP/img-kenr.webp')),
  },
  betsey: {
    img: filePath(require('../images/about/img-betseyn.jpg')),
    webp: filePath(require('../images/about/webP/img-betseyn.webp')),
  },
  standish: {
    img: filePath(require('../images/about/img-sogrady.jpg')),
    webp: filePath(require('../images/about/webP/img-sogrady.webp')),
  },
  chloe: {
    img: filePath(require('../images/about/img-chloe.jpg')),
    webp: filePath(require('../images/about/webP/img-chloe.webp')),
  },
  julia: {
    img: filePath(require('../images/about/img-julia.jpg')),
    webp: filePath(require('../images/about/webP/img-julia.webp')),
  },
  sevil: {
    img: filePath(require('../images/about/img-sevil.jpg')),
    webp: filePath(require('../images/about/img-sevil.jpg')),
  },
  martin: {
    img: filePath(require('../images/about/img-martin.jpg')),
    webp: filePath(require('../images/about/webP/img-martin.webp')),
  },
  placeholder: {
    img: filePath(require('../images/about/user-placeholder.jpeg')),
    webp: filePath(require('../images/about/webP/user-placeholder.webp')),
  },
};

// COMMON ASSETS
export const verifiedBadge = filePath(
  require('../images/shared/verified_badge.svg')
);
export const staffBadge2x = filePath(
  require('../images/profile-icn_staff_verified@2x.png')
);
export const smuleAppIcon = filePath(
  require('../images/shared/app-icons/app_smule.jpg')
);
export const smuleAppIcon2x = filePath(
  require('../images/shared/app-icons/app_smule@2x.jpg')
);
export const uploadStickerIcon = filePath(
  require('../images/shared/app-icons/upload_sticker.png')
);
export const uploadStickerIcon2x = filePath(
  require('../images/shared/app-icons/upload_sticker@2x.png')
);
export const autorapAppIcon = filePath(require('../images/app_autorap.svg'));
export const sliderMinIcon = filePath(
  require('../images/shared/image-cropper/icn_slider_min@2x.png')
);
export const sliderMaxIcon = filePath(
  require('../images/shared/image-cropper/icn_slider_max@2x.png')
);

// AUTORAP LANDING ASSETS:
export const FeaturesBackground = filePath(
  require('../images/features_graphite.jpg')
);
export const FooterSmokeMobile = filePath(
  require('../images/footer_smoke_mobile.jpg')
);
export const FooterSmokeMobile2x = filePath(
  require('../images/footer_smoke_mobile@2x.jpg')
);
export const OgImage = filePath(require('../images/image_autorap_product.jpg'));
export type StoreButtonsType = 'AppleStore' | 'GoogleStore';
type StoreButtons = { [key in StoreButtonsType]: StoreButton };
interface StoreButton {
  normal: string;
  retina: string;
}
export const StoreButtons: StoreButtons = {
  AppleStore: {
    normal: filePath(require('../images/appstore_apple.jpg')),
    retina: filePath(require('../images/appstore_apple@2x.jpg')),
  },
  GoogleStore: {
    normal: filePath(require('../images/appstore_google.jpg')),
    retina: filePath(require('../images/appstore_google@2x.jpg')),
  },
};
export const MobileNavigationSmoke = filePath(
  require('../images/mobile_navigation_smoke.jpg')
);
export const MobileNavigationSmoke2x = filePath(
  require('../images/mobile_navigation_smoke@2x.jpg')
);
export const HeaderSmoke = filePath(
  require('../images/static_header_smoke.jpg')
);
export const HeaderSmoke2x = filePath(
  require('../images/static_header_smoke@2x.jpg')
);
export const HeaderSmokeMobile = filePath(
  require('../images/static_header_smoke_mobile.jpg')
);
export const HeaderSmokeMobile2x = filePath(
  require('../images/static_header_smoke_mobile@2x.jpg')
);
export const AboutPreviews = [
  {
    '1x': filePath(require('../images/autorap_preview_1.jpg')),
    '2x': filePath(require('../images/autorap_preview_1@2x.jpg')),
  },
  {
    '1x': filePath(require('../images/autorap_preview_2.jpg')),
    '2x': filePath(require('../images/autorap_preview_2@2x.jpg')),
  },
  {
    '1x': filePath(require('../images/autorap_preview_3.jpg')),
    '2x': filePath(require('../images/autorap_preview_3@2x.jpg')),
  },
  {
    '1x': filePath(require('../images/autorap_preview_4.jpg')),
    '2x': filePath(require('../images/autorap_preview_4@2x.jpg')),
  },
];

// SMULE LANDING ASSETS:

export const SLOgImage = filePath(
  require('../images/smule-landing/app-icon@2x.png')
);

export const SLLogo = filePath(
  require('../images/smule-landing/app-icon@1x.png')
);
export const SLLogo2x = filePath(
  require('../images/smule-landing/app-icon@2x.png')
);

export const SLHeaderDesktopBackground = filePath(
  require('../images/smule-landing/header-background-desktop-v2@1x.jpg')
);
export const SLHeaderDesktopBackground2x = filePath(
  require('../images/smule-landing/header-background-desktop-v2@2x.jpg')
);
export const SLHeaderMobileBackground = filePath(
  require('../images/smule-landing/header-background-mobile-v2@1x.jpg')
);
export const SLHeaderMobileBackground2x = filePath(
  require('../images/smule-landing/header-background-mobile-v2@2x.jpg')
);

export type SLStoreButtonsType = 'AppleStore' | 'GoogleStore';
type SLStoreButtons = { [key in SLStoreButtonsType]: SLStoreButton };
interface SLStoreButton {
  vector: string;
}
export const SLStoreButtons: SLStoreButtons = {
  AppleStore: {
    vector: filePath(require('../images/smule-landing/app-store.svg')),
  },
  GoogleStore: {
    vector: filePath(require('../images/smule-landing/google-play.svg')),
  },
};

export const SLAboutPreviews = [
  {
    '1x': filePath(
      require('../images/smule-landing/about-screenshot-1@1x.jpg')
    ),
    '2x': filePath(
      require('../images/smule-landing/about-screenshot-1@2x.jpg')
    ),
  },
  {
    '1x': filePath(
      require('../images/smule-landing/about-screenshot-2@1x.jpg')
    ),
    '2x': filePath(
      require('../images/smule-landing/about-screenshot-2@2x.jpg')
    ),
  },
  {
    '1x': filePath(
      require('../images/smule-landing/about-screenshot-3@1x.jpg')
    ),
    '2x': filePath(
      require('../images/smule-landing/about-screenshot-3@2x.jpg')
    ),
  },
  {
    '1x': filePath(
      require('../images/smule-landing/about-screenshot-4@1x.jpg')
    ),
    '2x': filePath(
      require('../images/smule-landing/about-screenshot-4@2x.jpg')
    ),
  },
];

export const SLFooterDesktopBackground = filePath(
  require('../images/smule-landing/footer-background-desktop@1x.jpg')
);
export const SLFooterDesktopBackground2x = filePath(
  require('../images/smule-landing/footer-background-desktop@2x.jpg')
);
export const SLFooterMobileBackground = filePath(
  require('../images/smule-landing/footer-background-mobile@1x.jpg')
);
export const SLFooterMobileBackground2x = filePath(
  require('../images/smule-landing/footer-background-mobile@2x.jpg')
);

// GET VIP ICICI ASSETS

export const BANK_LOGOS_DESKTOP = filePath(
  require('../images/get-vip-icici/logos-desktop@2x.png')
);
export const BANK_LOGOS_MOBILE = filePath(
  require('../images/get-vip-icici/logos-mobile@2x.png')
);

// Home Page assets

export const HomePageGlobeAssets = {
  globeDesktop1x: filePath(require('../images/homepage/globe-desktop@1x.jpg')),
  globeDesktop2x: filePath(require('../images/homepage/globe-desktop@2x.jpg')),
  globeMobile1x: filePath(require('../images/homepage/globe-mobile@1x.jpg')),
  globeMobile2x: filePath(require('../images/homepage/globe-mobile@2x.jpg')),

  // WebP homepage assets
  globeDesktop1xWebp: filePath(
    require('../images/homepage/webp/globe-desktop@1x.webp')
  ),
  globeDesktop2xWebp: filePath(
    require('../images/homepage/webp/globe-desktop@2x.webp')
  ),
  globeMobile1xWebp: filePath(
    require('../images/homepage/webp/globe-mobile@1x.webp')
  ),
  globeMobile2xWebp: filePath(
    require('../images/homepage/webp/globe-mobile@2x.webp')
  ),
};

export const HomeGlobeVideo = filePath(
  require('../images/homepage/smule-home-video.mp4')
);

export const HomeVideoThumbnail = {
  poster: filePath(require('../images/homepage/homepage-video-thumbnail.jpg')),
  posterWebp: filePath(
    require('../images/homepage/webp/homepage-video-thumbnail.webp')
  ),
};

export const HomePageRippleAssets = {
  HomeRippleAvatar1: filePath(require('../images/homepage/person1.jpg')),
  HomeRippleAvatar2: filePath(require('../images/homepage/person2.jpg')),
  HomeRippleAvatar3: filePath(require('../images/homepage/person3.jpg')),
  HomeRippleAvatar4: filePath(require('../images/homepage/person4.jpg')),
  HomeRippleAvatar5: filePath(require('../images/homepage/person5.jpg')),

  // WebP homepage assets

  HomeRippleAvatar1Webp: filePath(
    require('../images/homepage/webp/person1.webp')
  ),
  HomeRippleAvatar2Webp: filePath(
    require('../images/homepage/webp/person2.webp')
  ),
  HomeRippleAvatar3Webp: filePath(
    require('../images/homepage/webp/person3.webp')
  ),
  HomeRippleAvatar4Webp: filePath(
    require('../images/homepage/webp/person4.webp')
  ),
  HomeRippleAvatar5Webp: filePath(
    require('../images/homepage/webp/person5.webp')
  ),
};

export const HomeAppsAutorapSvg = filePath(
  require('../images/app_autorap.svg')
);
export const HomeAppsSingSvg = filePath(require('../images/app_sing.svg'));

export const HomePageApps = {
  HomeAppsMagicPiano: filePath(
    require('../images/homepage/logo_magic_piano.png')
  ),
  HomeAppsMagicPiano2x: filePath(
    require('../images/homepage/logo_magic_piano@2x.png')
  ),
  HomeAppsAutorap: filePath(require('../images/homepage/logo_autorap.png')),
  HomeAppsAutorap2x: filePath(
    require('../images/homepage/logo_autorap@2x.png')
  ),

  // WebP

  HomeAppsMagicPianoWebP: filePath(
    require('../images/homepage/webp/logo_magic_piano.webp')
  ),
  HomeAppsMagicPiano2xWebP: filePath(
    require('../images/homepage/webp/logo_magic_piano@2x.webp')
  ),
  HomeAppsAutorapWebP: filePath(
    require('../images/homepage/webp/logo_autorap.webp')
  ),
  HomeAppsAutorap2xWebP: filePath(
    require('../images/homepage/webp/logo_autorap@2x.webp')
  ),
};

// Now Playing assets

export const NowPlayingGlobeAssets = {
  globeDesktop1x: filePath(
    require('../images/now-playing/now-playing-globe-desktop@1x.jpg')
  ),
  globeDesktop2x: filePath(
    require('../images/now-playing/now-playing-globe-desktop@2x.jpg')
  ),
  globeTablet1x: filePath(
    require('../images/now-playing/now-playing-globe-tablet@1x.jpg')
  ),
  globeTablet2x: filePath(
    require('../images/now-playing/now-playing-globe-tablet@2x.jpg')
  ),
  globeDesktop1xWebP: filePath(
    require('../images/now-playing/now-playing-globe-desktop@1x.webp')
  ),
  globeDesktop2xWebP: filePath(
    require('../images/now-playing/now-playing-globe-desktop@2x.webp')
  ),
  globeTablet1xWebP: filePath(
    require('../images/now-playing/now-playing-globe-tablet@1x.webp')
  ),
  globeTablet2xWebP: filePath(
    require('../images/now-playing/now-playing-globe-tablet@2x.webp')
  ),
};

export const FacebookIcon2x = filePath(
  require('../images/now-playing/fb-share-smule.png')
);

// Apps page assets

export const AppsPageAssets = {
  mobileSmuleLogo1x: filePath(
    require('../images/apps/mobile_app_smule_logo.png')
  ),
  mobileSmuleLogo2x: filePath(
    require('../images/apps/mobile_app_smule_logo@2x.png')
  ),
  desktopSmuleLogo1x: filePath(
    require('../images/apps/desktop_app_smule_logo.png')
  ),
  desktopSmuleLogo2x: filePath(
    require('../images/apps/desktop_app_smule_logo@2x.png')
  ),

  mobileStyleStudioLogo1x: filePath(
    require('../images/apps/mobile_app_style_studio_logo.png')
  ),
  mobileStyleStudioLogo2x: filePath(
    require('../images/apps/mobile_app_style_studio_logo@2x.png')
  ),
  desktopStyleStudioLogo1x: filePath(
    require('../images/apps/desktop_app_style_studio_logo.png')
  ),
  desktopStyleStudioLogo2x: filePath(
    require('../images/apps/desktop_app_style_studio_logo@2x.png')
  ),

  mobileMagicPianoLogo1x: filePath(
    require('../images/apps/mobile_app_magic_piano_logo.png')
  ),
  mobileMagicPianoLogo2x: filePath(
    require('../images/apps/mobile_app_magic_piano_logo@2x.png')
  ),
  desktopMagicPianoLogo1x: filePath(
    require('../images/apps/desktop_app_magic_piano_logo.png')
  ),
  desktopMagicPianoLogo2x: filePath(
    require('../images/apps/desktop_app_magic_piano_logo@2x.png')
  ),

  mobileAutorapLogo1x: filePath(
    require('../images/apps/mobile_app_autorap_logo.png')
  ),
  mobileAutorapLogo2x: filePath(
    require('../images/apps/mobile_app_autorap_logo@2x.png')
  ),
  desktopAutorapLogo1x: filePath(
    require('../images/apps/desktop_app_autorap_logo.png')
  ),
  desktopAutorapLogo2x: filePath(
    require('../images/apps/desktop_app_autorap_logo@2x.png')
  ),

  // Webp
  mobileSmuleLogo1xWebp: filePath(
    require('../images/apps/webp/mobile_app_smule_logo.webp')
  ),
  mobileSmuleLogo2xWebp: filePath(
    require('../images/apps/webp/mobile_app_smule_logo@2x.webp')
  ),
  desktopSmuleLogo1xWebp: filePath(
    require('../images/apps/webp/desktop_app_smule_logo.webp')
  ),
  desktopSmuleLogo2xWebp: filePath(
    require('../images/apps/webp/desktop_app_smule_logo@2x.webp')
  ),

  mobileStyleStudioLogo1xWebp: filePath(
    require('../images/apps/webp/mobile_app_style_studio_logo.webp')
  ),
  mobileStyleStudioLogo2xWebp: filePath(
    require('../images/apps/webp/mobile_app_style_studio_logo@2x.webp')
  ),
  desktopStyleStudioLogo1xWebp: filePath(
    require('../images/apps/webp/desktop_app_style_studio_logo.webp')
  ),
  desktopStyleStudioLogo2xWebp: filePath(
    require('../images/apps/webp/desktop_app_style_studio_logo@2x.webp')
  ),

  mobileMagicPianoLogo1xWebp: filePath(
    require('../images/apps/webp/mobile_app_magic_piano_logo.webp')
  ),
  mobileMagicPianoLogo2xWebp: filePath(
    require('../images/apps/webp/mobile_app_magic_piano_logo@2x.webp')
  ),
  desktopMagicPianoLogo1xWebp: filePath(
    require('../images/apps/webp/desktop_app_magic_piano_logo.webp')
  ),
  desktopMagicPianoLogo2xWebp: filePath(
    require('../images/apps/webp/desktop_app_magic_piano_logo@2x.webp')
  ),

  mobileAutorapLogo1xWebp: filePath(
    require('../images/apps/webp/mobile_app_autorap_logo.webp')
  ),
  mobileAutorapLogo2xWebp: filePath(
    require('../images/apps/webp/mobile_app_autorap_logo@2x.webp')
  ),
  desktopAutorapLogo1xWebp: filePath(
    require('../images/apps/webp/desktop_app_autorap_logo.webp')
  ),
  desktopAutorapLogo2xWebp: filePath(
    require('../images/apps/webp/desktop_app_autorap_logo@2x.webp')
  ),
};

// Groups page assets

export const GroupsPageAssets = {
  filterIcon: filePath(require('../images/groups/filter_icon.svg')),
  manageGroupIcon: filePath(require('../images/groups/manage_group_icon.svg')),
  threeDotsIcon: filePath(require('../images/groups/three_dots.svg')),
  managePerformanceIcon: filePath(
    require('../images/groups/manage_performance_icon.svg')
  ),
};
