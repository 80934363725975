/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export type Corner = 'mini' | 'small' | 'regular' | 'medium' | 'big' | 'large';

export type Corners = { [key in Corner]: string };

const corners: Corners = {
  mini: '2px',
  small: '4px',
  regular: '6px',
  medium: '8px',
  big: '12px',
  large: '24px',
};

export default corners;
