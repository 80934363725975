/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export const PerformancesActionsPrefix = 'PERFORMANCE';

const SET_PERFORMANCE_ENTITIES = `${PerformancesActionsPrefix}/SET_PERFORMANCE_ENTITIES`;
const SET_MEDIA_URLS = `${PerformancesActionsPrefix}/SET_MEDIA_URLS`;
const TRY_UPDATE_FAVORITE = `${PerformancesActionsPrefix}/TRY_UPDATE_FAVORITE`;
const UPDATE_FAVORITE_START = `${PerformancesActionsPrefix}/UPDATE_FAVORITE_START`;
const UPDATE_FAVORITE_SUCCESS = `${PerformancesActionsPrefix}/UPDATE_FAVORITE_SUCCESS`;
const UPDATE_FAVORITE_FAIL = `${PerformancesActionsPrefix}/UPDATE_FAVORITE_FAIL`;

const LOAD_PERFORMANCE_START = `${PerformancesActionsPrefix}/LOAD_PERFORMANCE_START`;
const LOAD_PERFORMANCE_SUCCESS = `${PerformancesActionsPrefix}/LOAD_PERFORMANCE_SUCCESS`;
const LOAD_PERFORMANCE_FAIL = `${PerformancesActionsPrefix}/LOAD_PERFORMANCE_FAIL`;

const IS_LOVED_START = `${PerformancesActionsPrefix}/IS_LOVED_START`;
const IS_LOVED_SUCCESS = `${PerformancesActionsPrefix}/IS_LOVED_SUCCESS`;
const IS_LOVED_FAIL = `${PerformancesActionsPrefix}/IS_LOVED_FAIL`;

const IS_FAVORITE_START = `${PerformancesActionsPrefix}/IS_FAVORITE_START`;
const IS_FAVORITE_SUCCESS = `${PerformancesActionsPrefix}/IS_FAVORITE_SUCCESS`;
const IS_FAVORITE_FAIL = `${PerformancesActionsPrefix}/IS_FAVORITE_FAIL`;

const UPDATE_PERMISSIONS_START = `${PerformancesActionsPrefix}/UPDATE_PERMISSIONS_START`;
const UPDATE_PERMISSIONS_SUCCESS = `${PerformancesActionsPrefix}/UPDATE_PERMISSIONS_SUCCESS`;
const UPDATE_PERMISSIONS_FAIL = `${PerformancesActionsPrefix}/UPDATE_PERMISSIONS_FAIL`;

export const PerformancesActionTypes = {
  SET_PERFORMANCE_ENTITIES,
  SET_MEDIA_URLS,

  LOAD_PERFORMANCE_START,
  LOAD_PERFORMANCE_SUCCESS,
  LOAD_PERFORMANCE_FAIL,

  IS_LOVED_START,
  IS_LOVED_SUCCESS,
  IS_LOVED_FAIL,

  TRY_UPDATE_FAVORITE,
  UPDATE_FAVORITE_START,
  UPDATE_FAVORITE_SUCCESS,
  UPDATE_FAVORITE_FAIL,

  IS_FAVORITE_START,
  IS_FAVORITE_SUCCESS,
  IS_FAVORITE_FAIL,

  UPDATE_PERMISSIONS_START,
  UPDATE_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS_FAIL,
};

export const PerformancesActions = {
  setPerformanceEntities: (performanceEntities: any) => ({
    type: SET_PERFORMANCE_ENTITIES,
    payload: performanceEntities,
  }),
  setMediaUrls: (perfKey, mediaUrls) => ({
    type: SET_MEDIA_URLS,
    payload: {
      perfKey,
      mediaUrls,
    },
  }),
  loadPerformanceStart: () => ({ type: LOAD_PERFORMANCE_START, payload: {} }),
  loadPerformanceSuccess: () => ({
    type: LOAD_PERFORMANCE_SUCCESS,
    payload: {},
  }),
  loadPerformanceFail: () => ({ type: LOAD_PERFORMANCE_FAIL, payload: {} }),

  isLovedStart: () => ({ type: IS_LOVED_START, payload: {} }),
  isLovedSuccess: (perfKey, isLoved) => ({
    type: IS_LOVED_SUCCESS,
    payload: { perfKey, isLoved },
  }),
  isLovedFail: () => ({ type: IS_LOVED_FAIL, payload: {} }),

  isFavoriteStart: () => ({ type: IS_FAVORITE_START, payload: {} }),
  isFavoriteSuccess: (perfKey, isFavorite) => ({
    type: IS_FAVORITE_SUCCESS,
    payload: { perfKey, isFavorite },
  }),
  isFavoriteFail: () => ({ type: IS_FAVORITE_FAIL, payload: {} }),

  tryUpdateFavorite: (isFavorite) => ({
    type: TRY_UPDATE_FAVORITE,
    meta: { isFavorite },
  }),
  updateFavoriteStart: () => ({ type: UPDATE_FAVORITE_START, payload: {} }),
  updateFavoriteSuccess: (perfKey, isFavorite) => ({
    type: UPDATE_FAVORITE_SUCCESS,
    payload: { perfKey, isFavorite },
  }),
  updateFavoriteFail: () => ({ type: UPDATE_FAVORITE_FAIL, payload: {} }),

  updatePermissionsStart: () => ({
    type: UPDATE_PERMISSIONS_START,
    payload: {},
  }),
  updatePermissionsSuccess: (perfKey, permissions) => ({
    type: UPDATE_PERMISSIONS_SUCCESS,
    payload: { perfKey, permissions },
  }),
  updatePermissionsFail: () => ({ type: UPDATE_PERMISSIONS_FAIL, payload: {} }),
};
