/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';

/* eslint-disable max-len */
const IconChevronRight = ({ color = 'white' }) => (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.03769L1 8.96231C1 9.84715 2.02882 10.3245 2.69477 9.74866L7.64149 5.78635C8.1195 5.37301 8.1195 4.62699 7.64149 4.21365L2.69477 0.251336C2.02882 -0.324512 1 0.152845 1 1.03769Z"
      fill={color}
    />
  </svg>
);

export default IconChevronRight;
