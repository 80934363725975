/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createCachedSelector } from 're-reselect';

import { State } from '@app/reducer';

const getLoves = (state: State) => state.loves;
const getLoveKey = (_, loveKey) => loveKey;
const getLove = createCachedSelector(
  getLoves,
  getLoveKey,
  (loves, loveKey) => loves[loveKey]
)((state, loveKey) => loveKey);

export const LovesSelectors = {
  getLoves,
  getLove,
};
