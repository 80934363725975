/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import axios from 'axios';
import signedAjax from '@app/utils/signed_request.js';
import { AppUids } from '@app/constants/utils';
import { FlowTypes } from '@app/pages/NowPlaying/modules/gifting/types'; // Todo extract as reusable types.
import {
  DenormalizedAccountInfo,
  InitialProfileDataResponse,
} from '@app/pages/Profile/types';
import { doApiRequest } from './utils';

type SuccessResponse = {
  active: boolean;
};

type MaxLimitReachedResponse = {
  error: {
    limitExceeded: boolean;
  };
};

type UpdateFavorite = SuccessResponse & MaxLimitReachedResponse;

export const updateFavorite = (performanceKey: string, isFavorited: boolean) =>
  doApiRequest<UpdateFavorite>({
    url: `/api/performance/${performanceKey}/${
      isFavorited ? 'unfavorite' : 'favorite'
    }`,
    method: 'post',
  });

type SocialStats = {
  followers: string;
  followees: string;
};

type UpdateFollowee = {
  active: boolean;
  data: SocialStats;
};

export const updateFollowee = (accountId: number, isFollowing: boolean) =>
  doApiRequest<UpdateFollowee | void>({
    url: `/api/social/${accountId}/${
      isFollowing ? 'remove-followee' : 'add-followee'
    }`,
    method: 'post',
  });

export const getUserProfiles = (
  accountIds: string[],
  isProfileVisit: boolean
) =>
  doApiRequest<DenormalizedAccountInfo[]>({
    url: `/api/social/users/${accountIds}`,
    method: 'get',
    params: {
      isProfileVisit,
    },
  });

//NEW PROFILE PAGE
export const getInitialProfileDataByHandle = (handle: string) =>
  axios
    .get<InitialProfileDataResponse>('/api/profile/', { params: { handle } })
    .then((r) => r.data);

export const getInitialProfileDataById = (accountId: number) =>
  axios
    .get<InitialProfileDataResponse>('/api/profile/', {
      params: { accountId },
    })
    .then((r) => r.data);

export const updateImage = (image) =>
  signedAjax({
    url: '/api/user/update',
    method: 'post',
    data: image,
  });

export const getProfileSongs = (accountId: number, offset = 0, limit = 6) =>
  axios({
    url: '/api/profile/songs',
    method: 'get',
    params: {
      accountId,
      offset,
      limit,
    },
  });

export const getProfileBeats = (accountId: number, offset = 0, limit = 6) =>
  axios({
    url: '/api/profile/beats',
    method: 'get',
    params: {
      accountId,
      offset,
      limit,
    },
  });

export const updateBlurb = (blurb: string) =>
  signedAjax({
    url: '/api/user/update',
    method: 'post',
    data: { blurb },
  });

export const getProfileRecordings = (
  accountId: number,
  appUid: AppUids,
  offset = 0,
  limit = 24
) =>
  axios({
    url: '/api/profile/performances',
    method: 'get',
    params: {
      accountId,
      appUid,
      offset,
      limit,
    },
  });

export const getProfileFavorites = (handle: string, offset = 0, limit = 20) =>
  axios({
    url: `/${handle}/favorites/json`,
    method: 'get',
    params: {
      offset,
      limit,
    },
  });

export const getProfileFollowers = (
  accountId: number,
  offset = 0,
  limit = 20
) =>
  axios({
    url: '/api/profile/followers',
    method: 'get',
    params: {
      accountId,
      offset,
      limit,
    },
  });

export const getProfileFollowees = (
  accountId: number,
  offset = 0,
  limit = 20
) =>
  axios({
    url: '/api/profile/followees',
    method: 'get',
    params: {
      accountId,
      offset,
      limit,
    },
  });

export const getProfileInitialGifts = (
  accountId: number,
  type = FlowTypes.ACCT
) =>
  axios({
    url: '/api/gifts/list',
    method: 'get',
    params: {
      type,
      accountId,
    },
  });

export const getProfileGifts = (
  accountId: number,
  performanceKey = false,
  cursor = 'start',
  limit = 25,
  type = FlowTypes.ACCT
) =>
  axios({
    url: '/api/gifts/list/recent',
    method: 'get',
    params: {
      type,
      accountId,
      performanceKey,
      cursor,
      limit,
    },
  });

export const getProfileTopGifters = (
  accountId: number,
  performanceKey = false,
  type = FlowTypes.ACCT
) =>
  axios({
    url: '/api/gifts/list/topgivers',
    method: 'get',
    params: {
      type,
      accountId,
      performanceKey,
    },
  });

export const updateUserBlockedStatus = (
  accountId: number,
  isUserBlocked: boolean
) =>
  signedAjax({
    url: `/api/social/${isUserBlocked ? 'unblock' : 'block'}`,
    method: 'post',
    params: {
      accountId,
    },
  });

export type ProfileAPItype = {
  updateFavorite: typeof updateFavorite;
  updateFollowee: typeof updateFollowee;
  getUserProfiles: typeof getUserProfiles;
  getInitialProfileDataByHandle: typeof getInitialProfileDataByHandle;
  getInitialProfileDataById: typeof getInitialProfileDataById;
  getProfileSongs: typeof getProfileSongs;
  getProfileBeats: typeof getProfileBeats;
  updateBlurb: typeof updateBlurb;
  updateImage: typeof updateImage;
  getProfileRecordings: typeof getProfileRecordings;
  getProfileFavorites: typeof getProfileFavorites;
  getProfileFollowers: typeof getProfileFollowers;
  getProfileFollowees: typeof getProfileFollowees;
  getProfileInitialGifts: typeof getProfileInitialGifts;
  getProfileGifts: typeof getProfileGifts;
  getProfileTopGifters: typeof getProfileTopGifters;
  updateUserBlockedStatus: typeof updateUserBlockedStatus;
};

export const ProfileApi = {
  updateFavorite,
  updateFollowee,
  getUserProfiles,
  getInitialProfileDataByHandle,
  getInitialProfileDataById,
  getProfileSongs,
  getProfileBeats,
  updateBlurb,
  updateImage,
  getProfileRecordings,
  getProfileFavorites,
  getProfileFollowers,
  getProfileFollowees,
  getProfileInitialGifts,
  getProfileGifts,
  getProfileTopGifters,
  updateUserBlockedStatus,
};
