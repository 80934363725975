/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export enum BodyTextSize {
  mini = 'mini',
  small = 'small',
  regular = 'regular',
  medium = 'medium',
}

export enum DisplayTextSize {
  small = 'small',
  regular = 'regular',
  medium = 'medium',
  big = 'big',
}

export enum TextWeight {
  regular = 'regular',
  medium = 'medium',
  bold = 'bold',
}
