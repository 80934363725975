/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { PlayersActionTypes } from '@app/components/Player/actions';
import { RecordingPageActionsPrefix } from '@app/pages/NowPlaying/actions';
import {
  STATE_KEY as PlayerActionsPrefix,
  PLAYER_TYPES,
} from '@app/components/Player/reducer';
import { PlayersSelectors } from '@app/components/Player/selectors';
import { PerformancesActionsPrefix } from '@app/pages/NowPlaying/modules/performances/actions';
import { LovesActionsPrefix } from '@app/pages/NowPlaying/modules/loves/actions';

import PerformancesAnalytics from './performancesAnalytics';
import RecordingPageAnalytics from './recordingPageAnalytics';
import PerformancePlayerAnalytics from './performancePlayerAnalytics';
import LovesAnalytics from './lovesAnalytics';

export default (store) => (next) => (action) => {
  if (action.type.match(new RegExp(`^${RecordingPageActionsPrefix}/.*`))) {
    RecordingPageAnalytics(store, action);
    next(action);
    return;
  }

  if (action.type.match(new RegExp(`^${PlayerActionsPrefix}/.*`))) {
    if (
      action.type !== PlayersActionTypes.INIT &&
      typeof action.payload.id === 'number'
    ) {
      const playerType = PlayersSelectors.getPlayerType(store.getState(), {
        id: action.payload.id,
      });
      if (playerType === PLAYER_TYPES.PERFORMANCE) {
        PerformancePlayerAnalytics(store, action);
      }
    }
    next(action);
    return;
  }

  if (action.type.match(new RegExp(`^${PerformancesActionsPrefix}/.*`))) {
    PerformancesAnalytics(store, action);
    next(action);
    return;
  }

  if (action.type.match(new RegExp(`^${LovesActionsPrefix}/.*`))) {
    LovesAnalytics(store, action);
    next(action);
    return;
  }

  next(action);
};
