/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable from 'seamless-immutable';

import { RouteChangeBlockerActionTypes } from './actions';
import { RouteChangeBlockerState } from './types';

const initialState = Immutable<RouteChangeBlockerState>({
  enabled: false,
  promptMessage: '',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RouteChangeBlockerActionTypes.SET_ROUTE_CHANGE_BLOCKER: {
      return state
        .set('enabled', action.payload.enable)
        .set('promptMessage', action.payload.message);
    }
    default:
      break;
  }
  return state;
};
