/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Cookies from 'cookies-js';

import * as LanguageHelpers from './supportedLanguages';

export const getLanguageFromPath = (path: string) => {
  const langMatch = path.match(LanguageHelpers.localesRegex);
  return langMatch && langMatch[1];
};

export const getLanguageFromBrowserLocale = (locale: string) => {
  switch (locale.toLowerCase()) {
    case 'zh-cn': {
      return 'zh-Hans';
    }
    default: {
      return locale.slice(0, 2);
    }
  }
};

const formatLocale = (locale: string) => {
  // Replace underscores with dashes
  const dashedLocale = locale.replace(/_/g, '-');

  // Get the individual pieces of the locale:
  // Language code, optional language supplement, country or region code
  // Examples:
  // zh-Hans-CN => [zh, Hans, CN]
  // en-US => [en, US]
  // es-419 => [es, 419]
  const localePieces = dashedLocale.split('-');

  switch (localePieces.length) {
    case 3: {
      // Language code + optional language supplement + country or region code
      return `${localePieces[0]}-${
        localePieces[1]
      }-${localePieces[2].toUpperCase()}`;
    }
    case 2: {
      // Language code + country or region code
      return `${localePieces[0]}-${localePieces[1].toUpperCase()}`;
    }
    case 1: {
      // Only language code
      return localePieces[0];
    }
    default: {
      return locale;
    }
  }
};
export const getSmuleLocaleFromCookie = () => Cookies.get('smule_locale');
export const setLocaleCookie = (locale: string) =>
  Cookies.set('smule_locale', locale);

export const getLanguageLocale = (path: string) => {
  const browserLocale =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator['userLanguage'];

  const browserLanguage = getLanguageFromBrowserLocale(browserLocale);

  const formattedBrowserLocale = formatLocale(browserLocale);
  const cookieLocale = getSmuleLocaleFromCookie();

  let locale: string, language: string;

  const routeLanguage = getLanguageFromPath(path);

  if (routeLanguage && LanguageHelpers.languageIsSupported(routeLanguage)) {
    language = routeLanguage;
    const cookieLanguage = cookieLocale
      ? LanguageHelpers.getLangFromLocale(cookieLocale)
      : null;
    locale =
      cookieLanguage === routeLanguage
        ? cookieLocale
        : LanguageHelpers.getDefaultLocaleForLanguage(routeLanguage);
  } else if (cookieLocale && LanguageHelpers.localeIsSupported(cookieLocale)) {
    language = LanguageHelpers.getLangFromLocale(cookieLocale);
    locale = cookieLocale;
  } else if (LanguageHelpers.languageIsSupported(browserLanguage)) {
    language = browserLanguage;
    locale = LanguageHelpers.localeIsSupported(formattedBrowserLocale)
      ? formattedBrowserLocale
      : LanguageHelpers.getDefaultLocaleForLanguage(browserLanguage);
  } else {
    locale = LanguageHelpers.DefaultLocale;
    language = LanguageHelpers.DefaultLanguage;
  }

  return { language, locale };
};
