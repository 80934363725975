/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import { createGlobalStyle } from 'styled-components';

const DEFAULT_COLOR = '#8826FB';

const GlobalStyle = createGlobalStyle<{
  color: string;
}>`
  .spinner {
    animation: spinner-rotate 2s linear infinite;
    border-radius: 50%;
  }

  .spinner.small {
    height: 20px;
    width: 20px;
  }

  .spinner.regular {
    height: 28px;
    width: 28px;
  }

  .spinner.big {
    height: 36px;
    width: 36px;
  }

  .spinner circle {
    fill: transparent;
    stroke: ${(props) => props.color};
    stroke-linecap: round;
    stroke-width: 3px;
  }

  .spinner.small circle {
    animation: spinner-dash-small 1.5s ease-in-out infinite;
  }

  .spinner.regular circle {
    animation: spinner-dash-regular 1.5s ease-in-out infinite;
  }

  .spinner.big circle {
    animation: spinner-dash-big 1.5s ease-in-out infinite;
  }

  @keyframes spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
  }

  @keyframes spinner-dash-small {
    0% {
      stroke-dasharray: 1, 52;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 36, 52;
      stroke-dashoffset: -14;
    }
    100% {
      stroke-dasharray: 36, 52;
      stroke-dashoffset: -50;
    }
  }

  @keyframes spinner-dash-regular {
    0% {
      stroke-dasharray: 1, 86;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 50, 86;
      stroke-dashoffset: -20;
    }
    100% {
      stroke-dasharray: 50, 86;
      stroke-dashoffset: -70;
    }
  }

  @keyframes spinner-dash-big {
    0% {
      stroke-dasharray: 1, 110;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 65, 110;
      stroke-dashoffset: -25;
    }
    100% {
      stroke-dasharray: 65, 110;
      stroke-dashoffset: -89;
    }
  }
`;

enum SpinnerType {
  Small = 'small',
  Regular = 'regular',
  Big = 'big',
}

interface SpinnerSvgDimensions {
  viewBox: number;
  cx: number;
  cy: number;
  r: number;
}

interface SpinnerSvgProps {
  type: SpinnerType;
}

const getSvgDimensions = (type: SpinnerType): SpinnerSvgDimensions => {
  const dimensionsMapping: { [key in SpinnerType]: SpinnerSvgDimensions } = {
    small: {
      viewBox: 20,
      cx: 10,
      cy: 10,
      r: 8,
    },
    regular: {
      viewBox: 20,
      cx: 10,
      cy: 10,
      r: 8,
    },
    big: {
      viewBox: 28,
      cx: 14,
      cy: 14,
      r: 12,
    },
  };
  return dimensionsMapping[type];
};

const SpinnerSvg = ({ type }: SpinnerSvgProps) => {
  const { viewBox, cx, cy, r } = getSvgDimensions(type);
  return (
    <svg className={`spinner ${type}`} viewBox={`0 0 ${viewBox} ${viewBox}`}>
      <circle cx={cx} cy={cy} r={r} />
    </svg>
  );
};

interface SpinnerProps {
  color?: string;
  children?: React.ReactNode;
}

const AnimatedSpinner: React.FC<SpinnerProps> = ({
  color = DEFAULT_COLOR,
  children,
}) => (
  <React.Fragment>
    <GlobalStyle color={color} />
    {children}
  </React.Fragment>
);

export const SmallSpinner: React.FC<SpinnerProps> = ({
  color = DEFAULT_COLOR,
}) => (
  <AnimatedSpinner color={color}>
    <SpinnerSvg type={SpinnerType.Small} />
  </AnimatedSpinner>
);

export const RegularSpinner: React.FC<SpinnerProps> = ({
  color = DEFAULT_COLOR,
}) => (
  <AnimatedSpinner color={color}>
    <SpinnerSvg type={SpinnerType.Regular} />
  </AnimatedSpinner>
);

export const BigSpinner: React.FC<SpinnerProps> = ({
  color = DEFAULT_COLOR,
}) => (
  <AnimatedSpinner color={color}>
    <SpinnerSvg type={SpinnerType.Big} />
  </AnimatedSpinner>
);
