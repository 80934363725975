/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';
import { COLORS, SHADOWS } from '@app/constants';
import * as screenType from '@app/styles/style_breakpoints';

const Icon = styled.span<{
  backgroundUrl: string;
  backgroundUrl2x: string;
}>`
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: ${SHADOWS.mediumShadow};

  background-image: ${(props) => `url('${props.backgroundUrl}')`};
  ${screenType.retina} {
    background-image: ${(props) => `url(${props.backgroundUrl2x})`};
  }

  @media only screen and (min-width: 360px) {
    width: 64px;
    height: 64px;
  }
`;
export const LabelWrapper = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 264px;
  margin-left: 16px;
  text-align: left;
`;
export const MainLabel = styled.span`
  font-size: 17px;
  font-weight: bold;
  line-height: 24px;
  color: ${COLORS.purple500};
`;
export const SubLabel = styled.span`
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  color: ${COLORS.gray300};
`;
export const UploadButtonStyles = styled.button<{
  disabled: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: transparent;
  text-decoration: none;
  transition: background-color 0.1s;
  border: none;
  font-family: 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;

  ${(props) =>
    props.disabled
      ? `
    opacity: 0.5;
    cursor:not-allowed;
  `
      : `
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.purple400};
    }
  `}
`;

interface UploadButtonProps {
  onClick: (event: React.MouseEvent) => void;
  testId: string;
  mainLabel: string;
  subLabel: string;
  disabled?: boolean;
  backgroundUrl: string;
  backgroundUrl2x: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  onClick,
  testId,
  mainLabel,
  subLabel,
  disabled = false,
  backgroundUrl,
  backgroundUrl2x,
}) => {
  const onClickHandler = disabled ? (e) => e.preventDefault() : onClick;
  return (
    <UploadButtonStyles
      onClick={onClickHandler}
      data-test-id={testId}
      disabled={disabled}
    >
      <Icon backgroundUrl={backgroundUrl} backgroundUrl2x={backgroundUrl2x} />
      <LabelWrapper>
        {<MainLabel>{mainLabel}</MainLabel>}
        <SubLabel>{subLabel}</SubLabel>
      </LabelWrapper>
    </UploadButtonStyles>
  );
};

export default UploadButton;
