/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { detectLocale } from '@app/utils/utils';
import {
  DenormalizedCommentRuby,
  DenormalizedPerformer,
  LoveEntity,
  Recommendations,
  Recording,
} from '@app/WebConfig/types';
import { DenormalizedSocialInfo } from '@app/pages/Profile/types';
import { doApiRequest } from './utils';

type GetCommentsResponse = {
  comments: {
    list: DenormalizedCommentRuby[];
    next_offset: number;
  };
  user_commenter: boolean;
};

type GetCommentersResponse = {
  cursor:
    | {
        next: string;
        hasNext: true;
      }
    | { hasNext: false };
  list: DenormalizedPerformer[];
  total: number;
};

export const getPerformance = (perfKey: string) =>
  doApiRequest<Recording>({
    url: `/api/performance/${perfKey}`,
    method: 'get',
  });

export const getNewUpNext = (
  cursor: string,
  campaignAdId: string | null,
  arrKey: string | null
) =>
  doApiRequest<Recommendations>({
    url: '/api/performance/upnext',
    method: 'get',
    params: { cursor, campaignAdId, arrKey },
  });

export const getComments = (perfKey: string, offset: number, size: number) =>
  doApiRequest<GetCommentsResponse>({
    url: `/api/performance/${perfKey}/comments`,
    method: 'get',
    params: { offset, limit: size },
  });

export const deletePerformance = (perfKey: string) =>
  doApiRequest<{ success: boolean }>({
    url: `/api/performance/${perfKey}`,
    method: 'delete',
  });

export const reportPerformance = (perfKey: string) =>
  doApiRequest<{ active: boolean }>({
    url: `/api/performance/${perfKey}/report`,
    method: 'post',
  });

export const submitLove = (perfKey: string) =>
  doApiRequest<{ active: boolean }>({
    url: `/api/performance/${perfKey}/love`,
    method: 'post',
  });

export const isLoved = (perfKey: string) =>
  doApiRequest<{ loved: boolean }>({
    url: `/api/performance/${perfKey}/love`,
    method: 'get',
  });

export const isFavorite = (perfKey: string) =>
  doApiRequest<{ data: { favorite: boolean } }>({
    url: `/api/performance/${perfKey}/favorite`,
    method: 'get',
  });

export const reportComment = (perfKey: string, commentKey: string) =>
  doApiRequest<{ active: true }>({
    url: `/api/performance/${perfKey}/comments/${commentKey}/report`,
    method: 'post',
  });

export const updatePermissions = (perfKey: string) =>
  doApiRequest<{ can_edit: boolean; can_delete: boolean; can_invite: boolean }>(
    {
      url: `/api/performance/${perfKey}/permissions`,
      method: 'get',
    }
  );

export const submitComment = (
  perfKey: string,
  message: string,
  recaptcha: any
) =>
  doApiRequest<DenormalizedCommentRuby | string>({
    url: `/api/performance/${perfKey}/comments`,
    method: 'post',
    data: { message, recaptcha },
  });

export const deleteComment = (perfKey: string, commentKey: string) =>
  doApiRequest<{ active: true }>({
    url: `/api/performance/${perfKey}/comments/${commentKey}`,
    method: 'delete',
  });

export const likeComment = (perfKey: string, commentKey: string) =>
  doApiRequest<{ active: true }>({
    url: `/api/performance/${perfKey}/comments/${commentKey}/like`,
    method: 'post',
  });

export const unLikeComment = (perfKey: string, commentKey: string) =>
  doApiRequest<{ active: true }>({
    url: `/api/performance/${perfKey}/comments/${commentKey}/unlike`,
    method: 'post',
  });

export const getCommenters = (perfKey: string, cursor: string) =>
  doApiRequest<GetCommentersResponse>({
    url: `/api/performance/${perfKey}/commenters`,
    method: 'get',
    params: { cursor },
  });

export const getCommentLikes = (perfKey: string, commentKey: string) =>
  doApiRequest<{ list: LoveEntity[]; next_offset: -1 }>({
    url: `/api/performance/${perfKey}/comments/${commentKey}/likes`,
    method: 'get',
  });

export const searchByHandle = (handle: string, offset: number) =>
  doApiRequest<{ accounts: DenormalizedSocialInfo[]; next_offset: number }>({
    url: '/api/social/search',
    method: 'get',
    params: { handle, offset },
  });

export const getLoves = (perfKey: string) =>
  doApiRequest<{ list: LoveEntity[] }>({
    url: `/api/performance/${perfKey}/loves`,
    method: 'get',
  });

export const inviteFollowers = (perfKey: string, selectedFollowers: number[]) =>
  doApiRequest<{ error?: string; success?: boolean }>({
    url: `/api/performance/${perfKey}/invite`,
    method: 'post',
    data: {
      invitees: selectedFollowers,
    },
  });

export const renderRecordingAjax = (perfKey: string, type: string) =>
  doApiRequest({
    url: `/api/performance/${perfKey}/render`,
    method: 'post',
    data: { type },
  });

export const listenStartAjax = (perfKey: string) =>
  doApiRequest({
    url: `/api/performance/${perfKey}/listen/start`,
    method: 'post',
  });

export const listenAjax = (perfKey: string) =>
  doApiRequest({
    url: `/api/performance/${perfKey}/listen`,
    method: 'post',
    data: {
      locale: detectLocale(),
    },
  });

export type PerformanceAPIType = {
  getPerformance: typeof getPerformance;
  getNewUpNext: typeof getNewUpNext;
  deletePerformance: typeof deletePerformance;
  reportPerformance: typeof reportPerformance;
  submitLove: typeof submitLove;
  isLoved: typeof isLoved;
  isFavorite: typeof isFavorite;
  getLoves: typeof getLoves;
  updatePermissions: typeof updatePermissions;
  getComments: typeof getComments;
  reportComment: typeof reportComment;
  deleteComment: typeof deleteComment;
  getCommentLikes: typeof getCommentLikes;
  likeComment: typeof likeComment;
  unLikeComment: typeof unLikeComment;
  submitComment: typeof submitComment;
  searchByHandle: typeof searchByHandle;
  getCommenters: typeof getCommenters;
  inviteFollowers: typeof inviteFollowers;
  renderRecordingAjax: typeof renderRecordingAjax;
  listenStartAjax: typeof listenStartAjax;
  listenAjax: typeof listenAjax;
};

export const PerformanceApi = {
  getPerformance,
  getNewUpNext,
  deletePerformance,
  reportPerformance,
  submitLove,
  isLoved,
  isFavorite,
  getLoves,
  updatePermissions,
  getComments,
  reportComment,
  deleteComment,
  getCommentLikes,
  likeComment,
  unLikeComment,
  submitComment,
  searchByHandle,
  getCommenters,
  inviteFollowers,
  renderRecordingAjax,
  listenStartAjax,
  listenAjax,
};
