/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { PlayersActionTypes } from '@app/components/Player/actions';
import {
  VideoJSPlayerActionTypes,
  VideoJSPlayerActions,
} from '@app/components/Player/player_types/video_js/video_js_player_reducer';
import { RecordingPageActions } from '@app/pages/NowPlaying/actions';
import { nowPlayingSelectors } from '@app/pages/NowPlaying/selectors';
import { VideoJSPlayerSelectors } from '@app/components/Player/player_types/video_js/video_js_player_selectors';
import { PerformancePlayerActionTypes } from '@app/components/Player/player_types/performance/performance_player_reducer';
import { PerformancePlayerSelectors } from '@app/components/Player/player_types/performance/performance_player_selectors';
import { PlayersSelectors } from '@app/components/Player/selectors';
import { Selectors as LoginSelectors } from '@app/layout/Login/reducer';
import { PerformancesSelectors } from '@app/pages/NowPlaying/modules/performances/selectors';
import { emitEvent, trackFacebookPixelEvent } from '@app/utils/event_logger';
import { emitGAEvent, isValidEntityKey } from '@app/utils/utils';

const PerformancePlayerAnalytics = (store, action) => {
  const state = store.getState();
  let eventName;
  let eventLabel;
  if (
    action.type !== PlayersActionTypes.INIT &&
    typeof action.payload.id === 'number'
  ) {
    eventName = PerformancePlayerSelectors.getEventCategory(state, {
      id: action.payload.id,
    });
    eventLabel = PerformancePlayerSelectors.getEventLabel(state, {
      id: action.payload.id,
    });
  }
  const manualText = 'manual';

  const emitConsumptionEvent = (pauseEvent) => {
    const { type, playerState } = PlayersSelectors.getCurrentPlayer(state);

    // consumption time event
    if (type === 'LIVE_PLAYER') return null;

    const performance = PerformancesSelectors.getPerformance(
      state,
      playerState.PERFORMANCE.perfKey
    );
    const isPaused = VideoJSPlayerSelectors.getPaused(state, {
      id: action.payload.id,
    });

    if (pauseEvent && isPaused) return null; // do not fire if pauseEvent and already paused

    const listenStartTime = VideoJSPlayerSelectors.getListenStartTime(state, {
      id: action.payload.id,
    });
    let listenEndTime = VideoJSPlayerSelectors.getCurrentTimeCs(state, {
      id: action.payload.id,
    });
    const seekedToPosition = VideoJSPlayerSelectors.getSeekedToPosition(state, {
      id: action.payload.id,
    });
    const lastEndTime = VideoJSPlayerSelectors.getPreviousEndTime(state, {
      id: action.payload.id,
    });
    if (seekedToPosition) {
      listenEndTime = lastEndTime;
      store.dispatch(
        VideoJSPlayerActions.seekedToPosition(action.payload.id, false)
      );
    }

    if (listenStartTime >= listenEndTime) return null; // do not fire unless end time > start time

    const consumptionType = VideoJSPlayerSelectors.getConsumptionType(state, {
      id: action.payload.id,
    });
    const manualStartAction = VideoJSPlayerSelectors.getManualStartAction(
      state,
      { id: action.payload.id }
    );
    const manualEndAction =
      pauseEvent !== null
        ? pauseEvent
        : VideoJSPlayerSelectors.getPauseRequestedCs(state, {
            id: action.payload.id,
          });
    const perfKey = performance.key;
    const isPerformer = PlayersSelectors.getIsPerformer(state)(
      performance.owner,
      performance.otherPerformers
    );
    const isVideo = performance.type === 'video';
    const perfArrKey = performance.arrKey;
    const compositionId = performance.songId;

    const totalDuration =
      listenEndTime -
      listenStartTime +
      nowPlayingSelectors.getTotalListenInterval(state);
    const totalIntervals =
      nowPlayingSelectors.getListenIntervalCount(state) + 1;

    if (totalDuration) {
      emitEvent('listen_interval', {
        target: perfKey || '-',
        context: consumptionType,
        value: isPerformer,
        k1: compositionId,
        k2: listenStartTime,
        k3: listenEndTime,
        k4: null,
        k5: perfArrKey,
        k6: isVideo,
        k7: manualStartAction,
        k8: manualEndAction,
        k9: `${totalDuration.toFixed(2)},${totalIntervals}`,
      });
    }

    store.dispatch(RecordingPageActions.setTotalListenInterval(totalDuration));
    store.dispatch(RecordingPageActions.setIntervalsCount(totalIntervals));

    return totalDuration;
  };

  // https://stackoverflow.com/questions/27745/getting-parts-of-a-url-regex
  // RexEx positions:
  // protocol: RegExp.$2,
  // host: RegExp.$3,
  // path: RegExp.$6,

  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;

  const emitNPTSEvent = (totalDuration?: number | null) => {
    const settings = LoginSelectors.getSettings(state);

    if (!settings || !settings.elControl || !settings.elControl.npt) {
      return;
    }

    const { type } = PlayersSelectors.getCurrentPlayer(state);

    if (type === 'LIVE_PLAYER') return;

    const src = PerformancePlayerSelectors.getSrc(state, {
      id: action.payload.id,
    });

    if (!src) {
      return;
    }

    const loadStartTime = VideoJSPlayerSelectors.getLoadStartTime(state, {
      id: action.payload.id,
    });
    const canPlayTime = VideoJSPlayerSelectors.getCanPlayTime(state, {
      id: action.payload.id,
    });

    const startupTime = canPlayTime - loadStartTime;

    if (startupTime < 0) {
      // if there is an edge case that causes a negative startupTime then do not log this as there has been an error
      console.error('invalid startupTime'); // eslint-disable-line
      return;
    }

    const matches = src.match(urlRegex);

    const scheme = matches[2];
    const host = matches[3];
    const path = `/${matches[6]}`;

    const connection =
      window.navigator['connection'] ||
      window.navigator['mozConnection'] ||
      window.navigator['webkitConnection'];
    const connectionType = connection
      ? connection.type || connection['effectiveType']
      : null;
    // connection.downlink value is never greater than 10 Mbps, as a non-standard anti-fingerprinting measure
    const MAX_CONNECTION_MBPS = 10;
    // convert downlink mbps units to kbps
    const bandwidth =
      connection && connection['downlink']
        ? Math.round(Math.min(connection['downlink'], MAX_CONNECTION_MBPS)) *
          1000
        : null;

    // allow totalDuration to be passed in in case not enough time to change state
    // convert to ms
    const durationWatched = Math.round(
      (totalDuration || nowPlayingSelectors.getTotalListenInterval(state)) *
        1000
    );

    const error = VideoJSPlayerSelectors.getError(state, {
      id: action.payload.id,
    });

    const stallCount = VideoJSPlayerSelectors.getStallCount(state, {
      id: action.payload.id,
    });

    const perfIsVideo = PerformancePlayerSelectors.getPerfIsVideo(state, {
      id: action.payload.id,
    });
    const droppedVideoFrames = VideoJSPlayerSelectors.getDroppedVideoFrames(
      state,
      { id: action.payload.id }
    );

    emitEvent('npt_s', {
      target: path,
      context: host,
      value: scheme,
      k1: connectionType,
      k2: startupTime,
      k4: bandwidth,
      k5: error,
      k6: durationWatched,
      k7: stallCount,
      k9: perfIsVideo ? droppedVideoFrames : null,
    });
  };

  switch (action.type) {
    case VideoJSPlayerActionTypes.SET_PASSIVE_CONSUMPTION: {
      emitConsumptionEvent(manualText);
      break;
    }
    case VideoJSPlayerActionTypes.SET_ACTIVE_CONSUMPTION: {
      emitConsumptionEvent(manualText);
      break;
    }
    case VideoJSPlayerActionTypes.SET_BACKGROUND_CONSUMPTION: {
      emitConsumptionEvent(manualText);
      break;
    }
    case VideoJSPlayerActionTypes.SET_READY: {
      emitGAEvent({
        eventName,
        eventCategory: 'Loaded',
        eventLabel,
        nonInteractive: true,
      });

      const src = PerformancePlayerSelectors.getSrc(state, {
        id: action.payload.id,
      });
      if (!src) {
        emitGAEvent({
          eventName,
          eventCategory: 'Not Rendered',
          eventLabel,
          nonInteractive: true,
        });
      }
      break;
    }
    case PerformancePlayerActionTypes.LISTEN_START_SUCCESS: {
      // Hack to prevent this event from firing when setting source on mobile without autoplay
      const renderComplete = PerformancePlayerSelectors.getRenderComplete(
        state,
        { id: action.payload.id }
      );
      if (renderComplete) break;
      const listenTrigger = PerformancePlayerSelectors.getListenTrigger(state, {
        id: action.payload.id,
      });
      emitGAEvent({
        eventName,
        eventCategory: 'Listen Start',
        eventLabel: `context:${eventLabel},trigger:${listenTrigger}`,
        nonInteractive: true,
      });
      break;
    }
    case PerformancePlayerActionTypes.LISTEN_SUCCESS: {
      const listenTrigger = PerformancePlayerSelectors.getListenTrigger(state, {
        id: action.payload.id,
      });
      emitGAEvent({
        eventName,
        eventCategory: 'Listen',
        eventLabel: `context:${eventLabel},trigger:${listenTrigger}`,
        nonInteractive: true,
      });
      break;
    }
    case PerformancePlayerActionTypes.SHOW_LOADING_SPINNER: {
      const currentTime = VideoJSPlayerSelectors.getCurrentTime(state, {
        id: action.payload.id,
      });
      const seeking = VideoJSPlayerSelectors.getSeeking(state, {
        id: action.payload.id,
      });
      if (currentTime > 0 && !seeking) {
        emitGAEvent({
          eventName,
          eventCategory: 'Loading Spinner',
          eventLabel,
          nonInteractive: true,
        });
      }
      break;
    }
    case VideoJSPlayerActionTypes.PLAY_CLICK: {
      const src = PerformancePlayerSelectors.getSrc(state, {
        id: action.payload.id,
      });
      if (src) {
        emitGAEvent({
          eventName,
          eventCategory: 'Play Clicked',
          eventLabel,
        });
      }
      break;
    }
    case VideoJSPlayerActionTypes.PLAY: {
      // Hack to prevent this event from firing when setting source on mobile without autoplay
      const renderComplete = PerformancePlayerSelectors.getRenderComplete(
        state,
        { id: action.payload.id }
      );
      if (renderComplete) break;
      emitGAEvent({
        eventName,
        eventCategory: 'Playback Started',
        eventLabel,
        nonInteractive: true,
      });
      if (
        parseFloat(
          VideoJSPlayerSelectors.getCurrentTimeCs(state, {
            id: action.payload.id,
          })
        ) === 0
      ) {
        const perfKey = PerformancePlayerSelectors.getPerfKey(state, {
          id: action.payload.id,
        });
        const perfStatus = PerformancePlayerSelectors.getPerfStatus(state, {
          id: action.payload.id,
        });
        const perfSongUid = PerformancePlayerSelectors.getPerfSongUid(state, {
          id: action.payload.id,
        });
        const perfEnsembleType = PerformancePlayerSelectors.getPerfEnsembleType(
          state,
          { id: action.payload.id }
        );
        const perfArrKey = PerformancePlayerSelectors.getPerfArrKey(state, {
          id: action.payload.id,
        });
        const perfIsVideo = PerformancePlayerSelectors.getPerfIsVideo(state, {
          id: action.payload.id,
        });
        const playlistId = nowPlayingSelectors.getPlaylistId(state);
        const isAwesomePlaylistId = isValidEntityKey(playlistId);
        const isHdVideo = PerformancePlayerSelectors.getIsHdVideo(state, {
          id: action.payload.id,
        });

        emitEvent('perf_listen', {
          target: perfKey,
          value: perfStatus,
          k1: perfSongUid,
          k3: perfEnsembleType,
          k5: perfArrKey,
          k6: perfIsVideo,
          // we need to send k7 only for awesomePlaylists
          k7: isAwesomePlaylistId ? playlistId : null,
          k8: isHdVideo ? 'hd' : 'sd',
        });
        trackFacebookPixelEvent('perf_listen');
      }
      break;
    }
    case VideoJSPlayerActionTypes.PAUSE_CLICK: {
      const src = PerformancePlayerSelectors.getSrc(state, {
        id: action.payload.id,
      });
      if (src) {
        emitGAEvent({
          eventName,
          eventCategory: 'Pause Clicked',
          eventLabel,
        });
      }
      break;
    }
    case VideoJSPlayerActionTypes.PAUSE: {
      emitConsumptionEvent(null);
      // Hack to prevent this event from firing when setting source on mobile without autoplay
      const renderComplete = PerformancePlayerSelectors.getRenderComplete(
        state,
        { id: action.payload.id }
      );
      if (renderComplete) break;
      emitGAEvent({
        eventName,
        eventCategory: 'Paused',
        eventLabel,
        nonInteractive: true,
      });
      break;
    }
    case VideoJSPlayerActionTypes.END: {
      emitNPTSEvent();
      emitGAEvent({
        eventName,
        eventCategory: 'Song End',
        eventLabel,
        nonInteractive: true,
      });
      break;
    }
    case VideoJSPlayerActionTypes.UNLOAD: {
      emitNPTSEvent();
      break;
    }
    case VideoJSPlayerActionTypes.TOGGLE_MUTE: {
      const muted = VideoJSPlayerSelectors.getMuted(state, {
        id: action.payload.id,
      });
      emitGAEvent({
        eventName,
        eventCategory: muted ? 'unmute' : 'mute',
        eventLabel,
      });
      break;
    }
    case VideoJSPlayerActionTypes.VOLUME_CHANGED: {
      const eventValue = Math.round(
        VideoJSPlayerSelectors.getVolume(state, { id: action.payload.id }) * 100
      );
      emitGAEvent({
        eventName,
        eventCategory: 'change_volume',
        eventLabel,
        eventValue,
      });
      break;
    }
    case PerformancePlayerActionTypes.TOGGLE_MAP: {
      const mapSelected = PerformancePlayerSelectors.getMapSelected(state, {
        id: action.payload.id,
      });
      emitGAEvent({
        eventName: 'Performance',
        eventCategory: mapSelected ? 'hide_globe' : 'show_globe',
      });
      break;
    }
    case PerformancePlayerActionTypes.AUTOPLAY_NEXT: {
      const perfKey = PerformancePlayerSelectors.getPerfKey(state, {
        id: action.payload.id,
      });
      const perfStatus = PerformancePlayerSelectors.getPerfStatus(state, {
        id: action.payload.id,
      });
      const perfSongUid = PerformancePlayerSelectors.getPerfSongUid(state, {
        id: action.payload.id,
      });
      const perfEnsembleType = PerformancePlayerSelectors.getPerfEnsembleType(
        state,
        { id: action.payload.id }
      );
      const perfArrKey = PerformancePlayerSelectors.getPerfArrKey(state, {
        id: action.payload.id,
      });
      const perfIsVideo = PerformancePlayerSelectors.getPerfIsVideo(state, {
        id: action.payload.id,
      });
      const perfLength = VideoJSPlayerSelectors.getDuration(state, {
        id: action.payload.id,
      });

      emitEvent('playlist_next_autoplay', {
        target: perfKey,
        value: perfStatus,
        k1: perfSongUid,
        k3: perfEnsembleType,
        k5: perfArrKey,
        k6: perfIsVideo,
        k7: perfLength,
      });
      break;
    }
    case PerformancePlayerActionTypes.PLAY_NEXT: {
      const totalDuration = emitConsumptionEvent(manualText);
      emitNPTSEvent(totalDuration);
      const perfKey = PerformancePlayerSelectors.getPerfKey(state, {
        id: action.payload.id,
      });
      const perfStatus = PerformancePlayerSelectors.getPerfStatus(state, {
        id: action.payload.id,
      });
      const perfSongUid = PerformancePlayerSelectors.getPerfSongUid(state, {
        id: action.payload.id,
      });
      const perfEnsembleType = PerformancePlayerSelectors.getPerfEnsembleType(
        state,
        { id: action.payload.id }
      );
      const perfArrKey = PerformancePlayerSelectors.getPerfArrKey(state, {
        id: action.payload.id,
      });
      const perfIsVideo = PerformancePlayerSelectors.getPerfIsVideo(state, {
        id: action.payload.id,
      });

      emitGAEvent({
        eventName: 'Player',
        eventCategory: 'Next',
        eventLabel: 'performance',
      });

      emitEvent('playlist_next_clk', {
        target: perfKey,
        context: 'nowplaying',
        value: perfStatus,
        k1: perfSongUid,
        k3: perfEnsembleType,
        k5: perfArrKey,
        k6: perfIsVideo,
      });
      break;
    }
    case PerformancePlayerActionTypes.PLAY_PREVIOUS: {
      const perfKey = PerformancePlayerSelectors.getPerfKey(state, {
        id: action.payload.id,
      });
      const perfStatus = PerformancePlayerSelectors.getPerfStatus(state, {
        id: action.payload.id,
      });
      const perfSongUid = PerformancePlayerSelectors.getPerfSongUid(state, {
        id: action.payload.id,
      });
      const perfEnsembleType = PerformancePlayerSelectors.getPerfEnsembleType(
        state,
        { id: action.payload.id }
      );
      const perfArrKey = PerformancePlayerSelectors.getPerfArrKey(state, {
        id: action.payload.id,
      });
      const perfIsVideo = PerformancePlayerSelectors.getPerfIsVideo(state, {
        id: action.payload.id,
      });

      emitGAEvent({
        eventName: 'Player',
        eventCategory: 'Previous',
        eventLabel: 'performance',
      });

      emitEvent('playlist_previous_clk', {
        target: perfKey,
        context: 'nowplaying',
        value: perfStatus,
        k1: perfSongUid,
        k3: perfEnsembleType,
        k5: perfArrKey,
        k6: perfIsVideo,
      });
      break;
    }
    // no default
  }
};

export default PerformancePlayerAnalytics;
