/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';

/* eslint-disable max-len */
const SearchIcon = ({ color = 'white', size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.646 16.7737C12.2941 17.5814 10.7131 18.0456 9.0238 18.0456C4.04009 18.0456 0 14.006 0 9.02281C0 4.03965 4.04009 0 9.0238 0C14.0075 0 18.0476 4.03965 18.0476 9.02281C18.0476 11.2282 17.2563 13.2488 15.9421 14.8162L22.5595 21.4329C23.1468 22.0201 23.1468 22.9723 22.5595 23.5595C21.9721 24.1468 21.0199 24.1468 20.4326 23.5595L13.646 16.7737ZM15.541 9.02281C15.541 12.6218 12.6231 15.5393 9.0238 15.5393C5.42446 15.5393 2.50661 12.6218 2.50661 9.02281C2.50661 5.42386 5.42446 2.50633 9.0238 2.50633C12.6231 2.50633 15.541 5.42386 15.541 9.02281Z"
      fill={color}
    />
  </svg>
);

export default SearchIcon;
