/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { Actions } from '@app/layout/Login';
import { noop } from '@app/utils/utils';

export const getCookieBannerVersion =
  () =>
  (dispatch, _, { api }) => {
    // Make sure the DAU analytics API is already executed before send a request to get cookieBannerVersion.
    // The reason to do this is to prevent async issues related to simultaneous execution of guest login.
    return (
      window.dauPromise &&
      window.dauPromise
        .then(() =>
          api.login.getCookieBannerVersion().then((response) => {
            if (response.data)
              dispatch(
                Actions.setCookieBannerVersion({
                  cookieBannerVersion: response.data.cookieBannerVersion,
                })
              );
          })
        )
        // Add catch statement in order to prevent unhandled promise rejection error
        .catch(noop)
    );
  };
