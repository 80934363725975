/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export enum AppUids {
  SING_IOS = 'sing',
  SING_ANDROID = 'sing_google',
  SING_AMAZON = 'sing_amazon',
  PIANO_IOS = 'minipiano',
  PIANO_ANDROID = 'minipiano_android',
  PIANO_AMAZON = 'minipiano_amazon',
  AUTORAP_IOS = 'autorap_ios',
  AUTORAP_ANDROID = 'autorap_goog',
  GUITAR_IOS = 'mg2_ios',
}

export enum AppNames {
  SING = 'Smule',
  PIANO = 'Magic Piano',
  AUTORAP = 'AutoRap',
  GUITAR = 'Magic Guitar',
}

export enum AppFamilies {
  SING = 'sing',
  PIANO = 'piano',
  AUTORAP = 'autorap',
  GUITAR = 'guitar',
}

export enum EnsembleTypes {
  SOLO = 'SOLO',
  DUET = 'DUET',
  GROUP = 'GROUP',
  BACKUP = 'BACKUP',
  MIX = 'MIX',
  BATTLE = 'BATTLE',
}

export enum SharingTypes {
  FACEBOOK = 'FACEBOOK',
  WHATSAPP = 'WHATSAPP',
  TWITTER = 'TWITTER',
  EMAIL = 'EMAIL',
  PINTEREST = 'PINTEREST',
  COPYLINK = 'COPYLINK',
  EMBED = 'EMBED',
  MORE = 'OTHER',
}
