/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useHistory } from 'react-router-dom';

const EnsemblesPage = () => {
  const history = useHistory();
  window.location.href = history.location.pathname;
  return null;
};

export default EnsemblesPage;
