/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';

const VALUES_SALT = '<*>';

interface FormattedMessageProps {
  id?: string;
  description?: string;
  defaultMessage: string;
  values?: object;
}

export const FormattedMessage: React.FC<FormattedMessageProps> | any = ({
  defaultMessage,
  values,
}) => {
  let parsedMessage = defaultMessage;

  if (typeof values !== 'undefined') {
    const parsedValues: React.ReactElement[] = [];
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        parsedMessage = parsedMessage.replace(`{${key}}`, VALUES_SALT);
        parsedValues.push(
          React.createElement(React.Fragment, { key }, values[key])
        );
      }
    }
    const parsedMessageParts = parsedMessage.split(VALUES_SALT);
    const resultMessage: React.ReactElement[] = [];
    parsedMessageParts.forEach((part) => {
      resultMessage.push(
        React.createElement(React.Fragment, { key: part }, [
          part,
          parsedValues.shift(),
        ])
      );
    });

    return resultMessage;
  }

  return <span dangerouslySetInnerHTML={{ __html: parsedMessage }} />;
};

export const defineMessages = (messagesObj) => messagesObj;

export const injectIntl = (Component, options) => (props) => {
  const rest = {
    [options.intlPropName]: {
      formatMessage: (message, replacements) => {
        let parsedMessage = message.defaultMessage || '';
        if (replacements) {
          for (const key in replacements) {
            if (Object.prototype.hasOwnProperty.call(replacements, key)) {
              parsedMessage = parsedMessage.replace(
                `{${key}}`,
                replacements[key]
              );
            }
          }
        }
        return parsedMessage;
      },
    },
  };
  return React.createElement(Component, { ...props, ...rest }, props.children);
};

export type intlShape = {
  formatMessage: (message: string, replacements?: object) => string;
};
