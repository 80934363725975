/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export type BodySize = 'mini' | 'small' | 'regular' | 'medium';
export type BodySizes = { [key in BodySize]: string };
export type DisplaySize = 'small' | 'regular' | 'medium' | 'big';
export type DisplaySizes = { [key in DisplaySize]: string };
export type Weight = 'regular' | 'medium' | 'bold';
export type Weights = { [key in Weight]: number };
export interface BodyTypographyStyles {
  fontSize: BodySizes;
  fontWeight: Weights;
  lineHeight: BodySizes;
}

export interface DisplayTypographyStyles {
  fontSize: DisplaySizes;
  fontWeight: Weights;
  lineHeight: DisplaySizes;
}
export interface Typography {
  body: BodyTypographyStyles;
  display: DisplayTypographyStyles;
}

const body: BodyTypographyStyles = {
  fontSize: {
    mini: '12px',
    small: '14px',
    regular: '15px',
    medium: '17px',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  lineHeight: {
    mini: '16px',
    small: '20px',
    regular: '24px',
    medium: '28px',
  },
};

const display: DisplayTypographyStyles = {
  fontSize: {
    small: '20px',
    regular: '24px',
    medium: '32px',
    big: '40px',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  lineHeight: {
    small: '28px',
    regular: '32px',
    medium: '36px',
    big: '44px',
  },
};

const typography: Typography = {
  body,
  display,
};

export default typography;
