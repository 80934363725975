/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Cookies from 'cookies-js';
import { smuleLog, sendNonInteractionEvent } from './utils';
import { emitEvent } from './event_logger';

class NavTracker {
  public flashScope: any | null;
  constructor() {
    this.flashScope = null;
    try {
      this.loadFlashScope();
      this.flushELEvents();
      this.flushGAEvents();
    } catch (e) {
      smuleLog(`Failed to submit nav tracking event: ${e}`);
    }
  }

  updateEphemeralStorage(storage) {
    if (storage) {
      Cookies.set('ephemeralStorage', JSON.stringify(storage), {
        expires: 60 * 30,
      });
    } else {
      Cookies.expire('ephemeralStorage');
    }
  }

  getEphemeralStorage() {
    const storageString = Cookies.get('ephemeralStorage');
    let storage = {};
    if (storageString && storageString !== '') {
      try {
        storage = JSON.parse(storageString);
      } catch (e) {
        smuleLog(
          `Failed to deserialize ephemeralStorage: ${e}; ${storageString}`
        );
      }
    }
    return storage;
  }

  loadFlashScope() {
    if (!this.flashScope) {
      this.flashScope = this.getEphemeralStorage();
      this.updateEphemeralStorage(null);
    }
  }

  getFlashScope(namespace) {
    return this.flashScope[namespace];
  }

  addToNextPageFlashScope(namespace, lambda) {
    const storage = this.getEphemeralStorage();
    storage[namespace] = lambda(storage[namespace]);
    this.updateEphemeralStorage(storage);
  }

  recordClick(page, area) {
    this.queueGAEvent(page, area, '$currentPage');
  }

  queueGAEvent(category, action, label, value?) {
    this.addToNextPageFlashScope('gaEvents', (v) => {
      const v2 = v || [];
      v2.push({ category, action, label, value });
      return v2;
    });
  }

  flushGAEvents() {
    const events = this.getFlashScope('gaEvents');
    if (!events) return;
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (event.label === '$currentPage') event.label = location.pathname;
      sendNonInteractionEvent(
        event.category,
        event.action,
        event.label,
        event.value
      );
    }
  }

  setSearchContext(context) {
    this.addToNextPageFlashScope('searchContext', () => context);
  }

  getSearchContext() {
    const searchContext = this.getFlashScope('searchContext');
    // Hack to properly store emojis in the nav tracker cookie (WEB-6338)
    if (searchContext && searchContext.term)
      searchContext.term = decodeURIComponent(searchContext.term);
    return searchContext;
  }

  queueELEvent(name, data) {
    this.addToNextPageFlashScope('elEvents', (v) => {
      const v2 = v || [];
      v2.push({ name, data });
      return v2;
    });
  }

  flushELEvents() {
    const events = this.getFlashScope('elEvents');
    if (!events) return;
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      emitEvent(event.name, event.data);
    }
  }
}

const instance = new NavTracker();
export default instance;
