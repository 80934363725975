/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createSelector } from 'reselect';

import { WidgetSelectors } from '@app/widgets/selectors';
import { STATE_KEY } from './actions';

const getJourneyBanner = createSelector(
  WidgetSelectors.getWidgets,
  (widgets) => widgets[STATE_KEY]
);
const getIsVisible = createSelector(
  getJourneyBanner,
  (journeyBanner) => journeyBanner.isVisible
);

export const Selectors = {
  getIsVisible,
};
