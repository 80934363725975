/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';

import { DisplayTextSize, TextWeight } from './types';
import { Wrapper } from './styles';

interface Props {
  size?: DisplayTextSize;
  weight?: TextWeight;
  children: React.ReactNode | string;
}

const DisplayText: React.FC<Props> = ({
  size = DisplayTextSize.regular,
  weight = TextWeight.regular,
  children,
}) => {
  return (
    <Wrapper type="display" size={size} weight={weight}>
      {children}
    </Wrapper>
  );
};

export default DisplayText;
