/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import Cookies from 'cookies-js';
import Config from '@app/WebConfig';
import { COOKIES_LOGGED_OUT_INITIATED } from '@app/constants';
import {
  TOGGLE_TOPBAR_OPTION,
  CLOSE_TOPBAR_OPTION,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  SHOW_FLOW,
  REG_SUCCESS,
  FB_SDK_LOADED,
  APPLE_SDK_LOADED,
  RECAPTCHA_LOADED,
  UPDATE_LOGIN_STATUS,
  UPDATE_VIP_STATUS,
  UPDATE_PROFILE_PIC,
  UPDATE_ACCOUNT_PAGE,
  UPDATE_FEED_STATUS,
  CANCEL_FEED_STATUS_POLL,
  ENABLE_FEED_STATUS_POLL,
  USER_LOGOUT,
  INPUT_EMAIL,
  SET_AGE_LIMIT,
  SET_RESUBMIT,
  INPUT_BDAY,
  INPUT_PASSWORD,
  INPUT_PASSWORD_CONFIRM,
  TOGGLE_SPINNER,
  SET_EMAIL_ERROR,
  SET_PASSWORD_ERROR,
  SET_PASSWORD_CONFIRM_ERROR,
  TOGGLE_RECAPTCHA,
  SET_NEW_ACCOUNT_OPT_IN,
  SHOW_NEW_ACCOUNT_OPT_IN,
  RESET_PASSWORD_EMAIL_SENT,
  UNEXPECTED_ERROR,
  SET_USER_NAME_FLOW,
  SET_FB_AUTH_RESPONSE,
  SET_APPLE_AUTH_RESPONSE,
  SET_MODAL_ANIMATION_STATE,
  SET_FB_LOGIN_ERROR,
  INPUT_PHONE,
  SET_PHONE_PIN_ID,
  INPUT_PIN,
  SET_PHONE_ERROR,
  GET_FOLLOWEES_START,
  GET_FOLLOWEES_SUCCESS,
  GET_FOLLOWEES_FAIL,
  GET_FOLLOWERS_START,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAIL,
  SET_SNP_SETTINGS,
  SET_COOKIE_BANNER_VERSION,
  SET_PLAYER_ID,
} from './action-types';

/* Flow constant */
export const FLOW_ID = {
  LOGIN: 'LOGIN', // Main Menu
  SET_USER_NAME: 'SET_USER_NAME',
  RESET_PASSWORD: 'RESET_PASSWORD',

  EMAIL: 'EMAIL', // Enter Email
  EMAIL_LOGIN: 'EMAIL_LOGIN', // Email is found, show login flow
  NEW_ACCOUNT: 'NEW_ACCOUNT', // Email not found, register new account
  AGE_GATE: 'AGE_GATE', // Before registration

  SET_PASSWORD: 'SET_PASSWORD',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_INVALID: 'SET_PASSWORD_INVALID',
  ACTIVATION_SUCCESS: 'ACTIVATION_SUCCESS',
  SEND_ACTIVATION: 'SEND_ACTIVATION',
  ACTIVATION_SENT: 'ACTIVATION_SENT',
  EMAIL_UPDATED: 'EMAIL_UPDATED',
  PHONE_AUTHENTICATION: 'PHONE_AUTHENTICATION',
  PHONE_CODE_SUBMISSION: 'PHONE_CODE_SUBMISSION',
};

export const LOGIN_ORIGIN = {
  FACEBOOK: 'LOGIN_ORIGIN/FACEBOOK',
  FACEBOOK_AUTO: 'LOGIN_ORIGIN/FACEBOOK_AUTO',
  APPLE: 'LOGIN_ORIGIN/APPLE',
  GOOGLE: 'LOGIN_ORIGIN/GOOGLE',
  PHONE: 'LOGIN_ORIGIN/PHONE',
  EMAIL: 'LOGIN_ORIGIN/EMAIL',
};

export const AGE_GATE_ORIGIN = {
  FACEBOOK: 'AGE_GATE_ORIGIN/FACEBOOK',
  FACEBOOK_AUTO: 'AGE_GATE_ORIGIN/FACEBOOK_AUTO',
  APPLE: 'AGE_GATE_ORIGIN/APPLE',
  GOOGLE: 'AGE_GATE_ORIGIN/GOOGLE',
  PHONE: 'AGE_GATE_ORIGIN/PHONE',
  EMAIL: 'AGE_GATE_ORIGIN/EMAIL',
};

// the literals are specified (and reused) for the GA tracking events
export const AGE_GATE_REG_TYPES = {
  FACEBOOK: 'fb',
  APPLE: 'apple',
  GOOGLE: 'google',
  PHONE: 'phone',
  EMAIL: 'email',
};

export const MODAL = {
  CLOSE_SUCCESS: 'LOGIN_MODAL/CLOSE_SUCCESS',
  CLOSE_CANCEL: 'LOGIN_MODAL/CLOSE_CANCEL',
};

/* ERRORS
  Input error should be initialize to FRAGMENT
  When User is typing, we don't want show error use FRAGMENT ()
  ERRORS.NONE is when input passes all validations
*/
export const ERRORS = {
  NONE: 'NO_ERRORS', // No error
  INVALID_EMAIL_FORMAT: 'INVALID_EMAIL_FORMAT',
  PASSWORD_TOO_SHORT: 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_LONG: 'PASSWORD_TOO_LONG',
  PASSWORD_TOO_WEAK: 'PASSWORD_TOO_WEAK',
  PASSWORD_INVALID: 'PASSWORD_INVALID',
  PASSWORD_DOES_NOT_MATCH: 'PASSWORD_DOES_NOT_MATCH',
  EMAIL_NOT_FOUND: 'EMAIL_NOT_FOUND',
  FRAGMENT: 'FRAGMENT', // User are still typing, this will suppress error messages.
  UNEXPECTED_ERROR_NEED_RELOAD: 'UNEXPECTED_ERROR_NEED_RELOAD',
  FB_LOGIN_UNEXPECTED_ERROR: 'FB_LOGIN_UNEXPECTED_ERROR',
  APPLE_LOGIN_UNEXPECTED_ERROR: 'APPLE_LOGIN_UNEXPECTED_ERROR',
  GOOGLE_LOGIN_UNEXPECTED_ERROR: 'GOOGLE_LOGIN_UNEXPECTED_ERROR',
  SEND_ACTIVATION_UNEXPECTED_ERROR: 'SEND_ACTIVATION_UNEXPECTED_ERROR',
  WRONG_PIN_ERROR: 'WRONG_PIN_ERROR',
  MAX_ATTEMPTS_ERROR: 'MAX_ATTEMPTS_ERROR',
  TTL_EXPIRED_ERROR: 'TTL_EXPIRED_ERROR',
  SMS_NOT_SEND_ERROR: 'SMS_NOT_SEND_ERROR',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  PHONE_GENERIC_ERROR: 'PHONE_GENERIC_ERROR',
  STALE_ACTIVATION_LINK: 'STALE_ACTIVATION_LINK',
};

// Error codes related to the phone login flow.
export const PHONE_LOGIN_ERRORS = {
  1: 'WRONG_PIN_ERROR',
  2: 'MAX_ATTEMPTS_ERROR',
  3: 'TTL_EXPIRED_ERROR',
  4: 'SMS_NOT_SEND_ERROR',
  65: 'USER_NOT_FOUND',
};

export const REG_TYPE = {
  FACEBOOK: 'fb',
  APPLE: 'apple',
  GOOGLE: 'google',
  PHONE: 'phone',
  EMAIL: 'email',
};

// some 'empty' and legacy pages need redirect on cancel/success login
const PAGES_LOGIN_CANCEL_REDIRECT =
  /\/(user\/login|user\/register|user\/password|password_reset|login)/;
const PAGES_LOGIN_SUCCESS_REDIRECT = /\/(password_reset)/;

const redirectIfNeeded = (regex) => {
  if (window.location.pathname && window.location.pathname.match(regex)) {
    window.location.href = Config.siteLinks.root;
  }
};

const redirectOnCloseIfNeeded = () => {
  redirectIfNeeded(PAGES_LOGIN_CANCEL_REDIRECT);
};

const redirectOnSuccessIfNeeded = () => {
  redirectIfNeeded(PAGES_LOGIN_SUCCESS_REDIRECT);
};

export const Actions = {
  toggleTopBarOption: () => ({ type: TOGGLE_TOPBAR_OPTION }),
  closeTopBarOption: () => ({ type: CLOSE_TOPBAR_OPTION }),
  openLoginModal: () => ({ type: OPEN_LOGIN_MODAL }),
  closeLoginModalCancel: () => {
    redirectOnCloseIfNeeded();

    return { type: CLOSE_LOGIN_MODAL, payload: { status: MODAL.CLOSE_CANCEL } };
  },
  closeLoginModalSuccess: () => {
    redirectOnSuccessIfNeeded();

    return {
      type: CLOSE_LOGIN_MODAL,
      payload: { status: MODAL.CLOSE_SUCCESS },
    };
  },
  setModalAnimationState: (inAnimation) => ({
    type: SET_MODAL_ANIMATION_STATE,
    payload: { inAnimation },
  }),

  inputEmail: (value) => ({ type: INPUT_EMAIL, value }),
  setAgeLimit: (value) => ({ type: SET_AGE_LIMIT, value }),
  setResubmit: (value) => ({ type: SET_RESUBMIT, value }),
  inputBDay: (value) => ({ type: INPUT_BDAY, value }),
  inputPassword: (value) => ({ type: INPUT_PASSWORD, value }),
  inputPasswordConfirm: (value) => ({ type: INPUT_PASSWORD_CONFIRM, value }),

  toggleSpinner: (visible) => ({ type: TOGGLE_SPINNER, visible }),

  showFlow: (flow, interactive, opt = {}) => ({
    type: SHOW_FLOW,
    flow,
    interactive,
    opt,
  }),
  regSuccess: (opt = {}) => ({ type: REG_SUCCESS, opt }),

  showRecaptcha: () => ({ type: TOGGLE_RECAPTCHA, visible: true }),
  hideRecaptcha: () => ({ type: TOGGLE_RECAPTCHA, visible: false }),

  setNewAccountOptIn: (value) => ({
    type: SET_NEW_ACCOUNT_OPT_IN,
    payload: { optIn: value },
  }),
  showNewAccountOptIn: (value) => ({
    type: SHOW_NEW_ACCOUNT_OPT_IN,
    payload: { showOptIn: value },
  }),
  resetPasswordEmailSent: (value) => ({
    type: RESET_PASSWORD_EMAIL_SENT,
    payload: { emailSent: value },
  }),

  setEmailError: (errorType) => ({ type: SET_EMAIL_ERROR, errorType }),
  clearEmailError: () => ({ type: SET_EMAIL_ERROR, errorType: ERRORS.NONE }),
  setPasswordError: (errorType) => ({ type: SET_PASSWORD_ERROR, errorType }),
  clearPasswordError: () => ({
    type: SET_PASSWORD_ERROR,
    errorType: ERRORS.NONE,
  }),
  setPasswordConfirmError: (errorType) => ({
    type: SET_PASSWORD_CONFIRM_ERROR,
    errorType,
  }),
  clearPasswordConfirmError: () => ({
    type: SET_PASSWORD_CONFIRM_ERROR,
    errorType: ERRORS.NONE,
  }),

  setUserNameFlowHandle: (handle) => ({
    type: SET_USER_NAME_FLOW,
    payload: { handle },
  }),
  setUserNameFlowError: (error) => ({
    type: SET_USER_NAME_FLOW,
    payload: { error },
  }),
  updateLoginStatus: (result, origin) => ({
    type: UPDATE_LOGIN_STATUS,
    payload: {
      loginStatus: {
        isLoggedIn: true,
        accountId: result.user.account_id,
        handle: result.user.handle,
        picUrl: result.user.pic_url,
        url: result.user.url,
        isVerified: result.user.is_verified,
        jid: result.user.jid,
        xmppHosts: result.user.xmpp_hosts,
        session: result.user.session,
        origin,
      },
    },
  }),

  setLoginStatus: (loginStatus) => ({
    type: UPDATE_LOGIN_STATUS,
    payload: { loginStatus },
  }),
  updateVipStatus: (vipStatus) => ({
    type: UPDATE_VIP_STATUS,
    payload: { vipStatus },
  }),
  updateProfilePic: (data) => ({
    type: UPDATE_PROFILE_PIC,
    payload: data,
  }),
  updateAccountPage: (hasAccountPage) => ({
    type: UPDATE_ACCOUNT_PAGE,
    payload: { hasAccountPage },
  }),
  updateFeedStatus: (activityCount, hasActivity) => ({
    type: UPDATE_FEED_STATUS,
    payload: { activityCount, hasActivity },
  }),
  cancelFeedStatusPoll: () => ({
    type: CANCEL_FEED_STATUS_POLL,
  }),
  enableFeedStatusPoll: () => ({
    type: ENABLE_FEED_STATUS_POLL,
  }),
  userLogout: () => {
    // Full page reload to logout page, prevent consequential requests to resurrecting the session.
    // Using logged_out_initiated cookie ensure the logout is initiated by the user.
    // These are the related jira tickets:
    //   https://smule-inc.atlassian.net/browse/WEB-9366
    //   https://smule-inc.atlassian.net/browse/WEB-9117
    Cookies.set(COOKIES_LOGGED_OUT_INITIATED, 1, { expires: 60 });
    window.location = Config.siteLinks.user_logout as unknown as Location;
  },

  fbSdkLoaded: (loaded) => ({ type: FB_SDK_LOADED, loaded }),
  setFbAuthResponse: (authResponse) => ({
    type: SET_FB_AUTH_RESPONSE,
    payload: { authResponse },
  }),
  clearFbAuthResponse: () => ({
    type: SET_FB_AUTH_RESPONSE,
    payload: { authResponse: null },
  }),
  appleSdkLoaded: (loaded) => ({ type: APPLE_SDK_LOADED, loaded }),
  setAppleAuthResponse: (authResponse) => ({
    type: SET_APPLE_AUTH_RESPONSE,
    payload: { authResponse },
  }),
  recaptchaLoaded: (loaded) => ({ type: RECAPTCHA_LOADED, loaded }),

  triggerUnexpectedError: (msg) => ({
    type: UNEXPECTED_ERROR,
    payload: { msg },
  }),

  inputPhone: (value) => ({ type: INPUT_PHONE, value }),
  setPhonePinId: (pinId) => ({ type: SET_PHONE_PIN_ID, pinId }),
  inputPin: (value) => ({ type: INPUT_PIN, value }),
  setPhoneError: (error) => ({ type: SET_PHONE_ERROR, error }),
  clearPhoneError: () => ({ type: SET_PHONE_ERROR, error: '' }),

  getFolloweesStart: () => ({ type: GET_FOLLOWEES_START, payload: {} }),
  getFolloweesSuccess: (paginated, followees, nextOffset) => ({
    type: GET_FOLLOWEES_SUCCESS,
    payload: { paginated, followees, nextOffset },
  }),
  getFolloweesFail: () => ({ type: GET_FOLLOWEES_FAIL, payload: {} }),

  getFollowersStart: () => ({ type: GET_FOLLOWERS_START, payload: {} }),
  getFollowersSuccess: (followers) => ({
    type: GET_FOLLOWERS_SUCCESS,
    payload: { followers },
  }),
  getFollowersFail: () => ({ type: GET_FOLLOWERS_FAIL, payload: {} }),
  setSnpSettings: (settings) => ({
    type: SET_SNP_SETTINGS,
    payload: { settings },
  }),
  setCookieBannerVersion: (cookieBannerVersion) => ({
    type: SET_COOKIE_BANNER_VERSION,
    payload: { cookieBannerVersion },
  }),
  setPlayerId: (playerId) => ({ type: SET_PLAYER_ID, payload: { playerId } }),
};

const getLogin = (state) => state.login;
const getLoginState = createSelector(getLogin, (login) => login.loginState);

const getHasSing = createSelector(
  getLoginState,
  (loginState) => loginState.hasSing
);
const isLoggedIn = createSelector(
  getLoginState,
  (loginState) => loginState.isLoggedIn
);
const getIsPollEnabled = createSelector(
  getLoginState,
  (loginState) => loginState.isFeedPollEnabled
);
const getUserAccountId = createSelector(
  getLoginState,
  (loginState) => loginState.accountId
);
const getUserPlayerId = createSelector(getLogin, (login) => login.playerId);
const getUserHandle = createSelector(
  getLoginState,
  (loginState) => loginState.handle
);
const getJid = createSelector(getLoginState, (loginState) => loginState.jid);
const getXmppHosts = createSelector(
  getLoginState,
  (loginState) => loginState.xmppHosts
);
const getSession = createSelector(
  getLoginState,
  (loginState) => loginState.session
);
const getTopBarState = createSelector(getLogin, (login) => login.topbar);
const topBarOptionToggled = createSelector(
  getTopBarState,
  (state) => state.showOption
);

const getLoginConfig = createSelector(getLogin, (login) => login.config);
const isActivationFromWeb = createSelector(
  getLoginConfig,
  (config) => config.activationFromWeb
);
const isActivationWithCreation = createSelector(
  getLoginConfig,
  (config) => config.activationWithCreation
);
const getActivationResult = createSelector(
  getLoginConfig,
  (config) => config.activationResult
);

const getModalState = createSelector(getLogin, (state) => state.modal);
const getModalSession = createSelector(getLogin, (state) => state.modalSession);
const getHeader = createSelector(getModalSession, (session) => session.header);
const getHeaderStatus = createSelector(getHeader, (header) => header.status);
const getLaunchOrigin = createSelector(
  getModalState,
  (modal) => modal.launchOrigin
);
const getModalSessionEmail = createSelector(
  getModalSession,
  (session) => session.email
);
const getAgeLimit = createSelector(
  getModalSession,
  (session) => session.ageLimit
);
const getAgeGateOrigin = createSelector(
  getModalSession,
  (session) => session.ageOrigin
);
const getResubmit = createSelector(
  getModalSession,
  (session) => session.resubmit
);
const getModalSessionBDay = createSelector(
  getModalSession,
  (session) => session.bday
);
const getModalSessionPassword = createSelector(
  getModalSession,
  (session) => session.password
);
const getModalSessionPasswordConfirm = createSelector(
  getModalSession,
  (session) => session.passwordConfirm
);
const getModalEmailError = createSelector(
  getModalSession,
  (session) => session.emailError
);
const getModalPasswordError = createSelector(
  getModalSession,
  (session) => session.passwordError
);
const getModalPasswordConfirmError = createSelector(
  getModalSession,
  (session) => session.passwordConfirmError
);
const getModalUnexpectedError = createSelector(
  getModalSession,
  (session) => session.unexpectedError
);

const getModalSetUserNameFlow = createSelector(
  getModalSession,
  (session) => session.setUserNameFlow
);
const getModalSetUserNameFlowHandle = createSelector(
  getModalSetUserNameFlow,
  (session) => session.handle
);
const getModalSetUserNameFlowError = createSelector(
  getModalSetUserNameFlow,
  (session) => session.error
);

const getPhoneLoginFlowState = createSelector(
  getModalSession,
  (session) => session.phoneLoginFlow
);
const getPhoneLoginNumber = createSelector(
  getPhoneLoginFlowState,
  (state) => state.phoneNumber
);
const getPhoneLoginPinId = createSelector(
  getPhoneLoginFlowState,
  (state) => state.pinId
);
const getPhoneLoginPinCode = createSelector(
  getPhoneLoginFlowState,
  (state) => state.pinCode
);
const getPhoneLoginError = createSelector(
  getPhoneLoginFlowState,
  (state) => state.error
);

const getNewAccountFlowState = createSelector(
  getModalSession,
  (session) => session.newAccountFlow
);
const getNewAccountOptIn = createSelector(
  getNewAccountFlowState,
  (state) => state.optIn
);
const getNewAccountOptInVisible = createSelector(
  getNewAccountFlowState,
  (state) => state.showOptIn
);

const getResetPasswordFlowState = createSelector(
  getModalSession,
  (session) => session.resetPasswordFlow
);
const isResetPasswordEmailSent = createSelector(
  getResetPasswordFlowState,
  (state) => state.emailSent
);

const getModalVisibility = createSelector(
  getModalState,
  (modal) => modal.visible
);
const isModalInAnimation = createSelector(
  getModalState,
  (modal) => modal.inAnimation
);
const getCurrentFlowId = createSelector(getModalState, (state) => state.flow);
const spinnerVisible = createSelector(getModalState, (modal) => modal.spinner);

const useTransition = createSelector(
  getModalState,
  (state) => state.transition
);

const fbSdk = createSelector(getLogin, (login) => login.fbSdk);
const isFbSdkLoaded = createSelector(fbSdk, (sdkState) => sdkState.loaded);
const getFbAuthResponse = createSelector(
  fbSdk,
  (sdkState) => sdkState.authResponse
);

const appleSdk = createSelector(getLogin, (login) => login.appleSdk);
const isAppleSdkLoaded = createSelector(
  appleSdk,
  (sdkState) => sdkState.loaded
);
const getAppleAuthResponse = createSelector(
  appleSdk,
  (sdkState) => sdkState.authResponse
);

const recaptcha = createSelector(getLogin, (login) => login.recaptcha);
const isRecaptchaLoaded = createSelector(recaptcha, (r) => r.loaded);
const isRecaptchaOn = createSelector(
  getModalSession,
  (session) => session.recaptcha
);

const getLoadingFollowees = createSelector(
  getLogin,
  (login) => login.loadingFollowees
);
const getFollowees = createSelector(
  getLoginState,
  (loginState) => loginState.followees
);
const getFolloweesNextCursor = createSelector(
  getLoginState,
  (loginState) => loginState.followeesNext
);
const getLoadingFollowers = createSelector(
  getLogin,
  (login) => login.loadingFollowers
);
const getFollowers = createSelector(
  getLoginState,
  (loginState) => loginState.followers
);
const getSettings = createSelector(
  getLogin,
  (loginState) => loginState.settings
);
const getSettingsLoaded = createSelector(
  getLogin,
  (loginState) => loginState.settingsLoaded
);
const getPoiEnabled = createSelector(
  getSettings,
  (settings) => settings && settings['web.uiux'] && settings['web.uiux'].poi
);
const getReferralEnabledSetting = createSelector(
  getSettings,
  (settings) =>
    settings && settings['web.uiux'] && settings['web.uiux'].referralEnabled
);
const getCookieBannerVersion = createSelector(
  getLogin,
  (loginState) => loginState.cookieBannerVersion
);
const getPhoneRegistrationEnabledSetting = createSelector(
  getSettings,
  (settings) =>
    settings &&
    settings['web.uiux'] &&
    settings['web.uiux'].phoneRegistrationEnabled
);

export const Selectors = {
  getLoginState,
  getIsPollEnabled,
  getSettings,
  getSettingsLoaded,
  getPoiEnabled,
  getReferralEnabledSetting,
  getCookieBannerVersion,
  getPhoneRegistrationEnabledSetting,
  getHasSing,
  getUserAccountId,
  getUserHandle,
  isLoggedIn,
  getJid,
  getXmppHosts,
  isActivationFromWeb,
  isActivationWithCreation,
  getActivationResult,
  getHeaderStatus,
  topBarOptionToggled,
  getModalVisibility,
  getCurrentFlowId,
  getModalSession,
  useTransition,
  isFbSdkLoaded,
  getFbAuthResponse,
  isAppleSdkLoaded,
  getAppleAuthResponse,
  spinnerVisible,
  getLaunchOrigin,
  getModalSessionEmail,
  getModalEmailError,
  getModalSessionBDay,
  getAgeLimit,
  getAgeGateOrigin,
  getResubmit,
  getModalSessionPassword,
  getModalPasswordError,
  getModalSessionPasswordConfirm,
  getModalPasswordConfirmError,
  getModalSetUserNameFlowHandle,
  getModalSetUserNameFlowError,
  isRecaptchaLoaded,
  isRecaptchaOn,
  getPhoneLoginNumber,
  getPhoneLoginPinId,
  getPhoneLoginPinCode,
  getPhoneLoginError,
  getNewAccountOptIn,
  getNewAccountOptInVisible,
  isResetPasswordEmailSent,
  getModalUnexpectedError,
  isModalInAnimation,
  getLoadingFollowees,
  getFollowees,
  getFolloweesNextCursor,
  getLoadingFollowers,
  getFollowers,
  getSession,
  getUserPlayerId,
};

const modalSessionDefault = {
  email: '',
  password: '',
  passwordConfirm: '',
  ageLimit: null,
  ageOrigin: null,
  resubmit: null,
  bday: new Date().getTime(),
  recaptcha: false,
  emailError: ERRORS.FRAGMENT,
  passwordError: ERRORS.FRAGMENT,
  passwordConfirmError: ERRORS.FRAGMENT,
  unexpectedError: ERRORS.NONE,
  header: {
    status: null,
  },
  loginFlow: {},
  emailFlow: {},
  phoneLoginFlow: {
    phoneNumber: '',
    pinId: null,
    pinCode: '',
    error: '',
  },
  newAccountFlow: {
    optIn: false,
    showOptIn: false,
  },
  resetPasswordFlow: {
    emailSent: false,
  },
  setUserNameFlow: {
    handle: '',
    error: null,
  },
};

// Inspired by community.rb's build_user_json method
const buildUser = (user) => ({
  accountId: user.account_id,
  handle: user.handle,
  firstName: user.first_name,
  lastName: user.last_name,
  picUrl: user.pic_url,
  url: user.url,
  isVerified: user.is_verified,
  verifiedType: user.verified_type,
  isVip: user.is_vip,
  jid: user.jid,
});

const getLoginDefaultState = () => ({
  isLoggedIn: false,
  handle: null,
  picUrl: null,
  isVerified: false,
  isVip: null,
  isStaff: false,
  hasSing: null,
  hasAccountPage: null,
  activityCount: null,
  hasActivity: false,
  isFeedPollEnabled: true,
  followees: [],
  followers: null,
  followeesNext: -1,
});

const getInitialState = () =>
  Immutable({
    loginState: getLoginDefaultState(),
    config: Config.loginConfig,
    modalSession: modalSessionDefault,
    modal: {
      visible: false,
      flow: null,
      transition: false,
      spinner: false,
      inAnimation: false,
      launchOrigin: null,
    },
    topbar: {
      showOption: false,
    },
    fbSdk: {
      loaded: false,
      authResponse: null,
    },
    appleSdk: {
      loaded: false,
      authResponse: null,
    },
    recaptcha: {
      loaded: false,
    },
    // Previously used for 'Invite Friends' modal, but this was changed to the 'Invite Followers' modal
    loadingFollowees: false,
    // Used for the 'Invite Followers' modal on the Recording page for an open duet seed
    loadingFollowers: false,
    settings: {},
    settingsLoaded: false,
    cookieBannerVersion: null,
    playerId: null,
  });

export default (state = getInitialState(), action) => {
  const payload = action.payload;
  // TODO: factor everything under payload attr
  switch (action.type) {
    case TOGGLE_TOPBAR_OPTION: {
      return state.setIn(['topbar', 'showOption'], !state.topbar.showOption);
    }
    case CLOSE_TOPBAR_OPTION: {
      return state.setIn(['topbar', 'showOption'], false);
    }
    case OPEN_LOGIN_MODAL: {
      return state.setIn(['modal', 'visible'], true);
    }
    case CLOSE_LOGIN_MODAL: {
      return state
        .setIn(['modal', 'visible'], false)
        .setIn(['modal', 'status'], payload.status)
        .set('modalSession', modalSessionDefault);
    }
    case SET_MODAL_ANIMATION_STATE: {
      let updatedState = state;
      if (!state.modal.visible && !payload.inAnimation) {
        updatedState = updatedState.setIn(['modal', 'launchOrigin'], null);
      }
      return updatedState.setIn(['modal', 'inAnimation'], payload.inAnimation);
    }
    case SHOW_FLOW: {
      if (action.opt.activationFailed) {
        state = state.setIn(
          ['modalSession', 'header', 'status'],
          ERRORS.STALE_ACTIVATION_LINK
        );
      } else {
        state = state.setIn(['modalSession', 'header', 'status'], null);
      }

      const modalState = {
        flow: action.flow,
        transition: !!action.transition,
        visible: true,
        inAnimation: state.modal.inAnimation,
        launchOrigin: action.opt.launchOrigin || state.modal.launchOrigin,
      };

      if (
        action.flow !== FLOW_ID.AGE_GATE &&
        action.flow !== FLOW_ID.NEW_ACCOUNT
      ) {
        // clear password on screen change (if it's not the age gate logic)
        state = state.setIn(['modalSession', 'password'], '');
      }

      if (action.flow === FLOW_ID.AGE_GATE && action.opt.ageOrigin) {
        state = state.setIn(
          ['modalSession', 'ageOrigin'],
          action.opt.ageOrigin
        );
      }

      if (typeof action.opt.resubmit !== 'undefined') {
        state = state.setIn(['modalSession', 'resubmit'], action.opt.resubmit);
      }

      return state
        .set('modal', modalState)
        .setIn(['modalSession', 'emailError'], ERRORS.FRAGMENT)
        .setIn(['modalSession', 'unexpectedError'], ERRORS.NONE)
        .setIn(['modalSession', 'passwordError'], ERRORS.FRAGMENT);
    }
    case FB_SDK_LOADED: {
      return state.setIn(['fbSdk', 'loaded'], !!action.loaded);
    }
    case SET_FB_AUTH_RESPONSE: {
      return state.setIn(['fbSdk', 'authResponse'], payload.authResponse);
    }
    case APPLE_SDK_LOADED: {
      return state.setIn(['appleSdk', 'loaded'], !!action.loaded);
    }
    case SET_APPLE_AUTH_RESPONSE: {
      return state.setIn(['appleSdk', 'authResponse'], payload.authResponse);
    }
    case UPDATE_LOGIN_STATUS: {
      const loginState = state.loginState.merge(payload.loginStatus);

      return state.set('loginState', loginState);
    }
    case UPDATE_VIP_STATUS: {
      return state.setIn(['loginState', 'isVip'], payload.vipStatus);
    }
    case UPDATE_PROFILE_PIC: {
      return state.setIn(['loginState', 'picUrl'], payload.picUrl);
    }
    case UPDATE_ACCOUNT_PAGE: {
      return state.setIn(
        ['loginState', 'hasAccountPage'],
        payload.hasAccountPage
      );
    }
    case UPDATE_FEED_STATUS: {
      return state
        .setIn(['loginState', 'hasActivity'], payload.hasActivity)
        .setIn(['loginState', 'activityCount'], payload.activityCount);
    }
    case CANCEL_FEED_STATUS_POLL: {
      return state.setIn(['loginState', 'isFeedPollEnabled'], false);
    }
    case ENABLE_FEED_STATUS_POLL: {
      return state.setIn(['loginState', 'isFeedPollEnabled'], true);
    }
    case USER_LOGOUT: {
      return state.set('loginState', getLoginDefaultState());
    }
    case TOGGLE_SPINNER: {
      return state.setIn(['modal', 'spinner'], action.visible);
    }
    case INPUT_EMAIL: {
      return state.setIn(['modalSession', 'email'], action.value);
    }
    case INPUT_BDAY: {
      return state.setIn(['modalSession', 'bday'], action.value);
    }
    case SET_AGE_LIMIT: {
      return state.setIn(['modalSession', 'ageLimit'], action.value);
    }
    case SET_RESUBMIT: {
      return state.setIn(['modalSession', 'resubmit'], action.value);
    }
    case INPUT_PASSWORD: {
      return state.setIn(['modalSession', 'password'], action.value);
    }
    case INPUT_PASSWORD_CONFIRM: {
      return state.setIn(['modalSession', 'passwordConfirm'], action.value);
    }
    case RECAPTCHA_LOADED: {
      return state.setIn(['recaptcha', 'loaded'], true);
    }
    case TOGGLE_RECAPTCHA: {
      return state.setIn(['modalSession', 'recaptcha'], action.visible);
    }
    case SET_EMAIL_ERROR: {
      return state.setIn(['modalSession', 'emailError'], action.errorType);
    }
    case SET_PASSWORD_ERROR: {
      return state.setIn(['modalSession', 'passwordError'], action.errorType);
    }
    case SET_PASSWORD_CONFIRM_ERROR: {
      return state.setIn(
        ['modalSession', 'passwordConfirmError'],
        action.errorType
      );
    }
    case INPUT_PHONE: {
      return state.setIn(
        ['modalSession', 'phoneLoginFlow', 'phoneNumber'],
        action.value
      );
    }
    case SET_PHONE_PIN_ID: {
      return state.setIn(
        ['modalSession', 'phoneLoginFlow', 'pinId'],
        action.pinId
      );
    }
    case INPUT_PIN: {
      return state.setIn(
        ['modalSession', 'phoneLoginFlow', 'pinCode'],
        action.value
      );
    }
    case SET_PHONE_ERROR: {
      return state.setIn(
        ['modalSession', 'phoneLoginFlow', 'error'],
        action.error
      );
    }
    case SET_NEW_ACCOUNT_OPT_IN: {
      return state.setIn(
        ['modalSession', 'newAccountFlow', 'optIn'],
        payload.optIn
      );
    }
    case SHOW_NEW_ACCOUNT_OPT_IN: {
      return state.setIn(
        ['modalSession', 'newAccountFlow', 'showOptIn'],
        payload.showOptIn
      );
    }
    case RESET_PASSWORD_EMAIL_SENT: {
      return state.setIn(
        ['modalSession', 'resetPasswordFlow', 'emailSent'],
        payload.emailSent
      );
    }
    case UNEXPECTED_ERROR: {
      return state.setIn(['modalSession', 'unexpectedError'], payload.msg);
    }
    case SET_FB_LOGIN_ERROR: {
      return state.setIn(
        ['modalSession', 'loginFlow', 'fbUnexpectedError'],
        payload.errorType
      );
    }
    case SET_USER_NAME_FLOW: {
      return state.setIn(
        ['modalSession', 'setUserNameFlow'],
        state.modalSession.setUserNameFlow.merge(payload)
      );
    }
    case GET_FOLLOWEES_START: {
      return state.set('loadingFollowees', true);
    }
    case GET_FOLLOWEES_SUCCESS: {
      const newAccounts = action.payload.paginated
        ? state.loginState.followees.concat(action.payload.followees)
        : action.payload.followees;
      return state
        .set('loadingFollowees', false)
        .setIn(['loginState', 'followees'], newAccounts)
        .setIn(['loginState', 'followeesNext'], action.payload.nextOffset);
    }
    case GET_FOLLOWEES_FAIL: {
      return state.set('loadingFollowees', false);
    }
    // TODO: Move followers to new module
    case GET_FOLLOWERS_START: {
      return state.set('loadingFollowers', true);
    }
    case SET_SNP_SETTINGS: {
      return state
        .merge(action.payload.settings || {}, { deep: true })
        .set('settingsLoaded', true);
    }
    case SET_COOKIE_BANNER_VERSION: {
      return state.merge(action.payload.cookieBannerVersion, { deep: true });
    }
    case GET_FOLLOWERS_SUCCESS: {
      return state
        .set('loadingFollowers', false)
        .setIn(
          ['loginState', 'followers'],
          action.payload.followers.map(buildUser)
        );
    }
    case GET_FOLLOWERS_FAIL: {
      return state.set('loadingFollowers', false);
    }
    case SET_PLAYER_ID: {
      return state.set('playerId', action.payload.playerId);
    }
    default:
      break;
  }
  return state;
};
