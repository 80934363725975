/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { State } from '@app/reducer';
import { WIDGETS_STATE_KEY } from './reducer';

const getWidgets = (state: State) => state[WIDGETS_STATE_KEY];
const getBrowserNavigated = (state: State) =>
  state[WIDGETS_STATE_KEY].browserNavigated.browserNavigated;

export const WidgetSelectors = {
  getWidgets,
  getBrowserNavigated,
};
