/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { STATE_KEY } from './selectors';

const INIT = `${STATE_KEY}/INIT`;

export const PlayersActionTypes = {
  INIT,
};

// Helper function to add the player id to Player ActionCreators
// Player components always pass in their id so the correct reducer is updated
export const hasPlayerId =
  (actionCreator) =>
  (id, ...rest) => {
    const action = actionCreator(...rest);
    return Object.assign(action, {
      payload: Object.assign(action.payload, { id }),
    });
  };

export const PlayersActions = {
  init: hasPlayerId((type, playerOptions) => ({
    type: INIT,
    payload: { type, playerOptions },
  })),
};
