/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export type PlayerState = { [key in string]: PlayerObject } & {
  currentPlayerId: number;
};

export enum PlayerTypes {
  PERFORMANCE = 'PERFORMANCE_PLAYER',
}

export interface PlayerObject {
  type: PlayerTypes;
  playerState: {
    VIDEO_JS: VideoJSState;
    PERFORMANCE: PerformanceState;
  };
}

export interface VideoJSState {
  autoplay: boolean;
  ready: boolean;
  canPlay: boolean;
  seeking: boolean;
  playRequested: boolean;
  pauseRequested: boolean;
  paused: boolean;
  ended: boolean;
  consumptionType: number;
  loadStartTime: number | null;
  canPlayTime: number | null;
  error: string | null;
  stallCount: number;
  duration: number;
  currentTime: number;
  previousEndTime: number;
  newCurrentTime: number | null;
  listenStartTime: number;
  manualStartAction: boolean;
  seekedToPosition: boolean;
  droppedVideoFrames: number;
  volume: number;
  muted: boolean;
  options: any;
  handleWaitingRequested: boolean;
}

export interface PerformanceState {
  perfKey: string | null;
  playlistId: string | null;
  playlistCursor: number;
  rendering: boolean;
  renderComplete: boolean;
  renderFailed: boolean;
  showLoadingSpinner: boolean;
  mapSelected: boolean;
  uiHidden: boolean;
  eventCategory: string | null;
  eventLabel: string | null;
  listenTriggerQueue: string | null;
  listenTrigger: string | null;
  checkRenderStarted: boolean;
  cancelledRender: boolean;
}

export enum PerformanceTypes {
  AUDIO = 'audio',
  VIDEO = 'video',
  VISUALIZER = 'visualizer',
}

export const TestIdPrefix = 'now-playing-video-';
