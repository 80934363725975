/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { RouterState, connectRouter } from 'connected-react-router';
import { ImmutableObject } from 'seamless-immutable';
import { History } from 'history';
import { combineReducers, Reducer, ReducersMapObject } from 'redux';
import { PartnerArtistsState } from '@app/pages/TopArtists/reducer';
import { gdprReducer, GDPRReducerState } from '@app/components/GDPR/reducer';
import { Reducer as loginReducer } from '@app/layout/Login';
import { HomepageState } from '@app/pages/Home/types';
import playerReducer from '@app/components/Player/reducer';
import { GroupsState } from '@app/pages/Groups/types';
import widgetsReducer, { WIDGETS_STATE_KEY } from './widgets/reducer';
import { WidgetsState } from './widgets/types';
import { ExploreState } from './pages/Explore/types';
import { I18nState } from './i18n/reducer';

//Now Playing related types
import { NowPlayingState } from '@app/pages/NowPlaying/reducer';
import { GiftingState } from '@app/pages/NowPlaying/modules/gifting/types';
import { CommentsState } from './pages/NowPlaying/modules/comments/types';
import { LovesState } from './pages/NowPlaying/modules/loves/types';
import { PerformersState } from './pages/NowPlaying/modules/performers';
import { PlaylistsState } from './pages/NowPlaying/modules/playlists/index';
import { PerformancesState } from './pages/NowPlaying/modules/performances/reducer';
import { ProfileState } from '@app/pages/Profile/types';
import { ArrangementState } from '@app/pages/Arrangement/types';

import { AwesomePlaylistsState } from '@app/pages/AwesomePlaylists/types';
import { ReducerTypes } from './store/types';
import { SongbookState } from './pages/Songbook/types';

export interface State {
  [ReducerTypes.Router]: RouterState;
  [ReducerTypes.Login]: ImmutableObject<any>;
  [ReducerTypes.Homepage]: ImmutableObject<HomepageState>;
  [ReducerTypes.PartnerArtists]: ImmutableObject<PartnerArtistsState>;
  [ReducerTypes.GDPR]: ImmutableObject<GDPRReducerState>;
  [ReducerTypes.NowPlaying]: ImmutableObject<NowPlayingState>;
  [ReducerTypes.Comments]: ImmutableObject<CommentsState>;
  [ReducerTypes.Loves]: ImmutableObject<LovesState>;
  [ReducerTypes.Gifting]: ImmutableObject<GiftingState>;
  [ReducerTypes.Performers]: ImmutableObject<PerformersState>;
  [ReducerTypes.Playlists]: ImmutableObject<PlaylistsState>;
  [ReducerTypes.Performances]: ImmutableObject<PerformancesState>;
  [ReducerTypes.Player]: ImmutableObject<{}>;
  [ReducerTypes.Groups]: ImmutableObject<GroupsState>;
  [ReducerTypes.Explore]: ImmutableObject<ExploreState>;
  [ReducerTypes.AwesomePlaylists]: ImmutableObject<AwesomePlaylistsState>;
  [ReducerTypes.I18n]: ImmutableObject<I18nState>;
  [WIDGETS_STATE_KEY]: WidgetsState;
  [ReducerTypes.ProfilePage]: ImmutableObject<ProfileState>;
  [ReducerTypes.Arrangement]: ImmutableObject<ArrangementState>;
  [ReducerTypes.Songbook]: ImmutableObject<SongbookState>;
}

export type ILoadedState = Partial<State>;
export type ILoadedReducers = Partial<ReducersMapObject<State>>;

const rootReducer = (
  history: History,
  dependencyReducers: ILoadedReducers = {}
): Reducer<ILoadedState> =>
  combineReducers({
    [ReducerTypes.Router]: connectRouter(history),
    [ReducerTypes.Login]: loginReducer,
    [ReducerTypes.GDPR]: gdprReducer,
    [ReducerTypes.Player]: playerReducer,
    [WIDGETS_STATE_KEY]: widgetsReducer,
    ...dependencyReducers,
  });

export const createRootReducer = rootReducer;

export default rootReducer;
