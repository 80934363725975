/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Selectors as LoginSelectors } from '@app/layout/Login/reducer';
import { Redirect } from 'react-router-dom';

export const RedirectToProfile = () => {
  const [path, setPath] = useState<string>('/');

  const accountId = useSelector(LoginSelectors.getUserAccountId);
  const handle = useSelector(LoginSelectors.getUserHandle);

  useEffect(() => {
    if (handle) setPath(`/${handle}`);
    else setPath(`/profile/${accountId}`);
  }, [accountId, handle]);

  if (path === '/') return null;
  return <Redirect to={path} />;
};
