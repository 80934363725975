/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export enum PlaylistTypes {
  RECOMMENDATIONS = 'recommendations',
  RECOMMENDATIONS_LEGACY = 'recommendations_legacy',
  LEGACY = 'legacy',
  TOPIC = 'topic',
  EXPLORE = 'explore',
  MOMENTS = 'moments',
  CHANNEL_SING = 'channel-sing',
  CHANNEL_AUTORAP = 'channel-autorap',
  CHANNEL_PIANO = 'channel-minipiano',
  // group feed
  SMULE_FAMILY_POSTS = 'Smule Family Posts',
  // awesome playlists type
  ACCT = 'ACCT',
  // Favorites playlist type
  ACCTFAV = 'ACCTFAV',
}

export type PlaylistKeys = 'up_next_recommendations';
