/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import axios from 'axios';
import qs from 'qs';
import { normalize } from 'normalizr';

import { currentTimeZone, noop, smuleLog } from '@app/utils/utils';
import signedAjax from '@app/utils/signed_request.js';
import { emitEvent } from '@app/utils/event_logger';
import {
  isValidEmailFormat,
  isPasswordTooShort,
  hasPlus,
  isSmuleEmail,
  splitErrors,
  sendTrackingEvent,
} from '@app/layout/Login/helpers';
import Config from '@app/WebConfig';
import { formatSNPBDay } from '@app/utils/date_util';
import { authEvents } from '@app/layout/Login/analytics/constants';
import { Performer } from '@app/pages/NowPlaying/types';
import {
  FLOW_ID,
  ERRORS,
  PHONE_LOGIN_ERRORS,
  Actions,
  Selectors,
  LOGIN_ORIGIN,
  AGE_GATE_ORIGIN,
  AGE_GATE_REG_TYPES,
  REG_TYPE,
} from './reducer';
import { onShowFlow, onInputError } from './analytics';
import { AppThunk } from '@app/store/types';

const getBDay = (getState) => {
  const state = getState();

  return Selectors.getAgeLimit(state)
    ? formatSNPBDay(Selectors.getModalSessionBDay(state))
    : null;
};

// Show flow with side effect;
export const launchFlow =
  (flowId, opt = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const currentFlowId = Selectors.getCurrentFlowId(state);
    let interactive = false;
    if (opt['interactive']) {
      // Allow interactive option to override the logic (esp when user click to open the login modal)
      interactive = true;
    } else if (currentFlowId !== null) {
      // No current flow, user click on validation email or go to login page.
      interactive = true;
    }
    onShowFlow({
      flow: flowId,
      interactive,
      opt,
    });
    dispatch(Actions.showFlow(flowId, interactive, opt));
  };

const showAgeGate = (dispatch, { minAgeRequired }, ageOrigin, label) => {
  sendTrackingEvent('reg_birthdate_pgview', label, true);
  emitEvent(authEvents.regBirthdayPGView.NAME);
  dispatch(Actions.setAgeLimit(minAgeRequired[0]));
  dispatch(launchFlow(FLOW_ID.AGE_GATE, { ageOrigin }));
};

// prevent submitting/reg with previously selected (and potentially) not allowed age
const resetAgeLimit = (dispatch) => {
  dispatch(Actions.setAgeLimit(null));
};

export const setEmailError = (type) => (dispatch) => {
  dispatch(Actions.setEmailError(type));
  onInputError(type); // emit analytics event
};

export const setPasswordError = (type) => (dispatch) => {
  dispatch(Actions.setPasswordError(type));
  onInputError(type); // emit analytics event
};

let latestRequestTimestamp = 0;
export const checkEmailAvailability =
  (email, isRecaptchaOn, recaptchaResponse) => (dispatch, getState) => {
    // Send ajax request to user controller to check if email exists in our database
    const requestData = { email };
    if (isRecaptchaOn && recaptchaResponse) {
      requestData['recaptcha'] = recaptchaResponse;
    }
    const requestTimestamp = latestRequestTimestamp + 1;
    latestRequestTimestamp = requestTimestamp;

    return signedAjax({
      url: '/api/user/check_email',
      method: 'post',
      data: requestData,
    })
      .then(({ data }) => {
        if (requestTimestamp !== latestRequestTimestamp) return;

        if (
          data.error &&
          (data.error.need_verification || data.error.invalid_recaptcha)
        ) {
          // If need verification, show Recaptcha hide email form.
          dispatch(Actions.showRecaptcha());
          return;
        }
        if (isRecaptchaOn) {
          dispatch(Actions.hideRecaptcha());
        }
        // check login state because of async issue showing the form after login success
        if (data.email && !Selectors.isLoggedIn(getState())) {
          // Email exists
          dispatch(launchFlow(FLOW_ID.EMAIL_LOGIN));
        } else {
          // new Email
          dispatch(launchFlow(FLOW_ID.EMAIL));
          dispatch(setEmailError(ERRORS.EMAIL_NOT_FOUND));
        }
      })
      .catch(() => {
        dispatch(
          Actions.triggerUnexpectedError(ERRORS.UNEXPECTED_ERROR_NEED_RELOAD)
        );
      });
  };

export const shouldShowOptIn = () => (dispatch) =>
  axios
    .get('/api/user/show-opt')
    .then(({ data }) => {
      dispatch(Actions.showNewAccountOptIn(data.showEmailOpt));
      dispatch(Actions.setNewAccountOptIn(!data.showEmailOpt));
    })
    .catch(() => {
      dispatch(Actions.showNewAccountOptIn(true));
      dispatch(Actions.setNewAccountOptIn(false));
    });

export const updatePlayerId =
  () =>
  (dispatch, getState, { api }) => {
    api.login.getPlayerId().then((response) => {
      if (response.data && response.data.playerId) {
        const playerId = response.data.playerId.toString();
        dispatch(Actions.setPlayerId(playerId));
      }
    });
  };
export const updateSNPSettings =
  (settingIds): AppThunk<Promise<unknown>> =>
  (dispatch, getState, { api }) => {
    // Make sure the DAU analytics API is already executed before send a request to get the SNP settings.
    // The reason to do this is to prevent async issues related to simultaneous execution of guest login.
    return (
      window.dauPromise &&
      window.dauPromise
        .then(() =>
          api.login.getSettings(settingIds).then((response) => {
            if (response.data)
              dispatch(Actions.setSnpSettings({ settings: response.data }));
          })
        )
        // Add catch statement in order to prevent unhandled promise rejection error
        .catch(noop)
    );
  };
export const updateUserSubStatus =
  () =>
  (dispatch, _, { api }) => {
    // Take care to update VIP status badge and "Account" page
    // item in the user status dropdown menu.
    api.login.getUserSubStatus().then(({ data }) => {
      dispatch(Actions.updateVipStatus(data.is_vip));
      dispatch(Actions.updateAccountPage(data.has_account_page));
    });
  };

export const updateFeedActivity =
  () =>
  (dispatch, _, { api }) => {
    // Take care to update VIP status badge and "Account" page
    // item in the user status dropdown menu.
    api.login
      .getFeedActivity()
      .then((data) => {
        dispatch(
          Actions.updateFeedStatus(data.activity_count, data.has_activity)
        );
        dispatch(Actions.enableFeedStatusPoll());
      })
      .catch((e) => {
        if (e.response && e.response.status === 403) {
          dispatch(Actions.cancelFeedStatusPoll());
        }
      });
  };

export const loginSuccess = (response, origin) => (dispatch, getState) => {
  const state = getState();
  if (response) {
    dispatch(Actions.updateLoginStatus(response, origin));
  }
  // After succesfull login the user status and
  // Feed status is updated from <FeedUpdater />

  dispatch(Actions.closeLoginModalSuccess());
  // update existing settings
  const settingIds = Object.keys(Selectors.getSettings(state));
  dispatch(updateSNPSettings(settingIds));
};

export const emailLogin =
  (email, password, recaptchaResponse) => (dispatch) => {
    emitEvent('signin_submit');
    const requestData = {
      'user[login]': email,
      'user[password]': password,
      tzOffset: currentTimeZone,
    };

    // Set recaptcha parameter in the request data object.
    if (recaptchaResponse) {
      requestData['recaptcha'] = recaptchaResponse;
    }
    dispatch(Actions.toggleSpinner(true));

    return signedAjax({
      url: '/api/user/email_login',
      method: 'post',
      data: requestData,
    })
      .then(({ data }) => {
        // If need verification or recaptcha was invalid - show Recaptcha and hide the login button.
        if (
          data.error &&
          (data.error.need_verification || data.error.invalid_recaptcha)
        ) {
          dispatch(Actions.showRecaptcha());
          return;
        }
        // In case of error, hide Recaptcha verification if it was shown.
        if (recaptchaResponse && !data.success) {
          dispatch(Actions.hideRecaptcha());
        }
        if (data.success) {
          sendTrackingEvent('login_success', 'smule_v1_reg', true);

          emitEvent(authEvents.regComplete.NAME, {
            k1: authEvents.regComplete.k1.EMAIL,
            value: authEvents.regComplete.value.EXISTING,
          });
          dispatch(loginSuccess(data, LOGIN_ORIGIN.EMAIL));
        } else if (data.activation_required) {
          dispatch(launchFlow(FLOW_ID.SEND_ACTIVATION));
        } else {
          dispatch(setPasswordError(ERRORS.PASSWORD_INVALID));
        }
      })
      .catch(({ status, statusText, data }) => {
        smuleLog(`login failed: ${statusText}; errorThrown=${data}`);
        // this.setPasswordState(PASSWORD_ERROR, 'Unexpected error occurred. Please reload the page and try again');
        if (status === 403) {
          window.location.href = Config.siteLinks.user_login;
        }
      })
      .then(() => dispatch(Actions.toggleSpinner(false)));
  };

export const setPassword = (password, passwordConfirm) => (dispatch) => {
  if (password !== passwordConfirm) {
    dispatch(Actions.setPasswordConfirmError(ERRORS.PASSWORD_DOES_NOT_MATCH));
    return null;
  }
  dispatch(Actions.toggleSpinner(true));
  const querySearch = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const token = querySearch.token;
  const accountId = querySearch.account_id;
  return signedAjax({
    url: '/api/user/reset_password',
    method: 'post',
    data: {
      password,
      passwordConfirmation: passwordConfirm,
      token,
      accountId,
    },
  })
    .then(({ data }) => {
      if (data.success) {
        sendTrackingEvent('new_pswd_success', 'forgot_password', true);
        if (querySearch.referral_code) {
          window.location.pathname = `/refer-a-friend/${querySearch.referral_code}`;
        } else {
          dispatch(launchFlow(FLOW_ID.SET_PASSWORD_SUCCESS));
        }
      } else {
        sendTrackingEvent('new_pswd_fail', 'forgot_password', true);
        dispatch(
          Actions.setPasswordConfirmError(ERRORS.UNEXPECTED_ERROR_NEED_RELOAD)
        );
      }
    })
    .catch(({ statusText, data }) => {
      sendTrackingEvent('new_pswd_fail', 'forgot_password', true);
      smuleLog(`password update failed: ${statusText}; errorThrown=${data}`);
      dispatch(
        Actions.setPasswordConfirmError(ERRORS.UNEXPECTED_ERROR_NEED_RELOAD)
      );
    })
    .then(() => dispatch(Actions.toggleSpinner(false)));
};

export const emailRegister =
  (email, password, optIn, useRecaptcha, recaptchaResponse, resubmit) =>
  (dispatch, getState) => {
    if (isValidEmailFormat(email) && !isPasswordTooShort(password)) {
      // try to create account
      const recaptcha = useRecaptcha ? recaptchaResponse : null;
      dispatch(Actions.toggleSpinner(true));
      emitEvent('signup_submit');
      return signedAjax({
        url: '/api/user/create',
        method: 'post',
        data: {
          email,
          password,
          optIn,
          enteredBirthDate: getBDay(getState),
          recaptcha,
          tzOffset: currentTimeZone,
          resubmit: resubmit || null,
        },
      })
        .then(({ data }) => {
          if (data.success) {
            // todo: move the success logic to activation success as the emailRegistration
            // will return an error for setting "email_verification_required: true"

            sendTrackingEvent('reg_success', 'smule_v1_reg', true);
            // Yay, Success! tell the modal so it can do its magic
            data.fromEmailRegister = true;

            dispatch(Actions.regSuccess({ type: REG_TYPE.EMAIL }));

            // TODO: Simplify this
            emitEvent(authEvents.regComplete.NAME, {
              k1: authEvents.regComplete.k1.EMAIL,
              value: authEvents.regComplete.value.NEW,
            });

            dispatch(launchFlow(FLOW_ID.ACTIVATION_SENT));
            emitEvent(authEvents.activationSend.NAME);

            dispatch(Actions.updateLoginStatus(data, LOGIN_ORIGIN.EMAIL));
          } else {
            dispatch(Actions.hideRecaptcha());
            resetAgeLimit(dispatch);

            if (data.error.invalid_email) {
              dispatch(setEmailError(ERRORS.INVALID_EMAIL_FORMAT));
            } else if (data.error.invalid_password) {
              dispatch(setPasswordError(ERRORS.PASSWORD_INVALID));
            } else if (data.error.minAgeRequired) {
              showAgeGate(
                dispatch,
                data.error,
                AGE_GATE_ORIGIN.EMAIL,
                AGE_GATE_REG_TYPES.EMAIL
              );
            } else if (data.error.need_verification) {
              dispatch(launchFlow(FLOW_ID.ACTIVATION_SENT));
              emitEvent(authEvents.regPending.NAME, {
                k1: authEvents.regPending.k1.EMAIL,
              });
              sendTrackingEvent(
                'reg_verification_pending',
                'smule_v1_reg',
                true
              );
              emitEvent(authEvents.activationSend.NAME);
            } else {
              dispatch(
                Actions.triggerUnexpectedError(
                  ERRORS.UNEXPECTED_ERROR_NEED_RELOAD
                )
              );
            }
          }
        })
        .catch(({ statusText, data }) => {
          smuleLog(`create account failed: ${statusText}; errorThrown=${data}`);
          dispatch(
            Actions.triggerUnexpectedError(ERRORS.UNEXPECTED_ERROR_NEED_RELOAD)
          );
        })
        .then(() => dispatch(Actions.toggleSpinner(false)));
    }
    return new Promise((_, reject) => reject('invalid email or password'));
  };

export const sendResetPasswordEmail =
  (email, useRecaptcha, recaptchaResponse) => (dispatch) => {
    // Submit password reset link if the email is valid
    dispatch(Actions.toggleSpinner(true));
    emitEvent('password_reset_send');
    const recaptcha = useRecaptcha ? recaptchaResponse : null;

    return signedAjax({
      url: '/api/user/send_reset_password_email',
      method: 'post',
      data: { email, recaptcha },
    })
      .then(({ data }) => {
        if (data.success) {
          sendTrackingEvent('email_send_success', 'forgot_password', true);
          dispatch(Actions.resetPasswordEmailSent(true));
        } else {
          dispatch(Actions.hideRecaptcha());
          const errorsStruct = splitErrors(data.error, 'Reset password failed');
          dispatch(Actions.triggerUnexpectedError(errorsStruct.message));
        }
      })
      .catch(({ statusText, data }) => {
        smuleLog(`password reset failed: ${statusText}; errorThrown=${data}`);
        // $this.error('Unexpected error occurred. Please reload the page and try again');
        dispatch(
          Actions.triggerUnexpectedError(ERRORS.UNEXPECTED_ERROR_NEED_RELOAD)
        );
      })
      .then(() => dispatch(Actions.toggleSpinner(false)));
  };

export const phonePinSend =
  (countryCode, phoneNumber, isRecaptchaOn, recaptchaResponse) =>
  (dispatch) => {
    const requestData: any = {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
    };

    if (phoneNumber === '' || !phoneNumber) {
      return dispatch(Actions.setPhoneError(ERRORS.PHONE_GENERIC_ERROR));
    }

    if (isRecaptchaOn && recaptchaResponse) {
      requestData.recaptcha = recaptchaResponse;
    }

    dispatch(Actions.toggleSpinner(true));
    return signedAjax({
      url: '/api/user/phone_pin_send',
      method: 'post',
      data: requestData,
    })
      .then(({ data }) => {
        if (
          data.error &&
          (data.error.need_verification || data.error.invalid_recaptcha)
        ) {
          dispatch(Actions.showRecaptcha());
          return;
        }

        if (isRecaptchaOn) {
          dispatch(Actions.hideRecaptcha());
        }

        if (data.pinId) {
          // Store the pin_id
          dispatch(Actions.setPhonePinId(data.pinId));
          dispatch(launchFlow(FLOW_ID.PHONE_CODE_SUBMISSION));
        } else {
          // Dispatch an error
          dispatch(
            Actions.setPhoneError(
              data.error.reason && PHONE_LOGIN_ERRORS[data.error.reason]
                ? PHONE_LOGIN_ERRORS[data.error.reason]
                : ERRORS.PHONE_GENERIC_ERROR
            )
          );
        }
      })
      .then(() => dispatch(Actions.toggleSpinner(false)));
  };

// TODO: Will be implemented at some later stage.
// export const phonePinResend = () => (dispatch, getState) => {};

export const phoneLogin = (pinCode) => (dispatch, getState) => {
  const state = getState();
  const requestData = {
    pinId: Selectors.getPhoneLoginPinId(state),
    pinCode: pinCode,
    tzOffset: currentTimeZone,
    enteredBirthDate: getBDay(getState),
  };

  dispatch(Actions.toggleSpinner(true));
  return signedAjax({
    url: '/api/user/phone_login',
    method: 'post',
    data: requestData,
  })
    .then(({ data }) => {
      if (data.error) {
        if (data.error.minAgeRequired) {
          showAgeGate(
            dispatch,
            data.error,
            AGE_GATE_ORIGIN.PHONE,
            AGE_GATE_REG_TYPES.PHONE
          );
        } else if (data.error.reason) {
          dispatch(
            Actions.setPhoneError(
              data.error.reason && PHONE_LOGIN_ERRORS[data.error.reason]
                ? PHONE_LOGIN_ERRORS[data.error.reason]
                : ERRORS.PHONE_GENERIC_ERROR
            )
          );
        } else {
          resetAgeLimit(dispatch);
          console.error(data.error.base); // eslint-disable-line no-console
          dispatch(launchFlow(FLOW_ID.LOGIN));
        }
      } else if (data.handleNew) {
        // for newly registered account
        dispatch(Actions.regSuccess({ type: REG_TYPE.PHONE }));
        // whether we should initialize handle for edit
        if (data.handlePrefill)
          dispatch(Actions.setUserNameFlowHandle(data.user.handle));
        dispatch(launchFlow(FLOW_ID.SET_USER_NAME));
        sendTrackingEvent('reg_success', 'phone_reg', true);
        emitEvent(authEvents.regComplete.NAME, {
          k1: authEvents.regComplete.k1.PHONE,
          k2: authEvents.regComplete.k2.CODE,
          value: authEvents.regComplete.value.NEW,
        });
      } else {
        dispatch(loginSuccess(data, LOGIN_ORIGIN.PHONE));
        sendTrackingEvent('login_success', 'phone_reg', true);
        emitEvent(authEvents.regComplete.NAME, {
          k1: authEvents.regComplete.k1.PHONE,
          k2: authEvents.regComplete.k2.CODE,
          value: authEvents.regComplete.value.EXISTING,
        });
      }
    })
    .catch(({ statusText }) => {
      console.error(statusText); // eslint-disable-line no-console
      resetAgeLimit(dispatch);
      dispatch(launchFlow(FLOW_ID.LOGIN));
      sendTrackingEvent('login_fail', 'phone_reg', true);
    })
    .then(() => dispatch(Actions.toggleSpinner(false)));
};

export const submitHandleChange = (handle) => (dispatch) => {
  if (typeof handle === 'string' && handle.trim().length > 0) {
    dispatch(Actions.toggleSpinner(true));
    return signedAjax({
      url: '/user/json/update_handle_json',
      method: 'post',
      data: {
        handle: handle.trim(),
      },
    })
      .then(({ data }) => {
        if (data.success) {
          dispatch(loginSuccess(data, LOGIN_ORIGIN.PHONE));
        } else {
          const errorsStruct = splitErrors(
            data.errors || [],
            'Handle update failed'
          );
          dispatch(Actions.setUserNameFlowError(errorsStruct.message));
        }
      })
      .catch(({ statusText, data }) => {
        smuleLog(`handle update failed: ${statusText}; errorThrown=${data}`);
        dispatch(
          Actions.setUserNameFlowError(ERRORS.UNEXPECTED_ERROR_NEED_RELOAD)
        );
      })
      .then(() => dispatch(Actions.toggleSpinner(false)));
  }

  return new Promise((_, reject) => reject('invalid username'));
};

export const basicEmailValidation = (email) => (dispatch) => {
  if (isValidEmailFormat(email)) {
    dispatch(Actions.clearEmailError());
  } else {
    dispatch(setEmailError(ERRORS.INVALID_EMAIL_FORMAT));
  }
};

let validateEmailHandle;
export const emailAvailabilityValidation =
  (email, isRecaptchaOn, emailError) => (dispatch) => {
    if (validateEmailHandle) clearTimeout(validateEmailHandle);
    if (isValidEmailFormat(email)) {
      if (!isSmuleEmail(email) && hasPlus(email)) {
        dispatch(setEmailError(ERRORS.INVALID_EMAIL_FORMAT));
      } else {
        dispatch(Actions.clearEmailError());
        validateEmailHandle = setTimeout(() => {
          dispatch(checkEmailAvailability(email, isRecaptchaOn, null));
        }, 500);
      }
    } else if (emailError !== ERRORS.FRAGMENT) {
      dispatch(setEmailError(ERRORS.FRAGMENT));
    }
  };

// Login with the web site (proxying to SNP)
export const facebookSiteLogin = (authResponse) => (dispatch, getState) => {
  sendTrackingEvent('fbconnect_success', 'fb_reg', true);
  dispatch(Actions.toggleSpinner(true));
  return signedAjax({
    method: 'post',
    url: '/api/user/fb_login',
    data: {
      sRequest: authResponse.signedRequest,
      aToken: authResponse.accessToken,
      tzOffset: currentTimeZone,
      enteredBirthDate: getBDay(getState),
    },
  })
    .then(({ data }) => {
      if (data && data.error) {
        if (data.error.minAgeRequired) {
          showAgeGate(
            dispatch,
            data.error,
            AGE_GATE_ORIGIN.FACEBOOK,
            AGE_GATE_REG_TYPES.FACEBOOK
          );
        } else {
          const errorsStruct = splitErrors(
            data.error,
            'Cannot create an account'
          );
          dispatch(Actions.triggerUnexpectedError(errorsStruct.message));
          resetAgeLimit(dispatch);
        }
      } else {
        if (data.handleNew) {
          sendTrackingEvent('reg_success', 'fb_reg', true);
          emitEvent(authEvents.regComplete.NAME, {
            k1: authEvents.regComplete.k1.FB,
            value: authEvents.regComplete.value.NEW,
          });
          dispatch(Actions.regSuccess({ type: REG_TYPE.FACEBOOK }));
        } else {
          sendTrackingEvent('login_success', 'fb_reg', true);
          emitEvent(authEvents.regComplete.NAME, {
            k1: authEvents.regComplete.k1.FB,
            value: authEvents.regComplete.value.EXISTING,
          });
        }
        dispatch(loginSuccess(data, LOGIN_ORIGIN.FACEBOOK));
      }
    })
    .catch((status) => {
      sendTrackingEvent('login_fail', 'fb_reg', true);
      dispatch(
        Actions.triggerUnexpectedError(ERRORS.FB_LOGIN_UNEXPECTED_ERROR)
      );
      if (status === 403) {
        window.location.href = Config.siteLinks.user_login;
      }
      resetAgeLimit(dispatch);
    })
    .then(() => dispatch(Actions.toggleSpinner(false)));
};

export const facebookAutoSiteLogin = (authResponse) => (dispatch) => {
  smuleLog('Autologin: User authorized the app, auto logging in from FB');
  sendTrackingEvent('autologin_start', 'fb_reg', true);

  return signedAjax({
    method: 'post',
    url: '/api/user/fb_login',
    data: {
      sRequest: authResponse.signedRequest,
      aToken: authResponse.accessToken,
      tzOffset: currentTimeZone,
    },
  })
    .then(({ data }) => {
      smuleLog('Autologin: success');
      sendTrackingEvent('autologin_success', 'fb_reg', true);
      dispatch(loginSuccess(data, LOGIN_ORIGIN.FACEBOOK_AUTO));
    })
    .catch(({ statusText }) => {
      smuleLog(`Autologin: Failed to login: ${statusText}`);
      sendTrackingEvent('autologin_fail', 'fb_reg', true);
    });
};

export const appleSiteLogin = () => (dispatch, getState) => {
  const authResponse = Selectors.getAppleAuthResponse(getState());
  dispatch(Actions.toggleSpinner(true));
  return signedAjax({
    method: 'post',
    url: '/api/user/apple_login',
    data: {
      code: authResponse.code,
      idToken: authResponse.idToken,
      nonce: authResponse.nonce,
      redirectUri: authResponse.redirectURI,
      email: authResponse.email,
      firstName: authResponse.firstName,
      lastName: authResponse.lastName,
      tzOffset: currentTimeZone,
      enteredBirthDate: getBDay(getState),
    },
  })
    .then(({ data }) => {
      if (data && data.error) {
        if (data.error.minAgeRequired) {
          showAgeGate(
            dispatch,
            data.error,
            AGE_GATE_ORIGIN.APPLE,
            AGE_GATE_REG_TYPES.APPLE
          );
        } else {
          const errorsStruct = splitErrors(
            data.error,
            'Cannot create an account'
          );
          dispatch(Actions.triggerUnexpectedError(errorsStruct.message));
          resetAgeLimit(dispatch);
        }
      } else {
        if (data.handleNew) {
          sendTrackingEvent('reg_success', 'apple_reg', true);
          emitEvent(authEvents.regComplete.NAME, {
            k1: authEvents.regComplete.k1.APPLE,
            value: authEvents.regComplete.value.NEW,
          });
          dispatch(Actions.regSuccess({ type: REG_TYPE.APPLE }));
        } else {
          sendTrackingEvent('login_success', 'apple_reg', true);
          emitEvent(authEvents.regComplete.NAME, {
            k1: authEvents.regComplete.k1.APPLE,
            value: authEvents.regComplete.value.EXISTING,
          });
        }
        dispatch(loginSuccess(data, LOGIN_ORIGIN.APPLE));
      }
    })
    .catch(({ status }) => {
      dispatch(
        Actions.triggerUnexpectedError(ERRORS.APPLE_LOGIN_UNEXPECTED_ERROR)
      );
      if (status === 403) {
        window.location.href = Config.siteLinks.user_login;
      }
      resetAgeLimit(dispatch);
    })
    .then(() => {
      dispatch(Actions.toggleSpinner(false));
    });
};

export const googleSiteLogin = (idToken: string) => (dispatch, getState) => {
  // Show the spinner
  dispatch(Actions.toggleSpinner(true));
  return signedAjax({
    method: 'post',
    url: '/api/user/google_login',
    data: {
      idToken,
      tzOffset: currentTimeZone,
      enteredBirthDate: getBDay(getState),
    },
  })
    .then(({ data }) => {
      if (data && data.error) {
        if (data.error.minAgeRequired) {
          showAgeGate(
            dispatch,
            data.error,
            AGE_GATE_ORIGIN.GOOGLE,
            AGE_GATE_REG_TYPES.GOOGLE
          );
        } else {
          const errorsStruct = splitErrors(
            data.error,
            'Cannot create an account'
          );
          dispatch(Actions.triggerUnexpectedError(errorsStruct.message));
          resetAgeLimit(dispatch);
        }
      } else {
        if (data.handleNew) {
          sendTrackingEvent('reg_success', 'goog_reg', true);
          emitEvent(authEvents.regComplete.NAME, {
            k1: authEvents.regComplete.k1.GOOGLE,
            value: authEvents.regComplete.value.NEW,
          });
          dispatch(Actions.regSuccess({ type: REG_TYPE.GOOGLE }));
        } else {
          sendTrackingEvent('login_success', 'goog_reg', true);
          emitEvent(authEvents.regComplete.NAME, {
            k1: authEvents.regComplete.k1.GOOGLE,
            value: authEvents.regComplete.value.EXISTING,
          });
        }
        dispatch(loginSuccess(data, LOGIN_ORIGIN.GOOGLE));
      }
    })
    .catch((status) => {
      dispatch(
        Actions.triggerUnexpectedError(ERRORS.GOOGLE_LOGIN_UNEXPECTED_ERROR)
      );
      if (status === 403) {
        window.location.href = Config.siteLinks.user_login;
      }
      resetAgeLimit(dispatch);
    })
    .then(() => {
      dispatch(Actions.toggleSpinner(false));
    });
};

let sendActivationHandle;
export const sendActivation = () => (dispatch) => {
  if (sendActivationHandle) return sendActivationHandle;
  dispatch(Actions.toggleSpinner(true));

  sendActivationHandle = signedAjax({
    method: 'post',
    url: '/user/json/send_activation_json',
    // the account ID is in session
  })
    .then(({ data }) => {
      if (data.success) {
        emitEvent('verification_send');
        sendTrackingEvent('resend_verification_success', 'smule_v1_reg', true);
        dispatch(Actions.closeLoginModalCancel());
      } else {
        sendTrackingEvent('resend_verification_fail', 'smule_v1_reg', true);
        dispatch(
          Actions.triggerUnexpectedError(
            ERRORS.SEND_ACTIVATION_UNEXPECTED_ERROR
          )
        );
      }
    })
    .catch(({ statusText, data }) => {
      smuleLog(`activation send failed: ${statusText}; errorThrown=${data}`);
      sendTrackingEvent('resend_verification_fail', 'smule_v1_reg', true);
      dispatch(
        Actions.triggerUnexpectedError(ERRORS.UNEXPECTED_ERROR_NEED_RELOAD)
      );
    })
    .then(() => {
      sendActivationHandle = null;
      dispatch(Actions.toggleSpinner(false));
    });

  return sendActivationHandle;
};

const getFollowees =
  (offset = 0) =>
  (dispatch, _, { api, schema }) => {
    dispatch(Actions.getFolloweesStart());
    return api.login
      .getFollowees(offset)
      .catch(() => dispatch(Actions.getFolloweesFail()))
      .then((response) => {
        if (response && response.data) {
          const normalizedResults = normalize<
            any,
            { performers: Array<Performer> }
          >(response.data.list, [schema.performer]);
          return dispatch(
            Actions.getFolloweesSuccess(
              offset > 0,
              normalizedResults.result.length === 0
                ? []
                : Object.values(normalizedResults.entities.performers),
              response.data.next_offset
            )
          );
        }
        return dispatch(Actions.getFolloweesFail());
      });
  };

const getFollowers =
  () =>
  (dispatch, _, { api }) => {
    dispatch(Actions.getFollowersStart());
    return api.login
      .getFollowers()
      .then((response) => {
        if (response && response.data) {
          return dispatch(Actions.getFollowersSuccess(response.data.list));
        }
        return dispatch(Actions.getFollowersFail());
      })
      .catch(() => dispatch(Actions.getFollowersFail()));
  };

const LoginAsyncActions = {
  launchFlow,
  checkEmailAvailability,
  shouldShowOptIn,
  updateFeedActivity,
  updateUserSubStatus,
  loginSuccess,
  emailLogin,
  emailRegister,
  sendResetPasswordEmail,
  submitHandleChange,
  basicEmailValidation,
  emailAvailabilityValidation,
  facebookSiteLogin,
  facebookAutoSiteLogin,
  sendActivation,
  updatePlayerId,
  getFollowees,
  getFollowers,
};

export default LoginAsyncActions;
