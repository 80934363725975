/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import IconChevron from '@app/components/icons/IconChevron';
import IconChevronRight from '@app/components/icons/IconChevronRight';
import ToggleButton from '@app/components/ToggleButton';

const AccordionWrapper = styled.div`
  margin-bottom: 10px;
`;

const Wrapper = styled.div<{
  isExpanded?: boolean;
}>`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.silver400};
  border-radius: ${(props) => props.theme.corners.medium};
  padding: 10px;

  ${(props) =>
    props.isExpanded === true &&
    css`
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const Left = styled.div`
  flex-basis: 50%;
`;
const Right = styled.div`
  flex-basis: 50%;
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.p`
  font-size: 15px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.gray400};
  margin: 0;
  cursor: pointer;
`;

const ChevronButton = styled.button`
  cursor: pointer;
  padding-left: 15px;
  padding-right: 12px;
  background: none;
  border: none;
  width: 40px;

  &:focus-visible {
    outline: none;
  }
`;

const VerticalCentered = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowLabel = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
`;

const Description = styled.p`
  margin: 0;
  background-color: ${(props) => props.theme.colors.silver200};
  border: 1px solid ${(props) => props.theme.colors.silver400};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 6px 8px 6px;
`;

interface ToggleAccordionItemProps {
  label: string;
  description: string | JSX.Element;
  arrowLabel?: string;
  isToggled?: boolean;
  onToggle?: () => void;
}

const ToggleAccordionItem: React.FC<ToggleAccordionItemProps> = ({
  label,
  description,
  arrowLabel,
  isToggled,
  onToggle,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const ToggleExpantion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <AccordionWrapper>
      <Wrapper isExpanded={isExpanded}>
        <Left>
          <Label onClick={ToggleExpantion}>{label}</Label>
        </Left>
        <Right>
          {arrowLabel && (
            <VerticalCentered>
              <ArrowLabel>{arrowLabel}</ArrowLabel>
            </VerticalCentered>
          )}
          {onToggle && isToggled !== undefined && (
            <VerticalCentered>
              <ToggleButton checked={isToggled} onToggle={onToggle} />
            </VerticalCentered>
          )}
          <ChevronButton onClick={ToggleExpantion}>
            {isExpanded ? (
              <IconChevron color="black" />
            ) : (
              <IconChevronRight color="black" />
            )}
          </ChevronButton>
        </Right>
      </Wrapper>
      {isExpanded && <Description>{description}</Description>}
    </AccordionWrapper>
  );
};

export default ToggleAccordionItem;
