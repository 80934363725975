/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import SmuleLogo from '@app/components/SmuleLogo';
import Config from '@app/WebConfig';
import theme from '@app/theme/theme';
import { I18nPropsType } from '@app/i18n/types';

export const FOOTER_MIN_HEIGHT = 89;
export const FOOTER_TABLET_HEIGHT = 151;
export const FOOTER_MOBILE_HEIGHT = 176;

const FOOTER_DESKTOP_BREAKPOINT = 1024;
export const desktopMediaQuery = `@media only screen and (min-width: ${FOOTER_DESKTOP_BREAKPOINT}px)`;

const FooterBackground = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.silver200};
  color: ${({ theme }) => theme.colors.gray300};
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1136px;
  height: ${FOOTER_MOBILE_HEIGHT}px;
  margin: 0 auto;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;

  ${({ theme }) => theme.media.tablet} {
    height: ${FOOTER_TABLET_HEIGHT}px;
  }

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: space-between;
    height: ${FOOTER_MIN_HEIGHT}px;
  }
`;

const Disclaimer = styled.div`
  padding: 10px;
  flex-basis: auto;
  order: 1;

  ${desktopMediaQuery} {
    flex-basis: 450px;
    order: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

const FooterLinkStyles = css`
  padding: 4px 10px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray300};
  display: inline-block;

  &:visited {
    color: ${({ theme }) => theme.colors.gray300};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const FooterSPALink = styled(Link).attrs((props) => ({
  to: props.href || '#',
}))`
  ${FooterLinkStyles}
`;

const Links = styled.div`
  flex-basis: auto;
  order: 2;

  ${desktopMediaQuery} {
    flex-basis: 450px;
  }
`;

const MobileBreak = styled.span`
  ${({ theme }) => theme.media.mobileOnly} {
    display: block;
  }
`;

const ResponsiveDot = styled.span`
  ${({ theme }) => theme.media.mobileOnly} {
    display: none;
  }
`;

type Props = {
  i18nProps: I18nPropsType;
};

const Footer: React.FC<Props> = ({ i18nProps }) => {
  const { t } = i18nProps;
  return (
    <FooterBackground>
      <FooterContainer>
        {/* <Disclaimer>© {Config.footerConfig.copyRightYear} Smule, Inc. All Rights Reserved.</Disclaimer> */}
        <Disclaimer>
          {t('web.site-footer.smule-copyright', {
            defaultValue: '©{{copyrightYear}} Smule, Inc. All Rights Reserved.',
            copyrightYear: Config.footerConfig.copyRightYear,
          })}
        </Disclaimer>
        <LogoContainer>
          <SmuleLogo color={theme.colors.gray200} />
        </LogoContainer>
        <Links>
          <FooterSPALink href={Config.siteLinks.termsofservice}>
            {t('web.site-footer.terms-of-service', 'Terms')}
          </FooterSPALink>
          &middot;
          <FooterSPALink href={Config.siteLinks.privacy}>
            {t('web.site-footer.privacy-policy', 'Privacy')}
          </FooterSPALink>
          &middot;
          <FooterSPALink href={Config.siteLinks.cookiepolicy}>
            {t('web.site-footer.cookie-policy', 'Cookies')}
          </FooterSPALink>
          <ResponsiveDot>&middot;</ResponsiveDot>
          <MobileBreak />
          <FooterSPALink href={Config.siteLinks.copyright}>
            {t('web.site-footer.copyright-policy', 'Copyright')}
          </FooterSPALink>
          &middot;
          <FooterSPALink href={Config.siteLinks.acknowledgments}>
            {t('web.site-footer.acknowledgments-page', 'Acknowledgments')}
          </FooterSPALink>
          &middot;
          <FooterSPALink href={Config.siteLinks.patents}>
            {t('web.site-footer.patents-page', 'Patents')}
          </FooterSPALink>
        </Links>
      </FooterContainer>
    </FooterBackground>
  );
};

export default Footer;
