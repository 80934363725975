/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import {
  DenormalizedArrangement,
  DenormalizedArrangementOwner,
  NormalizedArrangement,
  NormalizedArrangementOwner,
} from '@app/types/arrangement';
import { schema } from 'normalizr';

type NormalizedArrangementWithDenormalizedOwner = Omit<
  NormalizedArrangement,
  'owner'
> & {
  owner: DenormalizedArrangementOwner;
};

const buildArrangementOwnerInfo = (
  info: DenormalizedArrangementOwner
): NormalizedArrangementOwner => ({
  accountId: info.account_id,
  handle: info.handle,
  isVerified: info.is_verified,
  isVip: info.is_vip,
  jid: info.jid,
  picUrl: info.pic_url,
  url: info.url,
  verifiedType: info.verified_type,
});

export const arrangementOwnerInfo =
  new schema.Entity<NormalizedArrangementOwner>(
    'arrangementOwnerInfo',
    {},
    {
      idAttribute: 'account_id',
      processStrategy: (value) => buildArrangementOwnerInfo(value),
    }
  );

const buildArrangementInfo = (
  info: DenormalizedArrangement
): NormalizedArrangementWithDenormalizedOwner => ({
  artist: info.artist,
  coverUrl: info.cover_url,
  createdAt: info.created_at,
  highlyRated: info.highly_rated,
  key: info.key,
  lyrics: info.lyrics,
  owner: info.owner,
  subtitle: info.subtitle,
  title: info.title,
  totalVotes: info.total_votes,
  webUrl: info.web_url,
  lyricsList: info.lyrics_list,
  appFamily: info.app_family,
  composition: info.composition,
  lastPublishedVer: info.last_published_ver,
  processState: info.process_state,
  videoResolution: info.video_resolution,
});

export const arrangementInfo = new schema.Entity<NormalizedArrangement>(
  'arrangementInfo',
  {
    owner: arrangementOwnerInfo,
  },
  {
    idAttribute: 'key',
    processStrategy: (value) =>
      buildArrangementInfo(value) as unknown as NormalizedArrangement,
  }
);

export type ArrangementSchemaType = {
  arrangementOwnerInfo: typeof arrangementOwnerInfo;
  arrangementInfo: typeof arrangementInfo;
};
