/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '@app/theme/colors';
import { smuleLog } from '@app/utils/utils';
import { BigSpinner } from '@app/components/Spinners';
import { loadRecaptcha } from '@app/components/Recaptcha';
import { Actions, FLOW_ID, Selectors } from './reducer';
import Modal from './Modal';
import { recordDau } from './helpers';
import { SpinnerContainer } from './styles';
import { updateSNPSettings } from './async-actions';

const LoginAccount = lazy(() => import('./flows/LoginAccount'));
const SecondaryFlows = lazy(() => import('./flows/SecondaryFlows'));

const SecondaryFlowsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

interface StateProps {
  currentFlowId: string;
  isRecaptchaLoaded: boolean;
  snpSettingsLoaded: boolean;
  locale?: string;
}
interface DispatchProps {
  recaptchaLoaded: () => void;
  updateSnpSettings: () => void;
}
type Props = StateProps & DispatchProps;

class LoginModal extends Component<Props> {
  componentDidMount() {
    recordDau();

    // Take care to fetch SNP settings only in case of ACTIVATION_SUCCESS flow.
    if (
      !this.props.snpSettingsLoaded &&
      this.props.currentFlowId === FLOW_ID.ACTIVATION_SUCCESS
    ) {
      this.props.updateSnpSettings();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.currentFlowId === null &&
      this.props.currentFlowId !== prevProps.currentFlowId
    ) {
      // Load Recaptcha SDK only when a flow is displayed.
      !this.props.isRecaptchaLoaded && this.loadRecaptcha(this.props);
      // Take care to fetch SNP settings only in case they are not loaded yet.
      !this.props.snpSettingsLoaded && this.props.updateSnpSettings();
    }
  }
  loadRecaptcha({ recaptchaLoaded }) {
    loadRecaptcha()
      .then(() => {
        recaptchaLoaded();
      })
      .catch(smuleLog);
  }
  render() {
    const spinnerFallback = (
      <SpinnerContainer>
        <BigSpinner color={colors.white} />
      </SpinnerContainer>
    );

    // Make sure the SNP settings are loaded until display the initial login flow.
    // Otherwise show a spinner.
    if (!this.props.snpSettingsLoaded) {
      return <Modal>{spinnerFallback}</Modal>;
    }

    return (
      <Modal>
        {this.props.currentFlowId && (
          <Suspense fallback={spinnerFallback}>
            <LoginAccount />
          </Suspense>
        )}
        <SecondaryFlowsContainer>
          <Suspense fallback={spinnerFallback}>
            <SecondaryFlows />
          </Suspense>
        </SecondaryFlowsContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  // locale: state.intl.locale,
  currentFlowId: Selectors.getCurrentFlowId(state),
  isRecaptchaLoaded: Selectors.isRecaptchaLoaded(state),
  snpSettingsLoaded: Selectors.getSettingsLoaded(state),
});

const mapDispatchToProps = (dispatch) => ({
  recaptchaLoaded: () => dispatch(Actions.recaptchaLoaded(true)),
  updateSnpSettings: () => dispatch(updateSNPSettings(['web.uiux'])),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
