/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useEffect } from 'react';
import LoginAsyncActions from '@app/layout/Login/async-actions';
import { useAppDispatch } from '@app/store/configureStore';
import { useInterval } from '@app/utils/hooks/useInterval';
import { usePageVisibility } from '@app/utils/hooks/usePageVisibility';
import { useSelector } from 'react-redux';
import { Selectors } from '@app/layout/Login/reducer';

const FEED_UPDATE_TIME_INTERVAL = 60 * 1000; // 1 minute

const FeedUpdater: React.FC = () => {
  const dispatch = useAppDispatch();
  const isPageVisible = usePageVisibility();
  const isPollEnabled = useSelector(Selectors.getIsPollEnabled);
  const feedUpdateFunction = () =>
    dispatch(LoginAsyncActions.updateFeedActivity());
  // Initial call after component is mounted
  useEffect(() => {
    dispatch(LoginAsyncActions.updateFeedActivity());
    dispatch(LoginAsyncActions.updateUserSubStatus());
  }, []);

  useInterval(
    feedUpdateFunction,
    FEED_UPDATE_TIME_INTERVAL,
    isPageVisible && isPollEnabled
  );

  return null;
};

export default FeedUpdater;
