/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

const DefaultFacebookLocale = 'en_US';
const BillingLocaleToFacebookLocale = {
  'en-US': 'en_US',
  'en-GB': 'en_GB',
  'id-ID': 'id_ID',
  'pt-PT': 'pt_PT',
  'es-419': 'es_LA',
  'es-ES': 'es_ES',
  'ru-RU': 'ru_RU',
  'ms-MY': 'ms_MY',
  'th-TH': 'th_TH',
  'ja-JP': 'ja_JP',
  'tr-TR': 'tr_TR',
  'de-DE': 'de_DE',
  'fr-FR': 'fr_FR',
  'ko-KR': 'ko_KR',
  'it-IT': 'it_IT',
  'zh-Hans-CN': 'zh_CN',
};

declare global {
  interface Window {
    FB: any;
    fbASyncInit: any;
    fbAsyncInit: any;
  }
}

export type FbAuthResponse = {
  accessToken: string;
  expiresIn: string;
  signedRequest: string;
  userID: string;
} | null;

interface FbAuthStatusResponse {
  authResponse: FbAuthResponse;
  status: 'connected' | 'not_authorized' | 'unknown';
}

let loaded = false;
let loading = false;
const fbScriptId = 'facebook-jssdk';
export default class FB {
  static get loaded() {
    return loaded;
  }
  static get settings() {
    return {
      SCOPE: 'email',
    };
  }
  static get regSettings() {
    return {
      SCOPE: 'email',
    };
  }
  static loadSdk(
    facebookSdkConfig,
    locale = 'en-US'
  ): Promise<FbAuthStatusResponse> {
    return new Promise((resolve, reject) => {
      // We want to reload the SDK in case they have changed languages
      if (loading || loaded) FB.unloadSdk();
      if (!facebookSdkConfig) {
        reject('Need fb config.');
        return;
      }

      const facebookLocale =
        BillingLocaleToFacebookLocale[locale] || DefaultFacebookLocale;
      window.fbAsyncInit = () => {
        window.FB.Event.subscribe('auth.statusChange', resolve);
        // init the FB JS SDK
        window.FB.init(facebookSdkConfig);
        loaded = true;
        loading = false;
      };

      /* eslint-disable */
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `//connect.facebook.net/${facebookLocale}/sdk.js`;
        fjs.parentNode && fjs.parentNode.insertBefore(js, fjs);
      })(window.document, 'script', fbScriptId);
      /* eslint-enable */
      loading = true;
    });
  }
  static unloadSdk() {
    const fbScriptNode = window.document.getElementById(fbScriptId);
    if (fbScriptNode && fbScriptNode.parentNode)
      fbScriptNode.parentNode.removeChild(fbScriptNode);
    window.FB = null;
    loading = false;
    loaded = false;
  }
  static login(opt): Promise<FbAuthStatusResponse> {
    return new Promise((resolve, reject) => {
      window.FB.login((loginResponse) => {
        if (loginResponse.authResponse) {
          resolve(loginResponse);
        } else {
          reject(loginResponse);
        }
      }, opt);
    });
  }

  static share(href) {
    return new Promise((resolve) => {
      window.FB.ui(
        {
          method: 'share',
          href,
          display: 'popup',
        },
        (response) => {
          resolve(response);
        }
      );
    });
  }
}
