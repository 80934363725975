/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable, { ImmutableObject } from 'seamless-immutable';
import { createSelector } from 'reselect';
import { State } from '@app/reducer';
import { getLanguageFromBrowserLocale } from './i18nHelpers';

export interface I18nState {
  locale: string;
  isEnabled: boolean;
}

const initialState: ImmutableObject<I18nState> = Immutable({
  locale: 'en-US',
  isEnabled: false,
});

export const STATE_KEY = 'i18n';

const SET_LOCALE = `${STATE_KEY}/SET_LOCALE`;

const setLocale = (locale: string) => ({
  type: SET_LOCALE,
  payload: { locale },
});

export const I18nActions = {
  setLocale,
};

const i18nReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE: {
      return state.set('locale', action.payload.locale);
    }
    default:
      return state;
  }
};

const getI18nState = (state: State): ImmutableObject<I18nState> =>
  state[STATE_KEY];
const getLocale = createSelector(getI18nState, (i18nState) => i18nState.locale);
const getLanguage = createSelector(getLocale, (locale) =>
  getLanguageFromBrowserLocale(locale)
);

export const I18nSelectors = {
  getLocale,
  getLanguage,
};

export default i18nReducer;
