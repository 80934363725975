/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

const media = {
  mobile: '374px', // iphone 6
  mobileL: '413px', // iphone 6+
  mobileXL: '528px', // samsung s8+
  tablet: '768px', // ipad
  tabletL: '1024px', // ipad pro
};

export const promoSmule = {
  colors: {
    textColor: '#4a4a4a',
    bgColor: 'white',
    bgInstructionsColor: '#F7F7F7',
    ctaBgColor: '#3C86F7',
    ctaTextColor: '#ffffff',
    ctaTextColorDark: '#464646',
    ctaBorderColor: '#DBE0E1',
    artistTextColor: '#2e2f30',
    tabsBgColor: '#999999',
  },
  media,
};

export const promoAutorap = {
  colors: {
    textColor: '#ffffff',
    bgColor: '#16171C',
    bgInstructionsColor: '#333333',
    ctaBgColor: '#DB1B32',
    ctaTextColor: '#ffffff',
    ctaTextColorDark: '#464646',
    ctaBorderColor: '#212228',
    artistTextColor: '#a2a2a4',
    tabsBgColor: '#333333',
  },
  media,
};
