/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { COLORS } from '@app/constants';

export interface Page {
  themeColor: string;
  title?: string;
  bgColor?: string;
  bgNavigationBar?: string;
  loadingSpinner?: boolean;
}

interface PageMeta {
  home: Page;
  explore: Page;
  listen: Page;
  partnerArtists: Page;
  getVipICICI: Page;
  nowPlaying: Page;
  groups: Page;
  apps: Page;
  press: Page;
  about: Page;
  jobs: Page;
  cookiePolicy: Page;
  acknowledgments: Page;
  smuleScience: Page;
  profilePage: Page;
  awesomePlaylistPage: Page;
  communityGuidelines: Page;
  copyright: Page;
  privacy: Page;
  privacyCalifornia: Page;
  termsOfService: Page;
  tipsTerms: Page;
  summaryOfChanges: Page;
  notifications: Page;
  stylesLanding: Page;
  arrangement: Page;
  songbook: Page;
  nda: Page;
  patents: Page;
}

export const pageMeta: PageMeta = {
  home: {
    bgColor: COLORS.purple400,
    title:
      'Smule: | Sing 14M Karaoke Songs with Lyrics on the #1 Social Karaoke Singing App',
    themeColor: '#832BFB',
  },
  explore: {
    bgNavigationBar: COLORS.purple600,
    themeColor: COLORS.silver200,
    loadingSpinner: true,
    title:
      'Explore all performances and songs with lyrics | Smule: Social Singing Karaoke App.',
  },
  listen: {
    bgNavigationBar: COLORS.purple600,
    themeColor: COLORS.silver200,
    loadingSpinner: true,
  },
  partnerArtists: {
    bgColor: COLORS.purple500,
    title:
      'Smule Top Artists Program | Join top Artists on a duet in the Smule Social Singing Karaoke app community',
    themeColor: COLORS.silver200,
  },
  getVipICICI: {
    bgColor: COLORS.purple400,
    title: 'Get Vip | Smule',
    themeColor: COLORS.basePurple,
  },
  nowPlaying: {
    bgNavigationBar: COLORS.purple600,
    themeColor: COLORS.white,
    loadingSpinner: true,
  },
  groups: {
    bgNavigationBar: COLORS.purple600,
    themeColor: COLORS.silver200,
    loadingSpinner: true,
  },
  apps: {
    bgNavigationBar: COLORS.purple600,
    bgColor: '#FFFFFF',
    title: 'Smule Music Apps | Download all Smule Social singing karaoke apps',
    themeColor: COLORS.silver200,
    loadingSpinner: true,
  },
  press: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title:
      'Smule in the Press | Press updates and news for Smule Social singing karaoke app',
    themeColor: COLORS.silver200,
    loadingSpinner: true,
  },
  about: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title:
      'About Smule | Learn more about Smule Social Singing Karaoke app experience',
    themeColor: '#F0F2F4',
    loadingSpinner: true,
  },
  jobs: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title:
      'Smule Careers and Jobs | Join the Smule Social Singing Karaoke app team',
    themeColor: '#F0F2F4',
    loadingSpinner: true,
  },
  cookiePolicy: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Smule Cookie Policy | Smule Social Singing Karaoke app community',
    themeColor: COLORS.silver200,
  },
  acknowledgments: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Aknowledgments',
    themeColor: COLORS.silver200,
  },
  smuleScience: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Science at Smule | The Social Singing App',
    themeColor: COLORS.silver200,
  },
  profilePage: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    themeColor: COLORS.silver200,
  },
  awesomePlaylistPage: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    themeColor: COLORS.silver200,
  },
  communityGuidelines: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title:
      'Smule Community Guidelines | Smule Social Singing Karaoke app community',
    themeColor: COLORS.silver200,
  },
  copyright: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title:
      'Copyright and Intellectual Property Infringement | Smule Social singing karaoke app',
    themeColor: COLORS.silver200,
  },
  privacy: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Privacy Policy | Smule Social singing karaoke app',
    themeColor: COLORS.silver200,
  },
  privacyCalifornia: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'PRIVACY POLICY CALIFORNIA',
    themeColor: COLORS.silver200,
  },
  termsOfService: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Terms of service | Smule Social singing karaoke app',
    themeColor: COLORS.silver200,
  },
  tipsTerms: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Tips Terms and Conditions | Smule Social singing karaoke app',
    themeColor: COLORS.silver200,
  },
  summaryOfChanges: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Summary of Changes | Smule Social singing karaoke app',
    themeColor: COLORS.silver200,
  },
  notifications: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    themeColor: COLORS.silver200,
  },
  stylesLanding: {
    bgColor: COLORS.purple500,
    themeColor: COLORS.silver200,
    title:
      'Unleash your creativity and create stunning performances | Smule Styles',
  },
  arrangement: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    themeColor: COLORS.silver200,
  },
  songbook: {
    bgNavigationBar: COLORS.purple600,
    title: 'Famous Karaoke Songs & Lyrics | Smule: Social Singing Karaoke App',
    themeColor: COLORS.silver200,
  },
  nda: {
    title: 'NDA | Smule',
    themeColor: COLORS.silver200,
  },
  patents: {
    bgNavigationBar: COLORS.purple600,
    bgColor: COLORS.purple500,
    title: 'Patents',
    themeColor: COLORS.silver200,
  },
};
