/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable max-len */
import qs from 'qs';

import { AppUids, EnsembleTypes, SharingTypes } from '@app/constants/utils';
import Device, { OSTypes } from './device';

const createRecordingAndroidIntent = (uri, scheme, packageName) =>
  `intent://${uri}#Intent;scheme=${scheme};package=${packageName};end`;

export const createRecordingDeepLink = (
  appUid,
  perfKey,
  ensembleType,
  isChallenge
) => {
  const type = Device.deviceType();
  let baseUri;
  let deepLink;

  switch (appUid) {
    case AppUids.AUTORAP_IOS:
    case AppUids.AUTORAP_ANDROID: {
      const queryParams = qs.stringify({ id: perfKey, type: 'PERFORMANCE' });
      baseUri = `challenge?${queryParams}`;
      if (type === 'android') {
        deepLink = createRecordingAndroidIntent(
          baseUri,
          'smulesing',
          'com.smule.singandroid'
        );
      } else if (type === OSTypes.IOS) {
        deepLink = `smuleautorap://${baseUri}`;
      }
      break;
    }
    case AppUids.SING_IOS:
    case AppUids.SING_ANDROID: {
      baseUri = `sing/seed/${perfKey}`;
      let iOSUri = baseUri;
      let androidUri = baseUri;
      if (isChallenge) {
        switch (ensembleType) {
          case EnsembleTypes.DUET: {
            iOSUri = `profile/seed/${perfKey}`;
            androidUri = `play/${perfKey}`;
            break;
          }
          case EnsembleTypes.GROUP: {
            iOSUri = `play/${perfKey}`;
            androidUri = iOSUri;
          }
          // no default
        }
      }
      if (type === OSTypes.ANDROID) {
        deepLink = androidUri;
      } else if (type === OSTypes.IOS) {
        deepLink = iOSUri;
      }
    }
    // no default
  }
  return deepLink || null;
};

const sharingChannels = {
  [SharingTypes.FACEBOOK]: 'Facebook',
  [SharingTypes.WHATSAPP]: 'Whatsapp',
  [SharingTypes.TWITTER]: 'Twitter',
  [SharingTypes.PINTEREST]: 'Pinterest',
  [SharingTypes.EMAIL]: 'Email',
  [SharingTypes.COPYLINK]: 'Copy-Link',
  [SharingTypes.MORE]: 'More',
};

export const pathToAbsUrl = (absolutePath) =>
  `${location.protocol}//${location.hostname}${
    location.port ? `:${location.port}` : ''
  }${absolutePath}`;

export const createRecordingShareUrl = (webUrl, shareType) =>
  webUrl &&
  `${webUrl.replace(/\/ensembles$/, '')}?channel=${sharingChannels[shareType]}`;
export const createFullRecordingShareUrl = (webUrl, shareType) =>
  pathToAbsUrl(
    `${webUrl.replace(/\/ensembles$/, '')}?channel=${
      sharingChannels[shareType]
    }`
  );
export const createFullRecordingEscaptedShareUrl = (webUrl, shareType) =>
  pathToAbsUrl(
    `${encodeURIComponent(createRecordingShareUrl(webUrl, shareType))}`
  );

export const urlParse = (url) => {
  const parser = document.createElement('a');
  parser.href = url;

  return parser;
};
