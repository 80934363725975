/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { denormalize } from 'normalizr';

import * as schema from '@app/schemas/performance';
import { PerformancesSelectors } from '@app/pages/NowPlaying/modules/performances/selectors';
import { PerformersSelectors } from '@app/pages/NowPlaying/modules/performers/selectors';
import { CommentsSelectors } from '@app/pages/NowPlaying/modules/comments/selectors';
import { LovesSelectors } from '@app/pages/NowPlaying/modules/loves/selectors';
import { State } from '@app/reducer';
import { DenormalizedPlaylist } from '@app/pages/NowPlaying/types';

const getPlaylists = (state: State) => state.playlists;
const getPlaylistId = (_: State, playlistId: string) => playlistId;
const getPlaylist = createCachedSelector(
  getPlaylists,
  getPlaylistId,
  (playlists, playlistId) => playlists[playlistId]
)((_, playlistId) => playlistId || '');
const hasLoadedPerformances = createSelector(
  getPlaylist,
  (playlist) => !!playlist
);
const getDenormalizedPlaylist = createSelector(
  [
    getPlaylist,
    PerformancesSelectors.getPerformances,
    PerformersSelectors.getPerformers,
    CommentsSelectors.getComments,
    LovesSelectors.getLoves,
  ],
  (playlist, performances, performers, comments, loves) => {
    const denormalizedPlaylist: DenormalizedPlaylist = denormalize(
      playlist,
      schema.playlist,
      {
        performances,
        performers,
        comments,
        loves,
      }
    );
    return denormalizedPlaylist;
  }
);
const getPerfList = createSelector(
  getPlaylist,
  (playlist) => playlist && playlist.perfList
);
const getFilledPerfList = createSelector(
  getDenormalizedPlaylist,
  (playlist) => (playlist && playlist.perfList) || []
);

const getShouldLoadPlaylist = createCachedSelector(
  getFilledPerfList,
  getPlaylistId,
  (_, _1, playlistCursor) => playlistCursor,
  (_, _1, _2, loadBuffer) => loadBuffer,
  (perfList, _, playlistCursor, loadBuffer) => {
    const perfsLeft = perfList
      .slice(playlistCursor)
      .reduce(
        (sum, perf) =>
          sum +
          (perf.mediaUrls.video ||
          perf.mediaUrls.visualizer ||
          perf.mediaUrls.audio
            ? 1
            : 0),
        0
      );
    return perfsLeft <= loadBuffer;
  }
)((_, playlistId) => playlistId || '');

export const getPlaylistCursor = createSelector(getPlaylist, (playlist) =>
  playlist.cursor === 'start'
    ? 'start'
    : playlist.cursor.hasNext
    ? playlist.cursor.next
    : null
);

export const PlaylistsSelectors = {
  getPlaylists,
  getPlaylist,
  getDenormalizedPlaylist,
  getPerfList,
  getFilledPerfList,
  getShouldLoadPlaylist,
  hasLoadedPerformances,
  getPlaylistCursor,
};
