/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { css, keyframes } from 'styled-components';
import theme from '@app/theme/theme';

const defaultSlideAnimation = {
  fromX: '-50%',
  fromY: '-10px',
  fromOpacity: 0,
  toX: '-50%',
  toY: '0',
  toOpacity: 1,
};

type FadeAnimationOptions = {
  fromOpacity: 0 | 1;
  toOpacity: 0 | 1;
};

export const createSlideAnimation = (
  options = defaultSlideAnimation
) => keyframes`
  from {
    transform: translate(${options.fromX}, ${options.fromY});
    opacity: ${options.fromOpacity};
  }
  to {
    transform: translate(${options.toX}, ${options.toY});
    opacity: ${options.toOpacity};
  }
`;

export const createFadeAnimation = (
  options: FadeAnimationOptions = { fromOpacity: 0, toOpacity: 1 }
) => keyframes`
  from {
    opacity: ${options.fromOpacity};
  }
  to {
    opacity: ${options.toOpacity};
  }
`;

type PulsateAnimationOptions = {
  fromColor: string;
  toColor: string;
};

const createPulsateAnimation = (options: PulsateAnimationOptions) => keyframes`
  0% { background-color: ${options.fromColor}; }
  50% { background-color: ${options.toColor}; }
  100% { background-color: ${options.fromColor}; }
`;

export const PulsateAnimationCSS = (
  options: PulsateAnimationOptions = {
    fromColor: theme.colors.silver400,
    toColor: theme.colors.silver300,
  }
) => css`
  animation-name: ${createPulsateAnimation(options)};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
