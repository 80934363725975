/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import { useDispatch } from 'react-redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import Config from '@app/WebConfig';
import api from '@app/api';
import Cookies from 'cookies-js';
import {
  Actions as LoginActions,
  FLOW_ID as LOGIN_FLOW_ID,
} from '@app/layout/Login/reducer';
import { Actions as WarningActions } from '@app/pages/Home/components/Warning/reducer';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { createRootReducer, ILoadedReducers } from '@app/reducer';
import * as schema from '@app/schemas';
import analyticsMiddleware from '@app/middleware/analyticsMiddleware';
import { FLASH_MESSAGE_COOKIE_NAME } from '@app/constants';
import { ExtraArg, ReducerTypes } from './types';

export const history = createBrowserHistory();

// Setup the internal config
window['DataStore'] && Config.initWith(window['DataStore']);

function configureStoreDev(preloadedState?: any) {
  const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewares: any[] = [
    // Redux middleware that spits an error on you when you try to mutate
    // your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk.withExtraArgument<ExtraArg>({ api, schema }),

    routerMiddleware(history),
    analyticsMiddleware,
  ];

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(...middlewares))
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducer', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}

function configureStoreProd(initialState?: any) {
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk.withExtraArgument<ExtraArg>({ api, schema }),
    routerMiddleware(history),
    analyticsMiddleware,
  ];

  return createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    compose(applyMiddleware(...middlewares))
  );
}

// Setup the Redux store
const store: any =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd()
    : configureStoreDev();

// Set warning code
if (Config.warningCode) {
  store.dispatch(WarningActions.setInitialWarningCode(Config.warningCode));
}

// Populate initial login actions
if (Config.initialLoginStatus) {
  store.dispatch(LoginActions.setLoginStatus(Config.initialLoginStatus));
}

// Reading flash message/cookie
const activationflashCookieValue = Cookies.get(FLASH_MESSAGE_COOKIE_NAME);
if (activationflashCookieValue !== undefined) {
  const decodedCookie = atob(activationflashCookieValue);
  const activationObject = JSON.parse(decodedCookie);
  if (activationObject.activationResult) {
    switch (activationObject.activationResult) {
      case 'success':
        store.dispatch(
          LoginActions.showFlow(LOGIN_FLOW_ID.ACTIVATION_SUCCESS, false, {
            activationFromWeb: activationObject.activationFromWeb,
            activationWithCreation: activationObject.activationWithCreation,
          })
        );
        break;
      case 'failure':
        store.dispatch(
          LoginActions.showFlow(LOGIN_FLOW_ID.LOGIN, false, {
            activationFailed: true,
          })
        );
        break;
    }
  }

  // delete flash cookie
  Cookies.expire(FLASH_MESSAGE_COOKIE_NAME);
}

if (Config.loginConfig && Config.loginConfig.activationResult) {
  switch (Config.loginConfig.activationResult) {
    case 'success':
      store.dispatch(
        LoginActions.showFlow(LOGIN_FLOW_ID.ACTIVATION_SUCCESS, false, {
          activationFromWeb: Config.loginConfig.activationFromWeb,
          activationWithCreation: Config.loginConfig.activationWithCreation,
        })
      );
      break;
    case 'failure':
      store.dispatch(
        LoginActions.showFlow(LOGIN_FLOW_ID.LOGIN, false, {
          activationFailed: true,
        })
      );
      break;
    default:
      break;
  }
}

// Encapsulate dependency reducers store logic
(function () {
  // Add a dictionary to keep track of the registered async reducers
  store.dependencyReducers = {} as { [key in ReducerTypes]: ILoadedReducers };

  // And another dictionary to keep track of which async reducer is setup
  store.setupReducers = {} as { [key in ReducerTypes]: boolean };

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key: ReducerTypes, reducer: ILoadedReducers) => {
    store.injectReducers([{ key, reducer }]);
  };

  store.injectReducers = (
    reducers: { key: ReducerTypes; reducer: ILoadedReducers }[]
  ) => {
    reducers.forEach((reducer) => {
      if (!store.checkIfReducerIsInjected(reducer.key)) {
        store.dependencyReducers[reducer.key] = reducer.reducer;
        store.setupReducers[reducer.key] = false;
      }
    });
    store.replaceReducer(createRootReducer(history, store.dependencyReducers));
  };

  store.setupReducer = (reducerKey: ReducerTypes) => {
    if (!store.checkIfReducerIsSetup(reducerKey)) {
      store.setupReducers[reducerKey] = true;
    }
  };

  store.checkIfReducerIsInjected = (reducerKey: ReducerTypes) => {
    return typeof store.dependencyReducers[reducerKey] !== 'undefined';
  };

  store.checkIfReducerIsSetup = (reducerKey: ReducerTypes) => {
    return (
      typeof store.setupReducers[reducerKey] !== 'undefined' &&
      store.setupReducers[reducerKey]
    );
  };
})();

export function getStore() {
  return store;
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
