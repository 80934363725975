/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';
import Link from '@app/components/GDPR/components/Link';

const VendorItemWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.silver400};
  border-radius: ${(props) => props.theme.corners.medium};
  padding: 10px;

  align-items: center;
`;

const VendorLink = styled(Link)`
  margin-left: ${(props) => props.theme.spacing}px;
`;

const Label = styled.p`
  font-size: 15px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.gray400};
  margin: 0;
  flex-grow: 1;
`;

interface VendorItemProps {
  label: string;
  firstLink: string;
  firstLinkLabel: string;
  secondLink?: string;
  secondLinkLabel?: string;
}

const VendorItem: React.FC<VendorItemProps> = ({
  label,
  firstLink,
  firstLinkLabel,
  secondLink,
  secondLinkLabel,
}) => {
  return (
    <VendorItemWrapper>
      <Label>{label}</Label>
      <VendorLink href={firstLink}>{firstLinkLabel}</VendorLink>
      {secondLink && secondLinkLabel && (
        <VendorLink href={secondLink}>{secondLinkLabel}</VendorLink>
      )}
    </VendorItemWrapper>
  );
};

export default VendorItem;
