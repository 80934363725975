/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable max-len */
import React from 'react';

const CloseIcon = () => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="m16.2929.292893c.3905-.390524 1.0237-.390524 1.4142 0 .3905.390525.3905 1.023687 0 1.414217l-7.2929 7.29289 7.2929 7.2929c.3905.3905.3905 1.0237 0 1.4142s-1.0237.3905-1.4142 0l-7.2929-7.2929-7.29289 7.2929c-.39053.3905-1.023693.3905-1.414217 0-.3905249-.3905-.3905249-1.0237 0-1.4142l7.292897-7.2929-7.292897-7.29289c-.3905241-.39053-.3905241-1.023693 0-1.414217.390524-.3905241 1.023687-.3905241 1.414217 0l7.29289 7.292897z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default CloseIcon;
