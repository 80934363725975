/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import styled from 'styled-components';

import { Typography } from '@app/theme/typography';
import { BodyTextSize, DisplayTextSize, TextWeight } from './types';

const getFontSize = (
  type,
  size: BodyTextSize | DisplayTextSize,
  typography: Typography
) => {
  if (type === 'body') {
    switch (size) {
      case BodyTextSize.mini:
        return typography.body.fontSize.mini;
      case BodyTextSize.small:
        return typography.body.fontSize.small;
      case BodyTextSize.regular:
        return typography.body.fontSize.regular;
      case BodyTextSize.medium:
        return typography.body.fontSize.medium;
    }
  } else if (type === 'display') {
    switch (size) {
      case DisplayTextSize.small:
        return typography.display.fontSize.small;
      case DisplayTextSize.regular:
        return typography.display.fontSize.regular;
      case DisplayTextSize.medium:
        return typography.display.fontSize.medium;
      case DisplayTextSize.big:
        return typography.display.fontSize.big;
    }
  }
};

const getFontWeight = (weight: TextWeight, typography: Typography) => {
  switch (weight) {
    case TextWeight.regular:
      return typography.body.fontWeight.regular;
    case TextWeight.medium:
      return typography.body.fontWeight.medium;
    case TextWeight.bold:
      return typography.body.fontWeight.bold;
  }
};

const getLineHeight = (
  type,
  size: BodyTextSize | DisplayTextSize,
  typography: Typography
) => {
  if (type === 'body') {
    switch (size) {
      case BodyTextSize.mini:
        return typography.body.lineHeight.mini;
      case BodyTextSize.small:
        return typography.body.lineHeight.small;
      case BodyTextSize.regular:
        return typography.body.lineHeight.regular;
      case BodyTextSize.medium:
        return typography.body.lineHeight.medium;
    }
  } else if (type === 'display') {
    switch (size) {
      case DisplayTextSize.small:
        return typography.display.lineHeight.small;
      case DisplayTextSize.regular:
        return typography.display.lineHeight.regular;
      case DisplayTextSize.medium:
        return typography.display.lineHeight.medium;
      case DisplayTextSize.big:
        return typography.display.lineHeight.big;
    }
  }
};

interface WrapperProps {
  type: 'body' | 'display';
  size: BodyTextSize | DisplayTextSize;
  weight: TextWeight;
}

export const Wrapper = styled.span<WrapperProps>`
  font-size: ${(props) =>
    getFontSize(props.type, props.size, props.theme.typography)};
  font-weight: ${(props) =>
    getFontWeight(props.weight, props.theme.typography)};
  line-height: ${(props) =>
    getLineHeight(props.type, props.size, props.theme.typography)};
`;
