/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { LovesActionTypes } from '@app/pages/NowPlaying/modules/loves/actions';
import { emitGAEvent } from '@app/utils/utils';
import { LoginLaunchOrigins } from '@app/layout/Login/analytics/constants';

const LovesAnalytics = (store, action) => {
  switch (action.type) {
    case LovesActionTypes.TRY_SUBMIT_LOVE: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: LoginLaunchOrigins.fromLove,
        eventLabel: action.meta.eventLabel,
      });

      break;
    }
    case LovesActionTypes.SUBMIT_LOVE_SUCCESS: {
      emitGAEvent({
        eventName: 'Social',
        eventCategory: 'love',
        eventLabel: action.meta.eventLabel,
      });

      break;
    }

    // no default
  }
};

export default LovesAnalytics;
