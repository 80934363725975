/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export type Shadow = 'light' | 'lightInner' | 'medium' | 'heavy' | 'heavyMulti';

export type Shadows = { [key in Shadow]: string };

const shadows: Shadows = {
  light: '0px 2px 8px rgba(31, 32, 33, 0.1)',
  lightInner: 'inset 0px 2px 1px rgba(31, 32, 33, 0.05)',
  medium: '0px 2px 8px rgba(31, 32, 33, 0.3)',
  heavy: '0px 4px 12px rgba(31, 32, 33, 0.3)',
  heavyMulti:
    '0px 1px 2px rgba(31, 32, 33, 0.1), \
    0px 2px 2px rgba(31, 32, 33, 0.05), 0px 4px 12px rgba(31, 32, 33, 0.3)',
};

export default shadows;
