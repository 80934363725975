/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import signedAjax from '@app/utils/signed_request.js';

import { CLIENT_ERROR_CODES, SNP_FAMILY_ROLES } from '@app/pages/Groups/types';

const errorHandler = (error) => Promise.reject(error.response.data);

const parseResponse = (response) => {
  if (response.data.error) return Promise.reject(response.data);
  if (response.request.responseURL.includes('/user/login')) {
    return Promise.reject({
      error: 'session expired',
      code: CLIENT_ERROR_CODES.SESSION_EXPIRED,
    });
  }
  return { ...response.data };
};

/** generic API calls**/

const axiosWithErrorHandler = (axiosProps) =>
  signedAjax({
    ...axiosProps,
  })
    .catch(errorHandler)
    .then(parseResponse);

const fetchGroups = (cursor: string | null | undefined = 'start') =>
  signedAjax({
    url: '/api/groups',
    method: 'get',
    params: { cursor },
  });

const searchGroups = (term, sort, cursor) =>
  signedAjax({
    url: '/api/groups/search',
    method: 'post',
    data: {
      term,
      sort,
      cursor,
    },
  });

const checkOwnership = () =>
  axiosWithErrorHandler({
    url: '/api/groups/user',
    method: 'get',
  });

const fetchGroupInfo = (id) =>
  signedAjax({
    url: `/api/groups/${id}`,
    method: 'get',
  });

const fetchInitialGroupMembers = (id) =>
  signedAjax({
    url: `/api/groups/${id}/members`,
    method: 'get',
    params: {
      cursor: 'start',
    },
  });

const fetchGroupMembers = (id, cursor) =>
  signedAjax({
    url: `/api/groups/${id}/members`,
    method: 'get',
    params: {
      cursor,
    },
  });

const fetchGroupFeed = (sfamId, cursor: string | null | undefined = 'start') =>
  signedAjax({
    url: `/api/groups/${sfamId}/feed`,
    method: 'get',
    params: { cursor },
  });

const removeGroupPost = (sfamId, postId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/feed/remove-post`,
    method: 'post',
    data: { postId: parseInt(postId, 10) },
  });

const fetchFollowers = (offset = 0, limit = 20) =>
  signedAjax({
    url: '/api/social/followers',
    method: 'get',
    params: { offset, limit },
  });

const searchUsers = (handle, offset) =>
  axiosWithErrorHandler({
    url: '/api/social/search',
    method: 'get',
    params: { handle, offset },
  });

const sendMemberInvite = (sfamId, accountIds) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/invites/send`,
    method: 'post',
    params: { accountIds },
  });

const sendJoinRequest = (sfamId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/requests/send`,
    method: 'post',
  });

const cancelJoinRequest = (sfamId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/requests/cancel`,
    method: 'post',
  });

const acceptGroupInvite = (sfamId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/invites/accept`,
    method: 'post',
  });

const rejectGroupInvite = (sfamId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/invites/reject`,
    method: 'post',
  });

const removeMember = (sfamId, accountId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/members/remove`,
    method: 'post',
    data: { accountId },
  });

const banMember = (sfamId, accountId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/members/ban`,
    method: 'post',
    data: { accountId },
  });

const changeRole = (sfamId, accountId, role: SNP_FAMILY_ROLES) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/members/change-role`,
    method: 'post',
    data: {
      accountId,
      role,
    },
  });

const leaveGroup = (sfamId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/leave`,
    method: 'post',
  });

const fetchPendingRequests = (sfamId, cursor) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/requests`,
    method: 'get',
    params: { cursor },
  });

const fetchPendingInvites = (sfamId, cursor) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/invites`,
    method: 'get',
    params: { cursor },
  });

const approveMemberRequest = (sfamId, accountId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/requests/approve`,
    method: 'post',
    params: { accountId },
  });

const rejectMemberRequest = (sfamId, accountId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/requests/reject`,
    method: 'post',
    params: { accountId },
  });

const cancelMemberInvite = (sfamId, accountId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}/invites/cancel`,
    method: 'post',
    params: { accountId },
  });

const fetchUserGroups = (handle, cursor, limit = 20) =>
  signedAjax({
    url: `/api/groups/user/${handle}`,
    method: 'get',
    params: {
      cursor,
      limit,
    },
  });

const getCountiresAndLanguages = () =>
  axiosWithErrorHandler({
    url: '/api/groups/form-fields',
    method: 'get',
  });

const coverPhotoUpload = (data) =>
  axiosWithErrorHandler({
    url: '/api/groups/cover-photo-upload',
    method: 'post',
    data,
  });

const createGroup = (params) =>
  axiosWithErrorHandler({
    url: '/api/groups',
    method: 'post',
    params,
  });

const updateGroup = (sfamId, params) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}`,
    method: 'patch',
    params,
  });

const deleteGroup = (sfamId) =>
  axiosWithErrorHandler({
    url: `/api/groups/${sfamId}`,
    method: 'delete',
  });

export type GroupsApiType = {
  fetchGroups: typeof fetchGroups;
  searchGroups: typeof searchGroups;
  checkOwnership: typeof checkOwnership;
  fetchGroupInfo: typeof fetchGroupInfo;
  fetchInitialGroupMembers: typeof fetchInitialGroupMembers;
  fetchGroupMembers: typeof fetchGroupMembers;
  fetchGroupFeed: typeof fetchGroupFeed;
  removeGroupPost: typeof removeGroupPost;
  fetchFollowers: typeof fetchFollowers;
  searchUsers: typeof searchUsers;
  sendMemberInvite: typeof sendMemberInvite;
  sendJoinRequest: typeof sendJoinRequest;
  cancelJoinRequest: typeof cancelJoinRequest;
  acceptGroupInvite: typeof acceptGroupInvite;
  rejectGroupInvite: typeof rejectGroupInvite;
  removeMember: typeof removeMember;
  banMember: typeof banMember;
  changeRole: typeof changeRole;
  leaveGroup: typeof leaveGroup;
  fetchPendingRequests: typeof fetchPendingRequests;
  fetchPendingInvites: typeof fetchPendingInvites;
  approveMemberRequest: typeof approveMemberRequest;
  rejectMemberRequest: typeof rejectMemberRequest;
  cancelMemberInvite: typeof cancelMemberInvite;
  fetchUserGroups: typeof fetchUserGroups;
  getCountiresAndLanguages: typeof getCountiresAndLanguages;
  coverPhotoUpload: typeof coverPhotoUpload;
  createGroup: typeof createGroup;
  updateGroup: typeof updateGroup;
  deleteGroup: typeof deleteGroup;
};

export const GroupsApi = {
  fetchGroups,
  searchGroups,
  checkOwnership,
  fetchGroupInfo,
  fetchInitialGroupMembers,
  fetchGroupMembers,
  fetchGroupFeed,
  removeGroupPost,
  fetchFollowers,
  searchUsers,
  sendMemberInvite,
  sendJoinRequest,
  cancelJoinRequest,
  acceptGroupInvite,
  rejectGroupInvite,
  removeMember,
  banMember,
  changeRole,
  leaveGroup,
  fetchPendingRequests,
  fetchPendingInvites,
  approveMemberRequest,
  rejectMemberRequest,
  cancelMemberInvite,
  fetchUserGroups,
  getCountiresAndLanguages,
  coverPhotoUpload,
  createGroup,
  updateGroup,
  deleteGroup,
};
