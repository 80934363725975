/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { COLORS, SHADOWS, NAVIGATION_BAR_SIZES } from '@app/constants';
import { convertHexToRgba } from '@app/utils/utils';
import SearchIcon from '@app/components/icons/SearchIcon';
import Config from '@app/WebConfig';
import * as screenType from '@app/styles/style_breakpoints';

const preventDefaultOp = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const NativeButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
`;

const SearchButton = styled(NativeButton)`
  padding: 22px 8px 18px;

  &:hover {
    opacity: 0.7;
  }
`;

const SearchOverlay = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${convertHexToRgba(COLORS.purple500, 0.5)};
  z-index: 100;
`;

const SEARCH_INPUT_WIDTH = 560;

const SearchInputWrapper = styled.div<{ hasBanner: boolean }>`
  position absolute;
  top: ${(props) =>
    props.hasBanner ? NAVIGATION_BAR_SIZES.JOURNEY_BANNER_HEIGHT_PX : 0}px;
  left: calc(50% - ${SEARCH_INPUT_WIDTH}px/2);
  max-width: ${SEARCH_INPUT_WIDTH}px;
  width: 100%;
  height: 48px;
  margin-top: 12px;
  box-shadow: ${SHADOWS.mediumShadow};
  z-index: 101;

  ${screenType.mobileHeaderBreakPoint} {
    position: fixed;
    right: 0;
    left: 0;
    max-width: none;
    height: 64px;
    margin-top: 0;
  }
`;

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const SearchInputMagnifierIcon = styled.span`
  position: absolute;
  left: 12px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 88px 0 48px;
  border: none;
  border-radius: 6px;
  outline: none;
  color: ${COLORS.purple500};
  font-size: 17px;
  font-weight: bold;
  -webkit-appearance: none;

  // Remove all default browser input search styles.
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${COLORS.gray100};
    font-weight: normal;
  }

  ${screenType.mobileHeaderBreakPoint} {
    border-radius: 0;
    font-size: 15px;
  }
`;

const SubmitSearchButton = styled(NativeButton)`
  position: absolute;
  right: 4px;
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: ${COLORS.basePurple};
  font-size: 17px;
  font-weight: bold;
  color: ${COLORS.white};
  box-shadow: ${SHADOWS.lightShadow};

  ${screenType.mobileHeaderBreakPoint} {
    top: -9999px;
    left: -9999px;
    visibility: hidden;
    z-index: -1;
  }
`;

const CancelSearchButton = styled(NativeButton)`
  position: absolute;
  right: 16px;
  height: 40px;
  color: ${COLORS.basePurple};
  font-size: 15px;
  font-weight: bold;

  ${screenType.mobileHeaderBreakPoint} {
    display: none;
  }
`;

const MobileCancelSearchButton = styled(CancelSearchButton)`
  display: none;

  ${screenType.mobileHeaderBreakPoint} {
    display: initial;
  }
`;

const SearchBar: React.FC<{ hasBanner: boolean }> = ({ hasBanner = false }) => {
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const inputElement = useRef<HTMLInputElement>(null);

  const showSearchModal = () => {
    setIsSearchOpened(true);
  };
  const hideSearchModal = () => {
    setSearchTerm('');
    setIsSearchOpened(false);
  };
  const onChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (!isSearchOpened) {
      return;
    }
    const key = event.keyCode || event.which;

    switch (key) {
      case 27: // "Esc" key
        preventDefaultOp(event);
        hideSearchModal();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    if (isSearchOpened && inputElement && inputElement.current) {
      inputElement.current.focus();
      // Do some magic to autofocus the input field in iOS Safari.
      if ('setSelectionRange' in HTMLInputElement.prototype) {
        inputElement.current.setSelectionRange(0, 0);
      }
    }
  }, [isSearchOpened]);

  return (
    <>
      <SearchButton onClick={showSearchModal} aria-label="Search">
        <SearchIcon />
      </SearchButton>
      {isSearchOpened && (
        <>
          <SearchOverlay onClick={hideSearchModal} />
          <SearchInputWrapper hasBanner={hasBanner}>
            <SearchForm
              action={Config.siteLinks.search}
              method="get"
              accept-charset="utf-8"
            >
              <SearchInputMagnifierIcon>
                <SearchIcon color={COLORS.gray200} />
              </SearchInputMagnifierIcon>
              <SearchInput
                ref={inputElement}
                type="search"
                placeholder="Search artists, songs, users..."
                autoFocus
                autoComplete="off"
                maxLength={128}
                name="q"
                data-test-id="autocomplete-search"
                value={searchTerm}
                onChange={onChange}
              />
              {searchTerm.length ? (
                <SubmitSearchButton type="submit" aria-label="Search">
                  Search
                </SubmitSearchButton>
              ) : (
                <CancelSearchButton
                  type="button"
                  onClick={hideSearchModal}
                  aria-label="Cancel"
                >
                  Cancel
                </CancelSearchButton>
              )}
              <MobileCancelSearchButton
                type="button"
                onClick={hideSearchModal}
                aria-label="Cancel"
              >
                Cancel
              </MobileCancelSearchButton>
            </SearchForm>
          </SearchInputWrapper>
        </>
      )}
    </>
  );
};

export default SearchBar;
