/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createCachedSelector } from 're-reselect';
import { State } from '@app/reducer';

const getPerformers = (state: State) => state.performers;
const getPerformerId = (_: State, accountId: string) => accountId;
const getPerformer = createCachedSelector(
  getPerformers,
  getPerformerId,
  (performers, accountId) => performers[accountId]
)(getPerformerId);

export const PerformersSelectors = {
  getPerformers,
  getPerformer,
};
