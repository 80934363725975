/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useEffect } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import { State } from '@app/reducer';
import { COLORS, COOKIES_BANNER_VERSIONS } from '@app/constants';
import CookiesBanner from '@app/components/CookiesBanner';
import GDPR from '@app/components/GDPR';
import { Selectors as LoginSelectors } from '@app/layout/Login/reducer';
import { mobile } from '@app/styles/style_breakpoints';
import { getCookieBannerVersion } from '@app/components/GDPR/async-actions';

const Banner = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${COLORS.white};
  background-color: ${COLORS.black};
  box-sizing: border-box;
  bottom: 0;
  z-index: 1000;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.1);

  ${mobile}: {
    top: 0;
    bottom: auto;
  }
`;

interface TermsBannerStateProps {
  cookieBannerVersion: number | null;
}
interface TermsBannerDispatchProps {
  getCookieBannerVersion: () => void;
}

const TermsBanner: React.FC<
  TermsBannerStateProps & TermsBannerDispatchProps
> = ({ cookieBannerVersion, getCookieBannerVersion }) => {
  const hasGPC = !!window.navigator['globalPrivacyControl'];
  useEffect(() => {
    // If window.navigator['globalPrivacyControl'] === true,
    //   we should disable the functionality and marketing cookies
    //   and there is no need to get cookie banner version
    if (!hasGPC && cookieBannerVersion === null) {
      getCookieBannerVersion();
    }
  }, []);

  return (
    <Banner>
      {cookieBannerVersion === COOKIES_BANNER_VERSIONS.default && (
        <CookiesBanner />
      )}
      <GDPR cookieBannerVersionSetting={cookieBannerVersion} hasGPC={hasGPC} />
    </Banner>
  );
};

const mapStateToProps: MapStateToProps<TermsBannerStateProps, {}, State> = (
  state
) => ({
  cookieBannerVersion: LoginSelectors.getCookieBannerVersion(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCookieBannerVersion: () => dispatch(getCookieBannerVersion()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsBanner);
