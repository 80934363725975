/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { API } from '@app/api';
import { State } from '@app/reducer';
import { SchemaType } from '@app/schemas';

export type ExtraArg = {
  api: API;
  schema: SchemaType;
};

export type AppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  State,
  ExtraArg,
  AnyAction
>;

export enum ReducerTypes {
  Router = 'router',
  Homepage = 'homepage',
  Login = 'login',
  PartnerArtists = 'partnerArtists',
  WindowReducer = 'windowReducer',
  GDPR = 'gdpr',
  NowPlaying = 'nowPlaying',
  NowPlayingErrorReducder = 'nowPlayingErrorReducer',
  Comments = 'comments',
  Loves = 'loves',
  Gifting = 'gifting',
  Performers = 'performers',
  Playlists = 'playlists',
  Performances = 'performances',
  Player = 'player',
  Groups = 'groups',
  Explore = 'explorePage',
  ProfilePage = 'profile',
  AwesomePlaylists = 'awesomePlaylists',
  I18n = 'i18n',
  Arrangement = 'arrangement',
  Songbook = 'songbook',
}

export type DependencyReducer = {
  key: ReducerTypes;
  reducer: any;
};

export type SetupReducerFunction = (store: unknown) => Promise<void> | void;
