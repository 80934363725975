/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useEffect } from 'react';
import { connect } from 'react-redux';

import Config from '@app/WebConfig';
import { FbAuthResponse } from '@app/utils/fb_sdk';
import { smuleLog, areCookiesEnabled } from '@app/utils/utils';
import { facebookAutoSiteLogin } from './async-actions';
import { Selectors, Actions } from './reducer';
import { loadFbSdk, sendTrackingEvent, userExplicitlyLogout } from './helpers';

interface StateProps {
  isFbSdkLoaded: boolean;
  isLoggedIn: boolean;
}
interface DispatchProps {
  fbSdkLoaded: () => void;
  facebookAutoSiteLogin: (authResponse: FbAuthResponse) => void;
  setFbAuthResponse: (authResponse: FbAuthResponse) => void;
}
type Props = StateProps & DispatchProps;

const FbAutoLogin: React.FC<Props> = ({
  isFbSdkLoaded,
  isLoggedIn,
  fbSdkLoaded,
  facebookAutoSiteLogin,
  setFbAuthResponse,
}) => {
  useEffect(() => {
    (async () => {
      if (isFbSdkLoaded || isLoggedIn) {
        return;
      }

      if (!areCookiesEnabled() || Config.loginConfig.embeddedPlayer) {
        smuleLog('Autologin: Not supported, skipping auto login');
        sendTrackingEvent('autologin_disabled', 'fb_reg', true);
        return;
      }

      if (userExplicitlyLogout()) {
        smuleLog('Autologin: User explicitly logged out, skipping auto login');
        sendTrackingEvent('autologin_skipped', 'fb_reg', true);
        return;
      }

      try {
        const { status, authResponse } = await loadFbSdk();
        fbSdkLoaded();
        setFbAuthResponse(authResponse);

        if (status === 'connected') {
          facebookAutoSiteLogin(authResponse);
        }
      } catch (e) {
        return;
      }
    })();
  }, []);
  // componentWillReceiveProps(nextProps) {
  //   if (props.locale !== nextProps.locale) {
  //     FB.loadSdk(Config.facebookSdkConfig, nextProps.locale)
  //       .then(() => props.fbSdkLoaded());
  //   }
  // }
  return null;
};

const mapStateToProps = (state) => ({
  isFbSdkLoaded: Selectors.isFbSdkLoaded(state),
  isLoggedIn: Selectors.isLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  fbSdkLoaded: () => dispatch(Actions.fbSdkLoaded(true)),
  facebookAutoSiteLogin: (authResponse) =>
    dispatch(facebookAutoSiteLogin(authResponse)),
  setFbAuthResponse: (authResponse) =>
    dispatch(Actions.setFbAuthResponse(authResponse)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FbAutoLogin);
