/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useEffect, useState, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Config from '@app/WebConfig';
import SearchBar from '@app/layout/NavigationBar/SearchBar';
import { COLORS, NAVIGATION_BAR_SIZES } from '@app/constants';
import { convertHexToRgba } from '@app/utils/utils';
import SmuleLogoWithSlogan from '@app/components/SmuleLogoWithSlogan';
import HamburgerMenu from '@app/layout/NavigationBar/HamburgerMenu';
import useRequireLogin from '@app/layout/Login/useRequireLogin';
import { LoginLaunchOrigins } from '@app/layout/Login/analytics/constants';
import { Selectors as LoginSelectors } from '@app/layout/Login/reducer';
import { I18nPropsType } from '@app/i18n/types';
import { Selectors as JourneyBannerSelectors } from '@app/widgets/JourneyBanner/selectors';
import UploadDropdown from './UploadDropdown';
const UserProfileDropdown = lazy(
  () => import('@app/layout/NavigationBar/UserProfileDropdown')
);

import * as screenType from '@app/styles/style_breakpoints';
import FeedUpdater from './FeedUpdater';

const MAX_OPACITY = 1;
const MIN_OPACITY = 0.1;
const TRANSPARENCY_OFFSET_PX = 50;

const Container = styled.div<{
  hasBanner: boolean;
}>`
  position: fixed;
  top: ${(props) =>
    props.hasBanner ? NAVIGATION_BAR_SIZES.JOURNEY_BANNER_HEIGHT_PX : 0}px;
  right: 0;
  left: 0;
  width: 100%;
  height: ${NAVIGATION_BAR_SIZES.DESKTOP_HEIGHT_PX}px;
  background-color: ${convertHexToRgba(COLORS.purple500, MIN_OPACITY)};
  color: ${COLORS.white};
  z-index: 21;

  ${screenType.mobileHeaderBreakPoint} {
    height: ${NAVIGATION_BAR_SIZES.MOBILE_HEIGHT_PX}px;
  }
`;

const Wrapper = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  ${screenType.mobileHeaderBreakPoint} {
    justify-content: flex-end;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 24px;
  justify-content: space-between;
  flex-grow: 1;
  ${screenType.mobileHeaderBreakPoint} {
    margin-right: 16px;
  }
`;

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const SmuleLogoWrapper = styled(Link).attrs((props) => ({
  to: props.href || '#',
  'aria-label': 'Home page',
  rel: 'noopener noreferrer',
}))`
  text-decoration: none;
  margin-left: 80px; // 56 + 24
  display: flex;

  ${screenType.mobileHeaderBreakPoint} {
    margin-left: 64px; // 48 + 16
    & > * {
      width: 93px;
      height: 56px;
    }
  }
`;

const Separator = styled.span`
  width: 2px;
  height: 100%;
  margin-left: 24px;
  background: ${COLORS.white};
  opacity: 0.1;

  ${screenType.mobileHeaderBreakPoint} {
    display: none;
  }
`;

const DesktopLinkWrapper = styled.span`
  display: initial;
  margin-left: 24px;

  ${screenType.mobileHeaderBreakPoint} {
    display: none;
  }
`;

const SearchLinkWrapper = styled(DesktopLinkWrapper as any)`
  margin-left: 16px;

  ${screenType.mobileHeaderBreakPoint} {
    display: initial;
    margin-left: auto;
  }
`;

const GetVipLinkWrapper = styled(DesktopLinkWrapper as any)<{
  isLoggedIn: boolean;
}>``;

const NavLinkStyles = css`
  padding: 20px 0;
  color: ${COLORS.white};
  font-weight: bold;
  text-decoration: none;
  font-size: 17px;
  &:hover,
  &:active {
    color: ${COLORS.transparentWhite70};
  }
`;
const NavLink = styled.a`
  ${NavLinkStyles};
`;

const DesktopSPALinkWrapper = styled(Link).attrs((props) => ({
  to: props.href || '#',
}))`
  ${NavLinkStyles};
  margin-left: 24px;

  ${screenType.mobileHeaderBreakPoint} {
    display: none;
  }
`;

const LoginLink = styled(NavLink as any)`
  margin-left: 24px;
  padding: 6px 14px;
  border: 2px solid ${COLORS.white};
  border-radius: 6px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${COLORS.white};
    color: ${COLORS.basePurple};
  }

  ${screenType.mobileHeaderBreakPoint} {
    margin-left: 8px;
  }
`;

interface StateProps {
  isLoggedIn: boolean;
  hasBanner?: boolean;
}
interface OwnProps {
  background?: string;
  i18nProps: I18nPropsType;
}

type Props = StateProps & OwnProps;

const NavigationBar: React.FC<Props> = ({
  isLoggedIn,
  background,
  hasBanner = false,
  i18nProps,
}) => {
  const { t } = i18nProps;
  const [isNavOverScrolled, setIsNavOverScrolled] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(
    convertHexToRgba(COLORS.purple500, MIN_OPACITY)
  );
  const [showLoginFlow] = useRequireLogin(LoginLaunchOrigins.fromNavBar);

  const updateBackgroundColor = (): void => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    setIsNavOverScrolled(scrollTop < 0);
    // opacity should be a number between 0.1 and 1
    const opacity = Math.min(
      MAX_OPACITY,
      Math.max(MIN_OPACITY, scrollTop / TRANSPARENCY_OFFSET_PX)
    );
    setBackgroundColor(convertHexToRgba(COLORS.purple500, opacity));
  };

  useEffect(() => {
    // If static background is being provided - just set and skip the smooth opacity transition.
    if (background) {
      setBackgroundColor(background);
      return;
    }
    updateBackgroundColor();
    window.addEventListener('scroll', updateBackgroundColor);

    return () => window.removeEventListener('scroll', updateBackgroundColor);
  }, []);

  return (
    <Container
      style={{
        backgroundColor,
        position: isNavOverScrolled ? 'absolute' : 'fixed',
      }}
      hasBanner={hasBanner}
    >
      <Wrapper>
        <LeftWrapper>
          <HamburgerMenu i18nProps={i18nProps} />
          <SmuleLogoWrapper href={Config.siteLinks.root}>
            <SmuleLogoWithSlogan />
          </SmuleLogoWrapper>
          <Separator />
          <DesktopSPALinkWrapper href={Config.siteLinks.explore}>
            {t('web.site-layout.explore-page-title', 'Explore')}
          </DesktopSPALinkWrapper>
          <DesktopLinkWrapper>
            <NavLink href={Config.siteLinks.songbook}>
              {t('web.site-layout.songs-link', 'Songbook')}
            </NavLink>
          </DesktopLinkWrapper>
          <DesktopLinkWrapper>
            <UploadDropdown />
          </DesktopLinkWrapper>
        </LeftWrapper>
        <RightWrapper>
          <SearchLinkWrapper isLoggedIn={isLoggedIn}>
            <SearchBar hasBanner={hasBanner} />
          </SearchLinkWrapper>
          <LoginWrapper>
            <GetVipLinkWrapper isLoggedIn={isLoggedIn}>
              <NavLink href={Config.siteLinks.billing}>
                {t('web.site-layout.get-vip-link', 'Get VIP')}
              </NavLink>
            </GetVipLinkWrapper>
            {isLoggedIn ? (
              <Suspense fallback={<div />}>
                <UserProfileDropdown />
                <FeedUpdater />
              </Suspense>
            ) : (
              <LoginLink onClick={showLoginFlow}>
                {t('web.login.login-header.text', 'Login')}
              </LoginLink>
            )}
          </LoginWrapper>
        </RightWrapper>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: LoginSelectors.isLoggedIn(state),
  hasBanner: JourneyBannerSelectors.getIsVisible(state),
});

export default connect(mapStateToProps)(NavigationBar);
