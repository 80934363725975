/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

// old - See i18n_helper.rb to see SupportedLanguages definition
// new - hardcoded in the client for better practices and performance
const DEFAULT_LANG = 'en';

// copy of supported_languages.json
// disabledLocales logic doesn't work on non-dev environment and the these were commented below
const SUPPORTED_LANGS = {
  en: {
    available: true,
    defaultLocale: 'en-US',
    supportedLocales: {
      'en-US': 'English (US)',
      'en-GB': 'English (UK)',
    },
  },
  id: {
    available: true,
    defaultLocale: 'id-ID',
    supportedLocales: {
      'id-ID': 'Bahasa Indonesia',
    },
  },
  pt: {
    available: true,
    defaultLocale: 'pt-PT',
    supportedLocales: {
      'pt-PT': 'Português',
    },
  },
  es: {
    available: true,
    defaultLocale: 'es-419',
    supportedLocales: {
      'es-419': 'Español',
      'es-ES': 'Español (España)',
    },
  },
  ms: {
    available: true,
    defaultLocale: 'ms-MY',
    supportedLocales: {
      'ms-MY': 'Bahasa Melayu',
    },
  },
  ja: {
    available: true,
    defaultLocale: 'ja-JP',
    supportedLocales: {
      'ja-JP': '日本語',
    },
  },
  de: {
    available: true,
    defaultLocale: 'de-DE',
    supportedLocales: {
      'de-DE': 'Deutsch',
    },
  },
  fr: {
    available: true,
    defaultLocale: 'fr-FR',
    supportedLocales: {
      'fr-FR': 'Français',
    },
  },
  ko: {
    available: true,
    defaultLocale: 'ko-KR',
    supportedLocales: {
      'ko-KR': '한국어',
    },
  },
};

const SupportedLanguages = SUPPORTED_LANGS || [];
export default SupportedLanguages;

export const DefaultLanguage = DEFAULT_LANG;
export const DefaultLocale = `${DefaultLanguage}-US`;
export const languageIsSupported = (language) =>
  SupportedLanguages[language] && SupportedLanguages[language].available;

export const SupportedLanguagesList = Object.keys(SupportedLanguages);

export const SupportedLocales = SupportedLanguagesList.reduce(
  (supportedLocales, lang) => {
    const updatedSupportedLocales = supportedLocales;

    Object.keys(SupportedLanguages[lang].supportedLocales).forEach((locale) => {
      updatedSupportedLocales[locale] =
        SupportedLanguages[lang].supportedLocales[locale];
    });

    return updatedSupportedLocales;
  },
  {}
);
export const localeIsSupported = (locale) => !!SupportedLocales[locale];

const DefaultLocales = SupportedLanguagesList.reduce((defaultLocales, lang) => {
  const updatedDefaultLocales = defaultLocales;
  updatedDefaultLocales[lang] = SupportedLanguages[lang].defaultLocale;
  return updatedDefaultLocales;
}, {});
export const getDefaultLocaleForLanguage = (language) =>
  DefaultLocales[language];

const defaultLocalesList = Object.keys(DefaultLocales);
export const localesRegex = new RegExp(
  `/(${defaultLocalesList.join('|')})(?=/)`
);

const stripeLangFromLocaleRegex = /[-_][a-z0-9]+$/i;
export const getLangFromLocale = (locale) =>
  locale.replace(stripeLangFromLocaleRegex, '');

const languagesRegexPattern = `/(${SupportedLanguagesList.join('|')})(?=/)`;

export const languagesRegex = new RegExp(languagesRegexPattern);
