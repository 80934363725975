/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import ThemeType, { Grid, Breakpoints, Media } from './themeTyping';
import colors from './colors';
import shadows from './shadows';
import corners from './corners';
import typography from './typography';
import spacing from './spacing';
import { promoSmule, promoAutorap } from './promo';

const breakpoints: Breakpoints = {
  mobile: 0,
  tablet: 600,
  desktop: 960,
};

const tablet = `@media only screen and (min-width: ${breakpoints.tablet}px)`;
const desktop = `@media only screen and (min-width: ${breakpoints.desktop}px)`;
const mobileOnly = `@media only screen and (max-width: ${
  breakpoints.tablet - 1
}px)`;
const tabletOnly = `@media only screen and (min-width: ${
  breakpoints.tablet
}px) and (max-width: ${breakpoints.desktop - 1}px)`;
const retina =
  '@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

const media: Media = {
  tablet,
  desktop,
  mobileOnly,
  tabletOnly,
  retina,
};

const grid: Grid = {
  colNumber: 12,
  gutter: {
    mobile: { h: 8, v: 8, o: 16 },
    tablet: { h: 16, v: 16, o: 24 },
    desktop: { h: 16, v: 16, o: 24 },
  },
  maxWidth: {
    mobile: breakpoints.mobile + 420,
    tablet: breakpoints.tablet + 200,
    desktop: breakpoints.desktop + 240,
  },
};

const theme: ThemeType = {
  breakpoints,
  media,
  grid,
  colors,
  shadows,
  corners,
  typography,
  spacing,
  promo: promoSmule,
};

export const autorapTheme: ThemeType = {
  ...theme,
  promo: { ...promoAutorap },
};

export default theme;
