/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Cookies from 'cookies-js';
import qs from 'qs';

import {
  sendNonInteractionEvent,
  sendInteractionEvent,
  currentTimeZone,
  smuleLog,
} from '@app/utils/utils';
import Config from '@app/WebConfig';
import { ERRORS } from './reducer';
import { FormattedLoginErrors, FormattedLoginMessages } from './formatters';
import FB from '@app/utils/fb_sdk';

declare global {
  interface Window {
    dauPromise: Promise<void>;
  }
}

const stringCheck = (regex) => (str) => regex.test(str);
const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 24;

// returns true for string + @ + string + . + string
export const isValidEmailFormat = stringCheck(/^.+?@.+?$/i);
export const isSmuleEmail = stringCheck(/^[A-Z0-9._%+-]+@smule.com$/i);

export const hasPlus = (str) =>
  typeof str === 'string' && str.indexOf('+') >= 0;

export const splitErrors = (errors, defaultMessage) => {
  const split = { message: defaultMessage, errors: {} };
  if (errors && errors.base) {
    if (Array.isArray(errors.base)) {
      split.message = errors.base.join(', ');
    } else {
      split.message = errors.base;
    }
    delete errors.base;
  }
  split.message = split.message || defaultMessage;
  split.errors = errors;
  return split;
};

export const isPasswordTooShort = (str) => str.length < PASSWORD_MIN_LENGTH;
export const isPasswordTooLong = (str) => str.length > PASSWORD_MAX_LENGTH;
export const isPasswordTooWeak = (str) =>
  !stringCheck(/^(?=.*\d)(?=.*[a-z]).*$/i)(str);

export const sendTrackingEvent = (action, label, isInteraction) => {
  if (isInteraction) {
    sendInteractionEvent('registration', action, label);
  } else {
    sendNonInteractionEvent('registration', action, label);
  }
};

export const recordDau = () => {
  window.dauPromise = new Promise<void>((resolve, reject) => {
    if (
      Config.loginConfig.dauPeriodExeeded ||
      !document.getElementById('#record-dau-image')
    ) {
      const p = qs.stringify({
        from_page: Config.loginConfig.pagePath,
        tz_offset: currentTimeZone,
        nocache: new Date().getTime(),
      });

      const img = document.createElement('img');
      img.id = 'record-dau-image';
      img.src = `/api/analytics/dau?${p}`;
      img.width = 1;
      img.height = 1;
      img.style.visibility = 'hidden';
      img.style.position = 'absolute';
      img.style.right = '0';
      img.style.bottom = '0';
      img.classList.add('analytics-pixel');
      img.onload = () => {
        resolve();
      };
      img.onerror = () => {
        reject();
      };
      document.body.appendChild(img);
    } else {
      resolve();
    }
  });
};

export const isEnterKeyPressed = (reactEvent) => {
  const code = reactEvent.keyCode
    ? reactEvent.keyCode
    : reactEvent.which
    ? reactEvent.which
    : reactEvent.charCode;
  return code === 13;
};

export const userExplicitlyLogout = () => Cookies.get('logged_out') === '1';

export const loadFbSdk = async (locale = 'en-US') => {
  sendTrackingEvent('fbsdk_start', 'fb_reg', true);

  try {
    const response = await FB.loadSdk(Config.facebookSdkConfig, locale);
    const { status } = response;

    smuleLog('FB SDK successfully loaded');
    sendTrackingEvent('fbsdk_success', 'fb_reg', true);

    if (status !== 'connected') {
      sendTrackingEvent('fbsdk_unauthorized', 'fb_reg', true);
    }

    return response;
  } catch (error) {
    smuleLog(error);
    sendTrackingEvent('fbsdk_fail', 'fb_reg', true);
    throw error;
  }
};

export const getPasswordFormatHint = () =>
  FormattedLoginMessages.passwordFormatHint;

export const getErrorMsg = (error) => {
  switch (error) {
    case ERRORS.UNEXPECTED_ERROR_NEED_RELOAD:
      return FormattedLoginErrors.unexpectedError;

    case ERRORS.INVALID_EMAIL_FORMAT:
      return FormattedLoginErrors.invalidEmailFormat;

    case ERRORS.EMAIL_NOT_FOUND:
      return FormattedLoginErrors.emailNotFound;

    case ERRORS.PASSWORD_INVALID:
      return FormattedLoginErrors.invalidPassword;

    case ERRORS.PASSWORD_TOO_SHORT:
      return FormattedLoginErrors.passwordTooShort;

    case ERRORS.PASSWORD_TOO_LONG:
      return FormattedLoginErrors.passwordTooLong;

    case ERRORS.PASSWORD_TOO_WEAK:
      return FormattedLoginErrors.passwordTooWeak;

    case ERRORS.PASSWORD_DOES_NOT_MATCH:
      return FormattedLoginErrors.passwordDoesNotMatch;

    case ERRORS.FB_LOGIN_UNEXPECTED_ERROR:
    case ERRORS.GOOGLE_LOGIN_UNEXPECTED_ERROR:
    case ERRORS.APPLE_LOGIN_UNEXPECTED_ERROR:
      return FormattedLoginErrors.unexpectedError;

    case ERRORS.SEND_ACTIVATION_UNEXPECTED_ERROR:
      return FormattedLoginErrors.activationSentFail;

    case ERRORS.WRONG_PIN_ERROR:
      return FormattedLoginErrors.phoneWrongPinError;

    case ERRORS.MAX_ATTEMPTS_ERROR:
      return FormattedLoginErrors.phoneMaxAttemptsError;

    case ERRORS.TTL_EXPIRED_ERROR:
      return FormattedLoginErrors.phoneTtlExpiredError;

    case ERRORS.SMS_NOT_SEND_ERROR:
      return FormattedLoginErrors.phoneSmsNotSendError;

    case ERRORS.USER_NOT_FOUND:
      return FormattedLoginErrors.phoneUserNotFoundError;

    case ERRORS.PHONE_GENERIC_ERROR:
      return FormattedLoginErrors.phoneGenericError;

    case ERRORS.NONE:
    case ERRORS.FRAGMENT:
      return null;

    default: // TODO: This is temp solution to handle the error returns from rails, since it could be a message.
      return error;
  }
};
