/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { Cursor } from '@app/pages/Songbook/types';
import { doApiRequest } from './utils';
import { AccountIcon } from '@app/pages/AwesomePlaylists/types';

type SongLite = {
  arrKey: string;
  name?: string;
  compTitle: string;
  ownerAccountIcon: AccountIcon;
  artist: string;
  coverUrl: string;
};

export type SongbookArrangementsResponse = {
  songs: SongLite[];
  cursor: Cursor;
};

type GetArrangementParams =
  | {
      searchTerm: string;
      cursor: string;
    }
  | {
      cursor: string;
      category: string;
    };

const getArrangements = (params: GetArrangementParams) =>
  doApiRequest<SongbookArrangementsResponse>({
    url: '/api/songs',
    method: 'get',
    params,
  });

export type SongbookAPIType = {
  getArrangements: typeof SongbookApi.getArrangements;
};

export const SongbookApi = {
  getArrangements,
};
