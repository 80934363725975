/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import styled from 'styled-components';

import { Colors } from '@app/theme/colors';

export enum BackdropType {
  light = 'light',
  mediumDark = 'mediumDark',
  dark = 'dark',
  colorful = 'colorful',
  transparent = 'transparent',
  gradient = 'gradient',
}

export type BackdropPosition = 'fixed' | 'absolute';

interface BackdropProps {
  type: BackdropType;
  position?: BackdropPosition;
}

const getBackground = (type: BackdropType, colors: Colors) => {
  switch (type) {
    case BackdropType.light:
      return colors.white;
    case BackdropType.mediumDark:
    case BackdropType.dark:
      return colors.purple500;
    case BackdropType.colorful:
      return 'linear-gradient(225deg, #564CF2 0%, #8826FB 51.56%, #E322DE 100%)';
    case BackdropType.transparent:
      return 'transparent';
    case BackdropType.gradient:
      return 'linear-gradient(180deg, rgba(17, 14, 57, 0) 39.76%, #110E39 95.49%)';
  }
};

const getOpacity = (type: BackdropType) => {
  switch (type) {
    case BackdropType.light:
    case BackdropType.mediumDark:
      return '.5';
    case BackdropType.dark:
    case BackdropType.colorful:
      return '.95';
    case BackdropType.transparent:
    case BackdropType.gradient:
      return '1';
  }
};

const Backdrop = styled.div<BackdropProps>`
  position: ${(props) => props.position || 'fixed'};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => getBackground(props.type, props.theme.colors)};
  opacity: ${(props) => getOpacity(props.type)};
  z-index: 100100;
`;

export default Backdrop;
