/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';

import VideoJSPlayerReducer, {
  STATE_KEY as VIDEO_JS_STATE_KEY,
} from './player_types/video_js/video_js_player_reducer';
import PerformancePlayerReducer, {
  STATE_KEY as PERFORMANCE_STATE_KEY,
} from './player_types/performance/performance_player_reducer';
import { PlayerTypes } from './types';
import { PlayersActionTypes } from './actions';
import { STATE_KEY } from './selectors';
export { STATE_KEY } from './selectors';

export const PLAYER_TYPES = PlayerTypes;

const FullPerformancePlayerReducer = combineReducers({
  [VIDEO_JS_STATE_KEY]: VideoJSPlayerReducer,
  [PERFORMANCE_STATE_KEY]: PerformancePlayerReducer,
});

const isPlayerAction = (action) =>
  action.type.match(new RegExp(`^${STATE_KEY}/.*`));

/*
  Example PlayersReducer state:
  {
    0: {
      type: 'PERFORMANCE_PLAYER',
      playerState: {
        videoJS: { // ... VideoJS state here },
        performance: { // ... PerformancePlayer state here },
      },
    },
    1: {
      type: 'PERFORMANCE_PLAYER',
      playerState: {
        videoJS: { // ... VideoJS state here },
        performance: { // ... PerformancePlayer state here },
      },
    },
  }
*/

// The PlayersReducer chooses the right child PlayerReducer based on id
export default (state = Immutable({}), action) => {
  // Ignore non-player actions
  if (!isPlayerAction(action)) return state;

  // If the player already exists, get its state, otherwise create a new immutable obj to be its state
  const playerState = state[action.payload.id] || Immutable({});
  let playerType = playerState.type;
  let newPlayerState = playerState;

  // For newly initialized players
  if (action.type === PlayersActionTypes.INIT) {
    // Set the player type from the INIT action
    playerType = action.payload.type;
    newPlayerState = newPlayerState.set('type', playerType);
  }

  // Determine which reducer to use for this player
  let playerReducer;
  switch (playerType) {
    case PLAYER_TYPES.PERFORMANCE:
    default: {
      playerReducer = FullPerformancePlayerReducer;
      break;
    }
  }

  // Update the player's state, then update the PlayersReducer state
  newPlayerState = newPlayerState.set(
    'playerState',
    playerReducer(playerState.playerState, action)
  );
  return state
    .set('currentPlayerId', action.payload.id)
    .set(action.payload.id, newPlayerState);
};
