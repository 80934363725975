/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable max-len */

import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { denormalize } from 'normalizr';
import { ImmutableObject } from 'seamless-immutable';
import Cookies from 'cookies-js';

import { AppUids, AppNames, AppFamilies } from '@app/constants/utils';
import {
  createRecordingDeepLink,
  createFullRecordingShareUrl,
  createFullRecordingEscaptedShareUrl,
  pathToAbsUrl,
} from '@app/utils/url'; // eslint-disable-line
import { PerformancesSelectors } from '@app/pages/NowPlaying/modules/performances/selectors';
import { PerformersSelectors } from '@app/pages/NowPlaying/modules/performers/selectors';
import { CommentsSelectors } from '@app/pages/NowPlaying/modules/comments/selectors';
import { LovesSelectors } from '@app/pages/NowPlaying/modules/loves/selectors';
import { Selectors as LoginSelectors } from '@app/layout/Login/reducer';
import * as schema from '@app/schemas/performance';
import { RouterSelectors } from '@app/utils/router-selectors';
import { State } from '@app/reducer';
import { PlaylistsSelectors } from './modules/playlists/selectors';
import { DenormalizedPerformance, DenormalizedPlaylist } from './types';
import { isHdVideo } from '@app/utils/utils';

const ArrangementTypes = {
  ORIGINAL: 'original',
  COVER: 'cover',
};

const getNowPlayingPage = (state: State) => state.nowPlaying;

const getSelectedPerformanceKey = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.selectedPerformanceKey || ''
);

const getSelectedPerformance = createCachedSelector(
  getSelectedPerformanceKey,
  PerformancesSelectors.getPerformances,
  PerformersSelectors.getPerformers,
  CommentsSelectors.getComments,
  LovesSelectors.getLoves,
  (perfKey, performances, performers, comments, loves) => {
    const denormalizedPerformance: ImmutableObject<DenormalizedPerformance> =
      denormalize(perfKey, schema.performance, {
        performances,
        performers,
        comments,
        loves,
      });
    return denormalizedPerformance;
  }
)(getSelectedPerformanceKey);
const getPerfKey: (state, props?) => any = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.key
);
const getPerfFormType: (state, props?) => any = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.formType
);
const getPerfExpireAt = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.expireAt
);
const getShowExpireAt = createSelector(
  getPerfExpireAt,
  (expireAt) =>
    !!expireAt &&
    new Date(expireAt) <
      new Date(new Date().setFullYear(new Date().getFullYear() + 1))
);
const getIsExpired = createSelector(
  getPerfExpireAt,
  (expireAt) => !!expireAt && new Date(expireAt) < new Date()
);
const getEnsembleType = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.ensembleType
);
const getIsSeed = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.seed
);
const getIsClosed = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.closed
);
const getAppUid = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.appUid
);
const getAppName = createSelector(getAppUid, (appUid) => {
  switch (appUid) {
    case AppUids.SING_IOS:
    case AppUids.SING_ANDROID:
      return AppNames.SING;
    case AppUids.PIANO_IOS:
    case AppUids.PIANO_ANDROID:
    case AppUids.PIANO_AMAZON:
      return AppNames.PIANO;
    case AppUids.AUTORAP_IOS:
    case AppUids.AUTORAP_ANDROID:
      return AppNames.AUTORAP;
    case AppUids.GUITAR_IOS:
      return AppNames.GUITAR;
    default:
      return AppNames.SING;
  }
});
const getAppFamily: (state, props?) => AppFamilies = createSelector(
  getAppUid,
  (appUid) => {
    switch (appUid) {
      case AppUids.SING_IOS:
      case AppUids.SING_ANDROID:
        return AppFamilies.SING;
      case AppUids.PIANO_IOS:
      case AppUids.PIANO_ANDROID:
      case AppUids.PIANO_AMAZON:
        return AppFamilies.PIANO;
      case AppUids.AUTORAP_IOS:
      case AppUids.AUTORAP_ANDROID:
        return AppFamilies.AUTORAP;
      case AppUids.GUITAR_IOS:
        return AppFamilies.GUITAR;
      default:
        return AppFamilies.SING;
    }
  }
);

const getOwner = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.owner
);
const getOwnerId = createSelector(
  getOwner,
  (owner) => owner && owner.accountId
);
const getOwnerIsFollowing = createSelector(
  getOwner,
  (owner) => owner && owner.isFollowing
);
const getOwnerIsFollowingStatusUpdating = createSelector(
  getOwner,
  (owner) => owner && owner.isFollowStatusUpdating
);
const getPerformanceTitle = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.title
);
const getPerformanceArtist = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.artist
);
const getPerformanceMessage = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.message
);
const getPerformanceCreateAt = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.createdAt
);
const getPerformanceOtherPerformers = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.otherPerformers
);
const getOtherPerformers = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.otherPerformers
);
const getOtherPerformersCount = createSelector(
  getPerformanceOtherPerformers,
  (performers) => performers && performers.length
);
const getSingersCount = createSelector(
  getOtherPerformersCount,
  (singers) => singers + 1
);
const getSinglePerformer = createSelector(
  getPerformanceOtherPerformers,
  (singlePerformer) =>
    singlePerformer && singlePerformer.length === 1 && singlePerformer[0]
);
const getIsSinglePerformerFollowing = createSelector(
  getSinglePerformer,
  (singlePerformer) => singlePerformer && singlePerformer.isFollowing
);
const getIsSinglePerformerFollowingStatusUpdating = createSelector(
  getSinglePerformer,
  (singlePerformer) => singlePerformer && singlePerformer.isFollowStatusUpdating
);
const getPerformanceStats = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.stats
);
const getPerformanceLovesCount = createSelector(
  getPerformanceStats,
  (performance) => performance && performance.totalLoves
);
const getPerformanceListensCount = createSelector(
  getPerformanceStats,
  (stats) => stats && stats.totalListens
);
const getPerformanceGiftsCount = createSelector(
  getPerformanceStats,
  (stats) => stats && stats.totalGifts
);

const getSongId: (state, props?) => any = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.songId
);
const getIsOpenMic = createSelector(
  getSongId,
  (songId) => songId === '_open_mic_5m'
);

const getFetchingUpNext = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.fetchingUpNext
);

const getPlaylistId = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.playlistId || ''
);
const getPlaylistCursor = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.playlistCursor
);
const getListenTrigger = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.listenTrigger
);

const getVisbleInlineOverlay = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.visibleInlineOverlay
);

const getRecordingTitle = createSelector(
  [getIsOpenMic, getPerformanceArtist, getPerformanceTitle],
  (isOpenMic, artist, title) =>
    `${isOpenMic || !artist ? '' : `${artist} - `}${title}`
);
const getRecordingPageMetaKeywords = createSelector(
  [getIsOpenMic, getPerformanceArtist, getOwner, getPerformanceTitle],
  (isOpenMic, perfArtist, owner, perfTitle) => {
    const metaKeyWords =
      'smule, social singing app, karaoke singing lyrics, lyrics for singing, karaoke online, smule vip singing, sing songs english lyrics, lirik karaoke, karaoke app download, smule autotune, social singing karaoke subscription, social singing online app, smule artists search, smule artist program, sing with artists online app, sing duet with artist online, famous artists karaoke program, smule singing referral program, gifts for singing good';
    const artistKeyword =
      isOpenMic || perfArtist == null
        ? null
        : perfArtist.replace(/[()\-?!.,]/, '');
    return [
      artistKeyword ? `${artistKeyword}, ` : '',
      owner ? `${owner.handle}, ` : '',
      perfTitle ? `${perfTitle.replace(/[()\-?!.,]/, '')}, ` : ',',
      metaKeyWords,
    ].join('');
  }
);
const getCommentsLoading = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.commentsLoading
);
const userIsCommenter = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.userIsCommenter
);
const getComments = createSelector(
  getSelectedPerformance,
  (selectedPerformance) => selectedPerformance && selectedPerformance.comments
);
const getCommentsNextOffset = createSelector(
  getSelectedPerformance,
  (selectedPerformance) =>
    selectedPerformance && selectedPerformance.commentsNextOffset
);

const getAllCommentsCount = createSelector(
  getSelectedPerformance,
  (selectedPerformance) => {
    return selectedPerformance ? selectedPerformance.stats.totalComments : 0;
  }
);

const getPlaylist = createCachedSelector(
  getPlaylistId,
  PlaylistsSelectors.getPlaylists,
  PerformancesSelectors.getPerformances,
  PerformersSelectors.getPerformers,
  CommentsSelectors.getComments,
  LovesSelectors.getLoves,
  (playlistId, playlists, performances, performers, comments, loves) => {
    const denormalizedPlaylist: DenormalizedPlaylist = denormalize(
      playlistId,
      schema.playlist,
      {
        playlists,
        performances,
        performers,
        comments,
        loves,
      }
    );
    return denormalizedPlaylist;
  }
)(getPlaylistId);
const getPlaylistPerfKeys = createSelector(
  getPlaylistId,
  PlaylistsSelectors.getPlaylists,
  (playlistId, playlists) =>
    (playlists &&
      playlistId &&
      playlists[playlistId] &&
      playlists[playlistId].perfList) ||
    []
);

const getPlaylistPerformances = createSelector(
  getPlaylist,
  (playlist) => playlist && playlist.perfList
);

const getNextPlayablePerfIdx = createSelector(
  [getPlaylistCursor, getPlaylistPerformances],
  (cursor, perfList) => {
    if (!perfList) {
      return false;
    }
    for (let i = cursor + 1; i < perfList.length; i += 1) {
      if (
        perfList[i].mediaUrls.audio ||
        perfList[i].mediaUrls.video ||
        perfList[i].mediaUrls.visualizer
      ) {
        return i;
      }
    }
    return false;
  }
);

const getFirstPlayablePerformance = createSelector(
  [getPlaylistPerformances],
  (perfList) => (perfList && perfList.length > 0 && perfList[0]) || null
);

const getFirstPlayableArrKey = createSelector(
  [getFirstPlayablePerformance],
  (performance) => (performance && performance.arrKey) || null
);

const getNextPlayablePerformance = createSelector(
  [
    getNextPlayablePerfIdx,
    getPlaylistPerformances,
    RouterSelectors.getIsChallenge,
  ],
  (idx, perfList, isChallenge) => {
    const perf = ((idx || idx === 0) && perfList && perfList[idx]) || null;
    if (isChallenge && perf) {
      return Object.assign({}, perf, { isChallenge });
    }
    return perf;
  }
);
const getNextPlayablePerformances = createSelector(
  [
    getNextPlayablePerfIdx,
    getPlaylistPerformances,
    RouterSelectors.getIsChallenge,
  ],
  (idx, perfList, isChallenge) => {
    const performances =
      ((idx || idx === 0) && perfList && perfList.slice(idx)) || null;
    if (isChallenge && performances && performances.length > 0) {
      return performances.map((perf) =>
        Object.assign({}, perf, { isChallenge })
      );
    }
    return performances;
  }
);

const getNextPlayablePerfKey = createSelector(
  [getNextPlayablePerformance],
  (perf) => (perf && perf.key) || null
);

const getPreviousPlayablePerfIdx = createSelector(
  [getPlaylistCursor, getPlaylistPerformances],
  (cursor, perfList) => {
    if (!perfList || perfList.length === 0) return false;
    for (let i = cursor - 1; i >= 0; i -= 1) {
      if (
        perfList[i]?.mediaUrls.audio ||
        perfList[i]?.mediaUrls.video ||
        perfList[i]?.mediaUrls.visualizer
      )
        return i;
    }
    return false;
  }
);
const getPreviousPlayablePerformance = createSelector(
  [getPreviousPlayablePerfIdx, getPlaylistPerformances],
  (idx, perfList) => (idx || idx === 0) && perfList && perfList[idx]
);

const getPreviousPlayablePerfKey = createSelector(
  [getPreviousPlayablePerformance],
  (perf) => (perf && perf.key) || null
);

const getQueueNext = createSelector(
  getNowPlayingPage,
  (nowPlaying) => nowPlaying.queueNext
);
const getQueuePrevious = createSelector(
  getNowPlayingPage,
  (nowPlaying) => nowPlaying.queuePrevious
);

const getAutoplayEnabled = createSelector(
  getNowPlayingPage,
  (nowPlaying) => nowPlaying.autoplayEnabled
);
const getSongInfoUrl = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.songInfoUrl
);
const getIsFavorite = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.isFavorite
);
const getIsOwner = createSelector(
  getOwner,
  LoginSelectors.getUserAccountId,
  (owner, userAccountId) => owner && owner.accountId === userAccountId
);
const getArrType = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.arrType
);
const getCanDelete = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.canDelete
);
const getCanReportAbuse = createSelector(getIsOwner, (isOwner) => !isOwner);
const getCanReportInfringement = createSelector(
  getArrType,
  getIsOwner,
  (arrType, isOwner) => arrType === ArrangementTypes.ORIGINAL && !isOwner
);
const getPerformanceReported = createSelector(
  getNowPlayingPage,
  (nowPlayingPage) => nowPlayingPage.performanceReported
);
const getMoreActionsMenuVisible = createSelector(
  getNowPlayingPage,
  (nowPlayingPage) => nowPlayingPage.moreActionsMenuVisible
);
const getStats = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.stats
);
const getTotalComments = createSelector(
  getStats,
  (stats) => stats && stats.totalComments
);

const getLoves = createSelector(
  getSelectedPerformance,
  (selectedPerformance) => selectedPerformance && selectedPerformance.loves
);
const getLovesLoaded = createSelector(
  getSelectedPerformance,
  (selectedPerformance) =>
    selectedPerformance && selectedPerformance.lovesLoaded
);
const getLovesLoading = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.lovesLoading
);
const getInviteFollowersLoading = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.inviteFollowersLoading
);
const getInviteFollowersModalType = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.inviteFollowersModalType
);

const getCanJoin = createSelector(
  [getAppFamily, getIsSeed, getIsExpired, getIsClosed],
  (appFamily, isSeed, isExpired, isClosed) =>
    appFamily === AppFamilies.SING && isSeed && !isExpired && !isClosed
);

const getDeepLink = createSelector(
  [getAppUid, getPerfKey, getEnsembleType, RouterSelectors.getIsChallenge],
  createRecordingDeepLink
);

const getFacebookCampaignAdId = createSelector(
  getNowPlayingPage,
  (recordingPage) =>
    recordingPage.campaigns && recordingPage.campaigns.fb_campaign_id
      ? recordingPage.campaigns.fb_campaign_id
      : Cookies.get('fb_campaign_id') || null
);

// Sharing state
const getWebUrl = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.webUrl
);
const getShareType = (_, channel) => channel;
const getShareUrl = createCachedSelector(
  getWebUrl,
  getShareType,
  createFullRecordingShareUrl
)(getShareType);
const getShareUrlPath = createCachedSelector(
  getWebUrl,
  getShareType,
  createFullRecordingEscaptedShareUrl
)(getShareType);

const getEncodedShareUrl = createSelector(
  getShareUrlPath,
  (shareUrl) => shareUrl
);
const getArtistTwitter = createSelector(
  getSelectedPerformance,
  (perf) => (perf && perf.artistTwitter) || ''
);
const getCoverUrl = createSelector(
  getSelectedPerformance,
  (perf) => perf && perf.coverUrl
);
const getEmbedShareUrl = createSelector(
  getWebUrl,
  (webUrl) =>
    (webUrl && `${pathToAbsUrl(webUrl.replace(/\/ensembles$/, ''))}/frame`) ||
    ''
); // eslint-disable-line
const getPerfStatus = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.perfStatus
);
const getArrKey = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.arrKey
);
const getPerfType = createSelector(
  getSelectedPerformance,
  (performance) => performance && performance.type
);
const getLikeUnlikeCommentsInProgress = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.likeUnlikeCommentsInProgress
);

const getCommentInput = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.commentInput
);
const getLoadingNewComment = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.loadingNewComment
);
const getQuery = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.query
);
const getIsVideo: (state, props?) => any = createSelector(
  getPerfType,
  (perfType) => perfType === 'video'
);
const getIsPerformer: (state, props?) => any = createSelector(
  getIsOwner,
  getOtherPerformers,
  LoginSelectors.getUserAccountId,
  (isOwner, otherPerformers, userAccountId) =>
    otherPerformers
      ? otherPerformers.filter(
          (performer) => performer.accountId === userAccountId
        ).length > 0
      : isOwner
);
const getTotalListenInterval = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.totalListenInterval
);
const getListenIntervalCount = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.listenIntervalCount
);

const getHasSeenShortForm = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.hasSeenShortForm
);
const getHasSeenPlaybackPanel = createSelector(
  getNowPlayingPage,
  (recordingPage) => recordingPage.hasSeenPlaybackPanel
);

const getIsHdVideoPerformance = createSelector(
  getSelectedPerformance,
  (performance) =>
    !!(
      performance &&
      performance.videoResolution &&
      isHdVideo(performance.videoResolution)
    )
);

export const nowPlayingSelectors = {
  getFetchingUpNext,
  getPerfKey,
  getPerfFormType,
  getPerfExpireAt,
  getShowExpireAt,
  getEnsembleType,
  getAppName,
  getAppFamily,
  getIsOwner,
  getOwner,
  getOwnerId,
  getSongId,
  getOwnerIsFollowing,
  getPlaylistId,
  getPlaylistCursor,
  getListenTrigger,
  getVisbleInlineOverlay,
  getPerformanceTitle,
  getPerformanceMessage,
  getPerformanceCreateAt,
  getPerformanceOtherPerformers,
  getSingersCount,
  getSinglePerformer,
  getIsSinglePerformerFollowing,
  getPerformanceLovesCount,
  getPerformanceListensCount,
  getPerformanceGiftsCount,
  getRecordingTitle,
  getRecordingPageMetaKeywords,
  getNextPlayablePerfIdx,
  getNextPlayablePerformance,
  getNextPlayablePerformances,
  getNextPlayablePerfKey,
  getPreviousPlayablePerfIdx,
  getPreviousPlayablePerformance,
  getPreviousPlayablePerfKey,
  getQueueNext,
  getQueuePrevious,
  getAutoplayEnabled,
  getFirstPlayablePerformance,
  getFirstPlayableArrKey,
  getSongInfoUrl,
  getIsFavorite,
  getCanDelete,
  getCanReportAbuse,
  getCanReportInfringement,
  getPerformanceReported,
  getMoreActionsMenuVisible,
  getTotalComments,
  getLoves,
  getLovesLoaded,
  getLovesLoading,
  getInviteFollowersLoading,
  getInviteFollowersModalType,
  getPlaylistPerfKeys,
  getCanJoin,
  getDeepLink,
  getShareUrl,
  getEncodedShareUrl,
  getArtistTwitter,
  getCoverUrl,
  getEmbedShareUrl,
  getPerfStatus,
  getArrKey,
  getIsVideo,
  getFacebookCampaignAdId,
  getPerformanceArtist,

  getCommentsLoading,
  userIsCommenter,
  getComments,
  getCommentsNextOffset,
  getAllCommentsCount,
  getLikeUnlikeCommentsInProgress,
  getCommentInput,
  getLoadingNewComment,
  getQuery,
  getOtherPerformers,
  getIsPerformer,
  getTotalListenInterval,
  getListenIntervalCount,
  getOwnerIsFollowingStatusUpdating,
  getIsSinglePerformerFollowingStatusUpdating,
  getHasSeenShortForm,
  getHasSeenPlaybackPanel,
  getIsHdVideoPerformance,
};
