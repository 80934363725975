/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import { createSlideAnimation } from '@app/utils/css_animations';
import { isMobile } from '@app/utils/utils';
import { BodyText } from '@app/components/UI/Text';
import { BodyTextSize } from '@app/components/UI/Text/types';
import { BigSpinner } from '@app/components/Spinners';
import Backdrop, { BackdropType } from '@app/components/UI/Backdrop';
import { Selectors, Actions, ERRORS } from './reducer';
import { SpinnerContainer } from './styles';

const animationDuration = 300;

const Animations = {
  in: createSlideAnimation({
    fromX: '0px',
    fromY: '20px',
    fromOpacity: 0,
    toX: '0px',
    toY: '0',
    toOpacity: 1,
  }),
  out: createSlideAnimation({
    fromX: '0px',
    fromY: '0',
    fromOpacity: 1,
    toX: '0px',
    toY: '20px',
    toOpacity: 0,
  }),
};

const getModalContainerAnimation = (isVisible, isInAnimation) => {
  if (!isInAnimation) {
    return 'none';
  }
  return css`
    ${isVisible
      ? Animations.in
      : Animations.out} ${animationDuration}ms cubic-bezier(0.25, 0.1, 0.25, 1) both
  `;
};

const ModalContainer = styled.div<{
  isVisible: boolean;
  isInAnimation: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 333333;
  pointer-events: ${(props) =>
    props.isVisible || props.isInAnimation ? 'all' : 'none'};
  opacity: ${(props) => (props.isVisible || props.isInAnimation ? 1 : 0)};
  animation: ${(props) =>
    getModalContainerAnimation(props.isVisible, props.isInAnimation)};
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 356px;
  height: 100%;
  padding: 0 22px;
  z-index: 100200;
`;
const ModalBody = styled.div`
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;
const ModalBodyChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const StaleActivationWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing * 4}px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

interface OwnProps {
  children: React.ReactNode;
}
interface StateProps {
  headerStatus: string;
  inAnimation: boolean;
  visible: boolean;
  spinner: boolean;
}
interface DispatchProps {
  openLoginModal: () => void;
  closeLoginModalCancel: () => void;
  setModalAnimation: (animating: boolean) => void;
}
type Props = OwnProps & StateProps & DispatchProps;

class Modal extends Component<Props> {
  static defaultProps = {
    children: null,
    spinner: false,
    headerStatus: null,
  };

  constructor(props, context) {
    super(props, context);
    this.handleHashChange = this.handleHashChange.bind(this);
    this.stopAnimation = this.stopAnimation.bind(this);
  }
  componentDidMount() {
    window.addEventListener('hashchange', this.handleHashChange);
  }
  componentDidUpdate(prevProps) {
    // Start animating when the modal begins to be visible
    // OR              when the modal stops being visible
    if (
      (!prevProps.visible && this.props.visible) ||
      (prevProps.visible && !this.props.visible)
    ) {
      this.props.setModalAnimation(true);
      setTimeout(this.stopAnimation, animationDuration);
    }

    if (!prevProps.visible && this.props.visible) {
      // set push state
      if (isMobile) window.location.hash = 'login';
      document.body.style.overflow = 'hidden';
    }
    if (prevProps.visible && !this.props.visible) {
      document.body.style.overflow = '';
    }
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange);
  }
  handleHashChange() {
    if (this.props.visible && !window.location.hash)
      this.props.closeLoginModalCancel();
    else if (!this.props.visible && window.location.hash === '#login')
      this.props.openLoginModal();
  }
  stopAnimation() {
    this.props.setModalAnimation(false);
  }
  renderHeader() {
    let content;
    switch (this.props.headerStatus) {
      case ERRORS.STALE_ACTIVATION_LINK: {
        content = (
          <StaleActivationWrapper>
            <BodyText size={BodyTextSize.regular}>
              That link is old. Please authenticate again.
            </BodyText>
          </StaleActivationWrapper>
        );
        break;
      }
      // no default
    }
    return content ? <div>{content}</div> : null;
  }
  renderModal() {
    if (!this.props.inAnimation && !this.props.visible) {
      return null;
    }

    return (
      <ModalBody>
        {this.props.spinner && (
          <SpinnerContainer>
            <BigSpinner />
          </SpinnerContainer>
        )}
        {this.renderHeader()}
        <ModalBodyChildren>{this.props.children}</ModalBodyChildren>
      </ModalBody>
    );
  }
  render() {
    const { visible, inAnimation } = this.props;
    return (
      <ModalContainer isVisible={visible} isInAnimation={inAnimation}>
        {(visible || inAnimation) && <Backdrop type={BackdropType.dark} />}
        <ModalWrapper>{this.renderModal()}</ModalWrapper>
      </ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  visible: Selectors.getModalVisibility(state),
  inAnimation: Selectors.isModalInAnimation(state),
  spinner: Selectors.spinnerVisible(state),
  currentFlowId: Selectors.getCurrentFlowId(state),
  headerStatus: Selectors.getHeaderStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  openLoginModal: () => dispatch(Actions.openLoginModal()),
  closeLoginModalCancel: () => dispatch(Actions.closeLoginModalCancel()),
  setModalAnimation: (animating) =>
    dispatch(Actions.setModalAnimationState(animating)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal as any);
