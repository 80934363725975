/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable max-len */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { connect, MapStateToProps } from 'react-redux';
import Cookies from 'cookies-js';
import {
  CONSENT_COOKIES_NAME,
  CONSENT_COOKIES_EXPIRATION,
  COOKIES_BANNER_VERSIONS,
  CONSENT_COOKIES_CATEGORIES,
} from '@app/constants';
import { facebookPixelGrantConsent } from '@app/utils/fb_pixel';
import { State } from '@app/reducer';
import { Grid, GridBox } from '@app/components/Grid';
import ToggleAccordionItem from '@app/components/GDPR/components/ToggleAccordionItem';
import Overlay from '@app/components/GDPR/components/Overlay';
import Link from '@app/components/GDPR/components/Link';
import VendorItem from '@app/components/GDPR/components/VendorItem';
import {
  Selectors as GDPRSelectors,
  Actions as GDPRActions,
} from '@app/components/GDPR/reducer';
import CloseIcon from '@app/components/icons/CloseIcon';
import { emitEvent } from '@app/utils/event_logger';

const CloseIconButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  border: none;
  background: transparent;
  padding: ${(props) => props.theme.spacing * 2}px;
  margin-left: auto;

  ${(props) => props.theme.media.tablet} {
    padding: ${(props) => props.theme.spacing * 3}px;
  }

  ${(props) => props.theme.media.desktop} {
    padding: ${(props) => props.theme.spacing * 4}px;
  }

  svg {
    width: 12px;
    height: 12px;

    ${(props) => props.theme.media.tablet} {
      width: 18px;
      height: 18px;
    }
  }
`;

const Banner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100vh;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  padding-bottom: ${(props) => props.theme.spacing * 2}px;
  border-top-left-radius: ${(props) => props.theme.corners.medium};
  border-top-right-radius: ${(props) => props.theme.corners.medium};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${(props) => props.theme.media.desktop} {
    padding-top: ${(props) => props.theme.spacing * 3}px;
  }
`;

const Modal = styled.div<{
  haveBottomButton?: boolean;
}>`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  position: fixed;
  height: 100%;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing * 2}px;

  padding-bottom: ${(props) => props.theme.spacing * 2}px;

  ${(props) => props.theme.media.tablet} {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    height: calc(100vh - (2 * 15vh));
    max-width: 560px;
    padding-top: ${(props) => props.theme.spacing * 4}px;
    padding-bottom: ${(props) =>
      props.haveBottomButton
        ? props.theme.spacing * 12
        : props.theme.spacing * 4}px;
    border-radius: ${(props) => props.theme.corners.regular};
  }

  ${(props) => props.theme.media.desktop} {
    max-width: 784px;
  }
`;

const ModalInner = styled.div`
  padding-top: ${(props) => props.theme.spacing * 3}px;
  padding-bottom: ${(props) => props.theme.spacing * 10}px;
  padding-left: 18px;
  padding-right: 18px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  ${(props) => props.theme.media.tablet} {
    padding-top: 0;
    padding-left: ${(props) => props.theme.spacing * 6}px;
    padding-right: ${(props) => props.theme.spacing * 6}px;
  }

  ${(props) => props.theme.media.desktop} {
    padding-top: ${(props) => props.theme.spacing}px;
    padding-left: ${(props) => props.theme.spacing * 10}px;
    padding-right: ${(props) => props.theme.spacing * 10}px;
  }
`;

const HeaderStyled = styled.h3`
  font-size: ${({ theme }) => theme.typography.display.fontSize.regular};
  font-weight: ${({ theme }) => theme.typography.display.fontWeight.bold};
  line-height: ${(props) => props.theme.spacing * 4}px;
  margin-top: ${(props) => props.theme.spacing * 2}px;
  margin-bottom: ${(props) => props.theme.spacing * 3}px;

  ${(props) => props.theme.media.tablet} {
    margin-bottom: ${(props) => props.theme.spacing * 2}px;
  }
`;

const SubHeader = styled.h4`
  font-size: ${({ theme }) => theme.typography.body.fontSize.medium};
  font-weight: 700;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: ${(props) => props.theme.spacing * 2}px;
`;

const TextStyled = styled.p`
  font-size: 15px;
  line-height: ${(props) => props.theme.spacing * 3}px;
  margin-bottom: ${(props) => props.theme.spacing * 4}px;

  ${(props) => props.theme.media.desktop} {
    margin-bottom: ${(props) => props.theme.spacing * 5}px;
  }
`;

const Button = styled.button<{
  contained?: boolean;
  wide?: boolean;
}>`
  display: inline-block;
  font-size: ${({ theme }) => theme.typography.body.fontSize.regular};
  font-weight: 700;
  height: 32px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border-radius: ${(props) => props.theme.corners.regular};
  border: none;
  padding: 0px 8px 0px 8px;
  background: ${(props) =>
    props.contained ? props.theme.colors.basePurple : 'transparent'};
  color: ${(props) =>
    props.contained ? props.theme.colors.white : props.theme.colors.basePurple};
  margin-bottom: ${(props) => props.theme.spacing}px;
  ${(props) => props.theme.media.tablet} {
    margin-bottom: ${(props) => props.theme.spacing * 3}px;
  }
`;

const ConfirmButtonWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: fixed;
  width: calc(100% - ${(props) => props.theme.spacing * 4}px);
  left: ${(props) => props.theme.spacing * 2}px;
  right: ${(props) => props.theme.spacing * 2}px;
  bottom: 0;
  padding-top: ${(props) => props.theme.spacing * 2}px;
  padding-bottom: ${(props) => props.theme.spacing * 2}px;

  ${(props) => props.theme.media.tablet} {
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 272px;
  }

  ${(props) => props.theme.media.desktop} {
    max-width: 304px;
  }
`;

const VendorsButton = styled.button`
  font-size: 17px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.gray400};
  border: none;
  background: transparent;
  text-decoration: underline;
  padding: 0;
`;

const Header = () => <HeaderStyled>We Value Your Privacy</HeaderStyled>;

const Text = () => (
  <TextStyled>
    We and our partners use cookies to store and access personal data to
    distinguish you, identify your interests and deliver personalizing content
    and advertising. You may find out more about the purposes for which we and
    our partners use cookies in our{' '}
    <Link href="/cookiepolicy">
      <span>Cookie Policy</span>
    </Link>
    . You can revisit your consent choices or withdraw consent at any time by
    clicking the &quot;Manage Cookies&quot; button below.
  </TextStyled>
);

interface GDPRProps {
  cookieBannerVersionSetting?: number | null;
  hasGPC: boolean;
}

interface GDPRStateProps {
  shouldOpen?: boolean;
}

interface GDPRDispatchProps {
  resetShouldOpenFlag: () => void;
}

const CONSENT_TYPE = {
  all: 'all',
  custom: 'custom',
};

let intervalID,
  intervalIDCount = 2;

const GDPR: React.FC<GDPRProps & GDPRStateProps & GDPRDispatchProps> = ({
  cookieBannerVersionSetting,
  shouldOpen,
  resetShouldOpenFlag,
  hasGPC,
}) => {
  const isInIframe = window !== window.parent;

  const gdprConsentCookie = useMemo(
    () =>
      Cookies.get(CONSENT_COOKIES_NAME) &&
      JSON.parse(Cookies.get(CONSENT_COOKIES_NAME)),
    [Cookies.get(CONSENT_COOKIES_NAME)]
  );
  const getBannerVisibility = () =>
    !hasGPC &&
    !isInIframe &&
    ((!gdprConsentCookie &&
      cookieBannerVersionSetting === COOKIES_BANNER_VERSIONS.gdpr) ||
      (gdprConsentCookie &&
        gdprConsentCookie.cookieBannerVersionValue !==
          COOKIES_BANNER_VERSIONS.gdpr &&
        cookieBannerVersionSetting === COOKIES_BANNER_VERSIONS.gdpr));

  const [isVisible, setIsVisible] = useState(getBannerVisibility());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVendorsOpen, setIsVendorsOpen] = useState(false);

  const modalOverlayRef = useRef<HTMLDivElement>(null);

  const [functionalityCookiesConsent, setFunctionalityCookiesConsent] =
    useState(true);
  const [marketingCookiesConsent, setMarketingCookiesConsent] = useState(true);

  const setGDPRCookie = (
    cookieBannerVersionValue,
    acceptedCookies: Array<string>
  ) => {
    Cookies.set(
      CONSENT_COOKIES_NAME,
      JSON.stringify({
        cookieBannerVersionValue,
        acceptedCookies,
      }),
      { expires: CONSENT_COOKIES_EXPIRATION }
    );

    if (acceptedCookies.includes(CONSENT_COOKIES_CATEGORIES.marketing)) {
      facebookPixelGrantConsent();
    } else {
      Cookies.expire('fb_campaign_id');
    }

    if (!acceptedCookies.includes(CONSENT_COOKIES_CATEGORIES.functionality)) {
      Cookies.expire('py');
      Cookies.expire('smule_autoplay');
      window.localStorage.removeItem('searchHistory');
    }

    document.body.style.overflow = '';
    setIsVisible(false);
  };

  const handleConsentClick = (consentType) => {
    const acceptedCookiesList: Array<string> = [];
    if (marketingCookiesConsent) {
      acceptedCookiesList.push(CONSENT_COOKIES_CATEGORIES.marketing);
    }
    if (functionalityCookiesConsent) {
      acceptedCookiesList.push(CONSENT_COOKIES_CATEGORIES.functionality);
    }

    setGDPRCookie(COOKIES_BANNER_VERSIONS.gdpr, acceptedCookiesList);
    const acceptedCookiesString = acceptedCookiesList.join(',');

    setIsModalOpen(false);

    emitEvent('cookies_consent_clk', {
      target: consentType,
      k1: acceptedCookiesString,
    });

    clearInterval(intervalID);
  };

  useEffect(() => {
    if (shouldOpen) {
      setIsVisible(true);
      resetShouldOpenFlag();
    }
  }, [shouldOpen]);

  useEffect(() => {
    if (hasGPC) {
      setGDPRCookie(COOKIES_BANNER_VERSIONS.gdpr, []);
    }
    if (cookieBannerVersionSetting !== null) {
      if (getBannerVisibility()) {
        setIsVisible(true);
        const { pathname } = window.location;
        if (
          !pathname.endsWith('/privacy') &&
          !pathname.endsWith('/cookiepolicy')
        ) {
          document.body.style.overflow = 'hidden';
        }

        // Pause Now Playing video when GDPR appears
        intervalID = setInterval(() => {
          const videos = document.querySelectorAll('video');

          if (videos.length) {
            videos.forEach((video) => {
              if (!Array.from(video.classList).find((s) => s === 'play-always'))
                video.pause();
            });
          }

          intervalIDCount -= 1;

          if (intervalIDCount < 1) {
            clearInterval(intervalID);
          }
        }, 1000);
      }

      // Set gdprConsentCookie in the cases when the user shouldn't see the GDPR banner
      if (
        !gdprConsentCookie &&
        cookieBannerVersionSetting !== COOKIES_BANNER_VERSIONS.gdpr
      ) {
        setGDPRCookie(
          COOKIES_BANNER_VERSIONS.default,
          Object.values(CONSENT_COOKIES_CATEGORIES)
        );
      }
      if (
        gdprConsentCookie &&
        gdprConsentCookie.cookieBannerVersionValue ===
          COOKIES_BANNER_VERSIONS.gdpr &&
        cookieBannerVersionSetting !== COOKIES_BANNER_VERSIONS.gdpr
      ) {
        setGDPRCookie(
          COOKIES_BANNER_VERSIONS.default,
          Object.values(CONSENT_COOKIES_CATEGORIES)
        );
      }
    }
  }, [isInIframe, cookieBannerVersionSetting, hasGPC]);

  const openModal = () => {
    setMarketingCookiesConsent(false);
    setFunctionalityCookiesConsent(false);
    setIsModalOpen(true);
  };

  const close = () => {
    if (isVendorsOpen) {
      setIsVendorsOpen(false);
    } else {
      setIsModalOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (modalOverlayRef.current === event.target) {
      close();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Overlay>
      <Banner>
        <Grid
          gutter={{
            mobile: { h: 8, v: 0, o: 16 },
            tablet: { h: 16, v: 0, o: 24 },
            desktop: { h: 16, v: 0, o: 24 },
          }}
        >
          <GridBox mobile={12}>
            <Header />
            <Text />
          </GridBox>
          <GridBox mobile={12} tablet={5} desktop={3}>
            <Button
              contained
              onClick={() => handleConsentClick(CONSENT_TYPE.all)}
            >
              Accept Cookies
            </Button>
          </GridBox>
          <GridBox mobile={12} tablet={5} desktop={3}>
            <Button onClick={openModal}>Manage Cookies</Button>
          </GridBox>
        </Grid>
      </Banner>

      {isModalOpen && (
        <Overlay ref={modalOverlayRef} onClick={handleClickOutside}>
          <Modal haveBottomButton={!isVendorsOpen}>
            <CloseIconButton onClick={close}>
              <CloseIcon />
            </CloseIconButton>
            {!isVendorsOpen && (
              <ModalInner>
                <Header />
                <Text />
                <SubHeader>Manage Consent Preferences</SubHeader>
                <ToggleAccordionItem
                  label="Strictly Necessary Cookies"
                  description="These cookies are essential for you to browse the website and use its features, such as accessing secure areas of the site. Without these cookies, certain basic functionalities cannot be offered."
                  arrowLabel="Always Active"
                />
                <ToggleAccordionItem
                  label="Statistics Cookies"
                  description={
                    <>
                      We use Google Analytics, which uses cookies, to collect
                      anonymous traffic data to help us analyze how members and
                      visitors access and use Smule, and for other analytics
                      services relating to website activity and internet usage.
                      You can opt-out of this data collection by downloading and{' '}
                      <Link href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">
                        installing a browser add-on
                      </Link>
                      .
                    </>
                  }
                />
                <ToggleAccordionItem
                  label="Functionality Cookies"
                  description="These cookies allow Smule to remember choices you have made in the past ( e.g., your user name, location, contact preferences, or language, volume or autoplay settings) in order to offer you improved personalized functions."
                  isToggled={functionalityCookiesConsent}
                  onToggle={() => {
                    setFunctionalityCookiesConsent(
                      !functionalityCookiesConsent
                    );
                  }}
                />
                <ToggleAccordionItem
                  label="Marketing Cookies"
                  description="These cookies track your online activity to help advertisers deliver more relevant advertising, limit how many times you see an ad, and measure the efficiency of an advertising campaign.  Such information may be shared with other organizations or advertisers."
                  isToggled={marketingCookiesConsent}
                  onToggle={() => {
                    setMarketingCookiesConsent(!marketingCookiesConsent);
                  }}
                />
                <VendorsButton onClick={() => setIsVendorsOpen(true)}>
                  List of Vendors
                </VendorsButton>

                <ConfirmButtonWrapper>
                  <Button
                    contained
                    wide
                    onClick={() => handleConsentClick(CONSENT_TYPE.custom)}
                  >
                    Confirm My Choices
                  </Button>
                </ConfirmButtonWrapper>
              </ModalInner>
            )}
            {isVendorsOpen && (
              <ModalInner>
                <HeaderStyled>Vendors List</HeaderStyled>
                <TextStyled>
                  You can find below our current list of partners. As with any
                  business, we may change or work with different partners from
                  time to time. If this occurs, we may provide an updated list
                  of partners in the{' '}
                  <Link href="/privacy">
                    <span>Privacy Policy</span>
                  </Link>{' '}
                  or in another document which we will make available to you.
                </TextStyled>
                <VendorItem
                  label="MoPub"
                  firstLink="https://www.mopub.com/legal/privacy"
                  firstLinkLabel="Privacy Policy"
                  secondLink="https://www.mopub.com/optout"
                  secondLinkLabel="Opt-out"
                />
                <VendorItem
                  label="InMobi"
                  firstLink="https://www.inmobi.com/privacy-policy"
                  firstLinkLabel="Privacy Policy"
                  secondLink="https://www.inmobi.com/page/opt-out/"
                  secondLinkLabel="Opt-out"
                />
                <VendorItem
                  label="AdColony"
                  firstLink="https://www.adcolony.com/privacy-policy"
                  firstLinkLabel="Privacy Policy"
                  secondLink="https://www.adcolony.com/choices"
                  secondLinkLabel="Opt-out"
                />
                <VendorItem
                  label="AppLovin"
                  firstLink="https://www.applovin.com/privacy"
                  firstLinkLabel="Privacy Policy"
                  secondLink="https://www.applovin.com/optout"
                  secondLinkLabel="Opt-out"
                />
                <VendorItem
                  label="Stripe"
                  firstLink="https://stripe.com/cookies-policy/legal"
                  firstLinkLabel="Privacy Policy"
                  secondLink="https://stripe.com/cookies-policy/legal"
                  secondLinkLabel="Opt-out"
                />
                <VendorItem
                  label="Facebook"
                  firstLink="https://www.facebook.com/policy.php"
                  firstLinkLabel="Data Policy"
                  secondLink="https://www.facebook.com/policies/cookies/"
                  secondLinkLabel="Cookie Policy"
                />
                <VendorItem
                  label="Google"
                  firstLink="https://www.google.com/policies/technologies/partner-sites/"
                  firstLinkLabel="Opt-out"
                />
                <VendorItem
                  label="Fyber"
                  firstLink="https://www.fyber.com/legal/privacy-policy/"
                  firstLinkLabel="Privacy Policy"
                  secondLink="https://www.fyber.com/cookies-policy/"
                  secondLinkLabel="Opt-out"
                />
                <VendorItem
                  label="Tapjoy"
                  firstLink="https://www.tapjoy.com/legal/#privacy-policy"
                  firstLinkLabel="Privacy Policy"
                />
                <VendorItem
                  label="Adjust"
                  firstLink="https://www.adjust.com/terms/privacy-policy/"
                  firstLinkLabel="Privacy Policy"
                />
                <VendorItem
                  label="Apple"
                  firstLink="https://www.apple.com/legal/privacy/en-ww/"
                  firstLinkLabel="Privacy Policy"
                />
                <VendorItem
                  label="Ninox"
                  firstLink="https://ninox.com/en/privacy"
                  firstLinkLabel="Privacy Policy"
                />
                <VendorItem
                  label="SocketLabs"
                  firstLink="https://www.socketlabs.com/legal/privacy/"
                  firstLinkLabel="Privacy Policy"
                />
              </ModalInner>
            )}
          </Modal>
        </Overlay>
      )}
    </Overlay>
  );
};

const mapStateToProps: MapStateToProps<GDPRStateProps, {}, State> = (
  state
) => ({
  shouldOpen: GDPRSelectors.getShouldOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetShouldOpenFlag: () => dispatch(GDPRActions.resetShouldOpenFlag()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GDPR);
