/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { MessageType } from '@app/constants/snpResponseCodes';
import { VerifiedType } from '@app/WebConfig/types';

export enum FlowTypes {
  PERF = 'PERF',
  ACCT = 'ACCT',
}

export interface Flow {
  flow: FlowTypes | null;
}

export interface GiftingState {
  flow: null | FlowTypes;
  isCatalogLoading: boolean;
  isGiftLottieLoading: boolean;
  isAllGiversLoading: boolean;
  isTopGiversLoading: boolean;
  isLoading: boolean;
  isGiving: boolean;
  isGivenGiftAnimationShown: boolean;
  isPerfReceiverListLoading: boolean;
  isWalletCreditLoading: boolean;
  walletCredit?: number;
  transactions: {
    cursor: Cursor;
    list: Array<Transaction>;
  };
  giverList: Array<TopGiverResponse> | null;
  perfReceiverList: Array<Receiver> | null;
  catalog: Array<GiftCatalogItem>;
  currentCatalogPage?: number;
  selectedGift?: GiftCatalogItem;
  sentGiftLottieData?: {
    type: 'LOTTIE' | 'BITMAP';
    data: string;
  };
  sentGiftTimer?: number;
  performanceGiftCount?: number;
  lastTransactionId?: number;
  freeGiftLimit: number;
  error: MessageType | string | null;
}

export interface GiftResource {
  contentType: string;
  id: number;
  resourceId: number;
  type: string;
  url: string;
}

export interface GiftIcon {
  id: number;
  name: string;
  type: string;
  animation: GiftAnimation;
}

export interface GiftAnimation {
  autoPlay: boolean;
  autoReverse: boolean;
  id: number;
  maxAutoPlayDelay: number;
  minAutoPlayDelay: number;
  repeatCount: number;
  speed: number;
  type: string;
  resources: Array<GiftResource>;
}

export interface Gift {
  giftIcon: GiftIcon;
  count: number;
}

export interface GiftCatalogItem extends GiftIcon {
  credit: number;
  allowNote: boolean;
  tag: {
    id: number;
    name: string;
    color: number;
  };
  lottieData?: string;
}

export interface Transaction {
  gift: GiftIcon;
  giverInfo: AccountInfo;
  note: string;
  objectType: null;
  performance: null;
  time: number;
}

export interface AccountInfo {
  accountId: number;
  handle: string;
  jid: string;
  picUrl: string;
  subApps: Array<string>;
  verifiedType: VerifiedType;
}

export interface TopGiverResponse {
  giverInfo: AccountInfo;
  gifts: Array<Gift>;

  totalCount: number;
}

export interface Receiver {
  account: AccountInfo;
}

export interface AccountPerformancePayload {
  accountId?: string;
  performanceKey?: string;
}

export interface CursorAndLimit {
  cursor?: string;
  limit?: number;
}

export interface Cursor {
  next: string;
  hasNext: boolean;
}

export interface Fail {
  error: string;
}

export interface GivingGiftResponse {
  transactionId: number;
  creditBalance: number;
  giftCnt: number;
}

export type TransactionPayload = AccountPerformancePayload & CursorAndLimit;
