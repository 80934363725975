/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Immutable, { ImmutableObject } from 'seamless-immutable';
import { createSelector } from 'reselect';
import { State } from '@app/reducer';

const OPEN_GDPR_BANNER = 'GDPR/OPEN_GDPR_BANNER';
const RESET_SHOULD_OPEN_FLAG = 'GDPR/RESET_SHOULD_OPEN_FLAG';

export interface GDPRReducerState {
  shouldOpen: boolean;
}

export const Actions = {
  openGDPRBanner: () => ({ type: OPEN_GDPR_BANNER }),
  resetShouldOpenFlag: () => ({ type: RESET_SHOULD_OPEN_FLAG }),
};

const getGDPRState = (state: State): ImmutableObject<GDPRReducerState> =>
  state.gdpr;
const getShouldOpen = createSelector(getGDPRState, (state) => state.shouldOpen);

export const Selectors = {
  getShouldOpen,
};

const gdprState: ImmutableObject<GDPRReducerState> = Immutable({
  shouldOpen: false,
});

export const gdprReducer = (state = gdprState, action: any) => {
  switch (action.type) {
    case OPEN_GDPR_BANNER:
      return state.set('shouldOpen', true);
    case RESET_SHOULD_OPEN_FLAG:
      return state.set('shouldOpen', false);
    default:
      return state;
  }
};
