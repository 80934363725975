/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import theme from '@app/theme/theme';

declare global {
  interface Window {
    MSStream: any;
  }
}

export enum OSTypes {
  ANDROID = 'android',
  IOS = 'iOS',
}

const iosPattern = new RegExp('(iPad|iPhone|iPod)', 'i');
const androidPattern = new RegExp('android|\\s+adr\\s+\\d+', 'i');
// per https://stackoverflow.com/questions/7944460/detect-safari-browser
const safariPattern = new RegExp('^((?!chrome|android).)*safari', 'i');
const operaPattern = new RegExp('OPR|Opera');
// https://stackoverflow.com/questions/7000190/detect-all-firefox-versions-in-js
const firefoxPattern = new RegExp('Firefox', 'i');

let device: OSTypes | string;
export default class Device {
  static deviceType() {
    if (device || device === '') return device;

    device = iosPattern.test(window.navigator.userAgent)
      ? OSTypes.IOS
      : androidPattern.test(window.navigator.userAgent)
      ? OSTypes.ANDROID
      : '';

    return device;
  }
  static isIOS() {
    return iosPattern.test(window.navigator.userAgent) && !window.MSStream;
  }
  static isAndroid() {
    return androidPattern.test(window.navigator.userAgent);
  }
  static isSafari() {
    return safariPattern.test(window.navigator.userAgent);
  }
  static isFirefox() {
    return firefoxPattern.test(window.navigator.userAgent);
  }
  static isChrome() {
    return !!window['chrome'];
  }
  static isMobileDevice() {
    return Device.deviceType() !== '';
  }
  static isTabletDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const pattern =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return pattern.test(userAgent);
  }
  static isOpera() {
    return operaPattern.test(window.navigator.userAgent);
  }

  static isHls() {
    return Device.isIOS() || Device.isSafari();
  }
  static isMobileSize() {
    try {
      let content = window
        .getComputedStyle(document.body, ':after')
        .getPropertyValue('content');
      if (typeof content === 'string') {
        content = content.replace(/["']/g, ''); // firefox returns the value with quotes
      }
      return content === 'mobile';
    } catch (e) {
      return false;
    }
  }

  static hasShareSupport() {
    return typeof window.navigator['share'] === 'function';
  }
}

export const isDesktop = window.matchMedia(
  `(min-width: ${theme.breakpoints.desktop}px)`
).matches;
